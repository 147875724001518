<template>
  <el-drawer
    :close-on-click-modal="false"
    size="75%"
    :visible.sync="showDrawerBox"
    :with-header="false"
  >
    <div class="library">
      <div class="top">浩途智库</div>
      <div class="desc">
        平台智库可能涉及知识产权问题，可供上架的资源均已获取授权，不能上架资源存在付费资源，请联系我们获取权限。
      </div>
      <div class="tabs">
        <div
          v-for="(item, i) in cateList"
          :key="i"
          class="tab-item"
          :class="queryForm.cate === item.id ? 'active' : ''"
          @click="bindTab(item.id)"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="box">
        <el-empty v-if="noData" description="暂无数据" />
        <div v-else v-loading="listLoading" class="list" @scroll="handleScroll">
          <div
            v-for="(item, li) in list"
            :key="li"
            class="item"
            :class="item.status == 0 ? 'lock' : 'auth'"
          >
            <div class="img">
              <el-image fit="fill" :src="item.cover" />
            </div>
            <div class="info">
              <div class="i-top ellipsis">{{ item.title }}</div>
              <div class="i-desc">
                {{ item.introduce }}
              </div>
              <div class="i-bottom">
                <div class="left">
                  <el-tag>{{ '#' + item.cate_name }}</el-tag>
                </div>
                <div class="right">
                  <el-button
                    v-if="item.status == 1"
                    type="primary"
                    @click="chooseLibrary(item)"
                  >
                    立即上架
                  </el-button>
                  <el-button
                    v-if="item.status == 2"
                    icon="el-icon-success"
                    size="medium"
                    style="font-weight: bold"
                    type="text"
                  >
                    已发布
                  </el-button>
                  <el-button v-if="item.status == 0" class="unauth" type="info">
                    未授权
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
  import { getCateList, getListBy, upLibrary } from '@/api/resource'

  export default {
    name: 'LibraryEdit',
    data() {
      return {
        listLoading: false,
        cateList: [],
        list: [],
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          type: '1', //文章：0  课程：1
          cate: '',
        },
        total: 0,
        showDrawerBox: false,
        noData: false,
      }
    },
    methods: {
      handleScroll(event) {
        const { scrollTop, scrollHeight, clientHeight } = event.target
        // 检查是否滚动到底部
        if (scrollTop + clientHeight >= scrollHeight - 5) {
          // 留出5px的缓冲区
          this.loadMore()
        }
      },
      chooseLibrary(item) {
        upLibrary({ id: item.id }).then((res) => {
          this.$baseMessage(res.msg, 'success')
          this.$set(item, 'status', 2)
          this.$emit('fetch-data')
        })
      },
      async getCate() {
        const { all } = await getCateList()
        this.cateList = all
      },
      showLibrary(type) {
        this.list = []
        this.queryForm.pageNo = 1
        this.queryForm.type = type === 'article' ? '0' : '1'
        this.getCate()
        this.fetchData()
        this.showDrawerBox = true
      },
      bindTab(id) {
        this.list = []
        this.queryForm.cate = id
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      loadMore() {
        if (this.list.length >= this.total) {
          return false
        } else {
          this.queryForm.pageNo++
          this.fetchData()
        }
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount } = await getListBy(this.queryForm)
        this.list = [...this.list, ...data]

        this.total = totalCount
        this.listLoading = false
        if (this.list.length === 0) {
          this.noData = true
        } else {
          this.noData = false
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .library {
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    .top {
      position: relative;
      padding-left: 50px;
      line-height: 40px;
      padding-bottom: 10px;
      margin-bottom: 15px;
      border-bottom: 1px solid #eee;
      color: rgb(83, 85, 87);
      font-style: italic;
      font-weight: bold;
      font-size: 16px;
      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        bottom: 0;
        width: 40px;
        height: 40px;
        background: url('~@/assets/resource.svg') no-repeat;
        background-size: 100%;
      }
    }
    .desc {
      position: relative;
      border-radius: 8px;
      background: rgba(38, 205, 183, 0.05);
      color: rgba(109, 114, 120, 0.88);
      font-size: 13px;
      line-height: 20px;
      padding: 5px 15px 5px 35px;
      margin-bottom: 25px;
      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 10px;
        bottom: 0;
        width: 20px;
        height: 20px;
        margin: auto;
        background: url('~@/assets/res_sm.svg') no-repeat;
        background-size: 100%;
      }
    }
    .tabs {
      display: flex;
      align-items: center;
      background-color: #f9f9f9;
      padding: 15px 20px 0;
      .tab-item {
        padding: 10px 20px;
        cursor: pointer;
        &.active {
          background: #fff;
          mask: url('~@/assets/tabs_images/vab-tab.png');
          mask-size: 100% 100%;
          font-weight: bold;
          color: #26cdb7;
        }
      }
    }
    .box {
      flex: 1;
    }
    .list {
      /*display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;*/
      margin-left: -40px;
      margin-right: -40px;
      padding: 20px 25px;
      height: 600px;
      overflow-y: auto;
      .item {
        position: relative;
        float: left;
        display: flex;
        width: 510px;
        height: 160px;
        margin-top: 25px;
        border-radius: 7px;
        box-shadow: 0 0 26px 10px rgba(0, 0, 0, 0.04);
        background: #fff;
        padding: 20px;
        margin-left: 15px;
        margin-right: 15px;
        &:before {
          position: absolute;
          content: '';
          top: 15px;
          right: 15px;
          width: 28px;
          height: 28px;
          background-repeat: no-repeat;
          background-size: 100%;
        }
        &.auth:before {
          background-image: url('~@/assets/auth.svg');
        }
        &.lock:before {
          background-image: url('~@/assets/lock.svg');
        }
        .img {
          width: 160px;
          height: 120px;
          margin-right: 15px;
          .el-image {
            width: 100%;
            height: 100%;
            border-radius: 6px;
          }
        }
        .info {
          width: 0;
          flex: 1;
          .i-top {
            font-size: 16px;
            font-weight: bold;
            padding-right: 50px;
          }
          .i-desc {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            color: rgba(1, 1, 1, 0.36);
            font-size: 12px;
            margin: 15px 0;
            line-height: 1.6;
            height: 40px;
          }
          .i-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .unauth {
              color: #e02020;
              background: #f4f4f5;
              border: 0;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
</style>
