export const myComponents = [
  {
    label: '单行文本',
    value: 0,
    tag: 'input',
    type: '',
    maxlength: -1,
  },
  {
    label: '多行文本',
    value: 1,
    tag: 'textarea',
    maxlength: -1,
  },
  {
    label: '单选框',
    value: 2,
    tag: 'radio',
    options: [],
  },
  {
    label: '多选框',
    value: 3,
    tag: 'checkbox',
    options: [],
  },
  {
    label: '下拉框',
    value: 4,
    tag: 'select',
    options: [],
  },
  {
    label: '日期选择',
    value: 5,
    tag: 'picker-date',
  },
  {
    label: '省市区选择',
    value: 6,
    tag: 'picker-area',
  },
]
