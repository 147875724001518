<template>
  <div>
    <el-form ref="form" label-width="80px" :model="form">
      <el-form-item label="联系我们">
        <el-radio-group v-model="form.type">
          <el-radio label="tel">联系电话</el-radio>
          <el-radio label="link">客服链接</el-radio>
          <el-radio label="wechat">二维码</el-radio>
        </el-radio-group>
        <div v-if="form.type" style="margin-top: 15px">
          <el-input v-if="form.type === 'tel'" placeholder="请输入联系电话" />
          <el-input v-if="form.type === 'link'" placeholder="请输入客服链接" />
          <div v-if="form.type === 'wechat'" class="img-uploader">
            <div class="el-upload el-upload__text" @click="imgDialog">
              <img v-if="form.wechat" class="img" :src="form.wechat" />
              <i v-else class="el-icon-plus"></i>
            </div>
            <div slot="tip" class="el-upload__tip">
              <div>
                只能上传
                <span>.jpg、.png</span>
                文件，且
                <span>不超过1M</span>
              </div>
              <div>(图片尺寸1:1)</div>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="关于我们">
        <editor
          v-model="form.about"
          :is-clear="isClear"
          @change="editorChange"
        />
      </el-form-item>
      <el-form-item label="用户协议">
        <editor v-model="form.exp" :is-clear="isClear" @change="editorChange" />
      </el-form-item>
      <el-form-item label="意见反馈">
        <el-input placeholder="请输入链接" />
        <div class="txt-tips">请输入反馈跳转链接，推荐使用问卷星</div>
      </el-form-item>
      <el-form-item>
        <el-button size="medium" type="primary" @click="save">保存</el-button>
      </el-form-item>
    </el-form>
    <gallery ref="gallery" @checkedImg="getImg" />
  </div>
</template>

<script>
  import Editor from '@/components/editor'
  import Gallery from '@/components/gallery'
  export default {
    name: 'OtherSetting',
    components: { Editor, Gallery },
    data() {
      return {
        form: {
          type: '',
          wechat: '',
        },
        isClear: false,
      }
    },
    methods: {
      editorChange(val) {},
      imgDialog() {
        this.$refs['gallery'].showEdit()
      },
      getImg(val) {
        this.form.wechat = val.url
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let p = this.form
            console.log(p)
          }
        })
      },
    },
  }
</script>

<style scoped></style>
