<template>
  <div class="customWarning-container">
    <div style="margin-bottom: 20px; text-align: right">
      <el-button icon="el-icon-plus" type="primary" @click="handleEdit()">
        新增
      </el-button>
    </div>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column align="center" label="方案标题" prop="title" />
      <el-table-column align="center" label="最后更新时间" prop="update_at" />
      <el-table-column align="center" label="创建人" prop="cname" />
      <el-table-column align="center" label="备注" prop="remark" />
      <el-table-column align="center" label="操作" prop="title" width="180px">
        <template #default="{ row }">
          <el-button
            :disabled="row.default === '10'"
            type="text"
            @click="handleEdit(row, 'default')"
          >
            设为默认
          </el-button>
          <el-button type="text" @click="handleEdit(row, 'copy')">
            复制
          </el-button>
          <el-button type="text" @click="handleEdit(row, 'edit')">
            编辑
          </el-button>
          <el-button class="del" type="text" @click="handleEdit(row, 'del')">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import { getWarnPlan, doOpration } from '@/api/settingAuthority'

  export default {
    name: 'CustomWarning',
    data() {
      return {
        list: [],
        listLoading: true,
        total: 0,
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleEdit(row, type) {
        if (type === 'edit') {
          this.$router.push({
            path: '/setting/addWarnigPlan',
            query: { id: row.id },
          })
        } else if (type === 'del') {
          if (this.list.length === 1) {
            this.$baseMessage('至少保留一个预警方案', 'error')
            return false
          }
          this.$baseConfirm('确定删除该方案吗？', null, () => {
            doOpration({
              id: row.id,
              type: 'delete',
            }).then(async (res) => {
              this.$baseMessage(res.msg, 'success')
              await this.fetchData()
            })
          })
        } else if (type === 'default' || type === 'copy') {
          doOpration({
            id: row.id,
            type: type,
          }).then(async (res) => {
            this.$baseMessage(res.msg, 'success')
            await this.fetchData()
          })
        } else {
          if (this.list.length === 5) {
            this.$baseMessage('最多添加5个预警方案', 'error')
            return false
          } else {
            this.$router.push('/setting/addWarnigPlan')
          }
        }
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount } = await getWarnPlan()
        this.list = data
        this.total = totalCount
        this.listLoading = false
      },
    },
  }
</script>

<style lang="scss" scoped></style>
