<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="showFreeDrawer"
    width="800px"
    @close="close"
  >
    <div class="free-eidt-container">
      <el-row v-loading="loading" :gutter="15">
        <el-col
          :lg="{ span: 16, offset: 4 }"
          :md="{ span: 20, offset: 2 }"
          :sm="{ span: 20, offset: 2 }"
          :xl="{ span: 12, offset: 6 }"
          :xs="24"
        >
          <el-form ref="form" label-width="100px" :model="form" :rules="rules">
            <el-form-item label="选择量表" prop="paper_id">
              <el-select
                v-model="form.paper_id"
                clearable
                :disabled="form.type === 'edit'"
                filterable
                placeholder="请选择量表"
                @change="bindChange"
              >
                <el-option
                  v-for="item in evalList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="量表标题" prop="name">
              <el-input v-model="form.name" placeholder="请输入量表标题" />
            </el-form-item>
            <el-form-item label="副标题" prop="subhead">
              <el-input
                v-model="form.subhead"
                placeholder="请输入副标题"
                rows="5"
                type="textarea"
              />
            </el-form-item>
            <el-form-item label="分类" prop="cate_id">
              <el-select
                v-model="form.cate_id"
                clearable
                filterable
                placeholder="请选择分类"
              >
                <el-option
                  v-for="item in cateList"
                  :key="item.cate_id"
                  :label="item.name"
                  :value="item.cate_id"
                />
              </el-select>
              <el-button
                icon="el-icon-refresh"
                style="font-size: 15px; padding-left: 10px"
                type="text"
                @click="refreshCate"
              />
              <el-button type="text" @click="addCate">新增</el-button>
            </el-form-item>
            <el-form-item label="封面" prop="cover">
              <div class="img-uploader">
                <div class="el-upload el-upload__text" @click="imgDialog">
                  <img v-if="form.img" class="img" :src="form.img" />
                  <i v-else class="el-icon-plus"></i>
                </div>
                <div slot="tip" class="el-upload__tip">
                  <div>
                    只能上传
                    <span>.jpg、.png</span>
                    文件，且
                    <span>不超过1M</span>
                  </div>
                  <div>(图片尺寸1:1)</div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="价格" prop="price">
              <el-input-number v-model="form.price" :min="0" :precision="2" />
            </el-form-item>
            <el-form-item label="排序" prop="sort">
              <el-input-number
                v-model="form.sort"
                controls-position="right"
                :min="0"
                placeholder="越大越靠前"
              />
            </el-form-item>
            <el-form-item label="虚拟测评次数" prop="num">
              <el-input-number
                v-model="form.num"
                controls-position="right"
                :min="0"
              />
            </el-form-item>
            <el-form-item v-if="false" label="自定义样式" prop="style">
              <el-switch
                v-model="form.style"
                :active-value="20"
                :inactive-value="10"
              />
              <editor
                v-if="form.style === 20"
                v-model="form.content"
                :is-clear="isClear"
                @change="editorChange"
              />
            </el-form-item>
            <el-form-item label="上架" prop="status">
              <el-switch
                v-model="form.status"
                :active-value="10"
                :inactive-value="20"
              />
            </el-form-item>
            <el-form-item label="推荐" prop="rec">
              <el-switch
                v-model="form.rec"
                :active-value="10"
                :inactive-value="20"
              />
            </el-form-item>
            <el-form-item label="开放报告" prop="show_result">
              <el-switch
                v-model="form.show_result"
                :active-value="10"
                :inactive-value="20"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="save('form')">保存</el-button>
            </el-form-item>
          </el-form>
          <gallery ref="gallery" @checkedImg="getImg" />
        </el-col>
      </el-row>
      <cate ref="cate" />
    </div>
  </el-dialog>
</template>

<script>
  import { getEvalListBySid } from '@/api/mmptCategory'
  import Cate from '@/views/order/eval/components/set/cateEdit.vue'
  import Editor from '@/components/editor/index.vue'
  import Gallery from '@/components/gallery/index.vue'
  import { isNumber } from '@/utils/validate'
  import { editPaper, getCateList, getPaperDetail } from '@/api/psyeval'
  export default {
    name: 'PaperEdit',
    components: { Gallery, Editor, Cate },
    data() {
      const validateSort = (rule, value, callback) => {
        if (value !== '' && !isNumber(value)) {
          callback(new Error('请输入正整数'))
        } else {
          callback()
        }
      }
      return {
        showFreeDrawer: false,
        evalList: [],
        cateList: [],
        form: {
          sort: '', //非必填；分类排序；type为edit/add时必填
          name: '', //非必填；标题；type为edit/add时必填
          paper_id: '', //非必填；量表ID；type为edit/add时必填
          subhead: '', //非必填；副标题；type为edit/add时必填
          img: '', //非必填；封面；type为edit/add时必填
          cate_id: '',
          //style: 10, //非必填；详情类型，10默认 20富文本；type为edit/add时必填
          //content: '', //非必填；详情富文本，当style=20时必填；type为edit/add时必填
          status: 10, //非必填；状态，上架10下架20；
          num: '',
          price: '',
          rec: 20,
          show_result: 20,
        },
        isClear: false, //设置为true的时候，这个可以用this.form.content=''来替代
        rules: {
          name: [
            { required: true, trigger: 'blur', message: '请输入量表标题' },
          ],
          paper_id: [
            { required: true, trigger: 'change', message: '请选择量表' },
          ],
          cate_id: [
            { required: true, trigger: 'change', message: '请选择分类' },
          ],
          //sort: [{ validator: validateSort, trigger: 'blur' }],
        },
        title: '',
        hideUpload: false,
        hasImg: '',
        loading: true,
      }
    },
    methods: {
      bindChange(e) {
        const cur = this.evalList.filter((item) => e === item.id)
        this.form.name = cur[0].name
        this.form.subhead = cur[0].intro
      },
      getPath(row) {
        this.getEvalList()
        this.refreshCate()
        this.$nextTick(() => {
          if (row) {
            let rid = row.id
            this.title = '编辑量表'
            getPaperDetail({ id: rid }).then((res) => {
              let p = res.data
              this.form = {
                id: p.id,
                type: 'edit',
                sort: p.sort,
                name: p.name,
                paper_id: p.paper_id + '',
                subhead: p.subhead,
                img: p.img,
                cate_id: p.cate_id + '',
                /*style: p.style * 1,
                content: p.content,*/
                status: p.status * 1,
                num: p.num,
                price: p.price,
                rec: p.rec,
                show_result: p.show_result,
              }
            })
          } else {
            this.form.type = 'add'
            this.title = '新增量表'
          }
          this.loading = false
        })
        this.showFreeDrawer = true
      },
      imgDialog() {
        this.$refs['gallery'].showEdit()
      },
      getImg(val) {
        this.form.img = val.url
      },
      refreshCate() {
        getCateList().then((res) => {
          this.cateList = res.data
        })
      },
      getEvalList() {
        getEvalListBySid().then((res) => {
          this.evalList = res.data
        })
      },
      async addCate() {
        this.$refs['cate'].showEdit()
      },
      editorChange(val) {
        console.log(val)
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.showFreeDrawer = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let p = this.form
            if (p.style === 20) {
              if (!p.content) {
                this.$baseMessage('请输入自定义详情', 'error')
                return false
              }
            }
            p.id = p.id || 0
            p.sort = p.sort === '' ? 0 : p.sort
            const { msg, code } = await editPaper(p)
            this.$baseMessage(msg, code === 200 ? 'success' : 'error')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss"></style>
