<template>
  <div class="libraryks-container">
    <el-empty description=" " :image="img" style="margin-top: 100px" />
  </div>
</template>

<script>
  export default {
    name: 'Libraryks',
    data() {
      return {
        img: require('@/assets/seesoon.svg'),
      }
    },
  }
</script>

<style scoped lang="scss"></style>
