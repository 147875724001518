<template>
  <div class="templates-container">
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column align="center" type="index" />
      <el-table-column label="模板标题" prop="title" />
      <el-table-column label="信息格式" prop="content">
        <template #default="{ row }">
          <div style="white-space: pre-wrap" v-html="row.content"></div>
        </template>
      </el-table-column>
      <el-table-column label="示例" prop="example">
        <template #default="{ row }">
          <div style="white-space: pre-wrap" v-html="row.example"></div>
        </template>
      </el-table-column>
      <el-table-column v-if="false" align="center" label="操作">
        <template #default="{ row }">
          <el-button type="text" @click="createMsg(row)">创建</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="false"
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getTeamplate } from '@/api/tencent'

  export default {
    name: 'Templates',
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          pageNo: 1,
          pageSize: 10000,
        },
        button: {},
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      createMsg(row) {},
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getTeamplate({
          type: 'list',
        })
        this.list = data.template_list
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>

<style scoped lang="scss"></style>
