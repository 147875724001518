<template>
  <div class="set-pc">
    <div class="block">
      <div class="block-top">
        <span class="name">顶部导航</span>
        <el-button
          :disabled="!checkAccess({ permission: ['editNav'] })"
          type="text"
          @click="edit(null, 1, 1)"
        >
          点击添加
        </el-button>
        <div v-if="false" class="flex1">
          <span>鼠标悬停查看效果</span>
        </div>
      </div>
      <el-table
        ref="refTable"
        :data="navList"
        default-expand-all
        row-key="id"
        :tree-props="{ children: 'children' }"
        @row-click="clickRow"
      >
        <el-table-column label="菜单栏标题" prop="title" />
        <el-table-column label="显示" prop="show">
          <template #default="{ row }">
            <span @click.stop="">
              <el-switch
                :active-value="10"
                :inactive-value="20"
                :value="row.show"
                @change="setStatus(row)"
              />
            </span>
          </template>
        </el-table-column>
        <el-table-column label="更新时间" prop="update_at" />
        <el-table-column align="right" label="操作" width="180">
          <template #default="{ row }">
            <template v-if="row.rank == 1">
              <el-button
                :disabled="!checkAccess({ permission: ['editNav'] })"
                type="text"
                @click.stop="edit(row, 2, 'add')"
              >
                新增下级
              </el-button>
            </template>
            <el-button
              v-if="checkAccess({ permission: ['editNav'] })"
              type="text"
              @click.stop="edit(row, 2)"
            >
              编辑
            </el-button>
            <el-button
              v-else
              disabled
              title="暂无权限，如有需要，请联系管理员"
              type="text"
            >
              编辑
            </el-button>
            <el-button
              v-if="checkAccess({ permission: ['delNav'] })"
              type="text"
              @click.stop="del(row)"
            >
              删除
            </el-button>
            <el-button
              v-else
              disabled
              title="暂无权限，如有需要，请联系管理员"
              type="text"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <div class="block-top">
        <span class="name">底部固定栏</span>
      </div>
      <div class="bottom-bg">
        <div class="bg" @click="imgDialog">
          <el-image v-if="bgCover" fit="cover" :src="bgCover" />
          <div v-else class="choose-img">
            <el-button type="text">点击上传图片</el-button>
            <div class="img-tip">推荐尺寸：1920*300</div>
          </div>
        </div>
        <el-button type="primary" @click="editBg">保存图片</el-button>
      </div>
    </div>
    <p-index ref="p-index" @refresh="getMenu" />
    <gallery ref="gallery" @checkedImg="getImg" />
  </div>
</template>

<script>
  import Gallery from '@/components/gallery'
  import PIndex from '@/views/setting/components/p-index'
  import {
    editPCNav,
    getPCMenu,
    getPCBottomBg,
    editPCBottomBg,
  } from '@/api/idx'

  export default {
    name: 'Pc',
    components: { Gallery, PIndex },
    data() {
      return {
        navList: [],
        bgCover: '',
      }
    },
    created() {
      this.getMenu()
      this.getBg()
    },
    methods: {
      imgDialog() {
        this.$refs['gallery'].showEdit()
      },
      getImg(val) {
        this.bgCover = val.url
      },
      getBg() {
        getPCBottomBg().then((res) => {
          this.bgCover = res.data.cover
        })
      },
      editBg() {
        editPCBottomBg({
          style: 'bottom',
          cover: this.bgCover,
        }).then((res) => {
          if (res.code === 200) {
            this.$baseMessage(res.msg, 'success')
          }
        })
      },
      getMenu() {
        //获取菜单
        getPCMenu().then((res) => {
          this.navList = res.data
        })
      },
      clickRow(row, index, e) {
        //表格树结构点击展开
        this.$refs.refTable.toggleRowExpansion(row)
      },
      edit(row, rank, add) {
        this.$refs['p-index'].showEdit(row, rank, add)
      },
      del(row) {
        let txt = '你确定要删除当前【' + row.title + '】项吗'
        if (row.rank === 1)
          txt = '确定要删除当前菜单【' + row.title + '】及其子菜单吗'
        this.$baseConfirm(txt, null, async () => {
          editPCNav({ id: row.id, style: 'delete' }).then((res) => {
            if (res.code === 200) {
              this.getPMenu()
              this.$baseMessage(res.msg, 'success')
            }
          })
        })
      },
      setStatus(row) {
        //个人中心菜单设置显示隐藏
        let txt = row.show === 10 ? '隐藏' : '显示'
        let txt2 = row.rank === 1 ? '及其子菜单' : ''
        this.$baseConfirm(
          '确定' + txt + '当前菜单【' + row.title + '】' + txt2 + '吗',
          null,
          async () => {
            editPCNav({ id: row.id, style: 'show' }).then((res) => {
              if (res.code === 200) {
                this.getMenu()
                this.$baseMessage(res.msg, 'success')
              }
            })
          }
        )
      },
    },
  }
</script>

<style lang="scss" scoped>
  .bottom-bg {
    .bg {
      margin: 20px 0;
      height: 300px;
      background: #f2f2f2;
      border: 1px dashed #999;
      text-align: center;
      overflow: hidden;
      :deep(.el-image) {
        width: 100%;
        height: 100%;
      }
      .choose-img {
        margin: 100px 0;
        .img-tip {
          font-size: 12px;
          color: #999;
          margin-top: 30px;
        }
      }
    }
  }
</style>
