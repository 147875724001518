<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="form.typeName"
    :visible.sync="dialogFormVisible"
    width="700px"
    @close="close"
  >
    <el-form ref="form" class="form" label-width="80px" :model="form">
      <el-form-item
        label="题目"
        prop="title"
        :rules="{
          required: true,
          message: '题目不能为空',
          trigger: 'blur',
        }"
      >
        <el-input
          v-model="form.title"
          clearable
          :placeholder="!form.title ? num + 1 + '.题目' : form.title"
        />
      </el-form-item>
      <el-form-item label="必填" prop="required">
        <el-switch v-model="form.required" />
      </el-form-item>
      <el-form-item
        label="提示"
        prop="tips"
        :rules="{
          required: form.hasTips ? true : false,
          message: '提示内容',
          trigger: 'blur',
        }"
      >
        <el-switch v-model="form.hasTips" />
        <div v-if="form.hasTips">
          <el-input
            v-model="form.tips"
            class="ipt"
            clearable
            placeholder="提示内容"
          />
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">完成编辑</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: 'Write',
    data() {
      return {
        form: {},
        num: 0,
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit(row, index) {
        this.form = JSON.parse(JSON.stringify(row))
        this.num = index
        if (this.form.title === '.题目') {
          this.form.title = ''
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let data = {
              index: this.num,
              data: this.form,
            }
            this.$emit('update-item', data)
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-dialog {
      &__footer {
        padding-top: 0;
      }
    }
  }
  .form {
    .option-item {
      display: flex;
      align-items: center;
    }

    .ipt {
      width: 80%;
      margin-right: 15px;
    }
  }
</style>
