var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "resouce-container" },
    [
      _c("div", { staticClass: "top" }, [_vm._v("浩途智库")]),
      _c("div", { staticClass: "desc" }, [
        _vm._v(
          " 平台智库可能涉及知识产权问题，可供上架的资源均已获取授权，不能上架资源存在付费资源，请联系我们获取权限。 "
        ),
      ]),
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "视频课程", name: "video" } },
            [
              _vm.studioList.length
                ? _c("Video", { attrs: { studio: _vm.studioList } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "图文资讯", name: "article" } },
            [
              _vm.studioList.length
                ? _c("Article", { attrs: { studio: _vm.studioList } })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }