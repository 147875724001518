<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="字段类型" prop="tag">
        <el-select
          v-model="form.tag"
          clearable
          :disabled="title === '编辑'"
          filterable
          placeholder="请选择字段类型"
          @change="bindCheck"
        >
          <el-option
            v-for="item in zdType"
            :key="item.tag"
            :label="item.label"
            :value="item.tag"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="字段标题" prop="label">
        <el-input v-model="form.label" placeholder="请输入字段标题" />
      </el-form-item>
      <!--<el-form-item label="字段名称" prop="name">
        <el-input
          v-model="form.name"
          placeholder="请输入小写的英文或字母，如：title、username..."
        />
      </el-form-item>-->
      <el-form-item label="提示语" prop="placeholder">
        <el-input v-model="form.placeholder" placeholder="请输入提示语" />
      </el-form-item>
      <el-form-item v-if="form.tag === 'input'" label="文本类型" prop="type">
        <el-radio-group
          v-model="form.type"
          style="line-height: 26px"
          @change="rdoChange"
        >
          <el-radio label="text">文本</el-radio>
          <el-radio label="number">数字</el-radio>
          <el-radio label="digit">浮点型</el-radio>
          <el-radio label="tel">手机号</el-radio>
          <el-radio label="idcard">身份证</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="form.tag && (form.tag === 'input' || form.tag === 'textarea')"
        label="文本长度"
        prop="maxlength"
      >
        <el-input
          v-model="form.maxlength"
          :disabled="form.type === 'tel' || form.type === 'idcard'"
          placeholder="请输入文本最多可输入字符长度"
        />
      </el-form-item>
      <div
        v-show="
          form.tag &&
          (form.tag === 'radio' ||
            form.tag === 'checkbox' ||
            form.tag === 'select')
        "
      >
        <el-form-item
          v-for="(option, index) in form.options"
          :key="option.key"
          :label="'选项' + (index + 1)"
          :prop="'options.' + index + '.value'"
          :rules="{
            required:
              form.tag === 'radio' ||
              form.tag === 'checkbox' ||
              form.tag === 'select'
                ? true
                : false,
            message: `${'选项' + (index + 1) + '不能为空'}`,
            trigger: 'blur',
          }"
        >
          <div class="option-item">
            <el-input v-model="option.value" />
            <span
              v-if="form.options.length > 1"
              class="el-icon-remove-outline"
              @click.prevent="removeOption(option)"
            ></span>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-circle-plus-outline"
            type="text"
            @click.prevent="addOption"
          >
            新增选项
          </el-button>
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { myComponents } from './expand'
  import { expandEdit } from '@/api/teamList'
  import { isLowerCase, isNumber } from '@/utils/validate'

  export default {
    name: 'TeamFreestyleEdit',
    data() {
      const validateName = (rule, value, callback) => {
        if (!isLowerCase(value)) {
          callback(new Error('请输入小写的英文或字母，如：title、username...'))
        } else {
          callback()
        }
      }
      const validateLen = (rule, value, callback) => {
        if (!isNumber(value)) {
          callback(new Error('文字长度只能是数字'))
        } else {
          callback()
        }
      }
      return {
        zdType: myComponents, //字段类型
        form: {},
        rules: {
          tag: [
            { required: true, trigger: 'change', message: '请选择字段类型' },
          ],
          label: [
            { required: true, trigger: 'blur', message: '请输入字段标题' },
          ],
          /*name: [
            { required: true, trigger: 'blur', message: '请输入字段名称' },
            { trigger: 'blur', validator: validateName },
          ],*/
          placeholder: [
            { required: true, trigger: 'blur', message: '请输入占位提示' },
          ],
          maxlength: [
            { required: true, trigger: 'blur', message: '请输入长度' },
            { trigger: 'blur', validator: validateLen },
          ],
          type: [
            { required: true, trigger: 'change', message: '请选择文本类型' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加'
          this.initForm()
        } else {
          this.title = '编辑'
          expandEdit({ id: row.id, types: 'info' }).then((res) => {
            this.form = res.data
          })
        }
        this.dialogFormVisible = true
      },
      initForm(val) {
        this.form = {
          tag: val ? val : undefined,
          label: '',
          placeholder: '',
          maxlength: '',
          type: '',
          options: [{ value: '', key: Date.now() }], //选项
        }
      },
      bindCheck(val) {
        this.$refs['form'].resetFields()
        this.initForm(val)
      },
      rdoChange(val) {
        if (val === 'tel') {
          this.form.maxlength = 11
        } else if (val === 'idcard') {
          this.form.maxlength = 18
        } else {
          this.form.maxlength = ''
        }
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      addOption() {
        this.form.options.push({
          value: '',
          key: Date.now(),
        })
      },
      removeOption(item) {
        let index = this.form.options.indexOf(item)
        if (index !== -1) {
          this.form.options.splice(index, 1)
        }
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.form.types = this.title === '编辑' ? 'edit' : 'add'
            const { msg } = await expandEdit(this.form)
            this.$baseMessage(msg, 'success')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .option-item {
    display: flex;
    align-items: center;
    span {
      font-size: 22px;
      color: #f56c6c;
      cursor: pointer;
      padding: 3px;
    }
  }
</style>
