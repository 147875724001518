<template>
  <div>
    <el-form
      ref="form"
      label-width="80px"
      :model="form"
      style="margin-top: 10px"
    >
      <el-form-item label="轮播图">
        <div class="list">
          <div v-for="(ban, index) in form.banner" :key="index" class="item">
            <div class="delBtn" @click="deleteItem('banner', index)">
              <el-button circle icon="el-icon-close" size="mini" type="info" />
            </div>
            <div class="img-box">
              <el-form-item
                :prop="`banner[${index}].img`"
                :rules="{
                  required: true,
                  message: `${'请选择轮播图' + (index + 1) + '图片'}`,
                  trigger: ['blur', 'change'],
                }"
              >
                <div
                  class="choose-img w230"
                  @click="imgDialog('banner', index)"
                >
                  <el-image v-if="ban.img" fit="cover" :src="ban.img" />
                  <i v-else class="el-icon-plus"></i>
                </div>
                <div class="img-tip">推荐尺寸：1100*210</div>
              </el-form-item>
            </div>
            <div class="other">
              <el-form-item
                label="排序"
                :prop="`banner[${index}].sort`"
                :rules="[
                  {
                    pattern: /^[0-9]*$/,
                    message: '排序必须为正数',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model.number="ban.sort"
                  placeholder="请输入数字，数字越大越靠前"
                />
              </el-form-item>
              <el-form-item label="跳转链接">
                <el-input
                  v-model="ban.url"
                  placeholder="请输入跳转链接"
                  @focus="choseUrl(ban, 'banner', index)"
                />
              </el-form-item>
            </div>
          </div>
        </div>
        <el-button
          :disabled="form.banner.length > 4"
          plain
          type="primary"
          @click="addItem('banner')"
        >
          添加轮播图
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save">保存</el-button>
      </el-form-item>
    </el-form>

    <gallery ref="gallery" @checkedImg="getImg" />
    <choose-url ref="chooseUrl" :menu="menu" @getUrl="getUrl" />
  </div>
</template>

<script>
  import ChooseUrl from '../components/chooseUrl'
  import { getIndexInfo, saveIndexInfo } from '@/api/idx'
  import Gallery from '@/components/gallery'
  export default {
    name: 'PcBanner',
    components: { ChooseUrl, Gallery },
    data() {
      return {
        form: {},
        menu: [],
        curImg: null,
      }
    },
    created() {
      this.getData()
    },
    methods: {
      choseUrl(row, type, index) {
        this.curImg = {
          type: type,
          index: index,
        }
        this.$refs['chooseUrl'].showEdit(row, this.menu)
      },
      getUrl(url) {
        let cur = this.curImg
        this.$nextTick(() => {
          this.form[cur.type][cur.index].url = url.url
          this.form[cur.type][cur.index].isOut = url.isOut
        })
        this.$forceUpdate()
      },
      getData() {
        getIndexInfo({ source: 'pc' }).then((res) => {
          let d = res.data
          this.form = {
            banner: d.banner || [],
          }
          this.menu = d.menu
        })
      },
      imgDialog(type, index) {
        //记录当前点击的图片项
        this.curImg = {
          type: type,
          index: index,
        }
        this.$refs['gallery'].showEdit()
      },
      getImg(val) {
        let img = this.curImg
        this.form[img.type][img.index].img = val.url
        this.form[img.type][img.index].isTab = val.is_tab
      },
      addItem(type) {
        let data = {
          sort: '',
          url: '',
          img: '',
          isTab: false,
          isOut: 10,
        }
        if (type === 'icon') {
          data.name = ''
        }
        this.form[type].push(data)
      },
      deleteItem(type, index) {
        this.form[type].splice(index, 1)
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let d = this.form
            d.source = 'pc'
            const { code, msg } = await saveIndexInfo(d)
            this.$baseMessage(msg, code === 200 ? 'success' : 'error')
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .delBtn {
    position: absolute;
    top: -15px;
    right: -10px;
    :deep(.el-button--mini.is-circle) {
      padding: 3px;
      background: #bbb;
      border: none;
      &:hover {
        background: #e32020;
      }
    }
  }
</style>
