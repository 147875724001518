var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "menu-set-container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "public-account-management clearfix",
      },
      [
        _c("div", { staticStyle: { display: "flex", "min-width": "1000px" } }, [
          _c("div", [
            _c("div", { staticClass: "left" }, [
              _c("div", { staticClass: "weixin-hd" }),
              _c(
                "div",
                { staticClass: "weixin-menu menu_main clearfix" },
                [
                  _vm._l(_vm.menuList, function (item, i) {
                    return _c("div", { key: i, staticClass: "menu_bottom" }, [
                      _c(
                        "div",
                        {
                          staticClass: "menu_item el-icon-s-fold",
                          class: { active: _vm.isActive === i },
                          on: {
                            click: function ($event) {
                              return _vm.menuClick(i, item)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      ),
                      _vm.isSubMenuFlag === i
                        ? _c(
                            "div",
                            { staticClass: "submenu" },
                            [
                              _vm._l(item.sub_button, function (subItem, k) {
                                return _c(
                                  "div",
                                  {
                                    key: k,
                                    staticClass: "subtitle menu_bottom",
                                  },
                                  [
                                    item.sub_button
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "menu_subItem ellipsis",
                                              class: {
                                                active:
                                                  _vm.isSubMenuActive ===
                                                  i + "" + k,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.subMenuClick(
                                                    subItem,
                                                    i,
                                                    k
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(subItem.name) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              }),
                              !item.sub_button || item.sub_button.length < 5
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "menu_bottom menu_addicon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addSubMenu(i, item)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "el-icon-plus" })]
                                  )
                                : _vm._e(),
                              false
                                ? _c("div", { staticClass: "sub-sort" }, [
                                    _vm._m(0, true),
                                    _vm._m(1, true),
                                    _vm._m(2, true),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ])
                  }),
                  _vm.menuList.length < 3
                    ? _c(
                        "div",
                        {
                          staticClass: "menu_bottom menu_addicon",
                          on: { click: _vm.addMenu },
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
          _vm.showRightFlag
            ? _c("div", { staticClass: "right" }, [
                _c("div", { staticClass: "configure_page" }, [
                  _c("div", { staticClass: "top" }, [
                    _c("div", [_vm._v("菜单配置")]),
                    _c(
                      "div",
                      { staticClass: "delete_btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              icon: "el-icon-delete",
                              size: "mini",
                              type: "danger",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteMenu(_vm.tempObj)
                              },
                            },
                          },
                          [_vm._v(" 删除当前菜单 ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("菜单名称：")]),
                      _c("el-input", {
                        staticClass: "input_width",
                        attrs: { clearable: "", placeholder: "请输入菜单名称" },
                        on: {
                          input: function ($event) {
                            return _vm.bindInput(_vm.tempObj.name)
                          },
                        },
                        model: {
                          value: _vm.tempObj.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.tempObj, "name", $$v)
                          },
                          expression: "tempObj.name",
                        },
                      }),
                      _c("div", { staticClass: "maxlength" }, [
                        _vm._v(_vm._s(_vm.maxMsg)),
                      ]),
                    ],
                    1
                  ),
                  _vm.showConfigureContent
                    ? _c("div", [
                        false
                          ? _c(
                              "div",
                              { staticClass: "menu_content" },
                              [
                                _c("span", [_vm._v("菜单标识：")]),
                                _c("el-input", {
                                  staticClass: "input_width",
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入菜单 KEY",
                                  },
                                  model: {
                                    value: _vm.tempObj.menuKey,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempObj, "menuKey", $$v)
                                    },
                                    expression: "tempObj.menuKey",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "menu_content" },
                          [
                            _c("span", [_vm._v("菜单内容：")]),
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.tempObj.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tempObj, "type", $$v)
                                  },
                                  expression: "tempObj.type",
                                },
                              },
                              _vm._l(_vm.menuOptions, function (item) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: item.value,
                                    attrs: { label: item.value },
                                  },
                                  [_vm._v(" " + _vm._s(item.label) + " ")]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.tempObj.type === "view"
                          ? _c(
                              "div",
                              { staticClass: "configur_content" },
                              [
                                _c("span", [_vm._v("跳转链接：")]),
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入链接",
                                  },
                                  model: {
                                    value: _vm.tempObj.url,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempObj, "url", $$v)
                                    },
                                    expression: "tempObj.url",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.tempObj.type === "miniprogram"
                          ? _c("div", { staticClass: "configur_content" }, [
                              _c(
                                "div",
                                { staticClass: "applet" },
                                [
                                  _c("span", [_vm._v("小程序的 appid ：")]),
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入小程序的appid",
                                    },
                                    model: {
                                      value: _vm.tempObj.appid,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tempObj, "appid", $$v)
                                      },
                                      expression: "tempObj.appid",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "applet" },
                                [
                                  _c("span", [_vm._v("小程序页面路径：")]),
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder:
                                        "请输入小程序的页面路径，如：pages/index",
                                    },
                                    model: {
                                      value: _vm.tempObj.pagepath,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tempObj, "pagepath", $$v)
                                      },
                                      expression: "tempObj.pagepath",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "applet" },
                                [
                                  _c("span", [_vm._v("小程序备用网页：")]),
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder:
                                        "不支持小程序的老版本客户端将打开本网页",
                                    },
                                    model: {
                                      value: _vm.tempObj.url,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tempObj, "url", $$v)
                                      },
                                      expression: "tempObj.url",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("p", { staticClass: "blue" }, [
                                _vm._v(
                                  " tips:需要和公众号进行关联才可以把小程序绑定到微信菜单上哟！ "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.tempObj.type === "article_view_limited"
                          ? _c(
                              "div",
                              { staticClass: "configur_content" },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _vm.tempObj && _vm.tempObj.replyArticles
                                      ? _c(
                                          "div",
                                          { staticClass: "select-item" },
                                          [
                                            _c("wx-news", {
                                              attrs: {
                                                articles:
                                                  _vm.tempObj.replyArticles,
                                              },
                                            }),
                                            _c(
                                              "el-row",
                                              { staticClass: "ope-row" },
                                              [
                                                _c("el-button", {
                                                  attrs: {
                                                    circle: "",
                                                    icon: "el-icon-delete",
                                                    type: "danger",
                                                  },
                                                  on: {
                                                    click: _vm.deleteMaterial,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          [
                                            _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                    },
                                                    attrs: { span: 24 },
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "success",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.openMaterial,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 素材库选择 "),
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-circle-check el-icon--right",
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                    _c(
                                      "el-dialog",
                                      {
                                        attrs: {
                                          title: "选择图文",
                                          visible: _vm.dialogNewsVisible,
                                          width: "90%",
                                        },
                                        on: {
                                          "update:visible": function ($event) {
                                            _vm.dialogNewsVisible = $event
                                          },
                                        },
                                      },
                                      [
                                        _c("wx-material-select", {
                                          attrs: {
                                            "obj-data": {
                                              type: "news",
                                              accountId: _vm.accountId,
                                            },
                                          },
                                          on: {
                                            selectMaterial: _vm.selectMaterial,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.tempObj.type === "click"
                          ? _c(
                              "div",
                              { staticClass: "configur_content" },
                              [
                                false
                                  ? _c("wx-replay", {
                                      attrs: { "obj-data": _vm.tempObj },
                                    })
                                  : _vm._e(),
                                _c(
                                  "el-tabs",
                                  {
                                    attrs: { type: "border-card" },
                                    model: {
                                      value: _vm.tempObj.subType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tempObj, "subType", $$v)
                                      },
                                      expression: "tempObj.subType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-tab-pane",
                                      { attrs: { name: "text" } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "label" },
                                            slot: "label",
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-document",
                                            }),
                                            _vm._v(" 文本 "),
                                          ]
                                        ),
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入内容",
                                            rows: 5,
                                            type: "textarea",
                                          },
                                          model: {
                                            value: _vm.tempObj.value,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tempObj,
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression: "tempObj.value",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-tab-pane",
                                      { attrs: { name: "news" } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "label" },
                                            slot: "label",
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-news",
                                            }),
                                            _vm._v(" 图文 "),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  icon: "el-icon-circle-check",
                                                  type: "primary",
                                                },
                                              },
                                              [_vm._v(" 选择素材 ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _c("div", { staticClass: "right" }, [
                _c("p", [_vm._v("请从左侧选择菜单配置")]),
              ]),
        ]),
        _c(
          "div",
          { staticClass: "save_div" },
          [
            _c(
              "el-button",
              {
                staticClass: "save_btn",
                attrs: { size: "small", type: "success" },
                on: { click: _vm.handleSave },
              },
              [_vm._v(" 保存并发布菜单 ")]
            ),
            _vm.menuList.length
              ? _c(
                  "el-button",
                  {
                    staticClass: "save_btn",
                    attrs: { size: "small", type: "danger" },
                    on: { click: _vm.handleDelete },
                  },
                  [_vm._v(" 清空菜单 ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("i", { staticClass: "el-icon-top" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("i", { staticClass: "el-icon-delete" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("i", { staticClass: "el-icon-bottom" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }