<template>
  <div class="setIndex-container">
    <m-banner />
    <el-tabs v-if="false" v-model="activeName">
      <el-tab-pane label="手机端" name="mobile">
        <m-banner />
      </el-tab-pane>
      <el-tab-pane label="PC端" name="pc">
        <pc-banner />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import MBanner from './components/m-banner'
  import PcBanner from './components/pc-banner'
  export default {
    name: 'Banner',
    components: { MBanner, PcBanner },
    data() {
      return {
        activeName: 'mobile',
      }
    },
    methods: {},
  }
</script>

<style lang="scss">
  .setIndex-container {
    .list {
      .item {
        position: relative;
        display: flex;
        align-items: center;
        background: #f9f9f9;
        padding: 20px;
        margin-bottom: 15px;
        max-width: 600px;
        flex-wrap: wrap;
        .choose-img {
          width: 60px;
          height: 60px;
          text-align: center;
          border: 1px dashed #d9d9d9;
          border-radius: 3px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          line-height: 60px;
          background: #fff;
          &.w230 {
            width: 134px;
          }
          :deep(.el-image) {
            width: 100%;
            height: 100%;
          }
        }
        .img-tip {
          font-size: 12px;
          color: #999;
        }
        .other {
          flex: 1;
          margin-left: 20px;
        }
      }
    }
  }
</style>
