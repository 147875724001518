<template>
  <div class="setIndex-container">
    <mobile />
    <el-tabs v-if="false" v-model="activeName">
      <el-tab-pane label="手机端" name="mobile">
        <mobile />
      </el-tab-pane>
      <el-tab-pane label="PC端" name="pc">
        <pc />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import Mobile from './components/mobile'
  import Pc from './components/pc'
  export default {
    name: 'SetIndex',
    components: { Mobile, Pc },
    data() {
      return {
        activeName: 'mobile',
      }
    },
    created() {},
    methods: {},
  }
</script>

<style lang="scss">
  .setIndex-container {
    .block {
      margin-bottom: 40px;
      .block-top {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 12px;
        margin: 10px 0;
        &:before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          bottom: 0;
          width: 5px;
          height: 16px;
          margin: auto;
          background: #26cdb7;
        }
        .name {
          font-size: 16px;
          font-weight: bold;
          margin-right: 20px;
        }
        .flex1 {
          flex: 1;
          color: #bbb;
          text-align: right;
          cursor: default;
          font-size: 12px;
        }
      }
    }
  }
</style>
