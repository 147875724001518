<template>
  <div class="report-setting-container">
    <el-card shadow="never">
      <div slot="header" class="header">报告设置</div>
      <el-form ref="form" label-width="80px" :model="form">
        <el-form-item prop="title">
          <template #label>
            标题
            <el-tooltip content="展示在个人报告封面顶部。" placement="top">
              <el-button icon="el-icon-info" type="text" />
            </el-tooltip>
          </template>
          <el-input v-model="form.title" placeholder="请输入报告标题" />
        </el-form-item>
        <el-form-item prop="slogan">
          <template #label>
            slogan
            <el-tooltip content="展示在个人报告封面底部。" placement="top">
              <el-button icon="el-icon-info" type="text" />
            </el-tooltip>
          </template>
          <el-input v-model="form.slogan" placeholder="请输入slogan" />
        </el-form-item>
        <el-form-item prop="set_default">
          <template #label>
            样式
            <el-tooltip content="设置报告默认展示样式" placement="top">
              <el-button icon="el-icon-info" type="text" />
            </el-tooltip>
          </template>
          <el-radio-group v-model="form.set_default">
            <el-radio :label="0">默认样式</el-radio>
            <el-radio :label="1">体检样式</el-radio>
            <el-radio :label="2">医院简版</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button size="medium" type="primary" @click="save">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  import { paySettings, reportSettings } from '@/api/user'

  export default {
    name: 'ReportSetting',
    data() {
      return {
        form: {},
        rules: {
          title: [
            { required: true, message: '请输入报告标题', trigger: 'blur' },
          ],
          slogan: [
            {
              required: true,
              message: '请输入slogan',
              trigger: 'blur',
            },
          ],
        },
      }
    },
    created() {
      this.getSetting()
    },
    methods: {
      initData(d) {
        if (!d) d = {}
        return {
          title: d.title || '',
          slogan: d.slogan || '',
          set_default: d.set_default || 0,
        }
      },
      async getSetting() {
        const { data } = await reportSettings({ type: 'info' })
        this.form = this.initData(data)
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let p = this.form
            p.type = 'edit'
            const { data } = await reportSettings(p)
            this.$baseMessage(data.msg, data.code === 200 ? 'success' : 'error')
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style lang="scss">
  .report-setting-container {
    .el-card {
      border: 0 !important;
    }
    .el-card__header {
      padding-left: 0 !important;
      padding-top: 0 !important;
    }
    .el-input {
      width: 520px !important;
    }
    .el-form-item--small .el-form-item__label {
      color: #999;
    }
    .header {
      position: relative;
      font-size: 16px;
      font-weight: bold;
      padding: 0 20px;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 6px;
        background: #26cdb7;
        border-radius: 3px 0 0 3px;
      }
    }
  }
</style>
