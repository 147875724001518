<template>
  <el-dialog
    append-to-body
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="500px"
    @close="close"
  >
    <el-form
      ref="form"
      label-position="right"
      label-width="80px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="分类名称" prop="title">
        <el-input v-model="form.title" placeholder="请输入分类名称" />
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input v-model="form.sort" placeholder="请输入排序" />
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          placeholder="请输入备注"
          rows="3"
          type="textarea"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { isNumber } from '@/utils/validate'
  import { editCate, getDetail } from '@/api/resource'

  export default {
    name: 'CateEdit',
    data() {
      const validateSort = (rule, value, callback) => {
        if (!isNumber(value)) {
          callback(new Error('请输入正整数'))
        } else {
          callback()
        }
      }
      return {
        type: '', //类型(0图文,1视频)
        title: '',
        form: {},
        showDialog: false,
        rules: {
          title: [
            { required: true, trigger: 'blur', message: '请输入分类名称' },
          ],
          sort: [{ validator: validateSort, trigger: 'blur' }],
        },
      }
    },
    methods: {
      initData(param) {
        if (!param) param = {}
        this.form = {
          title: param.title || '',
          sort: param.sort || 0,
          remark: param.remark || '',
        }
      },
      showEdit(row, type) {
        this.type = type
        if (!row) {
          this.title = '添加'
          this.initData()
        } else {
          this.title = '编辑'
          getDetail({ id: row.id, type: 'case' }).then((res) => {
            let data = res.data
            this.initData(data)
            this.form.id = data.id
          })
        }
        this.showDialog = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.showDialog = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.form.type = this.type
            const { msg } = await editCate(this.form)
            this.$baseMessage(msg, 'success')

            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss"></style>
