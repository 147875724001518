var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "form-row" },
        [
          _c("div", { staticClass: "block-title" }, [_vm._v("基本情况")]),
          _c(
            "el-descriptions",
            { attrs: { border: "", column: 4 } },
            [
              _c("el-descriptions-item", { attrs: { label: "姓名" } }, [
                _vm._v(" " + _vm._s(_vm.form.name) + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "性别" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.form.sex === 1 ? "男" : _vm.form.sex === 2 ? "女" : ""
                    ) +
                    " "
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "入学年度" } }, [
                _vm._v(" " + _vm._s(_vm.form.enrolment_year) + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "出生年月" } }, [
                _vm._v(" " + _vm._s(_vm.form.birthday) + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "民族" } }, [
                _vm._v(" " + _vm._s(_vm.form.nation) + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "籍贯" } }, [
                _vm._v(" " + _vm._s(_vm.form.native) + " "),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "联系电话", span: 2 } },
                [_vm._v(" " + _vm._s(_vm.form.mobile) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "家庭住址", span: 4 } },
                [_vm._v(" " + _vm._s(_vm.form.addr) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "兴趣特长", span: 4 } },
                [_vm._v(" " + _vm._s(_vm.form.hobby) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "过往病史", span: 4 } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.form.is_mhistory === 0 ? "无" : _vm.form.mhistory
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      !!_vm.form.is_extend
        ? [
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c("div", { staticClass: "block-title" }, [_vm._v("家庭情况")]),
                _c(
                  "el-table",
                  {
                    class: { minh: !_vm.form.family_member.length },
                    attrs: { border: "", data: _vm.form.family_member },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "称谓", prop: "title" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "姓名", prop: "name" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "年龄", prop: "age" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "学历", prop: "seniority" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "职业", prop: "job" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "你对他（她）的喜欢程度", prop: "level" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm._v(
                                  " " + _vm._s(_vm.lovely[row.level]) + " "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        20064394
                      ),
                    }),
                  ],
                  1
                ),
                _c(
                  "el-descriptions",
                  { attrs: { column: 1 } },
                  [
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "家庭类型" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.familyList[_vm.form.family_type]) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "留守经历" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.form.family_rusu === 1 ? "有" : "无") +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "排行情况" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.form.family_seniority === 0
                                ? "独生子女"
                                : "多子女，排行：" +
                                    _vm.paih[_vm.form.seniority]
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c("div", { staticClass: "block-title" }, [_vm._v("学习经历")]),
                _c(
                  "el-table",
                  {
                    class: { minh: !_vm.form.career_scool.length },
                    attrs: { border: "", data: _vm.form.career_scool },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "起止日期", prop: "time" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(row.time[0] + " ~ " + row.time[1]) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2823554660
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "何校学习", prop: "scool" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "职务", prop: "duty" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "对当时所处集体的喜欢程度",
                        prop: "level",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm._v(
                                  " " + _vm._s(_vm.lovely[row.level]) + " "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        20064394
                      ),
                    }),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    class: { minh: !_vm.form.career_succ.length },
                    attrs: { border: "", data: _vm.form.career_succ },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "成功经历",
                        type: "index",
                        width: "80px",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "时间", prop: "time" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "事件", prop: "remark" },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    class: { minh: !_vm.form.career_fail.length },
                    attrs: { border: "", data: _vm.form.career_fail },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "失败经历",
                        type: "index",
                        width: "80px",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "时间", prop: "time" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "事件", prop: "remark" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c("div", { staticClass: "block-title" }, [_vm._v("自我评价")]),
                _c(
                  "el-descriptions",
                  { attrs: { column: 1 } },
                  [
                    _vm._l(_vm.form.evaluate, function (my, m) {
                      return [
                        _c(
                          "el-descriptions-item",
                          { key: m, attrs: { label: my.name } },
                          [
                            _vm._l(my.options, function (myopt, _m) {
                              return [
                                myopt.id === _vm.form.evaluate_result[m]
                                  ? _c("span", { key: _m }, [
                                      _vm._v(" " + _vm._s(myopt.remark) + " "),
                                    ])
                                  : _vm._e(),
                              ]
                            }),
                          ],
                          2
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "block-title" }, [_vm._v("困惑烦恼")]),
              _c("div", [_vm._v(_vm._s(_vm.form.confused || "暂无"))]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }