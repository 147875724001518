var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "set-mobile" },
    [
      _c(
        "div",
        { staticClass: "block" },
        [
          _c(
            "div",
            { staticClass: "block-top" },
            [
              _c("span", { staticClass: "name" }, [_vm._v("首页图标")]),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.checkAccess({ permission: ["editNav"] }),
                    type: "text",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.edit(null, true)
                    },
                  },
                },
                [_vm._v(" 点击添加 ")]
              ),
              _c(
                "el-popover",
                {
                  directives: [
                    {
                      name: "permissions",
                      rawName: "v-permissions",
                      value: { permission: ["editNav"] },
                      expression: "{ permission: ['editNav'] }",
                    },
                  ],
                  staticStyle: { "margin-left": "15px" },
                  attrs: {
                    placement: "bottom",
                    trigger: "manual",
                    width: "200",
                  },
                  model: {
                    value: _vm.showNum,
                    callback: function ($$v) {
                      _vm.showNum = $$v
                    },
                    expression: "showNum",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "set-num" },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("调整排版")]),
                      _c("el-input-number", {
                        attrs: { max: 6, min: 1, size: "mini" },
                        model: {
                          value: _vm.num,
                          callback: function ($$v) {
                            _vm.num = $$v
                          },
                          expression: "num",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "btn" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.showNums },
                            },
                            [_vm._v("取消")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: { click: _vm.saveNum },
                            },
                            [_vm._v(" 保存 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { slot: "reference", type: "text" },
                      on: { click: _vm.showNums },
                      slot: "reference",
                    },
                    [_vm._v(" 调整排版 ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex1" },
                [
                  _c(
                    "el-popover",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        placement: "left",
                        trigger: "hover",
                        width: "375",
                      },
                    },
                    [
                      _c("div", { staticClass: "page-m index" }, [
                        _c("div", { staticClass: "title" }, [_vm._v("首页")]),
                        _c("div", { staticClass: "bg banner" }),
                        _c(
                          "div",
                          { staticClass: "iconbar" },
                          _vm._l(_vm.iconList, function (nav, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "item",
                                style: _vm.iconWidth,
                              },
                              [
                                _c("el-image", {
                                  staticClass: "img",
                                  attrs: { src: nav.cover },
                                }),
                                _c("div", [_vm._v(_vm._s(nav.title))]),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          { staticClass: "icon-page" },
                          _vm._l(_vm.iconPage, function (icon) {
                            return _c("div", {
                              key: icon,
                              staticClass: "page-item",
                            })
                          }),
                          0
                        ),
                        _c("div", { staticClass: "bg other" }),
                        _c("div", { staticClass: "bottom" }),
                      ]),
                      _c(
                        "span",
                        { attrs: { slot: "reference" }, slot: "reference" },
                        [_vm._v("鼠标悬停查看效果")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { border: "", data: _vm.iconList } },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "图标", prop: "cover" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-image", {
                          attrs: { fit: "contain", src: row.cover },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "标题", prop: "title" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "更新时间",
                  prop: "update_at",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "操作", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: !_vm.checkAccess({
                                permission: ["editNav"],
                              }),
                              type: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.edit(row, true)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: !_vm.checkAccess({
                                permission: ["delNav"],
                              }),
                              type: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.del(row, "index")
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      false
        ? _c(
            "div",
            { staticClass: "block" },
            [
              _c(
                "div",
                { staticClass: "block-top" },
                [
                  _c("span", { staticClass: "name" }, [
                    _vm._v("个人中心菜单栏"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: !_vm.checkAccess({ permission: ["editNav"] }),
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.edit(null, false, 1)
                        },
                      },
                    },
                    [_vm._v(" 点击添加 ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "flex1" },
                    [
                      _c(
                        "el-popover",
                        {
                          staticStyle: { "margin-left": "15px" },
                          attrs: {
                            placement: "left",
                            trigger: "hover",
                            width: "375",
                          },
                        },
                        [
                          _c("div", { staticClass: "page-m ucenter" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("个人中心"),
                            ]),
                            _c("div", {
                              staticClass: "bg banner",
                              staticStyle: {
                                height: "110px",
                                "border-radius": "5px",
                              },
                            }),
                            _c("div", { staticClass: "iconbar" }),
                            _c(
                              "div",
                              { staticClass: "cust-menu" },
                              [
                                _vm._l(_vm.perList, function (menu, _i) {
                                  return _c(
                                    "div",
                                    { key: _i, staticClass: "c-item" },
                                    [
                                      menu.show === 10
                                        ? [
                                            _c("div", { staticClass: "name" }, [
                                              _vm._v(_vm._s(menu.title)),
                                            ]),
                                            _c("div", { staticClass: "desc" }),
                                            _c(
                                              "div",
                                              { staticClass: "arrow" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-right",
                                                }),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                }),
                                false
                                  ? [
                                      _vm._l(
                                        _vm.menu.children,
                                        function (child, index2) {
                                          return [
                                            child.show === 10
                                              ? _c(
                                                  "div",
                                                  {
                                                    key: index2,
                                                    staticClass: "c-item",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "name" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(child.title)
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", {
                                                      staticClass: "desc",
                                                    }),
                                                    _c(
                                                      "div",
                                                      { staticClass: "arrow" },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-arrow-right",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                          _c(
                            "span",
                            { attrs: { slot: "reference" }, slot: "reference" },
                            [_vm._v("鼠标悬停查看效果")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  ref: "refTable",
                  attrs: {
                    border: "",
                    data: _vm.perList,
                    "default-expand-all": "",
                    "row-key": "id",
                    "tree-props": { children: "children" },
                  },
                  on: { "row-click": _vm.clickRow },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "菜单栏标题", prop: "title" },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "显示", prop: "show" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": 10,
                                      "inactive-value": 20,
                                      value: row.show * 1,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.setStatus(row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3063757303
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "更新时间",
                      prop: "update_at",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "right", label: "操作", width: "180" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.rank == 1
                                ? [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          disabled: !_vm.checkAccess({
                                            permission: ["editNav"],
                                          }),
                                          type: "text",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.edit(
                                              row,
                                              false,
                                              2,
                                              "add"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 新增下级 ")]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm.checkAccess({ permission: ["editNav"] })
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.edit(row, false)
                                        },
                                      },
                                    },
                                    [_vm._v(" 编辑 ")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled: "",
                                        title:
                                          "暂无权限，如有需要，请联系管理员",
                                        type: "text",
                                      },
                                    },
                                    [_vm._v(" 编辑 ")]
                                  ),
                              _vm.checkAccess({ permission: ["delNav"] })
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.del(row, "personal")
                                        },
                                      },
                                    },
                                    [_vm._v(" 删除 ")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled: "",
                                        title:
                                          "暂无权限，如有需要，请联系管理员",
                                        type: "text",
                                      },
                                    },
                                    [_vm._v(" 删除 ")]
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1197614882
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("m-index", {
        ref: "m-index",
        attrs: { menu: _vm.menuList },
        on: { refresh: _vm.refresh },
      }),
      _c("m-ucenter", {
        ref: "m-ucenter",
        attrs: { menu: _vm.menuList },
        on: { refresh: _vm.refresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }