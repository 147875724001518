<template>
  <div class="left3">
    <Echart :id="id" height="1.5rem" :options="options" width="100%" />
  </div>
</template>

<script>
  import Echart from '@/extra/VabChart/echart'
  export default {
    components: {
      Echart,
    },
    props: {
      id: {
        type: String,
        required: true,
        default: 'chartRate',
      },
      tips: {
        type: Number,
        required: true,
        default: 50,
      },
      colors: {
        type: Object,
        default: function () {
          return {
            textStyle: '#3fc0fb',
            series: {
              color: ['#00bcd44a', 'transparent'],
              dataColor: {
                normal: '#03a9f4',
                shadowColor: '#97e2f5',
              },
            },
          }
        },
      },
    },
    data() {
      return {
        options: {},
      }
    },
    watch: {
      tips: {
        handler(newData) {
          this.options = {
            title: {
              text: this.id === 'centerRate1' ? newData : newData * 1 + '%',
              x: 'center',
              y: 'center',
              textStyle: {
                color: this.colors.textStyle,
                fontSize: 16,
              },
            },
            series: [
              {
                type: 'pie',
                radius: ['75%', '80%'],
                center: ['50%', '50%'],
                color: this.colors.series.color,
                label: {
                  show: false,
                },
                data: [
                  {
                    value: newData,
                    itemStyle: {
                      color: this.colors.series.dataColor.normal,
                      shadowBlur: 10,
                      shadowColor: this.colors.series.dataColor.shadowColor,
                    },
                  },
                  {
                    value: 100 - newData,
                  },
                ],
              },
            ],
          }
        },
        immediate: true,
        deep: true,
      },
    },
    mounted() {},
    methods: {},
  }
</script>

<style lang="scss"></style>
