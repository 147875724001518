var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "libraryks-container" },
    [
      _c("el-empty", {
        staticStyle: { "margin-top": "100px" },
        attrs: { description: " ", image: _vm.img },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }