var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "80px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "渠道名称", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入渠道名称" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "渠道编码", prop: "trench" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入渠道编码（英文字母）" },
                model: {
                  value: _vm.form.trench,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "trench", $$v)
                  },
                  expression: "form.trench",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "使用版本", prop: "version" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入使用版本" },
                model: {
                  value: _vm.form.version,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "version", $$v)
                  },
                  expression: "form.version",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "使用平台", prop: "platform" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.form.platform,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "platform", $$v)
                    },
                    expression: "form.platform",
                  },
                },
                [
                  _c("el-checkbox", { attrs: { label: "1" } }, [
                    _vm._v("付费测评"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "2" } }, [
                    _vm._v("团测"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "有效期", prop: "effect_time" } },
            [
              _c("el-date-picker", {
                attrs: {
                  "end-placeholder": "结束日期",
                  "start-placeholder": "开始日期",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.form.effect_time,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "effect_time", $$v)
                  },
                  expression: "form.effect_time",
                },
              }),
            ],
            1
          ),
          _vm.form.platform.includes("2")
            ? _c(
                "el-form-item",
                { attrs: { label: "是否限制", prop: "is_limit" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.is_limit,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_limit", $$v)
                        },
                        expression: "form.is_limit",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "10" } }, [
                        _vm._v("限制"),
                      ]),
                      _c("el-radio", { attrs: { label: "20" } }, [
                        _vm._v("不限制"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.is_limit == "10"
            ? _c(
                "el-form-item",
                { attrs: { label: "人数", prop: "limit_num" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "controls-position": "right",
                      max: 99999999,
                      min: 1,
                    },
                    model: {
                      value: _vm.form.limit_num,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "limit_num", $$v)
                      },
                      expression: "form.limit_num",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "10" } }, [_vm._v("正常")]),
                  _c("el-radio", { attrs: { label: "20" } }, [_vm._v("禁用")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "开启AI", prop: "open_ai" } },
            [
              _c("el-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.form.open_ai,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "open_ai", $$v)
                  },
                  expression: "form.open_ai",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }