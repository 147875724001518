var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.dialogFormVisible, width: "60%" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            inline: true,
            "label-width": "100px",
            model: _vm.form,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { width: "100%" },
              attrs: { label: "菜单类型", prop: "type" },
            },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                [_vm._v("菜单")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                [_vm._v("按钮")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "菜单标题", prop: "title" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "父级菜单", prop: "pid" } }, [
            _c(
              "div",
              { staticStyle: { width: "100%" } },
              [
                _c("el-cascader", {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    options: _vm.list,
                    props: { label: "title", value: "id", checkStrictly: true },
                  },
                  model: {
                    value: _vm.form.parents,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "parents", $$v)
                    },
                    expression: "form.parents",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm.form.type == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "组件名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "路由路径", prop: "path" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.path,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "path", $$v)
                      },
                      expression: "form.path",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "vue文件路径", prop: "component" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.component,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "component", $$v)
                      },
                      expression: "form.component",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "权限标识", prop: "ability" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.ability,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ability", $$v)
                  },
                  expression: "form.ability",
                },
              }),
            ],
            1
          ),
          _vm.form.type == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "重定向", prop: "redirect" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.redirect,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "redirect", $$v)
                      },
                      expression: "form.redirect",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "菜单图标", prop: "icon" } },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "icon-selector-popper",
                        trigger: "click",
                        width: "292",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "reference",
                            fn: function () {
                              return [
                                _c("el-input", {
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.form.icon,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "icon", $$v)
                                    },
                                    expression: "form.icon",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1856672453
                      ),
                    },
                    [
                      _c("vab-icon-selector", {
                        on: { "handle-icon": _vm.handleIcon },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "菜单排序", prop: "sort" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sort", $$v)
                  },
                  expression: "form.sort",
                },
              }),
            ],
            1
          ),
          _vm.form.type == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "隐藏", prop: "hidden" } },
                [
                  _c("el-switch", {
                    staticClass: "switchStyle",
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.form.hidden,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hidden", $$v)
                      },
                      expression: "form.hidden",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "avoid" } },
            [
              _c("el-switch", {
                staticClass: "switchStyle",
                attrs: { "active-value": 0, "inactive-value": 1 },
                model: {
                  value: _vm.form.avoid,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "avoid", $$v)
                  },
                  expression: "form.avoid",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }