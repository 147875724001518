var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "warning-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "div",
            { staticClass: "tabs" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: _vm.queryForm.type === 1 ? "success" : "" },
                  on: {
                    click: function ($event) {
                      return _vm.levelType(1)
                    },
                  },
                },
                [_vm._v(" 适应问题 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: _vm.queryForm.type === 2 ? "warning" : "" },
                  on: {
                    click: function ($event) {
                      return _vm.levelType(2)
                    },
                  },
                },
                [_vm._v(" 严重问题 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: _vm.queryForm.type === 3 ? "danger" : "" },
                  on: {
                    click: function ($event) {
                      return _vm.levelType(3)
                    },
                  },
                },
                [_vm._v(" 危机问题 ")]
              ),
              _c(
                "el-popover",
                {
                  staticClass: "popEx",
                  attrs: {
                    content:
                      "系统自动根据测评报告生成预警信息，划分适应问题，严重问题，干预问题三类，针对严重问题类和危机问题类预警名单学校需要重点关注并建立干预转介机制，适应性问题可通过开展个案辅导、团体辅导进行处理。",
                    placement: "bottom-start",
                    title: "预警使用说明",
                    trigger: "hover",
                    width: "350",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "el-icon-info",
                      staticStyle: {
                        "line-height": "30px",
                        "margin-bottom": "10px",
                      },
                      attrs: { slot: "reference" },
                      slot: "reference",
                    },
                    [_vm._v(" 预警等级 ")]
                  ),
                ]
              ),
            ],
            1
          ),
          _c("vab-query-form-left-panel", { attrs: { span: 10 } }),
          _vm.button.search
            ? _c(
                "vab-query-form-right-panel",
                { attrs: { span: 14 } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: { inline: true, model: _vm.queryForm },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _vm.isSid === "all"
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "选择渠道",
                                  },
                                  model: {
                                    value: _vm.queryForm.sid,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryForm, "sid", $$v)
                                    },
                                    expression: "queryForm.sid",
                                  },
                                },
                                _vm._l(_vm.studioList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入用户名",
                            },
                            model: {
                              value: _vm.queryForm.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "queryForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-search",
                                type: "primary",
                              },
                              on: { click: _vm.queryData },
                            },
                            [_vm._v(" 查询 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.list },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "用户名",
              prop: "name",
              "show-overflow-tooltip": "",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "组织结构", prop: "origin" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "测评量表",
              prop: "paper_name",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "测评类型", prop: "eval_type" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "预警因子集",
              prop: "warn_factor",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.warn_factor.length < 3
                      ? _vm._l(row.warn_factor, function (item, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "warning-box" },
                            [
                              _c("span", {
                                staticClass: "circle",
                                class: item.color,
                              }),
                              _vm._v(" " + _vm._s(item.name) + " "),
                            ]
                          )
                        })
                      : [
                          _c("div", { staticClass: "warning-box" }, [
                            _c("span", {
                              staticClass: "circle",
                              class: row.warn_factor[0].color,
                            }),
                            _vm._v(" " + _vm._s(row.warn_factor[0].name) + " "),
                          ]),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "right",
                                trigger: "click",
                                width: "200",
                              },
                            },
                            [
                              _vm._l(row.warn_factor, function (item, i) {
                                return _c(
                                  "div",
                                  { key: i, staticClass: "warning-box" },
                                  [
                                    _c("span", {
                                      staticClass: "circle",
                                      class: item.color,
                                    }),
                                    _vm._v(" " + _vm._s(item.name) + " "),
                                  ]
                                )
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference",
                                },
                                [_vm._v("查看更多>>")]
                              ),
                            ],
                            2
                          ),
                        ],
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "85" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v("干预")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(
                              row,
                              "/other/mmptRecord",
                              "record"
                            )
                          },
                        },
                      },
                      [_vm._v(" 报告 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("edit", { ref: "edit", on: { "fetch-data": _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }