<template>
  <div class="multi-container">
    <vab-query-form>
      <vab-query-form-top-panel v-if="button.search">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item v-if="isSid === 'all'">
            <el-select
              v-model="queryForm.sid"
              clearable
              filterable
              placeholder="请选择渠道"
              @change="bindChange"
            >
              <el-option
                v-for="item in studioList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-cascader
              ref="orCas"
              clearable
              :options="treeData"
              placeholder="组织结构"
              :props="defaultProps"
              @change="handleChange"
              @visible-change="cascaderClicked"
            />
          </el-form-item>
          <el-form-item prop="edu_code">
            <el-input
              v-model.trim="queryForm.edu_code"
              clearable
              placeholder="请输入编号"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入姓名"
            />
          </el-form-item>
          <el-form-item>
            <el-select v-model="queryForm.sex" clearable placeholder="选择性别">
              <el-option label="全部" value="0" />
              <el-option label="男" value="1" />
              <el-option label="女" value="2" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.account"
              clearable
              placeholder="请输入账号"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.phone"
              clearable
              placeholder="请输入联系方式"
            />
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="queryForm.loginTime"
              end-placeholder="登录结束日期"
              start-placeholder="登录开始日期"
              type="daterange"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
            <el-button type="text" @click="handleFold">
              <span v-if="fold">合并</span>
              <span v-else>展开</span>
              <vab-icon
                class="vab-dropdown"
                :class="{ 'vab-dropdown-active': !fold }"
                icon="arrow-up-s-line"
              />
            </el-button>
          </el-form-item>
          <div v-if="fold" class="more-condition">
            <div v-if="false">
              <el-form-item label="测评计划">
                <el-select
                  v-model="queryForm.sex"
                  clearable
                  placeholder="选择计划"
                >
                  <el-option label="全部" value="0" />
                </el-select>
              </el-form-item>
              <el-form-item label="状态">
                <el-select
                  v-model="queryForm.sex"
                  clearable
                  placeholder="选择状态"
                >
                  <el-option label="全部" value="0" />
                </el-select>
              </el-form-item>
              <el-form-item label="测评日期">
                <el-date-picker
                  v-model="queryForm.loginTime"
                  end-placeholder="计划结束日期"
                  start-placeholder="计划开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                />
              </el-form-item>
            </div>
            <div>
              <fieldset class="high-search">
                <legend>
                  <el-select
                    v-model="queryForm.ador"
                    placeholder="关系"
                    style="width: 80px"
                  >
                    <el-option label="并且" :value="1" />
                    <el-option label="或者" :value="2" />
                  </el-select>
                </legend>
                <div class="condition">
                  <div
                    v-for="(item, index) in highf"
                    :key="index"
                    class="con-item"
                  >
                    <el-select
                      v-model="item.c1"
                      clearable
                      filterable
                      placeholder="选择量表"
                      @change="selectPaper($event, index, 'paper')"
                    >
                      <el-option
                        v-for="(p, i) in paperList"
                        :key="i"
                        :label="p.name"
                        :value="i"
                      />
                    </el-select>
                    中
                    <el-select
                      v-model="item.c2"
                      class="w100"
                      clearable
                      @change="selectPaper($event, index, 'c2')"
                    >
                      <el-option
                        v-if="
                          paperList[item.c1] &&
                          paperList[item.c1].divisor.length
                        "
                        label="因子"
                        :value="1"
                      />
                      <el-option
                        v-if="paperList[item.c1]"
                        label="题目"
                        :value="2"
                      />
                    </el-select>
                    <el-select
                      v-model="item.c3"
                      clearable
                      filterable
                      @change="selectPaper($event, index, 'c3')"
                    >
                      <template v-if="item.c2 === 1">
                        <el-option
                          v-for="yz in paperList[item.c1].divisor"
                          :key="yz"
                          :label="yz"
                          :value="yz"
                        />
                      </template>
                      <template v-if="item.c2 === 2">
                        <el-option
                          v-for="(p, i) in paperList[item.c1].ques"
                          :key="i"
                          :label="p.name"
                          :value="i"
                        />
                      </template>
                    </el-select>
                    的
                    <el-select
                      v-model="item.c4"
                      class="w100"
                      clearable
                      @change="selectPaper($event, index, 'c4')"
                    >
                      <template v-if="item.c2 === 1">
                        <el-option label="得分大于等于" :value="1" />
                        <el-option label="得分小于" :value="2" />
                      </template>
                      <el-option
                        v-if="item.c2 === 2"
                        label="选项选择"
                        :value="3"
                      />
                    </el-select>
                    <el-input-number
                      v-if="item.c2 === 1"
                      v-model="item.c5"
                      controls-position="right"
                    />
                    <el-select
                      v-if="
                        item.c2 === 2 &&
                        paperList[item.c1] &&
                        paperList[item.c1].ques[item.c3]
                      "
                      v-model="item.c5"
                      clearable
                    >
                      <el-option
                        v-for="sel in paperList[item.c1].ques[item.c3].select"
                        :key="sel"
                        :label="sel"
                        :value="sel"
                      />
                    </el-select>
                    <div class="btn-box">
                      <span
                        v-if="highf.length > 1"
                        class="btn"
                        style="color: #e64e1f"
                        @click.prevent="removeOption(item)"
                      >
                        <i class="el-icon-remove-outline"></i>
                      </span>
                      <span
                        v-if="index + 1 === highf.length"
                        class="btn"
                        @click.prevent="addOption"
                      >
                        <i class="el-icon-circle-plus-outline"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table v-loading="listLoading" border :data="list">
      <el-table-column align="center" label="序号" type="index" width="50" />
      <el-table-column label="姓名" min-width="120" prop="name">
        <template #default="{ row }">
          <div class="user">
            <img v-if="row.sex === '男'" src="@/assets/male.png" />
            <img v-else-if="row.sex === '女'" src="@/assets/female.png" />
            <img v-else src="@/assets/unknow.png" />
            <span>{{ row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="性别" prop="sex" width="50" />
      <el-table-column align="center" label="账号" prop="account" />
      <el-table-column align="center" label="密码" prop="password" />
      <el-table-column align="center" label="联系方式" prop="phone" />
      <el-table-column
        align="center"
        label="组织结构"
        min-width="200"
        prop="origin"
      />
      <el-table-column align="center" label="上次登录" prop="login_at" />
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getStudioList, getMultiPaper } from '@/api/platformChannelManagement'
  import {
    getCurStudioOrgin,
    getHighUserList,
  } from '@/api/userManagementOrganization'
  import { mapGetters } from 'vuex'

  export default {
    name: 'MultiSearch',
    data() {
      return {
        fold: true,
        paperList: [],
        list: [],
        studioList: [],
        treeData: [], //获取组织结构
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          sid: '',
          name: '',
          phone: '',
          orgid: '',
          sex: '',
          account: '',
          loginTime: [],
          ador: 2, //组合条件（与或1,2
          edu_code: '',
          condition: [
            {
              pid: '', //量表id
              fp: '', //因子或题目1,2
              problem: '', //因子或题号
              options: '', //筛选条件（分数或选项）
              score: '', //条件（分数或选项）
            },
          ],
        },
        highf: [
          {
            c1: '',
            c2: '',
            c3: '',
            c4: '',
            c5: '',
          },
        ],
        button: {},
        docUrl: '',
        defaultProps: {
          children: 'children',
          label: 'name',
          value: 'id',
          checkStrictly: true,
        },
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    created() {
      this.fetchData()
      this.getPapers()
      if (this.isSid === 'all') this.getStudio()
    },
    methods: {
      async getPapers() {
        const { data } = await getMultiPaper()
        this.paperList = data
      },
      handleFold() {
        this.fold = !this.fold
      },
      cascaderClicked($event) {
        if ($event) {
          this.getOriginList(this.queryForm.sid)
        }
      },
      addOption() {
        //新增选项
        this.highf.push({
          c1: '',
          c2: '',
          c3: '',
          c4: '',
          c5: '',
        })
      },
      removeOption(item) {
        //删除选项
        let index = this.highf.indexOf(item)
        if (index !== -1) {
          this.highf.splice(index, 1)
        }
      },
      //选择量表
      selectPaper(val, index, type) {
        let cur = this.highf[index]
        if (type === 'paper') {
          cur.c2 = ''
          cur.c3 = ''
          cur.c4 = ''
          cur.c5 = ''
        } else if (type === 'c2') {
          cur.c3 = ''
          cur.c4 = ''
          cur.c5 = ''
        } else if (type === 'c3') {
          cur.c5 = ''
        } else if (type === 'c4') {
          cur.c5 = ''
        }
      },
      async getStudio() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      async getOriginList(sid) {
        //获取组织结构
        const { data } = await getCurStudioOrgin(
          this.isSid === 'all' ? { sid: sid } : ''
        )
        this.treeData = data
      },
      bindChange(val) {
        if (val) {
          this.getOriginList(val)
        } else {
          this.queryForm.orgid = ''
        }
      },
      handleChange(value) {
        let id = this.$refs.orCas.getCheckedNodes(true)
        this.queryForm.orgid = id.length ? id[0].value : ''
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        let plist = this.paperList
        this.queryForm.condition = this.highf.map((item, i) => {
          let obj = plist[item.c1]
          return {
            pid: obj?.id || '',
            fp: item.c2, //因子或题目1,2
            problem: (item.c2 === 1 ? item.c3 : obj?.ques[item.c3].name) || '', //因子或题号
            options: item.c4, //筛选条件（分数或选项）
            score: item.c5, //条件（分数或选项）
          }
        })
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        this.$delete(this.queryForm, 'excel')
        const { data, totalCount, button } = await getHighUserList(
          this.queryForm
        )
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .multi-container {
    .more-condition {
      padding: 15px 0;
      .high-search {
        border-radius: 5px;
        border: 1px solid #dcdfe6;
        margin-top: 15px;
        .condition {
          padding: 20px;
          .con-item {
            display: flex;
            align-items: center;
            padding: 10px 0;
            .el-select,
            .el-input-number {
              margin: 0 5px;
            }
            .w100 {
              width: 130px;
            }
            .btn-box {
              width: 100px;
            }
            .btn {
              display: inline-block;
              vertical-align: middle;
              margin-left: 10px;
              color: #26cdb7;
              i {
                font-size: 24px;
              }
            }
          }
        }
      }
    }
  }
</style>
