<template>
  <div class="addWarnigPlan-container">
    <el-row :gutter="15">
      <el-col
        :lg="{ span: 12, offset: 6 }"
        :md="{ span: 20, offset: 2 }"
        :sm="{ span: 20, offset: 2 }"
        :xl="{ span: 12, offset: 6 }"
        :xs="24"
      >
        <el-form
          ref="form"
          class="demo-form"
          :label-position="labelPosition"
          label-width="130px"
          :model="form"
          :rules="rules"
        >
          <div class="form-row">
            <div class="block-title">基本信息</div>
            <el-form-item label="方案标题" prop="title">
              <el-input v-model="form.title" />
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="form.remark" type="textarea" />
            </el-form-item>
          </div>
          <div class="form-row">
            <div class="block-title">预警逻辑</div>
            <vab-query-form>
              <vab-query-form-right-panel :span="24">
                <el-form
                  :inline="true"
                  :model="queryForm"
                  @submit.native.prevent
                >
                  <el-form-item>
                    <el-select
                      v-model="queryForm.lv"
                      clearable
                      filterable
                      placeholder="预警级别"
                    >
                      <el-option
                        v-for="item in warningList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.sort"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-input
                      v-model.trim="queryForm.name"
                      clearable
                      placeholder="请输入标题"
                    />
                  </el-form-item>
                  <el-form-item>
                    <el-select
                      v-model="queryForm.pid"
                      clearable
                      filterable
                      placeholder="请选择量表"
                    >
                      <el-option
                        v-for="item in evalList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      icon="el-icon-search"
                      type="primary"
                      @click="queryData"
                    >
                      查询
                    </el-button>
                    <el-button
                      icon="el-icon-refresh"
                      type="primary"
                      @click="refresh"
                    >
                      刷新
                    </el-button>
                    <el-button
                      icon="el-icon-plus"
                      type="primary"
                      @click="handleEdit('', 'add')"
                    >
                      新增
                    </el-button>
                  </el-form-item>
                </el-form>
              </vab-query-form-right-panel>
            </vab-query-form>
            <el-table v-loading="listLoading" :data="list">
              <el-table-column label="预警级别" prop="lv" />
              <el-table-column align="center" label="标题" prop="name" />
              <el-table-column align="center" label="量表" prop="pname" />
              <el-table-column align="center" label="预警项" prop="nums" />
              <el-table-column align="center" label="预警问题" prop="quest" />
              <el-table-column align="center" label="操作" prop="title">
                <template #default="{ row }">
                  <el-button type="text" @click="handleEdit(row, 'edit')">
                    编辑
                  </el-button>
                  <el-button
                    style="color: #da0c0c"
                    type="text"
                    @click="handleEdit(row, 'del')"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <!--<el-pagination
              background
              :current-page="queryForm.pageNo"
              :page-size="queryForm.pageSize"
              :layout="layout"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />-->
          </div>
          <el-form-item>
            <el-button type="primary" @click="submitForm('form')">
              {{ form.id ? '保存修改' : '立即创建' }}
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <warn-rule ref="rule" @warn-list="getRuleLists" />
  </div>
</template>

<script>
  import { doPlanEdit, doRuleEdit, getRuleList } from '@/api/settingAuthority'
  import { getWarnParam } from '@/api/crisis'
  import { getEvalListBySid } from '@/api/mmptCategory'
  import { handleActivePath } from '@/utils/routes'
  import warnRule from './components/addWarnigRule'
  import { mapActions } from 'vuex'
  export default {
    name: 'AddWarnigPlan',
    components: { warnRule },
    inject: ['reload'],
    data() {
      return {
        list: [], //预警逻辑列表
        layout: 'total, sizes, prev, pager, next, jumper',
        listLoading: false,
        labelPosition: 'right',
        form: {
          title: '',
          remark: '',
        },
        rules: {
          title: [
            { required: true, message: '请输入方案标题', trigger: 'blur' },
          ],
        },
        total: 0,
        warningList: [], //预警级别
        evalList: [], //量表
        queryForm: {
          pageNo: 1,
          pageSize: 1000,
          name: '',
          lv: '',
          pid: '',
          id: '',
        },
      }
    },
    created() {
      this.getData()
      this.getWarnLevel()
      this.getEvalList()
    },
    methods: {
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      handleEdit(row, type) {
        if (type === 'del') {
          this.$baseConfirm('确定删除该预警逻辑吗？', null, () => {
            doRuleEdit({
              plan: this.form.id || 0,
              id: row.id,
              type: 'delete',
            }).then(async (res) => {
              this.$baseMessage(res.msg, 'success')
              this.refresh()
            })
          })
        } else {
          let p = {
            id: row.id || 0,
            plan: this.$route.query.id || 0,
          }
          this.$refs['rule'].showEdit(p, type)
        }
      },
      async getEvalList() {
        const { data } = await getEvalListBySid()
        this.evalList = data
      },
      getWarnLevel() {
        getWarnParam({ type: 'warning' }).then((res) => {
          let list = []
          res.data.forEach((i) => {
            i.status == '1' && list.push(i)
          })
          this.warningList = list
        })
      },
      getData() {
        const route = this.$route
        this.$nextTick(() => {
          let rid = route.query.id
          if (rid) {
            this.getDetailById({ id: rid, type: 'info' })
            this.queryForm.id = rid
            this.getRuleLists()
          } else {
            this.form.type = 'add'
            this.queryForm.id = 0
          }
        })
      },
      async getDetailById(param) {
        const { data } = await doPlanEdit(param)
        this.form = data
        this.form.id = param.id
        this.form.type = 'edit'
      },
      async getRuleLists() {
        this.listLoading = true
        const { data, totalCount } = await getRuleList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.listLoading = false
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.getRuleLists()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.getRuleLists()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.getRuleLists()
      },
      refresh() {
        this.queryForm.pageNo = 1
        this.queryForm.lv = ''
        this.queryForm.name = ''
        this.queryForm.pid = ''
        this.getRuleLists()
      },
      submitForm(formName) {
        //提交
        this.$refs[formName].validate(async (valid, error) => {
          if (valid) {
            if (!this.list.length) {
              this.$baseMessage('请添加预警逻辑', 'error')
              return false
            }
            const { msg } = await doPlanEdit(this.form)
            this.$baseMessage(msg, 'success')
            //关闭当前tab，返回list页并刷新
            await this.delVisitedRoute(handleActivePath(this.$route, true))
            this.reload()
            this.$router.push('/setting/selfdom/customWarning')
          } else {
            // eslint-disable-next-line no-console
            for (let key in error) {
              this.$message.error(error[key][0].message)
              break
            }
            return false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .addWarnigPlan-container {
    .form-row {
      &:not(:first-child) {
        padding-top: 20px;
        margin-bottom: 20px;
      }
      .block-title {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        padding-left: 20px;
        margin-bottom: 20px;
        &:before {
          position: absolute;
          content: '';
          width: 6px;
          height: 16px;
          background: #26cdb7;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        }
      }
    }
  }
</style>
