<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="60%"
    @close="close"
  >
    <el-form
      ref="form"
      :inline="true"
      label-width="100px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="菜单类型" prop="type" style="width: 100%">
        <el-radio v-model="form.type" :label="1">菜单</el-radio>
        <el-radio v-model="form.type" :label="2">按钮</el-radio>
      </el-form-item>
      <el-form-item label="菜单标题" prop="title">
        <el-input v-model="form.title" />
      </el-form-item>
      <el-form-item label="父级菜单" prop="pid">
        <div style="width: 100%">
          <el-cascader
            v-model="form.parents"
            clearable
            filterable
            :options="list"
            :props="{ label: 'title', value: 'id', checkStrictly: true }"
          />
        </div>
      </el-form-item>
      <el-form-item v-if="form.type == 1" label="组件名称" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item v-if="form.type == 1" label="路由路径" prop="path">
        <el-input v-model="form.path" />
      </el-form-item>
      <el-form-item v-if="form.type == 1" label="vue文件路径" prop="component">
        <el-input v-model="form.component" />
      </el-form-item>
      <el-form-item label="权限标识" prop="ability">
        <el-input v-model="form.ability" />
      </el-form-item>
      <el-form-item v-if="form.type == 1" label="重定向" prop="redirect">
        <el-input v-model="form.redirect" />
      </el-form-item>
      <el-form-item v-if="form.type == 1" label="菜单图标" prop="icon">
        <el-popover
          popper-class="icon-selector-popper"
          trigger="click"
          width="292"
        >
          <template #reference>
            <el-input v-model="form.icon" clearable />
          </template>
          <vab-icon-selector @handle-icon="handleIcon" />
        </el-popover>
      </el-form-item>
      <el-form-item label="菜单排序" prop="sort">
        <el-input v-model="form.sort" />
      </el-form-item>
      <el-form-item v-if="form.type == 1" label="隐藏" prop="hidden">
        <el-switch
          v-model="form.hidden"
          :active-value="1"
          class="switchStyle"
          :inactive-value="0"
        />
      </el-form-item>
      <el-form-item label="状态" prop="avoid">
        <el-switch
          v-model="form.avoid"
          :active-value="0"
          class="switchStyle"
          :inactive-value="1"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import VabIconSelector from '@/extra/VabIconSelector'
  import { addSystemMenu } from '@/api/settingAuthority'

  export default {
    name: 'MenuManagementEdit',
    components: { VabIconSelector },
    props: {
      list: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        method: ['GET', 'POST', 'PUT', 'DELETE'],
        form: {
          type: 1,
          avoid: 0,
          hidden: 0,
        },
        value1: '100',
        rules: {
          name: [{ required: true, trigger: 'blur', message: '请输入name' }],
          path: [{ required: true, trigger: 'blur', message: '请输入path' }],
          component: [
            { required: true, trigger: 'blur', message: '请输入component' },
          ],
          title: [{ required: true, trigger: 'blur', message: '请输入标题' }],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      handleIcon(item) {
        this.form.icon = item
      },
      showEdit(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.title = '编辑'
          row.type = row.type || this.form.type
          this.form = Object.assign({}, row)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await addSystemMenu(this.form)
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>
<style scoped>
  .el-input,
  .el-select,
  .el-cascader,
  .el-switch {
    width: 200px;
  }
</style>
