export const myComponents = [
  {
    title: '选择题',
    class: 'select',
    data: [
      {
        label: '单选题',
        type: 'radio',
      },
      {
        label: '多选题',
        type: 'checkbox',
      },
      {
        label: '矩阵单选',
        type: 'matrix_radio',
      },
      {
        label: '矩阵多选',
        type: 'matrix_checkbox',
      },
    ],
  },
  {
    title: '填空题',
    class: 'write',
    data: [
      {
        label: '填空',
        type: 'text',
      },
      {
        label: '日期',
        type: 'picker-date',
      },
      {
        label: '地区',
        type: 'picker-area',
      },
      {
        label: '时间',
        type: 'picker-time',
      },
      /*{
        label: '性别',
        type: 'picker-sex',
      },*/
      {
        label: '手机',
        type: 'phone',
      },
    ],
  },
  {
    title: '其他',
    class: 'other',
    data: [
      {
        label: '分页',
        type: 'page',
      },
    ],
  },
]
