<template>
  <div class="pay-setting-container">
    <el-card shadow="never">
      <div slot="header" class="header">支付设置</div>
      <el-form ref="form" label-width="80px" :model="form">
        <el-form-item label="开发者ID" prop="appid">
          <el-input
            v-model="form.appid"
            placeholder="请输入开发者ID，即AppID"
          />
        </el-form-item>
        <el-form-item label="开发者密码" prop="appsecret">
          <el-input
            v-model="form.appsecret"
            placeholder="请输入开发者密码，即AppSecret"
          />
        </el-form-item>
        <el-form-item label="商户号ID" prop="shopid">
          <el-input v-model="form.shopid" placeholder="请输入商户号ID" />
        </el-form-item>
        <el-form-item label="商户号密钥" prop="paysecret">
          <el-input v-model="form.paysecret" placeholder="请输入商户号密钥" />
        </el-form-item>
        <el-form-item label="说明标题" prop="title">
          <el-input v-model="form.title" placeholder="请输入支付说明标题" />
        </el-form-item>
        <el-form-item label="说明内容" prop="content">
          <editor
            v-model="form.content"
            :is-clear="false"
            @change="editorChange"
          />
        </el-form-item>
        <el-form-item>
          <el-button size="medium" type="primary" @click="save">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  import editor from '@/components/editor'
  import { paySettings } from '@/api/user'
  export default {
    name: 'PaySetting',
    components: { editor },
    data() {
      return {
        form: {},
        rules: {
          appid: [
            { required: true, message: '请输入开发者ID', trigger: 'blur' },
          ],
          appsecret: [
            {
              required: true,
              message: '请输入开发者密码',
              trigger: 'blur',
            },
          ],
          shopid: [{ required: true, message: '商户号ID', trigger: 'blur' }],
          paysecret: [
            { required: true, message: '商户号密钥', trigger: 'blur' },
          ],
          title: [
            {
              required: true,
              message: '请输入支付说明标题',
              trigger: 'blur',
            },
          ],
          content: [
            { required: true, message: '请输入支付说明内容', trigger: 'blur' },
          ],
        },
      }
    },
    created() {
      this.getSetting()
    },
    methods: {
      editorChange(val) {
        //this.form.content = val
      },
      initData(d) {
        if (!d) d = {}
        return {
          appid: d.appid || '',
          appsecret: d.appsecret || '',
          shopid: d.shopid || '',
          paysecret: d.paysecret || '',
          title: d.title || '',
          content: d.content || '',
        }
      },
      async getSetting() {
        const { data } = await paySettings({ type: 'info' })
        this.form = this.initData(data)
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let p = this.form
            p.type = 'edit'
            const { data } = await paySettings(p)
            this.$baseMessage(data.msg, data.code === 200 ? 'success' : 'error')
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style lang="scss">
  .pay-setting-container {
    .el-card {
      border: 0 !important;
    }
    .el-card__header {
      padding-left: 0 !important;
      padding-top: 0 !important;
    }
    .el-input {
      width: 520px !important;
    }
    .el-form-item--small .el-form-item__label {
      color: #999;
    }
    .header {
      position: relative;
      font-size: 16px;
      font-weight: bold;
      padding: 0 20px;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 6px;
        background: #26cdb7;
        border-radius: 3px 0 0 3px;
      }
    }
  }
</style>
