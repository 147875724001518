<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="渠道名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入渠道名称" />
      </el-form-item>
      <el-form-item label="渠道编码" prop="trench">
        <el-input
          v-model="form.trench"
          placeholder="请输入渠道编码（英文字母）"
        />
      </el-form-item>
      <el-form-item label="使用版本" prop="version">
        <el-input v-model="form.version" placeholder="请输入使用版本" />
      </el-form-item>
      <el-form-item label="使用平台" prop="platform">
        <el-checkbox-group v-model="form.platform">
          <el-checkbox label="1">付费测评</el-checkbox>
          <el-checkbox label="2">团测</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="有效期" prop="effect_time">
        <el-date-picker
          v-model="form.effect_time"
          end-placeholder="结束日期"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item
        v-if="form.platform.includes('2')"
        label="是否限制"
        prop="is_limit"
      >
        <el-radio-group v-model="form.is_limit">
          <el-radio label="10">限制</el-radio>
          <el-radio label="20">不限制</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.is_limit == '10'" label="人数" prop="limit_num">
        <el-input-number
          v-model="form.limit_num"
          controls-position="right"
          :max="99999999"
          :min="1"
          style="width: 100%"
        />
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio label="10">正常</el-radio>
          <el-radio label="20">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="开启AI" prop="open_ai">
        <el-switch
          v-model="form.open_ai"
          :active-value="1"
          :inactive-value="0"
        />
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { editStudio } from '@/api/platformChannelManagement'
  import { isNumber } from '@/utils/validate'

  export default {
    name: 'PlatformChannelManagementEdit',
    data() {
      const validateTrench = (rule, value, callback) => {
        const reg = /^[a-zA-Z]+$/
        if (!reg.test(value)) {
          callback(new Error('请输入英文字母'))
        } else callback()
      }
      return {
        form: {
          name: '',
          version: '',
          over_at: '',
          status: '10',
          is_limit: '10',
          remark: '',
          trench: '',
          start_time: '',
          end_time: '',
          effect_time: [],
          limit_num: '',
          open_ai: 0,
          platform: ['2'],
        },
        rules: {
          name: [
            { required: true, trigger: 'blur', message: '请输入渠道名称' },
          ],
          trench: [
            { required: true, trigger: 'blur', validator: validateTrench },
          ],
          version: [
            {
              required: true,
              trigger: 'blur',
              message: '请输入自定义版本名称',
            },
          ],
          effect_time: [
            { required: true, trigger: 'blur', message: '请选择有效期' },
          ],
          limit_num: [
            { required: true, trigger: 'blur', message: '请输入人数' },
          ],
          is_limit: [
            { required: true, trigger: 'blur', message: '请选择是否限制' },
          ],
          platform: [
            {
              type: 'array',
              required: true,
              message: '请至少选择一项',
              trigger: 'change',
            },
          ],
        },
        title: '',
        dialogFormVisible: false,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
          },
        },
      }
    },
    created() {},
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.title = '编辑'
          this.getInfo(row.id)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.form.type = this.form.id ? 'edit' : 'add'
            this.form.start_time = this.form.effect_time[0]
            this.form.end_time = this.form.effect_time[1]
            const { msg, code } = await editStudio(this.form)
            if (code === 200) {
              this.$baseMessage(msg, 'success')
              this.$emit('fetch-data')
              this.close()
            } else {
              this.$baseMessage(msg, 'error')
            }
          } else {
            return false
          }
        })
      },
      getInfo(id) {
        editStudio({ id: id, type: 'info' }).then((res) => {
          let row = res.data
          this.form = Object.assign({}, row)
          this.form.status = String(row.status)
          this.form.is_limit = String(row.is_limit)
          if (row.start_time && row.end_time) {
            // 构建日期数据
            let dateArray = new Array()
            dateArray.push(row.start_time, row.end_time)
            this.$set(this.form, 'effect_time', dateArray)
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
