var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "set" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "80px", model: _vm.form } },
        [
          _c("el-form-item", { attrs: { label: "二维码", prop: "img" } }, [
            _c("div", { staticClass: "img-uploader" }, [
              _c(
                "div",
                {
                  staticClass: "el-upload el-upload__text",
                  on: { click: _vm.imgDialog },
                },
                [
                  _vm.form.img
                    ? _c("img", {
                        staticClass: "img",
                        attrs: { src: _vm.form.img },
                      })
                    : _c("i", { staticClass: "el-icon-plus" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c("div", [
                    _vm._v(" 只能上传 "),
                    _c("span", [_vm._v(".jpg、.png")]),
                    _vm._v(" 文件，且 "),
                    _c("span", [_vm._v("不超过1M")]),
                  ]),
                  _c("div", [_vm._v("(图片尺寸1:1)")]),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("gallery", { ref: "gallery", on: { checkedImg: _vm.getImg } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }