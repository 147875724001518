<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="form.typeName"
    :visible.sync="dialogFormVisible"
    width="700px"
    @close="close"
  >
    <el-form ref="form" class="form" label-width="140px" :model="form">
      <el-form-item
        label="选择关联题目"
        prop="index"
        :rules="{
          required: true,
          message: '请选择关联题目',
          trigger: 'blur',
        }"
      >
        <el-select
          v-model="form.index"
          clearable
          filterable
          placeholder="请选择"
        >
          <el-option
            v-for="(item, index) in list"
            :key="index"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.index !== false && form.index !== ''"
        label="选择关联选项"
        prop="select"
        :rules="{
          required: true,
          message: '请选择关联选项',
          trigger: 'blur',
        }"
      >
        <template v-for="(opt, o) in list">
          <template v-if="opt.id === form.index">
            <el-radio-group :key="o" v-model="form.select">
              <el-radio
                v-for="(item, index) in opt.options"
                :key="index"
                :label="index"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </template>
        </template>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">完成编辑</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: 'Radio',
    data() {
      return {
        list: [],
        form: {},
        num: 0,
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit(data, row, index) {
        this.form = JSON.parse(JSON.stringify(row))
        this.num = index
        this.list = data.filter((li, j) => {
          return (li.type === 'radio' || li.type === 'checkbox') && j < index
        })
        if (this.form.index === false) {
          this.form.index = ''
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let data = {
              index: this.num,
              data: this.form,
            }
            this.$emit('update-item', data)
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-dialog {
      &__footer {
        padding-top: 0;
      }
    }
    .el-radio,
    .el-checkbox {
      display: flex;
      line-height: 1.3;
      margin-right: 0;
      white-space: normal;
      margin-top: 8px;
      &__inner {
        margin-top: 2px !important;
      }
      &__label {
        flex: 1;
      }
    }
  }
  .form {
    .option-item {
      display: flex;
      align-items: center;
    }

    .ipt {
      width: 80%;
      margin-right: 15px;
    }
  }
</style>
