var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "order-eval-set-container" },
    [
      _c(
        "el-tabs",
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "参数设置" } },
            [_c("params")],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "报告设置" } },
            [_c("record")],
            1
          ),
          _c("el-tab-pane", { attrs: { label: "分类设置" } }, [_c("cate")], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }