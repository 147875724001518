<template>
  <div class="right1">
    <Echart id="right1" height="100%" :options="options" width="100%" />
  </div>
</template>

<script>
  import Echart from '@/extra/VabChart/echart'
  export default {
    components: {
      Echart,
    },
    props: {
      cdata: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        options: {},
      }
    },
    watch: {
      cdata: {
        handler(newData) {
          // 固定样式数据
          let lineStyle = {
            width: 1,
            type: 'dashed',
          }

          this.options = {
            color: ['#67F9D8', '#FFE434', '#56A3F1', '#FF917C'],
            tooltip: {
              trigger: 'item',
            },
            legend: {
              data: ['Data A', 'Data B', 'Data C', 'Data D'],
              bottom: 0,
            },
            radar: {
              indicator: newData.indicatorData,
              shape: 'circle',
              splitNumber: 4,
              radius: 70,
              center: ['50%', '40%'],
              name: {
                textStyle: {
                  color: 'rgb(255,255,255)',
                },
              },
              splitArea: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  color: 'rgba(238,239,241,0.5)',
                },
              },
              splitLine: {
                lineStyle: {
                  color: 'rgba(238,239,241,0.5)',
                },
              },
            },
            series: [
              {
                name: '北京',
                type: 'radar',
                lineStyle: lineStyle,
                data: newData.dataBJ,
                symbol: 'circle',
                symbolSize: 6,
              },
            ],
          }
        },
        immediate: true,
        deep: true,
      },
    },
    mounted() {},
    methods: {},
  }
</script>

<style lang="scss">
  .right1 {
    height: calc(100% - 0.6rem);
  }
</style>
