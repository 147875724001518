var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "编辑预警项",
        visible: _vm.dialogFormVisible,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _vm.initData
        ? _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "100px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入标题" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预警判断", prop: "jungle" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.jungle,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "jungle", $$v)
                        },
                        expression: "form.jungle",
                      },
                    },
                    _vm._l(_vm.initData.jungle, function (item) {
                      return _c(
                        "el-radio",
                        { key: item.id, attrs: { label: item.id } },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预警类型", prop: "warn_type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.warn_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "warn_type", $$v)
                        },
                        expression: "form.warn_type",
                      },
                    },
                    _vm._l(_vm.initData.type, function (item) {
                      return _c(
                        "el-radio",
                        { key: item.id, attrs: { label: item.id } },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.form.jungle === "1"
                ? [
                    _vm.form.warn_type === "1"
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "因子分",
                              prop: "factor_radio",
                              rules: [
                                {
                                  required: _vm.form.warn_type === "1",
                                  message: "请选择因子分",
                                  trigger: "change",
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.form.factor_radio,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "factor_radio", $$v)
                                  },
                                  expression: "form.factor_radio",
                                },
                              },
                              _vm._l(_vm.initData.factor, function (item) {
                                return _c(
                                  "el-radio",
                                  { key: item.id, attrs: { label: item.id } },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "题目",
                              prop: "quest",
                              rules: [
                                {
                                  required: true,
                                  message: "请输入题号",
                                  trigger: "blur",
                                },
                                {
                                  type: "number",
                                  message: "题号必须为数字值",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入题号" },
                              model: {
                                value: _vm.form.quest,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "quest", _vm._n($$v))
                                },
                                expression: "form.quest",
                              },
                            }),
                          ],
                          1
                        ),
                  ]
                : [
                    _vm.form.warn_type === "1"
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "因子分",
                              prop: "factor_checkbox",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择因子分",
                                  trigger: "change",
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                model: {
                                  value: _vm.form.factor_checkbox,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "factor_checkbox", $$v)
                                  },
                                  expression: "form.factor_checkbox",
                                },
                              },
                              _vm._l(_vm.initData.factor, function (item) {
                                return _c(
                                  "el-checkbox",
                                  { key: item.id, attrs: { label: item.id } },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "题目",
                              prop: "quest",
                              rules: [
                                {
                                  required: true,
                                  message: "请输入如题号",
                                  trigger: "blur",
                                },
                                {
                                  pattern: /^(\d+,?)+$/,
                                  message: "只能输入数字和英文逗号",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "多个题目请用英文逗号隔开",
                              },
                              model: {
                                value: _vm.form.quest,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "quest", $$v)
                                },
                                expression: "form.quest",
                              },
                            }),
                          ],
                          1
                        ),
                  ],
              _c(
                "el-form-item",
                { attrs: { label: "计分类型", prop: "score" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.score,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "score", $$v)
                        },
                        expression: "form.score",
                      },
                    },
                    _vm._l(_vm.initData.score, function (item) {
                      return _c(
                        "el-radio",
                        { key: item.id, attrs: { label: item.id } },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预警分值", prop: "warn_score" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入预警范围，例如：(1,20]" },
                    model: {
                      value: _vm.form.warn_score,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "warn_score", $$v)
                      },
                      expression: "form.warn_score",
                    },
                  }),
                  _c("div", { staticClass: "tips" }, [
                    _vm._v(
                      " “[]”英文中括号代表包含边界值，“()”英文小括号代表不包含边界值，比如(1,20]代表的范围为1到20，不包含1，包含20 "
                    ),
                  ]),
                ],
                1
              ),
              _vm.form.jungle === "2"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "预警项目数",
                        prop: "warn_nums",
                        rules: [
                          {
                            required: true,
                            message: "请输入预警项目数",
                            trigger: "blur",
                          },
                          {
                            pattern: /(\(|\[)(\d+,\d+)(?=\]|\))/g,
                            message: "格式不正确",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入预警项目数范围" },
                        model: {
                          value: _vm.form.warn_nums,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "warn_nums", $$v)
                          },
                          expression: "form.warn_nums",
                        },
                      }),
                      _c("div", { staticClass: "tips" }, [
                        _vm._v(
                          " “[]”英文中括号代表包含边界值，“()”英文小括号代表不包含边界值，比如(1,20]代表的范围为1到20，不包含1，包含20 "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }