var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-management-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 24, md: 24, sm: 24, xl: 24, xs: 24 } },
            [
              _c(
                "el-card",
                { attrs: { shadow: "hover" } },
                [
                  _c(
                    "vab-query-form",
                    [
                      _c(
                        "vab-query-form-top-panel",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permissions",
                                  rawName: "v-permissions",
                                  value: { permission: ["addMenu"] },
                                  expression: "{ permission: ['addMenu'] }",
                                },
                              ],
                              attrs: { icon: "el-icon-plus", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit()
                                },
                              },
                            },
                            [_vm._v(" 添加 ")]
                          ),
                          _c("el-button", { on: { click: _vm.toggle } }, [
                            _vm._v("展开/折叠"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "tableTree",
                      attrs: {
                        data: _vm.list,
                        "row-key": "id",
                        "tree-props": {
                          children: "children",
                          hasChildren: "hasChildren",
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "菜单标题",
                          "min-width": "150",
                          prop: "title",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "类型",
                          "min-width": "100",
                          prop: "type_text",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "组件名称",
                          "min-width": "100",
                          prop: "name",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "图标",
                          "min-width": "80",
                          prop: "icon",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                row.icon
                                  ? _c(
                                      "span",
                                      [
                                        row.icon
                                          ? _c("vab-icon", {
                                              attrs: { icon: row.icon },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "权限标识",
                          "min-width": "120",
                          prop: "ability",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "状态",
                          "min-width": "100",
                          prop: "avoid",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                row.avoid == 0
                                  ? _c("el-tag", [
                                      _vm._v(_vm._s(row.avoid_text)),
                                    ])
                                  : _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [_vm._v(_vm._s(row.avoid_text))]
                                    ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "排序",
                          "min-width": "80",
                          prop: "sort",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          fixed: "right",
                          label: "操作",
                          "show-overflow-tooltip": "",
                          width: "120",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                row.type == 1
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permissions",
                                            rawName: "v-permissions",
                                            value: { permission: ["addMenu"] },
                                            expression:
                                              "{ permission: ['addMenu'] }",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleAddSubmenu(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 添加 ")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permissions",
                                        rawName: "v-permissions",
                                        value: { permission: ["addMenu"] },
                                        expression:
                                          "{ permission: ['addMenu'] }",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEdit(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 编辑 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permissions",
                                        rawName: "v-permissions",
                                        value: { permission: ["deleteMenu"] },
                                        expression:
                                          "{ permission: ['deleteMenu'] }",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDelete(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("edit", {
        ref: "edit",
        attrs: { list: _vm.list },
        on: { "fetch-data": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }