<template>
  <div class="article">
    <vab-query-form>
      <vab-query-form-left-panel :span="2">
        <el-button icon="el-icon-plus" type="primary" @click="handleEdit">
          新增
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="22">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.search_value"
              clearable
              placeholder="请输入标题"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.cate"
              clearable
              filterable
              placeholder="请选择分类"
            >
              <el-option
                v-for="item in cateList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="课程名称" prop="title" />
      <el-table-column align="center" label="缩略图">
        <template #default="{ row }">
          <el-image :src="row.cover" />
        </template>
      </el-table-column>
      <el-table-column align="center" label="分类" prop="cate_name" />
      <el-table-column align="center" label="开放渠道" prop="open_status" />
      <el-table-column align="center" label="预览" prop="link">
        <template #default>
          <el-button type="text">复制链接</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建时间" prop="created_at" />
      <el-table-column align="center" label="操作" width="260px">
        <template #default="{ row }">
          <el-button
            icon="el-icon-edit-outline"
            type="primary"
            @click="handleEdit(row)"
          >
            编辑
          </el-button>
          <el-button
            icon="el-icon-notebook-1"
            @click="handleEdit(row, 'chapter')"
          >
            章节
          </el-button>
          <el-button type="danger" @click="delRow(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit v-bind="$attrs" ref="edit" @fetch-data="fetchData" />
    <chapter ref="chapter" />
  </div>
</template>

<script>
  import { delCourse, getCateList, getList } from '@/api/resource'
  import Edit from './edit'
  import Chapter from '../chapter/list'

  export default {
    name: 'ResVideoList',
    components: { Edit, Chapter },
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          type: 1,
          cate: '',
          search_value: '',
        },
        cateList: [],
      }
    },
    created() {
      this.getCate()
      this.fetchData()
    },
    methods: {
      delRow(row) {
        this.$baseConfirm('你确定要删除当前视频吗', null, async () => {
          const { msg } = await delCourse({ id: row.id })
          this.$baseMessage(msg, 'success')
          await this.fetchData()
        })
      },
      async getCate() {
        const { all } = await getCateList()
        this.cateList = all
      },
      handleEdit(row, type) {
        if (type === 'chapter') {
          this.$refs['chapter'].showDrawer(row)
        } else {
          if (row.id) {
            this.$refs['edit'].showEdit(row, this.cateList)
          } else {
            this.$refs['edit'].showEdit('', this.cateList)
          }
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>

<style scoped lang="scss"></style>
