import request from '@/utils/request'
//列表
export function getList(params) {
  return request({
    url: '/resource/list',
    method: 'get',
    params,
  })
}

//新增
export function add(data) {
  return request({
    url: '/resource/add',
    method: 'post',
    data,
  })
}

//编辑
export function doEdit(data) {
  return request({
    url: '/resource/edit',
    method: 'post',
    data,
  })
}

//详情
export function getDetail(params) {
  return request({
    url: '/resource/info',
    method: 'get',
    params,
  })
}

//删除
export function delCourse(params) {
  return request({
    url: '/resource/del',
    method: 'get',
    params,
  })
}

//分类列表
export function getCateList(params) {
  return request({
    url: '/resource/cate',
    method: 'get',
    params,
  })
}

//新增、修改分类
export function editCate(data) {
  return request({
    url: '/resource/add-cate',
    method: 'post',
    data,
  })
}

//删除分类
export function delCate(params) {
  return request({
    url: '/resource/del-cate',
    method: 'get',
    params,
  })
}

//章节列表
export function getChapterList(params) {
  return request({
    url: '/resource/chapter',
    method: 'get',
    params,
  })
}

//新增、修改章节
export function editChapter(data) {
  return request({
    url: '/resource/edit-chapter',
    method: 'post',
    data,
  })
}

//删除章节
export function delChapter(params) {
  return request({
    url: '/resource/del-chapter',
    method: 'get',
    params,
  })
}

//用户获取平台资源列表
export function getListBy(params) {
  return request({
    url: '/resource/plat-list',
    method: 'get',
    params,
  })
}

//用户上架平台资源
export function upLibrary(params) {
  return request({
    url: '/resource/added',
    method: 'get',
    params,
  })
}
