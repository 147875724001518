var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "templates-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { border: "", data: _vm.list },
        },
        [
          _c("el-table-column", { attrs: { align: "center", type: "index" } }),
          _c("el-table-column", {
            attrs: { label: "模板标题", prop: "title" },
          }),
          _c("el-table-column", {
            attrs: { label: "信息格式", prop: "content" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", {
                      staticStyle: { "white-space": "pre-wrap" },
                      domProps: { innerHTML: _vm._s(row.content) },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "示例", prop: "example" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", {
                      staticStyle: { "white-space": "pre-wrap" },
                      domProps: { innerHTML: _vm._s(row.example) },
                    }),
                  ]
                },
              },
            ]),
          }),
          false
            ? _c("el-table-column", {
                attrs: { align: "center", label: "操作" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.createMsg(row)
                                },
                              },
                            },
                            [_vm._v("创建")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3361495759
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      false
        ? _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.queryForm.pageNo,
              layout: _vm.layout,
              "page-size": _vm.queryForm.pageSize,
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }