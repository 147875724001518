<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="800px"
    @close="close"
  >
    <el-form
      ref="form"
      label-position="right"
      label-width="90px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="课程名称" prop="title">
        <el-input v-model="form.title" placeholder="请输入课程名称" />
      </el-form-item>
      <el-form-item label="课程简介" prop="introduce">
        <el-input
          v-model="form.introduce"
          placeholder="请输入课程简介"
          rows="5"
          type="textarea"
        />
      </el-form-item>
      <el-form-item label="章节数量" prop="chapter_sum">
        <el-input-number
          v-model="form.chapter_sum"
          controls-position="right"
          :min="1"
        />
      </el-form-item>
      <el-form-item label="缩略图" prop="cover">
        <div class="img-uploader">
          <div class="el-upload el-upload__text" @click="selImg">
            <img v-if="form.cover" class="img" :src="form.cover" />
            <i v-else class="el-icon-plus"></i>
          </div>
          <div slot="tip" class="el-upload__tip">
            <div>
              只能上传
              <span>.jpg、.png</span>
              文件，且
              <span>不超过1M</span>
            </div>
            <div>(图片尺寸3:2)</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="分类" prop="cate">
        <el-select
          v-model="form.cate"
          clearable
          filterable
          placeholder="请选择分类"
        >
          <el-option
            v-for="item in cateList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
        <el-button
          icon="el-icon-refresh"
          style="font-size: 15px; padding-left: 10px"
          title="刷新"
          type="text"
          @click="getCate"
        />
        <el-button type="text" @click="addCate">新增</el-button>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input v-model="form.sort" placeholder="数字越大越靠前" />
      </el-form-item>
      <el-form-item label="课程介绍" prop="descr">
        <editor v-model="form.descr" :is-clear="false" @change="editorChange" />
      </el-form-item>
      <el-form-item label="课程类型" prop="vd_type">
        <el-radio-group v-model="form.vd_type">
          <el-radio :label="1">音频</el-radio>
          <el-radio :label="2">视频</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否推荐" prop="recom">
        <el-switch v-model="form.recom" :active-value="1" :inactive-value="0" />
      </el-form-item>
      <el-form-item label="开放组织" prop="all_group">
        <template slot="label">
          开放组织
          <el-tooltip
            content="默认即表示开放给所有组织结构"
            effect="dark"
            placement="right-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
        <el-transfer
          v-model="form.all_group"
          :data="originList"
          filterable
          :props="{ key: 'id', label: 'name' }"
          :titles="['组织列表', '已选组织']"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
    <select-img ref="selectImg" @checkedImg="getImg" />
    <cate ref="cate" />
  </el-dialog>
</template>

<script>
  import { add, doEdit, getDetail } from '@/api/resource'
  import SelectImg from '@/components/gallery'
  import Editor from '@/components/wangEditor'
  import Cate from './cateEdit'
  import { isNumber } from '@/utils/validate'
  import { getCurStudioOrgin } from '@/api/userManagementOrganization'

  export default {
    name: 'VideoEdit',
    components: { SelectImg, Editor, Cate },
    props: {
      cate: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      const validateSort = (rule, value, callback) => {
        if (!isNumber(value)) {
          callback(new Error('请输入正整数'))
        } else {
          callback()
        }
      }
      return {
        originList: [],
        title: '',
        showDialog: false,
        form: {},
        rules: {
          title: [{ required: true, trigger: 'blur', message: '请输入标题' }],
          introduce: [
            { required: true, trigger: 'blur', message: '请输入简介' },
          ],
          sort: [{ validator: validateSort, trigger: 'blur' }],
          descr: [{ required: true, message: '课程介绍不能为空' }],
        },
        cateList: [],
      }
    },
    watch: {
      cate: {
        handler(newData) {
          this.cateList = newData
        },
        immediate: true,
        deep: true,
      },
    },
    created() {
      this.getOriginList()
    },
    methods: {
      addCate() {
        this.$refs['cate'].showEdit('', 1)
      },
      getCate() {
        this.$parent.getCate()
      },
      async getOriginList() {
        const { data } = await getCurStudioOrgin()
        this.originList = data
      },
      initData(param) {
        if (!param) param = {}
        this.form = {
          title: param.title || '',
          introduce: param.introduce || '',
          cover: param.cover || '',
          cate: param.cate || '',
          sort: param.sort || 0,
          course_type: param.course_type || 0,
          descr: param.descr || '',
          url: param.url || '',
          type: 1,
          all_group: param.all_group || [],
          chapter_sum: param.chapter_sum || 1,
          source: param.source == 0 ? 0 : 1,
          recom: param.recom || '',
          vd_type: param.vd_type || 0,
        }
      },
      editorChange(val) {
        this.form.descr = val
      },
      selImg() {
        this.$refs['selectImg'].showEdit()
      },
      getImg(val) {
        this.form.cover = val.url
        this.$forceUpdate()
      },
      showEdit(row, cate) {
        this.cateList = cate
        if (!row) {
          this.title = '添加'
          this.initData()
        } else {
          this.title = '编辑'
          getDetail({ id: row.id, type: 'course' }).then((res) => {
            let data = res.data
            this.initData(data)
            this.form.id = data.id
          })
        }
        this.showDialog = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.showDialog = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.form.id) {
              const { msg } = await doEdit(this.form)
              this.$baseMessage(msg, 'success')
            } else {
              const { msg } = await add(this.form)
              this.$baseMessage(msg, 'success')
            }

            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss"></style>
