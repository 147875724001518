<template>
  <div class="set">
    <el-row>
      <el-col :lg="{ span: 12 }" :md="{ span: 16 }" :xs="{ span: 24 }">
        <el-form ref="form" label-width="80px" :model="form">
          <el-form-item label="平台名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入平台名称" />
          </el-form-item>
          <el-form-item label="logo" prop="logo">
            <div class="img-uploader">
              <div class="el-upload el-upload__text" @click="imgDialog">
                <img v-if="form.logo" class="img" :src="form.logo" />
                <i v-else class="el-icon-plus"></i>
              </div>
              <div slot="tip" class="el-upload__tip">
                <div>
                  只能上传
                  <span>.jpg、.png</span>
                  文件，且
                  <span>不超过1M</span>
                </div>
                <div>(图片尺寸1:1)</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="开发者ID" prop="appid">
            <el-input
              v-model="form.appid"
              placeholder="请输入开发者ID，即AppID"
            />
          </el-form-item>
          <el-form-item label="开发者密码" prop="appsecret">
            <el-input
              v-model="form.appsecret"
              placeholder="请输入开发者密码，即AppSecret"
            />
          </el-form-item>
          <el-form-item label="商户号ID" prop="shopid">
            <el-input v-model="form.shopid" placeholder="请输入商户号ID" />
          </el-form-item>
          <el-form-item label="商户号密钥" prop="paysecret">
            <el-input v-model="form.paysecret" placeholder="请输入商户号密钥" />
          </el-form-item>
          <el-form-item>
            <el-button size="medium" type="primary" @click="save">
              保存
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <gallery ref="gallery" @checkedImg="getImg" />
  </div>
</template>

<script>
  import { setPay } from '@/api/psyeval'
  import Gallery from '@/components/gallery/index.vue'

  export default {
    name: 'Params',
    components: { Gallery },
    data() {
      return {
        form: {},
        rules: {
          appid: [
            { required: true, message: '请输入开发者ID', trigger: 'blur' },
          ],
          appsecret: [
            {
              required: true,
              message: '请输入开发者密码',
              trigger: 'blur',
            },
          ],
          shopid: [{ required: true, message: '商户号ID', trigger: 'blur' }],
          paysecret: [
            { required: true, message: '商户号密钥', trigger: 'blur' },
          ],
        },
      }
    },
    created() {
      this.getSetting()
    },
    methods: {
      initData(d) {
        if (!d) d = {}
        return {
          name: d.name || '',
          logo: d.logo || '',
          appid: d.appid || '',
          appsecret: d.appsecret || '',
          shopid: d.shopid || '',
          paysecret: d.paysecret || '',
        }
      },
      async getSetting() {
        const { data } = await setPay({ type: 'info' })
        this.form = this.initData(data)
      },
      imgDialog() {
        this.$refs['gallery'].showEdit()
      },
      getImg(val) {
        this.form.logo = val.url
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let p = this.form
            p.type = 'edit'
            const { data } = await setPay(p)
            this.$baseMessage(data.msg, data.code === 200 ? 'success' : 'error')
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style lang="scss"></style>
