<template>
  <el-drawer
    class="add-subject-container"
    :close-on-click-modal="false"
    size="75%"
    :title="title"
    :visible.sync="drawer"
  >
    <!--题目列表-->
    <div v-loading="loading" class="ti-box">
      <div class="ti-title">
        <div>
          <el-button type="primary" @click="uploadSubject">导入题目</el-button>
          <span style="margin-left: 10px">
            支持批量导入，请先
            <el-button title="下载模版" type="text" @click="downLoadDoc">
              下载模版
            </el-button>
          </span>
        </div>
        <el-button class="btn" type="primary" @click="submit">
          发布量表
        </el-button>
      </div>
      <div class="ti-subject">
        <div v-for="(item, index) in list" :key="index" class="item">
          <div class="title">
            <div class="l-name">
              {{ item.title }}
            </div>
          </div>
          <div class="option">
            <div v-for="(rdo, j) in item.options" :key="j" :class="`el-radio`">
              <span :class="`el-radio__inner`"></span>
              <span :class="`el-radio__label`">
                {{ rdo.label }}
              </span>
              <div v-if="rdo.score" style="color: #999; font-size: 12px">
                {{ rdo.score + ' 分' }}
              </div>
            </div>
          </div>
          <div class="operation">
            <el-button type="text" @click="handleEdit(item, index)">
              编辑
            </el-button>
            <el-button
              v-if="list.length > 1"
              class="del"
              type="text"
              @click="removeSub(item)"
            >
              删除
            </el-button>
            <el-button
              :disabled="index === 0"
              type="text"
              @click="upRecord(list, index)"
            >
              上移
            </el-button>
            <el-button
              :disabled="index === list.length - 1"
              type="text"
              @click="downRecord(list, index)"
            >
              下移
            </el-button>
            <el-button
              :disabled="index === 0"
              type="text"
              @click="toFirst(list, index)"
            >
              最前
            </el-button>
            <el-button
              :disabled="index === list.length - 1"
              type="text"
              @click="toLast(list, index)"
            >
              最后
            </el-button>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="tip">
          <el-button plain style="width: 40%" type="primary" @click="addType">
            添加题目
          </el-button>
        </div>
      </div>
    </div>
    <radio ref="radio" @update-item="updateItem" />
    <upload ref="upload" @refresh="showDrawer" />
  </el-drawer>
</template>

<script>
  import { editSubject, getEvalSubject } from '@/api/eval'
  import radio from './editSubject'
  import upload from './exportSubject'
  import { getOrginDoc } from '@/api/userManagementOrganization'
  export default {
    name: 'EditSubject',
    components: {
      radio,
      upload,
    },
    data() {
      return {
        id: '',
        loading: true,
        title: '',
        list: [],
        initData: {},
        docUrl: '',
        drawer: false,
      }
    },
    created() {},
    methods: {
      uploadSubject() {
        this.$refs['upload'].showEdit(this.id)
      },
      getDoc() {
        getOrginDoc({ type: 'quest' }).then((res) => {
          if (res.code === 200) {
            this.docUrl = res.data
          }
        })
      },
      downLoadDoc() {
        window.location.href = this.docUrl
      },
      initItem() {
        //拿到当前list中最大的ID,设置ID
        let maxId = Math.max.apply(
          Math,
          this.list.map(function (o) {
            return o.id
          })
        )
        let id = this.list.length ? maxId + 1 : 1
        let data = {
          id: id,
          title: '题目',
          options: [
            {
              label: '选项1',
              score: '',
            },
            {
              label: '选项2',
              score: '',
            },
          ],
        }
        return data
      },
      showDrawer(id) {
        this.id = id
        this.$nextTick(() => {
          this.title = id ? '编辑量表' : '新增量表'
          if (id) {
            this.getDetailById(id)
          } else {
            let data = this.initItem()
            this.list.push(data)
            this.loading = false
          }
        })
        this.getDoc()
        this.drawer = true
      },
      async getDetailById(id) {
        this.loading = true
        //let page = 1
        const { data } = await getEvalSubject({ id })
        this.initData = data
        this.list = data.content.map((item, index) => {
          if (item.id === undefined) {
            item.id = index
          }
          return item
        })
        this.loading = false
      },
      addType() {
        let item = this.initItem()
        this.list.push(item)
      },
      removeSub(item) {
        //删除题目
        let li = this.list[0]
        this.$baseConfirm('确定要删除当前项吗', null, async () => {
          let index = this.list.indexOf(item)
          if (index !== -1) {
            this.list.splice(index, 1)
            let page = 1
            this.list = this.list.map((list) => {
              list.page = page
              if (list.type === 'page') {
                page++
              }
              return list
            })
          }
        })
      },
      updateItem(val) {
        let p = JSON.parse(JSON.stringify(val))
        this.list[p.index] = p.data
        this.$forceUpdate()
      },
      swapItems(arr, index1, index2) {
        //交换元素 --//数组,元素1，元素2
        arr[index1] = arr.splice(index2, 1, arr[index1])[0]
        return arr
      },
      upRecord(arr, $index) {
        //上移
        if ($index === 0) {
          return
        }
        if (arr[$index].index !== arr[$index - 1].id) {
          this.swapItems(arr, $index, $index - 1)
        } else {
          this.$baseMessage('当前题与上一题为关联关系，无法上移', 'error')
        }
      },
      downRecord(arr, $index) {
        //下移
        if ($index === arr.length - 1) {
          return
        }
        if (arr[$index].id !== arr[$index + 1].index) {
          this.swapItems(arr, $index, $index + 1)
        } else {
          this.$baseMessage('当前题与下一题为关联关系，无法下移', 'error')
        }
      },
      toFirst(fieldData, index) {
        //置顶
        if (index !== 0) {
          if (fieldData[index].type === 'page') {
            this.$baseMessage('分页不能置顶', 'error')
            return false
          }
          // fieldData[index] = fieldData.splice(0, 1, fieldData[index])[0]; 这种方法是与另一个元素交换了位子，
          let arr1 = fieldData.slice(0, index + 1)
          let arr2 = arr1.filter((item) => item.id === fieldData[index].index)
          if (arr2.length > 0) {
            this.$baseMessage('题目存在关联关系，无法置顶', 'error')
            return false
          }
          fieldData.unshift(fieldData.splice(index, 1)[0])
        }
      },
      toLast(fieldData, index) {
        //置底
        // fieldData[index] = fieldData.splice(0, 1, fieldData[index])[0]; 这种方法是与另一个元素交换了位子，
        let arr1 = fieldData.slice(index, fieldData.length)
        let arr2 = arr1.filter((item) => fieldData[index].id === item.index)
        if (arr2.length > 0) {
          this.$baseMessage('题目存在关联关系，无法置底', 'error')
          return false
        }
        fieldData.push(fieldData.splice(index, 1)[0])
      },
      handleEdit(row, index) {
        this.$refs['radio'].showEdit(row, index)
      },
      async submit() {
        let data = this.list
        let id = this.id
        const { msg } = await editSubject(id, {
          content: data,
        })
        this.$baseMessage(msg, 'success')
        this.$emit('fetch-data')
        this.drawer = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .add-subject-container {
    .survey-box {
      display: flex;
      overflow-y: scroll;
    }
    .type-box {
      position: sticky;
      top: 0;
      width: 220px;
      padding-right: 20px;
      border-right: 2px solid #e9e7e7;
      .item {
        margin-bottom: 40px;
        .item-name {
          padding: 3px 10px;
          font-weight: bold;
        }
        .sub-item {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .sub-item-name {
            width: 50%;
            padding: 10px;
            .lbl {
              width: 100%;
            }
          }
        }
      }
    }
    .ti-box {
      display: flex;
      width: 70%;
      margin: auto;
      height: 100%;
      flex-direction: column;
      overflow-y: hidden;
      .ti-title {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        position: sticky;
        top: 0;
        z-index: 10;
        background: #fff;
      }
      .ti-subject {
        flex: 1;
        overflow-y: auto;
        .item {
          background: #f9f9f9;
          padding: 15px 20px;
          &:not(:first-child) {
            margin-top: 15px;
          }
          &:hover {
            background: #eee;
          }
          .title {
            display: flex;
            line-height: 20px;
            .l-name {
              flex: 1;
              span {
                color: red;
              }
            }
            .r-type {
              font-size: 12px;
              color: #999;
              margin-left: 15px;
            }
          }
          .option {
            padding-left: 20px;
            .el-radio,
            .el-checkbox {
              display: flex;
              line-height: 1.3;
              margin-right: 0;
              white-space: normal;
              margin-top: 8px;
              &__inner {
                margin-top: 2px !important;
              }
              &__label {
                flex: 1;
              }
            }
          }
          .matrix-box {
            .matrix-item {
              display: flex;
              margin: 10px 0;
              .matrix-title {
                width: 30%;
              }
              .matrix-sub-item {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .flex1 {
                  flex: 1;
                  text-align: center;
                }
              }
            }
          }
          .page {
            color: #999;
            text-align: center;
            font-size: 12px;
            div {
              margin: 10px 0;
            }
          }
          .txt {
            width: 200px;
            margin: 10px 0;
          }
          .operation {
            text-align: right;
          }
        }
      }
      .bottom {
        text-align: center;
        .tip {
          font-size: 12px;
          color: #999;
          margin: 15px 0;
        }
        .btn {
          width: 100%;
        }
      }
    }
  }
</style>
