<template>
  <el-dialog
    :close-on-click-modal="false"
    title="干预对象"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <div class="form-row">
        <el-form-item label="干预对象">
          <el-input v-model="name" disabled style="width: 220px" />
        </el-form-item>
        <el-form-item label="干预时间" prop="date">
          <el-date-picker
            v-model="form.date"
            :picker-options="pickerOptions"
            placeholder="请选择日期"
            type="date"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="干预时长" prop="times">
          <el-input
            v-model="form.times"
            placeholder="请输入时长"
            style="width: 220px"
          />
          小时
        </el-form-item>
        <el-form-item label="干预问题" prop="quest">
          <el-select
            v-model="form.quest"
            filterable
            placeholder="请选择干预问题"
          >
            <el-option
              v-for="item in questList"
              :key="item.id"
              :label="item.quest"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="干预等级" prop="level">
          <el-select
            v-model="form.level"
            filterable
            placeholder="请选择干预等级"
          >
            <el-option
              v-for="item in levelList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="干预程度" prop="type">
          <el-select
            v-model="form.type"
            filterable
            placeholder="请选择干预程度"
          >
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="内容记录" prop="detail">
          <el-input
            v-model="form.detail"
            placeholder="请输入内容"
            type="textarea"
          />
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getParams, meddleAdd } from '@/api/crisis'
  import { isNum } from '@/utils/validate'

  export default {
    name: 'AddSingleCrisis',
    data() {
      const validateNumber = (rule, value, callback) => {
        if (value.length && !isNum(value)) {
          callback(new Error('请输入正确的数字'))
        } else {
          callback()
        }
      }
      return {
        name: '',
        form: {
          detail: '',
          times: '',
          quest: '',
          date: '',
          userArr: [],
          level: '',
          type: '',
          uid: '',
        },
        rules: {
          times: [
            { required: true, message: '请输入时长', trigger: 'blur' },
            { required: true, trigger: 'blur', validator: validateNumber },
          ],
          quest: [
            { required: true, message: '请选择干预问题', trigger: 'change' },
          ],
          level: [
            { required: true, message: '请选择干预等级', trigger: 'change' },
          ],
          type: [
            { required: true, message: '请选择干预程度', trigger: 'change' },
          ],
          detail: [
            { required: true, message: '请输入内容记录', trigger: 'blur' },
          ],
          date: [
            {
              required: true,
              message: '请选择时间',
              trigger: 'change',
            },
          ],
        },
        dialogFormVisible: false,
        questList: [], //干预问题
        levelList: [], //干预等级
        typeList: [], //干预程度
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now()
          },
        },
      }
    },
    created() {},
    methods: {
      getQuesList(type) {
        getParams({ type: type }).then((res) => {
          this[type + 'List'] = res.data
        })
      },
      showEdit(row) {
        this.name = row.name
        this.form.uid = row.uid
        this.getQuesList('quest')
        this.getQuesList('type')
        this.getQuesList('level')
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let obj = this.form
            let p = {
              detail: obj.detail,
              times: obj.times,
              quest: obj.quest,
              date: obj.date,
              userArr: [
                {
                  uid: obj.uid,
                  level: obj.level,
                  type: obj.type,
                },
              ],
            }
            const { msg } = await meddleAdd(p)
            this.$baseMessage(msg, 'success')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style lang="scss">
  .box {
    display: flex;
    flex-direction: row;
    height: 400px;
    > div {
      &.left {
        width: 260px;
      }
      &.right {
        flex: 1;
      }
      margin: 0 10px;
      border-radius: 8px;
      border: 1px solid #eee;
      padding: 15px;
    }
  }
</style>
