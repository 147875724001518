<template>
  <el-dialog
    :close-on-click-modal="false"
    title="首页图标"
    :visible.sync="dialogVisible"
    width="600px"
    @close="close()"
  >
    <el-form
      ref="form"
      label-position="right"
      label-width="80px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title" placeholder="请输入标题" />
      </el-form-item>
      <el-form-item
        label="排序"
        prop="sort"
        :rules="[
          {
            pattern: /^[0-9]*$/,
            message: '排序必须为正数',
            trigger: 'blur',
          },
        ]"
      >
        <el-input
          v-model.number="form.sort"
          placeholder="请输入数字，数字越大越靠前"
        />
      </el-form-item>
      <el-form-item label="图标">
        <div style="display: flex">
          <div class="choose-img" @click="imgDialog">
            <el-image v-if="form.cover" fit="contain" :src="form.cover" />
            <i v-else class="el-icon-plus"></i>
          </div>
          <div class="img-tip">推荐尺寸：120*120</div>
        </div>
      </el-form-item>
      <el-form-item label="跳转方式" prop="type">
        <el-radio-group v-model="form.type">
          <el-radio :label="10">内部链接</el-radio>
          <el-radio :label="20">外部链接</el-radio>
          <el-radio v-if="false" :label="30">富文本</el-radio>
        </el-radio-group>
        <div v-if="form.type === 10">
          <div class="menu">
            <div v-for="(item, index) in menuList" :key="index" class="item">
              <label>{{ item.title }}</label>
              <div class="sub-item">
                <el-button
                  v-for="(m, i) in item.data"
                  :key="i"
                  :class="{ active: curItem && curItem.link === m.link }"
                  plain
                  type="primary"
                  @click="chooseUrl(m)"
                >
                  {{ m.name }}
                </el-button>
              </div>
            </div>
          </div>
          <div v-if="curItem && curItem.is_det">
            <el-select
              v-model="url.in"
              filterable
              placeholder="请选择链接"
              style="width: 100%"
              @change="bindChange"
            >
              <el-option
                v-for="(m, _j) in list"
                :key="_j"
                :label="m.title"
                :value="m.url"
              />
            </el-select>
          </div>
        </div>
        <div v-if="form.type === 20">
          <el-input v-model="url.out" placeholder="请输入跳转链接" />
        </div>
        <div v-if="form.type === 30">
          <editor
            v-model="form.content"
            :is-clear="isClear"
            @change="editorChange"
          />
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close()">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
    <gallery ref="gallery" @checkedImg="getImg" />
  </el-dialog>
</template>

<script>
  import Gallery from '@/components/gallery'
  import Editor from '@/components/editor'
  import { getH5SubMenu, editIdxIcon } from '@/api/idx'
  import { isUrl } from '@/utils/validate'
  export default {
    name: 'MIndex',
    components: { Gallery, Editor },
    props: {
      menu: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        title: '',
        form: {
          style: '', //操作类型：add,edit,delete
          title: '',
          sort: '',
          cover: '',
          type: '', //链接类型
          link: '',
          content: '',
        },
        rules: {
          title: [{ required: true, trigger: 'blur', message: '请输入标题' }],
        },
        menuList: [], //内部链接菜单
        list: [], //内部子链接列表
        url: {
          in: '',
          out: '',
          content: '',
        },
        curItem: null,
        isClear: false,
        isIndex: '',
        dialogVisible: false,
      }
    },
    watch: {
      menu: {
        handler(newData) {
          this.menuList = newData
        },
        immediate: true,
        deep: true,
      },
    },
    created() {},
    methods: {
      showEdit(row) {
        if (row) {
          let data = JSON.parse(JSON.stringify(row))
          this.form = data
          this.form.type = data.type * 1
          this.form.style = 'edit'
          this.curItem = data
          if (data.type * 1 === 10) {
            this.url.in = data.link
            this.menuList.map((item, index) => {
              let o = item.data.filter((o) => data.link.includes(o.link))
              if (o.length) {
                this.getSubList(o[0])
                this.curItem = o[0]
              }
            })
          } else if (data.type * 1 === 20) {
            this.url.out = data.link
          }
        } else {
          this.form.style = 'add'
          this.curItem = null
        }
        this.dialogVisible = true
      },
      editorChange(val) {
        console.log(val)
      },
      bindChange(e) {
        this.url.in = e
      },
      async getSubList(data) {
        let p = {
          type: data.type,
          link: data.link,
          param: data.params,
        }
        const { rows } = await getH5SubMenu(p)
        this.list = rows
      },
      chooseUrl(row) {
        this.curItem = row
        if (row.is_det) {
          this.list = []
          this.url.in = ''
          this.$nextTick(() => {
            this.getSubList(row)
          })
        } else {
          this.url.in = row.link
        }
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogVisible = false
      },
      imgDialog() {
        this.$refs['gallery'].showEdit()
      },
      getImg(val) {
        this.form.cover = val.url
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let p = this.form
            let url = this.url
            if (p.type === 10) {
              if (!url.in) {
                this.$baseMessage('请选择内部链接', 'warning')
                return false
              }
              p.link = url.in
            } else if (p.type === 20) {
              if (!url.out) {
                this.$baseMessage('请输入外部链接', 'warning')
                return false
              } else if (!isUrl(url.out)) {
                this.$baseMessage('外部链接格式不正确', 'warning')
                return false
              }
              p.link = url.out
            } else if (p.type === 30) {
              if (!p.content) {
                this.$baseMessage('请输入富文本内容', 'warning')
                return false
              }
            }
            const { msg, code } = await editIdxIcon(p)
            this.$baseMessage(msg, code === 200 ? 'success' : 'error')
            code === 200 && this.$emit('refresh', 'index')
            this.close()
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped></style>
