<template>
  <div class="platform-channel-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="8">
        <el-button
          v-if="button.add"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit"
        >
          添加
        </el-button>
        <el-button
          v-else
          disabled
          icon="el-icon-plus"
          title="暂无权限，如有需要，请联系管理员"
          type="primary"
        >
          添加
        </el-button>
        <el-button icon="el-icon-plus" type="primary" @click="addAdmin">
          新增管理员
        </el-button>
        <el-button
          v-if="button.add"
          icon="el-icon-plus"
          type="primary"
          @click="handleAddNotice"
        >
          发布公告
        </el-button>
        <el-button
          v-else
          disabled
          icon="el-icon-plus"
          title="暂无权限，如有需要，请联系管理员"
          type="primary"
        >
          发布公告
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel v-if="button.search" :span="16">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-select
              v-model="queryForm.sid"
              clearable
              filterable
              placeholder="渠道名称"
            >
              <el-option
                v-for="item in studioList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="queryForm.status" placeholder="状态">
              <el-option label="全部" value="0" />
              <el-option label="启用" value="10" />
              <el-option label="禁用" value="20" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      :expand-row-keys="expands"
      :row-key="getRowKeys"
      @row-click="clickRowHandle"
      @selection-change="setSelectRows"
    >
      <el-table-column v-if="false" type="expand">
        <template slot-scope="props">
          <el-form class="demo-table-expand" label-position="left">
            <el-form-item label="商品名称">
              <span>{{ props.row.name }}</span>
            </el-form-item>
            <el-form-item label="所属店铺">
              <span>{{ props.row.name }}</span>
            </el-form-item>
            <el-form-item label="商品 ID">
              <span>{{ props.row.id }}</span>
            </el-form-item>
            <el-form-item label="店铺 ID">
              <span>{{ props.row.name }}</span>
            </el-form-item>
            <el-form-item label="商品分类">
              <span>{{ props.row.name }}</span>
            </el-form-item>
            <el-form-item label="店铺地址">
              <span>{{ props.row.name }}</span>
            </el-form-item>
            <el-form-item label="商品描述">
              <span>{{ props.row.name }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column align="center" label="ID" prop="id" width="80" />
      <el-table-column label="渠道名称" prop="name" />
      <el-table-column align="center" label="使用版本" prop="version" />
      <el-table-column align="center" label="状态" prop="status">
        <template #default="{ row }">
          <el-button
            size="mini"
            :type="row.status != '10' ? 'danger' : 'success'"
            @click.stop="handleDelete(row)"
          >
            {{ row.status != '10' ? '已禁用' : '已启用' }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="是否限制" prop="is_limit">
        <template #default="{ row }">
          {{ row.is_limit == '10' ? '限制' : '不限制' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="人数" prop="limit_num">
        <template #default="{ row }">
          {{ row.is_limit == '10' ? row.limit_num : '不限' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="剩余人数" prop="remain_num">
        <template #default="{ row }">
          {{ row.is_limit == '10' ? row.remain_num : '不限' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="添加时间" prop="created_at" />
      <el-table-column align="center" label="有效期限" prop="effect_time" />
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <el-button
            v-if="button.edit"
            type="text"
            @click.stop="handleEdit(row)"
          >
            编辑
          </el-button>
          <el-button
            v-else
            disabled
            title="暂无权限，如有需要，请联系管理员"
            type="text"
          >
            编辑
          </el-button>
          <el-button
            v-if="button.edit"
            type="text"
            @click.stop="handleAddNum(row)"
          >
            增加
          </el-button>
          <el-button
            v-else
            disabled
            title="暂无权限，如有需要，请联系管理员"
            type="text"
          >
            增加
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
    <addnum ref="addnum" @fetch-data="fetchData" />
    <notice ref="notice" />
  </div>
</template>

<script>
  import {
    getList,
    getStudioList,
    doDelete,
  } from '@/api/platformChannelManagement'
  import Edit from './components/PlatformChannelManagementEdit'
  import Addnum from './components/PlatformChannelManagementAddnum'
  import Notice from './components/PlatformChannelManagementNotice'

  export default {
    name: 'ChannelManagement',
    components: { Edit, Addnum, Notice },
    data() {
      return {
        list: [],
        studioList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          sid: '',
          status: '',
        },
        button: {},
        expands: [],
      }
    },
    created() {
      this.getStudio()
      this.fetchData()
      //this.$set(this.list[], 'status', 'SUCCESS')
    },
    methods: {
      async getStudio() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      getRowKeys(row) {
        return row.id
      },
      clickRowHandle(row, column, event) {
        if (this.expands.includes(row.id)) {
          this.expands = this.expands.filter((val) => val !== row.id)
        } else {
          this.expands = []
          this.expands.push(row.id)
        }
      },
      addAdmin() {
        this.$router.push('/authority/addAdmin')
      },
      handleEdit(row) {
        if (row.id) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleAddNum(row) {
        if (row.id) {
          this.$refs['addnum'].showEdit(row)
        }
      },
      handleAddNotice() {
        this.$refs['notice'].showEdit()
      },
      handleDelete(row) {
        let str = row.status === '10' ? '禁用' : '启用'
        this.$baseConfirm('你确定要' + str + '当前渠道吗', null, async () => {
          const { msg } = await doDelete({ id: row.id })
          this.$baseMessage(msg, 'success')
          await this.fetchData()
        })
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>
