<template>
  <div class="order-eval-set-container">
    <el-tabs>
      <el-tab-pane label="参数设置">
        <params />
      </el-tab-pane>
      <el-tab-pane label="报告设置">
        <record />
      </el-tab-pane>
      <el-tab-pane label="分类设置">
        <cate />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import Params from '@/views/order/eval/components/set/params.vue'
  import Record from '@/views/order/eval/components/set/record.vue'
  import Cate from '@/views/order/eval/components/set/cate.vue'
  export default {
    name: 'EvalSet',
    components: {
      Params,
      Record,
      Cate,
    },
  }
</script>

<style scoped lang="scss">
  .order-eval-set-container {
    .set {
      margin-top: 20px;
    }
  }
</style>
