var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "10px" },
          attrs: { "label-width": "80px", model: _vm.form },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "轮播图" } },
            [
              _c(
                "div",
                { staticClass: "list" },
                _vm._l(_vm.form.banner, function (ban, index) {
                  return _c("div", { key: index, staticClass: "item" }, [
                    _c(
                      "div",
                      {
                        staticClass: "delBtn",
                        on: {
                          click: function ($event) {
                            return _vm.deleteItem("banner", index)
                          },
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            circle: "",
                            icon: "el-icon-close",
                            size: "mini",
                            type: "info",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "img-box" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: `banner[${index}].img`,
                              rules: {
                                required: true,
                                message: `${
                                  "请选择轮播图" + (index + 1) + "图片"
                                }`,
                                trigger: ["blur", "change"],
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "choose-img w230",
                                on: {
                                  click: function ($event) {
                                    return _vm.imgDialog("banner", index)
                                  },
                                },
                              },
                              [
                                ban.img
                                  ? _c("el-image", {
                                      attrs: { fit: "cover", src: ban.img },
                                    })
                                  : _c("i", { staticClass: "el-icon-plus" }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "img-tip" }, [
                              _vm._v("推荐尺寸：690*300"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "other" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "排序",
                              prop: `banner[${index}].sort`,
                              rules: [
                                {
                                  pattern: /^[0-9]*$/,
                                  message: "排序必须为正数",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入数字，数字越大越靠前",
                              },
                              model: {
                                value: ban.sort,
                                callback: function ($$v) {
                                  _vm.$set(ban, "sort", _vm._n($$v))
                                },
                                expression: "ban.sort",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "跳转链接" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入跳转链接" },
                              on: {
                                focus: function ($event) {
                                  return _vm.choseUrl(ban, "banner", index)
                                },
                              },
                              model: {
                                value: ban.url,
                                callback: function ($$v) {
                                  _vm.$set(ban, "url", $$v)
                                },
                                expression: "ban.url",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.form.banner && _vm.form.banner.length > 4,
                    plain: "",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addItem("banner")
                    },
                  },
                },
                [_vm._v(" 添加轮播图 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("gallery", { ref: "gallery", on: { checkedImg: _vm.getImg } }),
      _c("choose-url", {
        ref: "chooseUrl",
        attrs: { menu: _vm.menu },
        on: { getUrl: _vm.getUrl },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }