var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.showFreeDrawer,
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showFreeDrawer = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "div",
        { staticClass: "free-eidt-container" },
        [
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { gutter: 15 },
            },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    lg: { span: 16, offset: 4 },
                    md: { span: 20, offset: 2 },
                    sm: { span: 20, offset: 2 },
                    xl: { span: 12, offset: 6 },
                    xs: 24,
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        "label-width": "100px",
                        model: _vm.form,
                        rules: _vm.rules,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "选择量表", prop: "paper_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                disabled: _vm.form.type === "edit",
                                filterable: "",
                                placeholder: "请选择量表",
                              },
                              on: { change: _vm.bindChange },
                              model: {
                                value: _vm.form.paper_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "paper_id", $$v)
                                },
                                expression: "form.paper_id",
                              },
                            },
                            _vm._l(_vm.evalList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "量表标题", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入量表标题" },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "副标题", prop: "subhead" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入副标题",
                              rows: "5",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.form.subhead,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "subhead", $$v)
                              },
                              expression: "form.subhead",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "分类", prop: "cate_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择分类",
                              },
                              model: {
                                value: _vm.form.cate_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "cate_id", $$v)
                                },
                                expression: "form.cate_id",
                              },
                            },
                            _vm._l(_vm.cateList, function (item) {
                              return _c("el-option", {
                                key: item.cate_id,
                                attrs: {
                                  label: item.name,
                                  value: item.cate_id,
                                },
                              })
                            }),
                            1
                          ),
                          _c("el-button", {
                            staticStyle: {
                              "font-size": "15px",
                              "padding-left": "10px",
                            },
                            attrs: { icon: "el-icon-refresh", type: "text" },
                            on: { click: _vm.refreshCate },
                          }),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.addCate },
                            },
                            [_vm._v("新增")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "封面", prop: "cover" } },
                        [
                          _c("div", { staticClass: "img-uploader" }, [
                            _c(
                              "div",
                              {
                                staticClass: "el-upload el-upload__text",
                                on: { click: _vm.imgDialog },
                              },
                              [
                                _vm.form.img
                                  ? _c("img", {
                                      staticClass: "img",
                                      attrs: { src: _vm.form.img },
                                    })
                                  : _c("i", { staticClass: "el-icon-plus" }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "el-upload__tip",
                                attrs: { slot: "tip" },
                                slot: "tip",
                              },
                              [
                                _c("div", [
                                  _vm._v(" 只能上传 "),
                                  _c("span", [_vm._v(".jpg、.png")]),
                                  _vm._v(" 文件，且 "),
                                  _c("span", [_vm._v("不超过1M")]),
                                ]),
                                _c("div", [_vm._v("(图片尺寸1:1)")]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "价格", prop: "price" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 0, precision: 2 },
                            model: {
                              value: _vm.form.price,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "price", $$v)
                              },
                              expression: "form.price",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "排序", prop: "sort" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              "controls-position": "right",
                              min: 0,
                              placeholder: "越大越靠前",
                            },
                            model: {
                              value: _vm.form.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "sort", $$v)
                              },
                              expression: "form.sort",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "虚拟测评次数", prop: "num" } },
                        [
                          _c("el-input-number", {
                            attrs: { "controls-position": "right", min: 0 },
                            model: {
                              value: _vm.form.num,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "num", $$v)
                              },
                              expression: "form.num",
                            },
                          }),
                        ],
                        1
                      ),
                      false
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "自定义样式", prop: "style" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 20,
                                  "inactive-value": 10,
                                },
                                model: {
                                  value: _vm.form.style,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "style", $$v)
                                  },
                                  expression: "form.style",
                                },
                              }),
                              _vm.form.style === 20
                                ? _c("editor", {
                                    attrs: { "is-clear": _vm.isClear },
                                    on: { change: _vm.editorChange },
                                    model: {
                                      value: _vm.form.content,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "content", $$v)
                                      },
                                      expression: "form.content",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "上架", prop: "status" } },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 10, "inactive-value": 20 },
                            model: {
                              value: _vm.form.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "推荐", prop: "rec" } },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 10, "inactive-value": 20 },
                            model: {
                              value: _vm.form.rec,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "rec", $$v)
                              },
                              expression: "form.rec",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "开放报告", prop: "show_result" } },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 10, "inactive-value": 20 },
                            model: {
                              value: _vm.form.show_result,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "show_result", $$v)
                              },
                              expression: "form.show_result",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.save("form")
                                },
                              },
                            },
                            [_vm._v("保存")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("gallery", {
                    ref: "gallery",
                    on: { checkedImg: _vm.getImg },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("cate", { ref: "cate" }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }