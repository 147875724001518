<template>
  <vab-keep-alive />
</template>

<script>
  export default {
    name: 'Tencent',
  }
</script>

<style scoped lang="scss"></style>
