<template>
  <el-dialog
    :close-on-click-modal="false"
    title="选择链接"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form">
      <el-form-item label="链接类型">
        <el-radio-group v-model="form.isOut">
          <el-radio :label="20">外部链接</el-radio>
          <el-radio :label="10">内部链接</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="链接地址">
        <el-input
          v-if="form.isOut === 20"
          v-model="url.out"
          placeholder="链接地址"
        />
        <div v-else class="menu">
          <div v-for="(item, index) in menuList" :key="index" class="item">
            <label>{{ item.title }}</label>
            <div class="sub-item">
              <el-button
                v-for="(m, i) in item.data"
                :key="i"
                :plain="curItem?.link !== m.link"
                type="primary"
                @click="chooseUrl(m)"
              >
                {{ m.name }}
              </el-button>
            </div>
          </div>
          <!--<el-select
            v-model="cur.pid"
            filterable
            placeholder="请选择"
            style="margin-right: 10px"
            @change="bindChange($event, 'p')"
          >
            <el-option
              v-for="(m, _i) in menuList"
              :key="_i"
              :label="m.title"
              :value="_i"
            />
          </el-select>
          <el-select
            v-model="cur.id"
            clearable
            filterable
            placeholder="请选择"
            @change="bindChange($event, 's')"
          >
            <el-option
              v-for="(m, _j) in menuList[cur.pid]?.data"
              :key="_j"
              :label="m.name"
              :value="_j"
            />
          </el-select>-->
          <div v-if="curItem?.is_det" style="margin-top: 10px">
            <el-select
              v-model="url.in"
              filterable
              placeholder="请选择链接"
              style="width: 100%"
              @change="bindChange"
            >
              <el-option
                v-for="(m, _j) in list"
                :key="_j"
                :label="m.title"
                :value="m.url"
              />
            </el-select>
          </div>
          <!--          <div v-for="(item, index) in menuList" :key="index" class="item">
            <label>{{ item.title }}</label>
            <div>
              <el-button v-for="(url, j) in item.data" :key="j" type="primary">
                {{ url.data.name }}
              </el-button>
            </div>
          </div>-->
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import { getH5SubMenu } from '@/api/idx'
  import { isUrl } from '@/utils/validate'
  export default {
    name: 'ChooseUrl',
    props: {
      menu: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        menuList: [],
        list: [],
        curItem: {},
        form: {
          isOut: '',
          url: '',
        },
        url: {
          out: '',
          in: '',
        },
        dialogFormVisible: false,
      }
    },
    watch: {
      menu: {
        handler(newData) {
          this.menuList = newData
        },
        immediate: true,
        deep: true,
      },
    },
    created() {},
    methods: {
      bindChange(e) {
        this.url.in = e
      },
      async getSubList(data) {
        let p = {
          type: data.type,
          link: data.link,
          param: data.params,
        }
        const { rows } = await getH5SubMenu(p)
        this.list = rows
      },
      showEdit(row) {
        this.form = JSON.parse(JSON.stringify(row))
        if (row.isOut == 20) {
          this.url.out = row.url
        } else {
          this.url.in = row.url
          this.menuList.map((item, index) => {
            let o = item.data.filter((o) => row.url.includes(o.link))
            if (o.length) {
              this.getSubList(o[0])
              this.curItem = o[0]
            }
          })
        }
        this.dialogFormVisible = true
      },
      close() {
        this.list = []
        this.curItem = {}
        this.url = {
          out: '',
          in: '',
        }
        this.$refs['form'].resetFields()
        this.dialogFormVisible = false
      },
      chooseUrl(row) {
        this.curItem = row
        if (row.is_det) {
          this.list = []
          this.url.in = ''
          this.$nextTick(() => {
            this.getSubList(row)
          })
        } else {
          this.url.in = row.link
        }
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let f = this.form
            let url = this.url
            if (f.isOut === 10) {
              if (!url.in) {
                this.$baseMessage('请选择内部链接', 'warning')
                return false
              }
              f.url = url.in
            } else if (f.isOut === 20) {
              if (!url.out) {
                this.$baseMessage('请输入外部链接', 'warning')
                return false
              } else if (!isUrl(url.out)) {
                this.$baseMessage('外部链接格式不正确', 'warning')
                return false
              }
              f.url = url.out
            }
            this.$emit('getUrl', f)
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .menu {
    .item {
      display: flex;
      align-items: center;
      &:not(:first-child) {
        margin-top: 10px;
      }
      label {
        width: 80px;
        text-align: right;
        padding-right: 10px;
      }
    }
  }
</style>
