<template>
  <div class="survey-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="4">
        <el-button
          v-if="button.add"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit('', '/survey/addSurvey')"
        >
          添加
        </el-button>
        <el-button
          v-else
          disabled
          icon="el-icon-plus"
          title="暂无权限，如有需要，请联系管理员"
          type="primary"
        >
          添加
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="20">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.title"
              clearable
              placeholder="请输入标题"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="问卷名" prop="title" />
      <el-table-column align="center" label="题目" prop="eval_num">
        <template #default="{ row }">
          {{ row.num + '题' }}
        </template>
      </el-table-column>
      <!--<el-table-column align="center" prop="" label="链接" width="120">
        <template #default="{ row }">
          <el-button type="text" @click="joinT(row)">查看</el-button>
        </template>
      </el-table-column>-->
      <el-table-column align="center" label="完成" prop="num">
        <template #default="{ row }">
          {{ (row.eval_num || 0) + '份' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建人" prop="admin_name" />
      <el-table-column align="center" label="更新时间" prop="updated_at" />
      <el-table-column align="center" label="操作" width="160">
        <template #default="{ row }">
          <el-button v-if="false" type="text">发布</el-button>
          <el-button
            v-if="button.edit"
            type="text"
            @click="handleEdit(row, '/survey/addSurvey')"
          >
            编辑
          </el-button>
          <el-button
            v-else
            disabled
            title="暂无权限，如有需要，请联系管理员"
            type="text"
          >
            编辑
          </el-button>
          <el-button v-if="false" type="text">复制</el-button>
          <el-button
            type="text"
            @click="handleEdit(row, '/other/surveyAnalyse', 'analyse')"
          >
            分析
          </el-button>
          <el-button type="text" @click="handleDownload(row.id)">
            数据
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <el-dialog
      :close-on-click-modal="false"
      title="下载"
      :visible.sync="dialogFormVisible"
      width="450px"
      @close="close"
    >
      <div v-if="downloadStatus" class="download-team">
        <div class="item">
          <div>打包时间</div>
          <div>{{ downloadStatus.time }}</div>
        </div>
        <div class="item">
          <div>操作</div>
          <div>
            <el-button v-if="downloadStatus.pack === 2" disabled type="text">
              打包中
            </el-button>
            <el-button v-else type="text" @click="packTeam('pack')">
              {{ downloadStatus.pack === 1 ? '打包' : '重新打包' }}
            </el-button>
            <el-button
              v-if="downloadStatus.download === 1"
              type="text"
              @click="packTeam('download')"
            >
              下载
            </el-button>
            <el-button v-else disabled type="text">下载</el-button>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button style="width: 100px" type="primary" @click="close">
          关闭
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getSurveyList,
    packReport,
    checkToReport,
    getTeamTestUploadStatus,
  } from '@/api/teamList'
  export default {
    name: 'SurveyList',
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          title: '',
        },
        button: {},
        downloadStatus: null,
        dialogFormVisible: false,
        curId: '',
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      joinT() {},
      handleEdit(row, url, type) {
        if (row.id) {
          if (type === 'analyse') {
            let routeData = this.routeTo({ id: row.id, title: row.title }, url)
            window.open(routeData.href, '_blank')
          } else {
            this.$router.push({
              path: url,
              query: { id: row.id },
            })
          }
        } else {
          this.$router.push(url)
        }
      },
      routeTo(id, url) {
        let routeData = this.$router.resolve({
          path: url,
          query: id,
        })
        return routeData
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getSurveyList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
      close() {
        this.curId = ''
        this.dialogFormVisible = false
      },
      handleDownload(id) {
        this.dialogFormVisible = true
        this.curId = id
        let command = {
          id: id,
          type: 4,
        }
        getTeamTestUploadStatus(command).then((res) => {
          this.downloadStatus = res.data
        })
      },
      packTeam(act) {
        let param = {
          id: this.curId,
          type: 4,
          act: act,
        }
        let msg = '打包预计需要10分钟，完成后请刷新页面再进行下载'
        if (this.downloadStatus.pack === 3) {
          msg = '重新打包将删除旧文件，确定重新打包吗？'
        }
        if (act === 'pack') {
          this.$baseConfirm(msg, null, () => {
            packReport(param).then((res) => {
              this.downloadStatus.pack = 2
              this.downloadStatus.download = 2
            })
          })
        } else {
          packReport(param).then((res) => {
            window.open(res.data.url, '_self')
          })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .download-team {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    line-height: 30px;
    .item {
      width: 50%;
    }
  }
</style>
