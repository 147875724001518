<template>
  <div class="resouce-container">
    <div class="top">浩途智库</div>
    <div class="desc">
      平台智库可能涉及知识产权问题，可供上架的资源均已获取授权，不能上架资源存在付费资源，请联系我们获取权限。
    </div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="视频课程" name="video">
        <Video v-if="studioList.length" :studio="studioList" />
      </el-tab-pane>
      <el-tab-pane label="图文资讯" name="article">
        <Article v-if="studioList.length" :studio="studioList" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import Article from './components/article/list'
  import Video from './components/video/list'
  import { getStudioList } from '@/api/platformChannelManagement'
  import { getCateList } from '@/api/resource'
  export default {
    name: 'Resouse',
    components: { Article, Video },
    data() {
      return {
        activeName: 'video',
        studioList: [],
      }
    },
    created() {
      this.getStudio()
    },
    methods: {
      async getStudio() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      handleClick(tab, event) {},
    },
  }
</script>
<style lang="scss">
  .resouce-container {
    .top {
      position: relative;
      padding-left: 50px;
      line-height: 40px;
      padding-bottom: 10px;
      margin-bottom: 15px;
      border-bottom: 1px solid #eee;
      color: rgb(83, 85, 87);
      font-style: italic;
      font-weight: bold;
      font-size: 16px;
      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        bottom: 0;
        width: 40px;
        height: 40px;
        background: url('~@/assets/resource.svg') no-repeat;
        background-size: 100%;
      }
    }
    .el-tabs--card > .el-tabs__header {
      background: #f9f9f9;
      padding: 20px 10px 0 10px;
      border: none;
    }
    .el-tabs--card > .el-tabs__header .el-tabs__item,
    .el-tabs--card > .el-tabs__header .el-tabs__nav {
      border: none;
    }
    .el-tabs__item.is-active {
      background: #fff;
      mask: url('~@/assets/tabs_images/vab-tab.png');
      mask-size: 100% 100%;
      font-weight: bold;
    }
  }
</style>
