<template>
  <div class="warning-container">
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form
          ref="queryForms"
          :inline="true"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item v-if="isSid === 'all'" prop="sid">
            <el-select
              v-model="queryForm.sid"
              clearable
              filterable
              placeholder="选择渠道"
            >
              <el-option
                v-for="item in studioList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="name">
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入姓名"
            />
          </el-form-item>
          <el-form-item prop="account">
            <el-input
              v-model.trim="queryForm.account"
              clearable
              placeholder="请输入账号"
            />
          </el-form-item>
          <el-form-item prop="origin">
            <el-cascader
              ref="orCas"
              v-model="queryForm.origin"
              clearable
              :options="treeData"
              placeholder="组织结构"
              :props="defaultProps"
              @change="handleChange"
              @visible-change="cascaderClicked"
            />
          </el-form-item>
          <el-form-item prop="settle_time">
            <el-date-picker
              v-model="queryForm.settle_time"
              align="right"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              range-separator="-"
              start-placeholder="开始日期"
              type="daterange"
              unlink-panels
              value-format="yyyy-MM-dd"
              @change="selectDate"
            />
          </el-form-item>
          <el-form-item prop="settle_type">
            <el-select
              v-model="queryForm.settle_type"
              clearable
              filterable
              placeholder="结案状态"
            >
              <el-option
                v-for="item in settleList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
            <el-button icon="el-icon-refresh" type="primary" @click="refresh">
              刷新
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table v-loading="listLoading" border :data="list">
      <el-table-column align="center" label="姓名" prop="name" width="100">
        <template #default="{ row }">
          <el-button
            type="text"
            @click="handleEdit(row, '/userManagement/member/archives')"
          >
            {{ row.name }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="账号" prop="account" />
      <el-table-column align="center" label="组织结构" prop="origin" />
      <el-table-column align="center" label="结案时间" prop="settle_time" />
      <el-table-column align="center" label="结案状态" prop="settle_type">
        <template #default="{ row }">
          <el-tag :type="row.settle_type === '解除预警' ? '' : 'info'">
            {{ row.settle_type }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="评估记录" width="100">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row, '/mental/assess')">
            查看
          </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="干预记录" width="100">
        <template #default="{ row }">
          <el-button
            type="text"
            @click="handleEdit(row, '/mental/interveList')"
          >
            查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getSettleList, getWarnParam } from '@/api/crisis'
  import { getStudioList } from '@/api/platformChannelManagement'
  import { mapGetters } from 'vuex'
  import { getCurStudioOrgin } from '@/api/userManagementOrganization'

  export default {
    name: 'SettleStatus',
    data() {
      return {
        list: [],
        treeData: [], //组织结构
        studioList: [],
        settleList: [], //结案状态
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          name: '',
          account: '',
          sid: '',
          origin: '',
          settle_time: '',
          settle_type: '',
        },
        defaultProps: {
          children: 'children',
          label: 'name',
          value: 'id',
          checkStrictly: true,
        },
        button: {},
        pickerOptions: {
          shortcuts: [
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              },
            },
          ],
        },
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    created() {
      this.getStudio()
      this.getSettles()
      this.fetchData()
    },
    methods: {
      getSettles() {
        getWarnParam({ type: 'settle' }).then((res) => {
          this.settleList = res.data
        })
      },
      cascaderClicked($event) {
        if ($event) {
          this.getOriginList(this.queryForm.sid)
        }
      },
      handleChange(value) {
        let id = this.$refs.orCas.getCheckedNodes(true)
        this.queryForm.origin = id.length ? id[0].value : ''
      },
      async getStudio() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      async getOriginList(sid) {
        //获取组织结构
        const { data } = await getCurStudioOrgin(
          this.isSid === 'all' ? { sid: sid } : ''
        )
        this.treeData = data
      },
      selectDate(val) {
        this.queryForm.warn_at = val
      },
      handleEdit(row, url, type) {
        if (type === 'add') {
          if (url) {
            this.$refs['addrecord'].showEdit(row, url)
          } else {
            this.$refs['edit'].showEdit()
          }
        } else {
          let obj = {}
          if (url === '/userManagement/member/archives') {
            obj = { id: row.uid }
          } else {
            obj = { account: row.account }
          }
          this.$router.push({
            path: url,
            query: obj,
          })
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      refresh() {
        this.$refs.queryForms.resetFields()
        this.queryData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getSettleList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .tabs {
    margin-bottom: 15px;
    border-bottom: 2px solid #f5f5f5;
    .el-button {
      width: 80px;
    }
    .popEx {
      margin-left: 30px;
      color: #999;
    }
  }
  .warning-box {
    line-height: 24px;
    .circle {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      margin-right: 4px;
      animation: move 2s ease-in-out infinite;
      &.red {
        background-color: red;
        box-shadow: 0 0 4px red;
      }
      &.yellow {
        background-color: yellow;
        box-shadow: 0 0 4px yellow;
      }
      &.orange {
        background-color: orange;
        box-shadow: 0 0 4px orange;
      }
      @keyframes move {
        0% {
          transform: scale(0.6);
        }
        50% {
          transform: scale(1);
        }
        100% {
          transform: scale(0.6);
        }
      }
    }
  }
</style>
