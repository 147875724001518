var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "platform-channel-management-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-left-panel",
            { attrs: { span: 8 } },
            [
              _vm.button.add
                ? _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus", type: "primary" },
                      on: { click: _vm.handleEdit },
                    },
                    [_vm._v(" 添加 ")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: "",
                        icon: "el-icon-plus",
                        title: "暂无权限，如有需要，请联系管理员",
                        type: "primary",
                      },
                    },
                    [_vm._v(" 添加 ")]
                  ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-plus", type: "primary" },
                  on: { click: _vm.addAdmin },
                },
                [_vm._v(" 新增管理员 ")]
              ),
              _vm.button.add
                ? _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus", type: "primary" },
                      on: { click: _vm.handleAddNotice },
                    },
                    [_vm._v(" 发布公告 ")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: "",
                        icon: "el-icon-plus",
                        title: "暂无权限，如有需要，请联系管理员",
                        type: "primary",
                      },
                    },
                    [_vm._v(" 发布公告 ")]
                  ),
            ],
            1
          ),
          _vm.button.search
            ? _c(
                "vab-query-form-right-panel",
                { attrs: { span: 16 } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: { inline: true, model: _vm.queryForm },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "渠道名称",
                              },
                              model: {
                                value: _vm.queryForm.sid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "sid", $$v)
                                },
                                expression: "queryForm.sid",
                              },
                            },
                            _vm._l(_vm.studioList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "状态" },
                              model: {
                                value: _vm.queryForm.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "status", $$v)
                                },
                                expression: "queryForm.status",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "0" },
                              }),
                              _c("el-option", {
                                attrs: { label: "启用", value: "10" },
                              }),
                              _c("el-option", {
                                attrs: { label: "禁用", value: "20" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-search",
                                type: "primary",
                              },
                              on: { click: _vm.queryData },
                            },
                            [_vm._v(" 查询 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            border: "",
            data: _vm.list,
            "expand-row-keys": _vm.expands,
            "row-key": _vm.getRowKeys,
          },
          on: {
            "row-click": _vm.clickRowHandle,
            "selection-change": _vm.setSelectRows,
          },
        },
        [
          false
            ? _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "el-form",
                            {
                              staticClass: "demo-table-expand",
                              attrs: { "label-position": "left" },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "商品名称" } },
                                [_c("span", [_vm._v(_vm._s(props.row.name))])]
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "所属店铺" } },
                                [_c("span", [_vm._v(_vm._s(props.row.name))])]
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "商品 ID" } },
                                [_c("span", [_vm._v(_vm._s(props.row.id))])]
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "店铺 ID" } },
                                [_c("span", [_vm._v(_vm._s(props.row.name))])]
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "商品分类" } },
                                [_c("span", [_vm._v(_vm._s(props.row.name))])]
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "店铺地址" } },
                                [_c("span", [_vm._v(_vm._s(props.row.name))])]
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "商品描述" } },
                                [_c("span", [_vm._v(_vm._s(props.row.name))])]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  739416710
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", prop: "id", width: "80" },
          }),
          _c("el-table-column", { attrs: { label: "渠道名称", prop: "name" } }),
          _c("el-table-column", {
            attrs: { align: "center", label: "使用版本", prop: "version" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态", prop: "status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: row.status != "10" ? "danger" : "success",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleDelete(row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(row.status != "10" ? "已禁用" : "已启用") +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "是否限制", prop: "is_limit" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(row.is_limit == "10" ? "限制" : "不限制") +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "人数", prop: "limit_num" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(row.is_limit == "10" ? row.limit_num : "不限") +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "剩余人数", prop: "remain_num" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(row.is_limit == "10" ? row.remain_num : "不限") +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "添加时间", prop: "created_at" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "有效期限", prop: "effect_time" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm.button.edit
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleEdit(row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: "",
                              title: "暂无权限，如有需要，请联系管理员",
                              type: "text",
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                    _vm.button.edit
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleAddNum(row)
                              },
                            },
                          },
                          [_vm._v(" 增加 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: "",
                              title: "暂无权限，如有需要，请联系管理员",
                              type: "text",
                            },
                          },
                          [_vm._v(" 增加 ")]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("edit", { ref: "edit", on: { "fetch-data": _vm.fetchData } }),
      _c("addnum", { ref: "addnum", on: { "fetch-data": _vm.fetchData } }),
      _c("notice", { ref: "notice" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }