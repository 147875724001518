<template>
  <div class="mmpt-record-container">
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-select
              v-model="queryForm.pid"
              clearable
              filterable
              placeholder="请选择量表"
            >
              <el-option
                v-for="item in evalList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.status"
              clearable
              placeholder="请选择测评状态"
            >
              <el-option label="已完成" value="10" />
              <el-option label="未完成" value="20" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <!--class="sel-ipt"-->
            <el-select v-if="false" v-model="queryForm.search_key">
              <el-option label="用户名" value="username" />
              <el-option label="手机号" value="phone" />
            </el-select>
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入用姓名"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.account"
              clearable
              placeholder="请输入账号"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" label="序号" type="index" width="80" />
      <el-table-column align="center" label="姓名" prop="name" />
      <el-table-column align="center" label="账号" prop="account" />
      <el-table-column
        align="center"
        label="量表名称"
        min-width="200"
        prop="paper_name"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="状态" prop="status_name" />
      <el-table-column align="center" label="答题时长" prop="use_at" />
      <el-table-column
        align="center"
        label="开始时间"
        prop="test_at"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="完成时间"
        prop="complete_at"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="操作" width="85">
        <template #default="{ row }">
          <el-button
            :disabled="row.status === 20"
            type="text"
            @click="toNext(row.id, '/other/commRecord')"
          >
            报告
          </el-button>
          <el-button
            :disabled="row.status === 20"
            type="text"
            @click="toNext(row.id, '/other/commAnswer')"
          >
            答案
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { baseURL } from '@/config'
  import { getEvalListBySid } from '@/api/mmptCategory'
  import { getFreeRecord } from '@/api/eval'
  import Edit from '../../mmpt/record/components/MmptRecordEdit'

  export default {
    name: 'EvalRecord',
    components: { Edit },
    data() {
      return {
        list: [],
        evalList: [], //获取所有量表
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          name: '',
          status: '',
          pid: '',
          account: '',
        },
      }
    },
    created() {
      this.fetchData()
      this.getEvalList()
    },
    methods: {
      async getEvalList() {
        const { data } = await getEvalListBySid()
        this.evalList = data
      },
      downloadAnswer(id) {
        //window.location.href = baseURL + '/psyevallist/test-answer?id=' + id
      },
      toNext(id, url) {
        let routeData = this.routeTo({ id: id }, url)
        window.open(routeData.href, '_blank')
      },
      routeTo(id, url) {
        let routeData = this.$router.resolve({
          path: url,
          query: id,
        })
        return routeData
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount } = await getFreeRecord(this.queryForm)
        this.list = data
        this.total = totalCount
        this.listLoading = false
      },
    },
  }
</script>
<style lang="scss">
  .mmpt-record-container {
    .sel-ipt {
      .el-form-item__content {
        display: flex;
        .el-select {
          .el-input__inner {
            width: 100px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        > .el-input {
          .el-input__inner {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-left: -1px;
          }
        }
      }
    }
  }
</style>
