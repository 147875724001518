<template>
  <div class="order-eval-list-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="8">
        <el-button
          v-if="button.add"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit"
        >
          添加
        </el-button>
        <el-button
          v-else
          disabled
          icon="el-icon-plus"
          title="暂无权限，如有需要，请联系管理员"
          type="primary"
        >
          添加
        </el-button>
        <el-button type="primary" @click="setStatus('', 10)">
          批量上架
        </el-button>
        <el-button type="danger" @click="setStatus('', 20)">批量下架</el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="16">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入量表标题"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.cate_id"
              clearable
              filterable
              placeholder="请选择分类"
            >
              <el-option
                v-for="item in cateList"
                :key="item.cate_id"
                :label="item.name"
                :value="item.cate_id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.is_pay"
              clearable
              filterable
              placeholder="是否付费"
            >
              <el-option label="付费" :value="10" />
              <el-option label="免费" :value="20" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.status"
              clearable
              filterable
              placeholder="是否上架"
            >
              <el-option label="已上架" :value="10" />
              <el-option label="已下架" :value="20" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="selectChange"
    >
      <el-table-column align="center" type="selection" />
      <el-table-column align="center" label="量表标题" prop="name" />
      <el-table-column align="center" label="分类" prop="cate_name" />
      <el-table-column align="center" label="封面" prop="img">
        <template #default="{ row }">
          <el-image lazy :src="row.img" />
        </template>
      </el-table-column>
      <el-table-column align="center" label="排序" prop="sort" />
      <el-table-column align="center" label="上架状态" prop="status">
        <template #default="{ row }">
          <el-popconfirm
            v-if="row.status == 10"
            title="当前量表已上架，是否下架？"
            @confirm="setStatus(row, 20)"
          >
            <el-button size="mini" type="primary" slot="reference">
              已上架
            </el-button>
          </el-popconfirm>
          <el-popconfirm
            v-else
            title="当前量表已下架，是否上架？"
            @confirm="setStatus(row, 10)"
          >
            <el-button size="mini" type="info" slot="reference">
              已下架
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column align="center" label="是否推荐" prop="rec">
        <template #default="{ row }">
          <el-popconfirm
            v-if="row.rec == 10"
            title="设为不推荐？"
            @confirm="setRec(row, 20)"
          >
            <el-button size="mini" type="primary" slot="reference">
              推荐
            </el-button>
          </el-popconfirm>
          <el-popconfirm v-else title="设为推荐？" @confirm="setRec(row, 10)">
            <el-button size="mini" type="info" slot="reference">
              不推荐
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column align="center" label="价格" prop="price" />
      <el-table-column align="center" label="已测次数" prop="real_num" />
      <el-table-column align="center" label="二维码/链接">
        <template #default="{ row }">
          <el-image lazy :preview-src-list="[row.code]" :src="row.code" />
          <div>
            <el-button type="text" @click="copy(row.code_url)">
              复制链接
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="来源" prop="source">
        <template #default="{ row }">
          <el-tag :type="row.source === '系统内置' ? 'info' : 'priamry'">
            {{ row.source }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="80">
        <template #default="{ row }">
          <el-button v-if="button.edit" type="text" @click="handleEdit(row)">
            编辑
          </el-button>
          <el-button v-else disabled type="text">编辑</el-button>
          <!--          <el-button
            v-if="button.delete"
            type="text"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
          <el-button v-else disabled type="text">删除</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import Edit from '@/views/order/eval/components/paperEdit.vue'
  import {
    delPaper,
    getCateList,
    getPaperList,
    setRec,
    setStatus,
  } from '@/api/psyeval'
  import clip from '@/utils/clipboard'

  export default {
    name: 'PaperList',
    components: { Edit },
    data() {
      return {
        list: [],
        cateList: [], //获取分类
        selectRows: '',
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          name: '',
          cate_id: '',
          status: '',
          is_pay: '',
        },
        button: {},
      }
    },
    created() {
      this.getCateList()
      this.fetchData()
    },
    methods: {
      copy(val) {
        clip(val, event)
      },
      selectChange(val) {
        this.selectRows = val
      },
      async getCateList() {
        const { data } = await getCateList()
        this.cateList = data
      },
      setStatus(row, status) {
        let ids = []
        if (row.id) {
          ids = [row]
        } else {
          if (this.selectRows.length) {
            ids = this.selectRows.map((item) => item.id)
          } else {
            this.$baseMessage('请选择要操作的数据！', 'warning')
            return
          }
        }
        setStatus({
          id: ids,
          status: status,
        }).then(async (res) => {
          this.$baseMessage(res.msg, res.code === 200 ? 'success' : 'error')
          await this.fetchData()
        })
      },
      setRec(row, rec) {
        setRec({
          id: row.id,
          rec: rec,
        }).then(async (res) => {
          this.$baseMessage(res.msg, res.code === 200 ? 'success' : 'error')
          await this.fetchData()
        })
      },
      handleEdit(row) {
        if (row.id) {
          this.$refs['edit'].getPath(row)
        } else {
          this.$refs['edit'].getPath()
        }
      },
      handleDelete(row) {
        this.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg, code } = await delPaper({
            id: row.id,
          })
          this.$baseMessage(msg, code === 200 ? 'success' : 'error')
          await this.fetchData()
        })
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getPaperList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>

<style scoped lang="scss"></style>
