var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "80px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "字段类型", prop: "tag" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    disabled: _vm.title === "编辑",
                    filterable: "",
                    placeholder: "请选择字段类型",
                  },
                  on: { change: _vm.bindCheck },
                  model: {
                    value: _vm.form.tag,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "tag", $$v)
                    },
                    expression: "form.tag",
                  },
                },
                _vm._l(_vm.zdType, function (item) {
                  return _c("el-option", {
                    key: item.tag,
                    attrs: { label: item.label, value: item.tag },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "字段标题", prop: "label" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入字段标题" },
                model: {
                  value: _vm.form.label,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "label", $$v)
                  },
                  expression: "form.label",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提示语", prop: "placeholder" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入提示语" },
                model: {
                  value: _vm.form.placeholder,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "placeholder", $$v)
                  },
                  expression: "form.placeholder",
                },
              }),
            ],
            1
          ),
          _vm.form.tag === "input"
            ? _c(
                "el-form-item",
                { attrs: { label: "文本类型", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { "line-height": "26px" },
                      on: { change: _vm.rdoChange },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "text" } }, [
                        _vm._v("文本"),
                      ]),
                      _c("el-radio", { attrs: { label: "number" } }, [
                        _vm._v("数字"),
                      ]),
                      _c("el-radio", { attrs: { label: "digit" } }, [
                        _vm._v("浮点型"),
                      ]),
                      _c("el-radio", { attrs: { label: "tel" } }, [
                        _vm._v("手机号"),
                      ]),
                      _c("el-radio", { attrs: { label: "idcard" } }, [
                        _vm._v("身份证"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.tag &&
          (_vm.form.tag === "input" || _vm.form.tag === "textarea")
            ? _c(
                "el-form-item",
                { attrs: { label: "文本长度", prop: "maxlength" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled:
                        _vm.form.type === "tel" || _vm.form.type === "idcard",
                      placeholder: "请输入文本最多可输入字符长度",
                    },
                    model: {
                      value: _vm.form.maxlength,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "maxlength", $$v)
                      },
                      expression: "form.maxlength",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.form.tag &&
                    (_vm.form.tag === "radio" ||
                      _vm.form.tag === "checkbox" ||
                      _vm.form.tag === "select"),
                  expression:
                    "\n        form.tag &&\n        (form.tag === 'radio' ||\n          form.tag === 'checkbox' ||\n          form.tag === 'select')\n      ",
                },
              ],
            },
            [
              _vm._l(_vm.form.options, function (option, index) {
                return _c(
                  "el-form-item",
                  {
                    key: option.key,
                    attrs: {
                      label: "选项" + (index + 1),
                      prop: "options." + index + ".value",
                      rules: {
                        required:
                          _vm.form.tag === "radio" ||
                          _vm.form.tag === "checkbox" ||
                          _vm.form.tag === "select"
                            ? true
                            : false,
                        message: `${"选项" + (index + 1) + "不能为空"}`,
                        trigger: "blur",
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "option-item" },
                      [
                        _c("el-input", {
                          model: {
                            value: option.value,
                            callback: function ($$v) {
                              _vm.$set(option, "value", $$v)
                            },
                            expression: "option.value",
                          },
                        }),
                        _vm.form.options.length > 1
                          ? _c("span", {
                              staticClass: "el-icon-remove-outline",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeOption(option)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-circle-plus-outline",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.addOption.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(" 新增选项 ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }