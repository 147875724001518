<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="form.typeName"
    :visible.sync="dialogFormVisible"
    width="700px"
    @close="close"
  >
    <el-form ref="form" class="form" label-width="80px" :model="form">
      <el-form-item
        label="题目"
        prop="title"
        :rules="{
          required: true,
          message: '题目不能为空',
          trigger: 'blur',
        }"
      >
        <el-input
          v-model="form.title"
          clearable
          :placeholder="!form.title ? num + 1 + '.题目' : form.title"
        />
      </el-form-item>
      <el-form-item label="必填" prop="required">
        <el-switch v-model="form.required" />
      </el-form-item>
      <el-form-item
        label="提示"
        prop="tips"
        :rules="{
          required: form.hasTips ? true : false,
          message: '提示内容',
          trigger: 'blur',
        }"
      >
        <el-switch v-model="form.hasTips" />
        <div v-if="form.hasTips">
          <el-input
            v-model="form.tips"
            class="ipt"
            clearable
            placeholder="提示内容"
          />
        </div>
      </el-form-item>
      <el-form-item
        v-for="(option, index) in form.options"
        :key="option.key"
        :label="index === 0 ? '普通选项' : ''"
        :prop="'options.' + index + '.label'"
        :rules="{
          required: true,
          message: `${'选项' + (index + 1) + '不能为空'}`,
          trigger: 'blur',
        }"
      >
        <div class="option-item">
          <el-input
            v-model="option.label"
            class="ipt"
            clearable
            :placeholder="'选项' + (index + 1)"
          />
          <el-button
            v-if="form.options.length > 1"
            style="color: #e64e1f"
            type="text"
            @click.prevent="removeOption(option)"
          >
            删除
          </el-button>
          <el-button
            v-if="index + 1 === form.options.length"
            type="text"
            @click.prevent="addOption"
          >
            添加
          </el-button>
        </div>
      </el-form-item>
      <el-form-item
        label="其他选项"
        prop="other"
        :rules="{
          required: form.hasOther ? true : false,
          message: '选项其他不能为空',
          trigger: 'blur',
        }"
      >
        <el-switch v-model="form.hasOther" />
        <div class="tips">若有其他选项且需要用户输入时，请开启</div>
        <div v-if="form.hasOther">
          <el-input
            v-model="form.other"
            class="ipt"
            clearable
            placeholder="选项其他"
          />
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">完成编辑</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: 'Radio',
    data() {
      return {
        form: {},
        num: 0,
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit(row, index) {
        this.form = JSON.parse(JSON.stringify(row))
        this.num = index
        if (this.form.title === '.题目') {
          this.form.title = ''
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      addOption() {
        //新增选项
        this.form.options.push({
          text: false,
          required: false,
          label: '',
          key: Date.now(),
        })
      },
      removeOption(item) {
        //删除选项
        let index = this.form.options.indexOf(item)
        if (index !== -1) {
          this.form.options.splice(index, 1)
        }
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let data = {
              index: this.num,
              data: this.form,
            }
            this.$emit('update-item', data)
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-dialog {
      &__footer {
        padding-top: 0;
      }
    }
  }
  .form {
    .option-item {
      display: flex;
      align-items: center;
    }

    .ipt {
      width: 80%;
      margin-right: 15px;
    }
    .tips {
      font-size: 12px;
      color: #bbb;
    }
  }
</style>
