<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="850px"
    @close="close"
  >
    <el-form ref="form" label-width="50px" :model="form" :rules="rules">
      <el-form-item label="标题" prop="title">
        <el-input
          v-model="form.title"
          clearable
          maxlength="20"
          placeholder="请输入标题,最长20个字符"
        />
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-radio-group v-model="form.type">
          <el-radio :label="1">普通公告</el-radio>
          <el-radio :label="5">升级提醒</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <editor
          v-model="form.content"
          :is-clear="false"
          @change="editorChange"
        />
        <!--<editor
          ref="Editor"
          v-model="form.content"
          class="CKEditor"
          @change="valContxt"
        />-->
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { addNotice } from '@/api/platformChannelManagement'
  import editor from '@/components/editor'

  export default {
    name: 'PlatformChannelManagementNotice',
    components: { editor },
    data() {
      return {
        form: {
          title: '',
          type: '',
          content: '',
        },
        rules: {
          title: [
            { required: true, trigger: 'blur', message: '请输入公告标题' },
          ],
          type: [{ required: true, trigger: 'change', message: '请选择类型' }],
          content: [
            { required: true, trigger: 'blur', message: '请输入公告内容' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      editorChange(val) {
        console.log(val)
      },
      showEdit() {
        this.title = '发布公告'
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg, code } = await addNotice(this.form)
            if (code === 200) {
              this.$baseMessage(msg, 'success')
              this.$emit('fetch-data')
              this.close()
            } else {
              this.$baseMessage(msg, 'error')
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
