var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.showDialog,
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "right",
            "label-width": "80px",
            model: _vm.form,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标题", prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入标题" },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "简介", prop: "introduce" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入简介",
                  rows: "5",
                  type: "textarea",
                },
                model: {
                  value: _vm.form.introduce,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "introduce", $$v)
                  },
                  expression: "form.introduce",
                },
              }),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "缩略图", prop: "cover" } }, [
            _c("div", { staticClass: "img-uploader" }, [
              _c(
                "div",
                {
                  staticClass: "el-upload el-upload__text",
                  on: { click: _vm.selImg },
                },
                [
                  _vm.form.cover
                    ? _c("img", {
                        staticClass: "img",
                        attrs: { src: _vm.form.cover },
                      })
                    : _c("i", { staticClass: "el-icon-plus" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c("div", [
                    _vm._v(" 只能上传 "),
                    _c("span", [_vm._v(".jpg、.png")]),
                    _vm._v(" 文件，且 "),
                    _c("span", [_vm._v("不超过1M")]),
                  ]),
                  _c("div", [_vm._v("(图片尺寸3:2)")]),
                ]
              ),
            ]),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "分类", prop: "cate" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择分类",
                  },
                  model: {
                    value: _vm.form.cate,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "cate", $$v)
                    },
                    expression: "form.cate",
                  },
                },
                _vm._l(_vm.cateList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.title, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "sort" } },
            [
              _c("el-input", {
                attrs: { placeholder: "数字越大越靠前" },
                model: {
                  value: _vm.form.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sort", $$v)
                  },
                  expression: "form.sort",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上传类型", prop: "course_type" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.course_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "course_type", $$v)
                    },
                    expression: "form.course_type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("内容")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("链接")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.course_type === 0
            ? _c(
                "el-form-item",
                {
                  key: "descr",
                  attrs: {
                    label: "文本",
                    prop: "descr",
                    rules: { required: true, message: "文本内容不能为空" },
                  },
                },
                [
                  _c("editor", {
                    attrs: { "is-clear": false },
                    on: { change: _vm.editorChange },
                    model: {
                      value: _vm.form.descr,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "descr", $$v)
                      },
                      expression: "form.descr",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.course_type === 1
            ? _c(
                "el-form-item",
                {
                  key: "url",
                  attrs: {
                    label: "链接",
                    prop: "url",
                    rules: { required: true, message: "链接不能为空" },
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入链接" },
                    model: {
                      value: _vm.form.url,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "url", $$v)
                      },
                      expression: "form.url",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "开放渠道", prop: "open" } },
            [
              _c("el-transfer", {
                attrs: {
                  data: _vm.studioList,
                  filterable: "",
                  props: { key: "id", label: "name" },
                  titles: ["渠道列表", "已选渠道"],
                },
                model: {
                  value: _vm.form.open,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "open", $$v)
                  },
                  expression: "form.open",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
      _c("select-img", { ref: "selectImg", on: { checkedImg: _vm.getImg } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }