var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.form.typeName,
        visible: _vm.dialogFormVisible,
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: { "label-width": "80px", model: _vm.form },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "题目",
                prop: "title",
                rules: {
                  required: true,
                  message: "题目不能为空",
                  trigger: "blur",
                },
              },
            },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  placeholder: !_vm.form.title
                    ? _vm.num + 1 + ".题目"
                    : _vm.form.title,
                },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "必填", prop: "required" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.required,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "required", $$v)
                  },
                  expression: "form.required",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "提示",
                prop: "tips",
                rules: {
                  required: _vm.form.hasTips ? true : false,
                  message: "提示内容",
                  trigger: "blur",
                },
              },
            },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.hasTips,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "hasTips", $$v)
                  },
                  expression: "form.hasTips",
                },
              }),
              _vm.form.hasTips
                ? _c(
                    "div",
                    [
                      _c("el-input", {
                        staticClass: "ipt",
                        attrs: { clearable: "", placeholder: "提示内容" },
                        model: {
                          value: _vm.form.tips,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "tips", $$v)
                          },
                          expression: "form.tips",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._l(_vm.form.row, function (option, index) {
            return _c(
              "el-form-item",
              {
                key: index,
                attrs: {
                  label: index === 0 ? "分题目" : "",
                  prop: "row." + index,
                  rules: {
                    required: true,
                    message: `${"分题目" + (index + 1) + "不能为空"}`,
                    trigger: "blur",
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "option-item" },
                  [
                    _c("el-input", {
                      staticClass: "ipt",
                      attrs: {
                        clearable: "",
                        placeholder: "分题目" + (index + 1),
                      },
                      model: {
                        value: _vm.form.row[index],
                        callback: function ($$v) {
                          _vm.$set(_vm.form.row, index, $$v)
                        },
                        expression: "form.row[index]",
                      },
                    }),
                    _vm.form.row.length > 1
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#e64e1f" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.removeOption("row", option)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        )
                      : _vm._e(),
                    index + 1 === _vm.form.row.length
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addOption("row")
                              },
                            },
                          },
                          [_vm._v(" 添加 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          }),
          _vm._l(_vm.form.options, function (option, index) {
            return _c(
              "el-form-item",
              {
                key: option.key,
                attrs: {
                  label: index === 0 ? "选项" : "",
                  prop: "options." + index + ".label",
                  rules: {
                    required: true,
                    message: `${"选项" + (index + 1) + "不能为空"}`,
                    trigger: "blur",
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "option-item" },
                  [
                    _c("el-input", {
                      staticClass: "ipt",
                      attrs: {
                        clearable: "",
                        placeholder: "选项" + (index + 1),
                      },
                      model: {
                        value: option.label,
                        callback: function ($$v) {
                          _vm.$set(option, "label", $$v)
                        },
                        expression: "option.label",
                      },
                    }),
                    _vm.form.options.length > 1
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#e64e1f" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.removeOption("options", option)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        )
                      : _vm._e(),
                    index + 1 === _vm.form.options.length
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addOption("options")
                              },
                            },
                          },
                          [_vm._v(" 添加 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("完成编辑")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }