var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-list-container" },
    [
      _c(
        "el-collapse",
        {
          on: { change: _vm.handleChange },
          model: {
            value: _vm.list,
            callback: function ($$v) {
              _vm.list = $$v
            },
            expression: "list",
          },
        },
        _vm._l(_vm.list, function (item) {
          return _c(
            "el-collapse-item",
            { key: item.id, attrs: { name: item.id, title: item.title } },
            [
              item.type == 1
                ? _c("div", [_vm._v(" " + _vm._s(item.cotnent) + " ")])
                : _c("div", [
                    _c(
                      "a",
                      { attrs: { href: item.content, target: "_blank" } },
                      [_vm._v("点击下载")]
                    ),
                  ]),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }