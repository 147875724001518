var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add-survey-container" },
    [
      _c("div", { staticClass: "add-title" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "el-row",
        { attrs: { gutter: 15 } },
        [
          _c(
            "el-col",
            {
              staticClass: "survey-box",
              attrs: {
                md: { span: 16, offset: 4 },
                xl: { span: 16, offset: 4 },
              },
            },
            [
              _c(
                "div",
                { staticClass: "type-box" },
                _vm._l(_vm.tx, function (tab, index) {
                  return _c("div", { key: index, staticClass: "item" }, [
                    _c("div", { staticClass: "item-name" }, [
                      _vm._v(_vm._s(tab.title)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "sub-item" },
                      _vm._l(tab.data, function (item, _i) {
                        return _c(
                          "div",
                          { key: _i, staticClass: "sub-item-name" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "lbl",
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addType(item)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(item.label) + " ")]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ])
                }),
                0
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "ti-box",
                },
                [
                  _vm.initData
                    ? _c("div", { staticClass: "ti-title" }, [
                        _c(
                          "div",
                          { staticClass: "t" },
                          [
                            _c("span", [_vm._v("标题")]),
                            _c("el-input", {
                              staticClass: "ipt",
                              attrs: { placeholder: "请输入标题" },
                              model: {
                                value: _vm.initData.title,
                                callback: function ($$v) {
                                  _vm.$set(_vm.initData, "title", $$v)
                                },
                                expression: "initData.title",
                              },
                            }),
                          ],
                          1
                        ),
                        false
                          ? _c(
                              "div",
                              { staticClass: "t" },
                              [
                                _c("span", [_vm._v("说明")]),
                                _c("el-input", {
                                  staticClass: "ipt",
                                  attrs: {
                                    placeholder: "请输入说明",
                                    type: "textarea",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "ti-subject" },
                    _vm._l(_vm.list, function (item, index) {
                      return _c("div", { key: index, staticClass: "item" }, [
                        item.type !== "page"
                          ? _c("div", { staticClass: "title" }, [
                              _c("div", { staticClass: "l-name" }, [
                                item.index === false
                                  ? _c("span", [_vm._v("*")])
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.title === ".题目"
                                        ? index + 1 + item.title
                                        : item.title
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "r-type" }, [
                                _vm._v(_vm._s(item.typeName)),
                              ]),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "option" },
                          [
                            item.type === "radio" || item.type === "checkbox"
                              ? [
                                  _vm._l(item.options, function (rdo, j) {
                                    return _c(
                                      "div",
                                      { key: j, class: `el-${item.type}` },
                                      [
                                        _c("span", {
                                          class: `el-${item.type}__inner`,
                                        }),
                                        _c(
                                          "span",
                                          { class: `el-${item.type}__label` },
                                          [
                                            _vm._v(
                                              " " + _vm._s(rdo.label) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                  item.hasOther
                                    ? _c("div", { class: `el-${item.type}` }, [
                                        _c("span", {
                                          class: `el-${item.type}__inner`,
                                        }),
                                        _c(
                                          "span",
                                          { class: `el-${item.type}__label` },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.other) + " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              : item.type === "matrix_radio" ||
                                item.type === "matrix_checkbox"
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "matrix-box" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "matrix-item" },
                                        [
                                          _c("div", {
                                            staticClass: "matrix-title",
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "matrix-sub-item" },
                                            _vm._l(
                                              item.options,
                                              function (mr, m) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: m,
                                                    staticClass: "flex1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(mr.label) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                      _vm._l(item.row, function (row, r) {
                                        return _c(
                                          "div",
                                          {
                                            key: r,
                                            staticClass: "matrix-item",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "matrix-title" },
                                              [_vm._v(_vm._s(row))]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "matrix-sub-item",
                                              },
                                              _vm._l(
                                                item.options,
                                                function (mr, m) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: m,
                                                      staticClass: "flex1",
                                                    },
                                                    [
                                                      _c("span", {
                                                        class:
                                                          item.type ===
                                                          "matrix_radio"
                                                            ? "el-radio__inner"
                                                            : "el-checkbox__inner",
                                                        attrs: {
                                                          value: mr.label,
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      }),
                                    ],
                                    2
                                  ),
                                ]
                              : item.type === "page"
                              ? [
                                  _c("div", { staticClass: "page" }, [
                                    _c("div", [_vm._v("分页")]),
                                    _c("div", [
                                      _vm._v(
                                        "第" +
                                          _vm._s(item.page) +
                                          "页/共" +
                                          _vm._s(item.page + 1) +
                                          "页"
                                      ),
                                    ]),
                                  ]),
                                ]
                              : [_c("el-input", { staticClass: "txt" })],
                          ],
                          2
                        ),
                        _c(
                          "div",
                          { staticClass: "operation" },
                          [
                            item.type !== "page"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEdit(item, index)
                                      },
                                    },
                                  },
                                  [_vm._v(" 编辑 ")]
                                )
                              : _vm._e(),
                            item.type !== "page"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleRelation(item, index)
                                      },
                                    },
                                  },
                                  [_vm._v(" 关联 ")]
                                )
                              : _vm._e(),
                            _vm.list.length > 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeSub(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" 删除 ")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: { disabled: index === 0, type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.upRecord(_vm.list, index)
                                  },
                                },
                              },
                              [_vm._v(" 上移 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: index === _vm.list.length - 1,
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downRecord(_vm.list, index)
                                  },
                                },
                              },
                              [_vm._v(" 下移 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { disabled: index === 0, type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toFirst(_vm.list, index)
                                  },
                                },
                              },
                              [_vm._v(" 最前 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: index === _vm.list.length - 1,
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toLast(_vm.list, index)
                                  },
                                },
                              },
                              [_vm._v(" 最后 ")]
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "bottom" },
                    [
                      _c("div", { staticClass: "tip" }, [
                        _vm._v("点击左侧边栏添加题目"),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "primary" },
                          on: { click: _vm.submit },
                        },
                        [_vm._v(" 发布问卷 ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c("radio", { ref: "radio", on: { "update-item": _vm.updateItem } }),
      _c("matrix", { ref: "matrix", on: { "update-item": _vm.updateItem } }),
      _c("write", { ref: "text", on: { "update-item": _vm.updateItem } }),
      _c("relation", {
        ref: "relation",
        on: { "update-item": _vm.updateItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }