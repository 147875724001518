<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="800px"
    @close="close"
  >
    <el-form
      ref="form"
      label-position="right"
      label-width="80px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="包含题数" prop="num">
        <el-input
          v-model="form.num"
          prefix-icon="el-icon-edit"
          style="width: 150px"
        />
      </el-form-item>
      <el-form-item label="量表类型" prop="is_major">
        <el-select
          v-model="form.is_major"
          filterable
          placeholder="请选择量表类型"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="渠道" prop="sid_arr">
        <el-transfer
          v-model="form.sid_arr"
          :data="studioList"
          filterable
          :props="{ key: 'id', label: 'name' }"
          :titles="['渠道列表', '已选渠道']"
        />
      </el-form-item>
      <el-form-item label="上传文件" prop="file">
        <el-upload
          ref="upload"
          accept=".xls,.xlsx"
          :action="UploadUrl()"
          :auto-upload="false"
          :before-upload="beforeUploadFile"
          :file-list="form.file"
          :on-change="fileChange"
          :on-error="handleError"
          :on-exceed="exceedFile"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
        >
          <el-button
            v-show="!hasFile"
            slot="trigger"
            size="small"
            type="primary"
          >
            选取文件
          </el-button>
          <el-button v-show="hasFile" disabled size="small" type="primary">
            选取文件
          </el-button>
          <div slot="tip" class="el-upload__tip">
            <span v-if="title === '编辑'">
              （温馨提示：若选择文件上传，之前上传的文件将会被覆盖）
            </span>
            只能上传
            <span>xls、xlsx</span>
            文件，且
            <span>不超过2M</span>
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { doEdit, addEval } from '@/api/platformGaugeManagement'
  import { getStudioList } from '@/api/platformChannelManagement'
  import { isNumber } from '@/utils/validate'

  export default {
    name: 'PlatformGaugeManagementEdit',
    data() {
      const validateNum = (rule, value, callback) => {
        if (value !== '' && !isNumber(value)) {
          callback(new Error('请输入正整数'))
        } else callback()
      }
      return {
        form: {
          num: '',
          is_major: '',
          sid_arr: [],
          file: [],
        },
        rules: {
          num: [
            { required: true, trigger: 'blur', message: '请输入包含题目数' },
            { required: true, trigger: 'blur', validator: validateNum },
          ],
          is_major: [
            { required: true, trigger: 'change', message: '请选择量表类型' },
          ],
          sid_arr: [
            { required: true, trigger: 'change', message: '请选择渠道' },
          ],
          file: [
            { required: false, trigger: 'change', message: '请选择上传文件' },
          ],
        },
        title: '',
        dialogFormVisible: false,
        options: [
          {
            value: '1',
            label: '专业量表',
          },
          {
            value: '0',
            label: '趣味量表',
          },
        ],
        studioList: [],
        hasFile: false,
      }
    },
    created() {},
    methods: {
      showEdit(row) {
        this.studioData()
        if (!row) {
          this.title = '添加'
          this.rules.file[0].required = true
        } else {
          this.title = '编辑'
          this.rules.file[0].required = false
          let formDataInfo = new FormData() //  用FormData存放上传文件
          this.form.id = row.paper_id
          formDataInfo.append('id', row.paper_id)
          formDataInfo.append('type', 'info')
          doEdit(formDataInfo).then((res) => {
            this.form = res.data
          })
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
        this.hasFile = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let isLt10M
            if (this.form.file) {
              isLt10M = this.form.file.every(
                (file) => file.size / 1024 / 1024 < 2
              )
            } else {
              isLt10M = true
            }
            if (!isLt10M) {
              this.$message.error('请检查，上传文件大小不能超过2M!')
            } else {
              let dataPar = this.form
              let formData = new FormData() //  用FormData存放上传文件
              if (this.form.paper_id !== undefined)
                formData.append('id', this.form.paper_id)
              formData.append('num', dataPar.num)
              formData.append('is_major', dataPar.is_major)
              formData.append('sid_arr', dataPar.sid_arr)
              if (this.form.file) {
                formData.append(
                  'file',
                  this.form.file[0].raw,
                  this.form.file[0].raw.name
                )
              }
              formData.append('type', this.title === '编辑' ? 'edit' : 'add')
              const { msg } =
                this.title === '编辑'
                  ? await doEdit(formData)
                  : await addEval(formData)
              this.$baseMessage(msg, 'success')
              this.$emit('fetch-data')
              this.close()
            }
          } else {
            return false
          }
        })
      },
      async studioData() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      // 文件超出个数限制时的钩子
      exceedFile(files, fileList) {
        this.$baseMessage(
          `只能选择 1 个文件，当前共选择了 ${
            files.length + fileList.length
          } 个`,
          'warning'
        )
      },
      // 文件状态改变时的钩子
      fileChange(file, fileList) {
        let existFile = fileList
          .slice(0, fileList.length - 1)
          .find((f) => f.name === file.name)
        if (existFile) {
          this.$baseMessage('当前文件已经存在!', 'error')
          fileList.pop()
          return false
        }
        if (fileList.length === 1) {
          this.hasFile = true
        }
        this.form.file = fileList
      },
      // 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
      beforeUploadFile(file) {
        let extension = file.name.substring(file.name.lastIndexOf('.') + 1)
        let size = file.size / 1024 / 1024
        if (extension !== 'xls' && extension !== 'xlsx') {
          this.$baseMessage('只能上传后缀是.xls、.xlsx的文件', 'warning')
        }
        if (size > 2) {
          this.$baseMessage('文件大小不得超过2M', 'warning')
        }
      },
      handleRemove(file, fileList) {
        if (!fileList.length) {
          this.hasFile = false
        }
        this.form.file = []
      },
      // 文件上传成功时的钩子
      handleSuccess(res, file, fileList) {
        this.$baseMessage('上传成功', 'success')
      },
      // 文件上传失败时的钩子
      handleError(err, file, fileList) {
        this.$baseMessage('文件上传失败', 'error')
      },
      UploadUrl: function () {
        // 因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
        return ''
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-upload__tip {
    display: inline;
    margin-left: 10px;
    > span {
      font-weight: bold;
      color: $base-color-red;
    }
  }
</style>
