var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tencent-set-container" },
    [
      _c(
        "el-card",
        { attrs: { shadow: "never" } },
        [
          _c(
            "div",
            {
              staticClass: "header",
              attrs: { slot: "header" },
              slot: "header",
            },
            [_vm._v("公众号参数")]
          ),
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "80px", model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "开发者ID", prop: "appid" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入开发者ID，即AppID" },
                    model: {
                      value: _vm.form.appid,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "appid", $$v)
                      },
                      expression: "form.appid",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开发者密码", prop: "appsecret" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入开发者密码，即AppSecret" },
                    model: {
                      value: _vm.form.appsecret,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "appsecret", $$v)
                      },
                      expression: "form.appsecret",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium", type: "primary" },
                      on: { click: _vm.save },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }