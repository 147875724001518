<template>
  <div class="addWarnigRule-container">
    <el-row :gutter="15">
      <el-col
        :lg="{ span: 12, offset: 6 }"
        :md="{ span: 20, offset: 2 }"
        :sm="{ span: 20, offset: 2 }"
        :xl="{ span: 12, offset: 6 }"
        :xs="24"
      >
        <el-form
          ref="form"
          class="demo-form"
          :label-position="labelPosition"
          label-width="130px"
          :model="form"
          :rules="rules"
        >
          <div class="form-row">
            <div class="block-title">基本信息</div>
            <el-form-item label="标题" prop="name">
              <el-input v-model="form.name" />
            </el-form-item>
            <el-form-item label="量表" prop="pid">
              <el-select
                v-model="form.pid"
                clearable
                :disabled="form.type === 'edit'"
                filterable
                placeholder="请选择量表"
                @change="bindChange"
              >
                <el-option
                  v-for="item in evalList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="预警级别" prop="lv">
              <el-select
                v-model="form.lv"
                clearable
                filterable
                placeholder="预警级别"
              >
                <el-option
                  v-for="item in warningList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.sort"
                />
              </el-select>
            </el-form-item>
          </div>
          <div class="form-row">
            <div class="block-title">
              <span>预警项</span>
              <div>
                <el-button
                  icon="el-icon-refresh"
                  type="primary"
                  @click="refresh"
                >
                  刷新
                </el-button>
                <el-button
                  icon="el-icon-plus"
                  type="primary"
                  @click="handleEdit({ pid: form.pid, id: form.id }, 'add')"
                >
                  新增
                </el-button>
              </div>
            </div>
            <el-table
              ref="ruleTable"
              v-loading="listLoading"
              :data="list"
              @select="selectRow"
              @selection-change="setSelectRows"
            >
              <el-table-column align="center" type="selection" />
              <el-table-column align="center" label="标题" prop="name" />
              <el-table-column
                align="center"
                label="预警判断"
                prop="jungle_name"
              />
              <el-table-column
                align="center"
                label="预警分值"
                prop="warn_score"
              />
              <el-table-column align="center" label="操作" prop="title">
                <template #default="{ row }">
                  <el-button type="text" @click="handleEdit(row, 'edit')">
                    编辑
                  </el-button>
                  <el-button
                    style="color: #da0c0c"
                    type="text"
                    @click="handleEdit(row, 'del')"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <!--            <el-pagination-->
            <!--              background-->
            <!--              :current-page="queryForm.pageNo"-->
            <!--              :page-size="queryForm.pageSize"-->
            <!--              :layout="layout"-->
            <!--              :total="total"-->
            <!--              @size-change="handleSizeChange"-->
            <!--              @current-change="handleCurrentChange"-->
            <!--            />-->
          </div>
          <el-form-item label="关联关系" prop="relate">
            <el-radio-group v-model="form.relate">
              <el-radio label="and">和</el-radio>
              <el-radio label="or">或</el-radio>
            </el-radio-group>
            <div style="line-height: initial; color: #bbb">
              当选择“和”时，需要同时满足所有勾选的预警项时，才会触发预警；当选择“或”时，只需满足一项勾选的预警项即可触发预警
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('form')">
              {{ form.id ? '保存修改' : '立即创建' }}
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <warn-relate ref="warn" @rule-list="getRuleLists" />
  </div>
</template>

<script>
  import { getEvalListBySid } from '@/api/mmptCategory'
  import { getWarnParam } from '@/api/crisis'
  import { doRuleEdit } from '@/api/settingAuthority'
  import { handleActivePath } from '@/utils/routes'
  import WarnRelate from './components/warnRelate'
  import { mapActions } from 'vuex'

  export default {
    name: 'AddWarnigRule',
    components: { WarnRelate },
    inject: ['reload'],
    data() {
      return {
        list: [], //预警逻辑列表
        layout: 'total, sizes, prev, pager, next, jumper',
        listLoading: false,
        labelPosition: 'right',
        selectRows: [],
        form: {
          name: '',
          pid: '',
          lv: '',
          check: [],
          relate: '',
        },
        rules: {
          name: [{ required: true, message: '请输入标题', trigger: 'blur' }],
          pid: [{ required: true, trigger: 'change', message: '请选择量表' }],
          lv: [
            { required: true, trigger: 'change', message: '请选择预警级别' },
          ],
          relate: [
            { required: true, trigger: 'change', message: '请选择关联' },
          ],
        },
        total: 0,
        warningList: [], //预警级别
        evalList: [], //量表
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.getData()
      this.getWarnLevel()
      this.getEvalList()
    },
    methods: {
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      selectRow(val, rows) {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.ruleTable.toggleRowSelection(row, true)
          })
        } else {
          this.$refs.ruleTable.clearSelection()
        }
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      bindChange(val) {
        this.form.pid = val
        this.getRuleLists()
      },
      handleEdit(row, type) {
        if (type === 'del') {
          this.$baseConfirm('确定删除该预警项吗？', null, () => {
            doRuleEdit({
              id: row.id,
              type: type,
            }).then(async (res) => {
              this.$baseMessage(res.msg, 'success')
              this.refresh()
            })
          })
        } else {
          this.$refs['warn'].showEdit(row, type)
        }
      },
      async getEvalList() {
        const { data } = await getEvalListBySid()
        this.evalList = data
      },
      getWarnLevel() {
        getWarnParam({ type: 'warning' }).then((res) => {
          let list = []
          res.data.forEach((i) => {
            i.status == '1' && list.push(i)
          })
          this.warningList = list
        })
      },
      getData() {
        const route = this.$route
        this.$nextTick(() => {
          let rid = route.query.id
          let plan = route.query.plan
          if (plan && rid) {
            this.getDetailById({ plan: plan, id: rid, type: 'info' })
          } else {
            this.form.type = 'add'
            this.form.id = rid || 0
            this.form.plan = plan || 0
          }
        })
      },
      // 获取预警详情
      async getDetailById(param) {
        const { data } = await doRuleEdit(param)
        this.form = data
        this.form.id = param.id
        this.form.plan = param.plan
        this.form.type = 'edit'
        this.form.check = data.check
        await this.getRuleLists()
      },
      // 预警项列表
      async getRuleLists() {
        this.listLoading = true
        const { data, totalCount } = await doRuleEdit({
          type: 'warning',
          id: this.form.id,
          pid: this.form.pid,
        })
        this.list = data
        this.total = totalCount
        this.listLoading = false
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.getRuleLists()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.getRuleLists()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.getRuleLists()
      },
      refresh() {
        this.queryForm.pageNo = 1
        this.getRuleLists()
      },
      submitForm(formName) {
        //提交
        this.$refs[formName].validate(async (valid, error) => {
          if (valid) {
            console.log(this.form)
            if (!this.list.length) {
              this.$baseMessage('请添加预警逻辑', 'error')
              return false
            }
            const { msg } = await doRuleEdit(this.form)
            this.$baseMessage(msg, 'success')
            //关闭当前tab，返回list页并刷新
            await this.delVisitedRoute(handleActivePath(this.$route, true))
            this.reload()
            this.$router.push('/setting/addWarnigPlan')
          } else {
            // eslint-disable-next-line no-console
            for (let key in error) {
              this.$message.error(error[key][0].message)
              break
            }
            return false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .addWarnigRule-container {
    .form-row {
      &:not(:first-child) {
        padding-top: 20px;
        margin-bottom: 20px;
      }
      .block-title {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        padding-left: 20px;
        margin-bottom: 20px;
        &:before {
          position: absolute;
          content: '';
          width: 6px;
          height: 16px;
          background: #26cdb7;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        }
      }
    }
  }
</style>
