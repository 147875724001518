var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report-setting-container" },
    [
      _c(
        "el-card",
        { attrs: { shadow: "never" } },
        [
          _c(
            "div",
            {
              staticClass: "header",
              attrs: { slot: "header" },
              slot: "header",
            },
            [_vm._v("报告设置")]
          ),
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "80px", model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  attrs: { prop: "title" },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _vm._v(" 标题 "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "展示在个人报告封面顶部。",
                                placement: "top",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: { icon: "el-icon-info", type: "text" },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入报告标题" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { prop: "slogan" },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _vm._v(" slogan "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "展示在个人报告封面底部。",
                                placement: "top",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: { icon: "el-icon-info", type: "text" },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入slogan" },
                    model: {
                      value: _vm.form.slogan,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "slogan", $$v)
                      },
                      expression: "form.slogan",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { prop: "set_default" },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _vm._v(" 样式 "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "设置报告默认展示样式",
                                placement: "top",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: { icon: "el-icon-info", type: "text" },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.set_default,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "set_default", $$v)
                        },
                        expression: "form.set_default",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("默认样式"),
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("体检样式"),
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("医院简版"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium", type: "primary" },
                      on: { click: _vm.save },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }