var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customWarning-container" },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px", "text-align": "right" } },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-plus", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleEdit()
                },
              },
            },
            [_vm._v(" 新增 ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { border: "", data: _vm.list },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "方案标题", prop: "title" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "最后更新时间",
              prop: "update_at",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "创建人", prop: "cname" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "备注", prop: "remark" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              prop: "title",
              width: "180px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { disabled: row.default === "10", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row, "default")
                          },
                        },
                      },
                      [_vm._v(" 设为默认 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row, "copy")
                          },
                        },
                      },
                      [_vm._v(" 复制 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row, "edit")
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "del",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row, "del")
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }