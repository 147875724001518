<template>
  <div class="order-eval-record-container">
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form
          ref="queryForms"
          :inline="true"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item>
            <el-select
              v-model="queryForm.paper_id"
              clearable
              filterable
              placeholder="请选择量表"
            >
              <el-option
                v-for="item in evalList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.status"
              clearable
              filterable
              placeholder="订单状态"
            >
              <el-option label="已支付" :value="1" />
              <el-option label="未支付" :value="0" />
              <el-option label="已完成" :value="2" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="queryForm.time"
              end-placeholder="结束日期"
              start-placeholder="开始日期"
              type="daterange"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <div class="m-tab">
      <div
        v-for="(tab, i) in status"
        :key="i"
        class="m-t-item"
        :class="queryForm.type === tab.id ? 'cur' : ''"
        @click="bindStatus(tab.id)"
      >
        {{ tab.name }}
      </div>
    </div>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="用户信息">
        <template #default="{ row }">
          <div style="display: flex; align-items: center">
            <el-image :src="row.user.avatar" />
            <span style="padding-left: 8px; flex: 1">
              {{ row.user.nickname }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="绑定手机" prop="user.phone" />
      <el-table-column align="center" label="测评量表" prop="paper.name" />
      <el-table-column align="center" label="价格" prop="paper.price" />
      <el-table-column align="center" label="订单状态" prop="status">
        <template #default="{ row }">
          <span v-if="row.status == 0" style="color: red">未支付</span>
          <span v-if="row.status == 1">已支付</span>
          <span v-if="row.status == 2">已完成</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="测评时间" prop="created_at" />
      <el-table-column align="center" label="操作" width="150px">
        <template #default="{ row }">
          <el-button
            :disabled="row.status == 0"
            type="text"
            @click="handleView(row, '/other/answerNotes')"
          >
            答题记录
          </el-button>
          <el-button
            :disabled="row.status != 2"
            type="text"
            @click="handleView(row, '/other/mmptRecord')"
          >
            查看报告
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getOrderList } from '@/api/psyeval'
  import { getEvalListBySid } from '@/api/mmptCategory'

  export default {
    name: 'OrderRecord',
    data() {
      return {
        list: [],
        evalList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        status: [
          {
            name: '已生成',
            id: 1,
          },
          {
            name: '未生成',
            id: 2,
          },
        ],
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          paper_id: '',
          type: 1, //已生成1、未生成2（已支付和未支付状态数据）
          status: '', //已支付1（已支付未答题）、未支付0（未付款未答题）已完成2（已付款已答题）
          time: [], //时间段筛选
        },
      }
    },
    created() {
      this.getEvalList()
      this.fetchData()
    },
    methods: {
      bindStatus(val) {
        this.queryForm.type = val
        this.queryData()
      },
      handleView(row, url) {
        let routeData = this.routeTo({ id: row.oid, type: 'pay' }, url)
        window.open(routeData.href, '_blank')
      },
      routeTo(id, url) {
        let routeData = this.$router.resolve({
          path: url,
          query: id,
        })
        return routeData
      },
      getEvalList() {
        getEvalListBySid().then((res) => {
          this.evalList = res.data
        })
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount } = await getOrderList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.listLoading = false
      },
    },
  }
</script>

<style scoped lang="scss">
  .order-eval-record-container {
    .m-tab {
      display: flex;
      align-items: center;
      .m-t-item {
        padding: 10px 30px;
        border-radius: 10px 10px 0 0;
        cursor: pointer;
        &:hover,
        &.cur {
          background: #26cdb7;
          color: #fff;
          mask: url('~@/assets/tabs_images/vab-tab.png');
          mask-size: 100% 100%;
        }
      }
    }
  }
</style>
