var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "首页图标",
        visible: _vm.dialogVisible,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: function ($event) {
          return _vm.close()
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "right",
            "label-width": "80px",
            model: _vm.form,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标题", prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入标题" },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "排序",
                prop: "sort",
                rules: [
                  {
                    pattern: /^[0-9]*$/,
                    message: "排序必须为正数",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入数字，数字越大越靠前" },
                model: {
                  value: _vm.form.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sort", _vm._n($$v))
                  },
                  expression: "form.sort",
                },
              }),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "图标" } }, [
            _c("div", { staticStyle: { display: "flex" } }, [
              _c(
                "div",
                { staticClass: "choose-img", on: { click: _vm.imgDialog } },
                [
                  _vm.form.cover
                    ? _c("el-image", {
                        attrs: { fit: "contain", src: _vm.form.cover },
                      })
                    : _c("i", { staticClass: "el-icon-plus" }),
                ],
                1
              ),
              _c("div", { staticClass: "img-tip" }, [
                _vm._v("推荐尺寸：120*120"),
              ]),
            ]),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "跳转方式", prop: "type" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 10 } }, [
                    _vm._v("内部链接"),
                  ]),
                  _c("el-radio", { attrs: { label: 20 } }, [
                    _vm._v("外部链接"),
                  ]),
                  false
                    ? _c("el-radio", { attrs: { label: 30 } }, [
                        _vm._v("富文本"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm.form.type === 10
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "menu" },
                      _vm._l(_vm.menuList, function (item, index) {
                        return _c("div", { key: index, staticClass: "item" }, [
                          _c("label", [_vm._v(_vm._s(item.title))]),
                          _c(
                            "div",
                            { staticClass: "sub-item" },
                            _vm._l(item.data, function (m, i) {
                              return _c(
                                "el-button",
                                {
                                  key: i,
                                  class: {
                                    active:
                                      _vm.curItem &&
                                      _vm.curItem.link === m.link,
                                  },
                                  attrs: { plain: "", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.chooseUrl(m)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(m.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                    _vm.curItem && _vm.curItem.is_det
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择链接",
                                },
                                on: { change: _vm.bindChange },
                                model: {
                                  value: _vm.url.in,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.url, "in", $$v)
                                  },
                                  expression: "url.in",
                                },
                              },
                              _vm._l(_vm.list, function (m, _j) {
                                return _c("el-option", {
                                  key: _j,
                                  attrs: { label: m.title, value: m.url },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.form.type === 20
                ? _c(
                    "div",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入跳转链接" },
                        model: {
                          value: _vm.url.out,
                          callback: function ($$v) {
                            _vm.$set(_vm.url, "out", $$v)
                          },
                          expression: "url.out",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.type === 30
                ? _c(
                    "div",
                    [
                      _c("editor", {
                        attrs: { "is-clear": _vm.isClear },
                        on: { change: _vm.editorChange },
                        model: {
                          value: _vm.form.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "content", $$v)
                          },
                          expression: "form.content",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
      _c("gallery", { ref: "gallery", on: { checkedImg: _vm.getImg } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }