<template>
  <div class="gauge-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="8">
        <el-button
          v-if="button.add"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit"
        >
          添加量表
        </el-button>
        <el-button
          v-else
          disabled
          icon="el-icon-plus"
          title="暂无权限，如有需要，请联系管理员"
          type="primary"
        >
          添加量表
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel v-if="button.search" :span="16">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.evalName"
              clearable
              placeholder="请输入量表名"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.evalType"
              filterable
              placeholder="请选择量表类型"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column label="文档名称" prop="name" />
      <el-table-column align="center" label="题量" prop="num" width="210">
        <template v-if="false" #default="{ row }">
          <template v-if="row.edit">
            <el-input
              :ref="`inputNum_${row.paper_id}`"
              v-model="row.num"
              class="edit-ipt"
              size="mini"
            />
            <el-button size="mini" @click="cancelEdit(row)">取消</el-button>
            <el-button
              size="mini"
              type="primary"
              @click="handleInputConfirm(row)"
            >
              保存
            </el-button>
          </template>
          <el-button v-else size="mini" @click="showInput(row)">
            <i class="el-icon-edit"></i>
            {{ row.num }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="类型" prop="major">
        <template #default="{ row }">
          <el-tag :type="row.is_major == 1 ? 'success' : 'warning'">
            {{ row.major }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="文件格式" prop="subhead" />
      <el-table-column align="center" label="开放渠道" prop="sid_arr" />
      <el-table-column align="center" fixed="right" label="操作" width="120">
        <template #default="{ row }">
          <!--<el-button
            :icon="row.is_plan == 1 ? 'el-icon-minus' : 'el-icon-plus'"
            size="mini"
            :type="row.is_plan == 1 ? 'danger' : 'primary'"
            @click="handleEdit(row)"
          >
            {{ row.is_plan == 1 ? '移出' : '加入' }}团测
          </el-button>-->
          <el-button v-if="button.edit" type="text" @click="handleEdit(row)">
            编辑
          </el-button>
          <el-button
            v-else
            disabled
            title="暂无权限，如有需要，请联系管理员"
            type="text"
          >
            编辑
          </el-button>
          <el-button
            v-if="button.delete"
            type="text"
            @click="handleDelete(row)"
          >
            禁用
          </el-button>
          <el-button
            v-else
            disabled
            title="暂无权限，如有需要，请联系管理员"
            type="text"
          >
            禁用
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getList, doDelete } from '@/api/platformGaugeManagement'
  import Edit from './components/PlatformGaugeManagementEdit'
  import { isNumber } from '@/utils/validate'

  export default {
    name: 'GaugeManagement',
    components: { Edit },
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          evalName: '',
          evalType: '',
        },
        options: [
          {
            value: '0',
            label: '全部',
          },
          {
            value: '1',
            label: '专业量表',
          },
          {
            value: '2',
            label: '趣味量表',
          },
        ],
        inputVisible: false,
        button: {},
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      showInput(row) {
        row.edit = true
        this.$nextTick(() => {
          this.$refs[`inputNum_${row.paper_id}`].focus()
        })
      },
      cancelEdit(row) {
        row.num = row.originalNum
        row.edit = false
      },
      handleInputConfirm(row) {
        if (isNumber(row.num)) {
          row.edit = false
          row.originalNum = row.num
        } else {
          this.$baseMessage('请输入数字', 'error')
        }
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.paper_id) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleDelete(row) {
        if (row.paper_id) {
          this.$baseConfirm('你确定要禁用当前量表吗', null, async () => {
            const { msg } = await doDelete({ ids: row.paper_id })
            this.$baseMessage(msg, 'success')
            await this.fetchData()
          })
        } else {
          if (this.selectRows.length > 0) {
            const ids = this.selectRows.map((item) => item.paper_id).join()
            this.$baseConfirm('你确定要删除选中项吗', null, async () => {
              const { msg } = await doDelete({ ids })
              this.$baseMessage(msg, 'success')
              await this.fetchData()
            })
          } else {
            this.$baseMessage('未选中任何行', 'error')
            return false
          }
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getList(this.queryForm)
        this.list = data.map((v) => {
          this.$set(v, 'edit', false)
          v.originalNum = v.num
          return v
        })
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>
<style lang="scss">
  .gauge-container {
    .edit-ipt {
      width: 60px;
      margin-right: 6px;
      input {
        padding: 0 5px !important;
      }
    }
  }
</style>
