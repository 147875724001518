var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add-form-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 15 } },
        [
          _c(
            "el-col",
            {
              attrs: {
                lg: { span: 12, offset: 4 },
                md: { span: 20, offset: 2 },
                sm: { span: 20, offset: 2 },
                xl: { span: 12, offset: 4 },
                xs: 24,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "demo-form",
                  attrs: {
                    "label-position": _vm.labelPosition,
                    "label-width": "130px",
                    model: _vm.form,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "干预对象", prop: "userArr" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.addPerson },
                            },
                            [_vm._v(" 添加干预对象 ")]
                          ),
                        ],
                        1
                      ),
                      _vm.form.userArr.length
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "预警审核", prop: "userArr" } },
                            [
                              _c(
                                "el-table",
                                { attrs: { data: _vm.form.userArr } },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "编号",
                                      prop: "uid",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "姓名",
                                      prop: "name",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "干预等级",
                                      prop: "level",
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ row }) {
                                            return [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    filterable: "",
                                                    placeholder: "请选择",
                                                  },
                                                  model: {
                                                    value: row.level,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "level",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.level",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.levelList,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.id,
                                                      attrs: {
                                                        label: item.title,
                                                        value: item.id,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2330698870
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "干预程度",
                                      prop: "type",
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ row }) {
                                            return [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    filterable: "",
                                                    placeholder: "请选择",
                                                  },
                                                  model: {
                                                    value: row.type,
                                                    callback: function ($$v) {
                                                      _vm.$set(row, "type", $$v)
                                                    },
                                                    expression: "row.type",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.typeList,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.id,
                                                      attrs: {
                                                        label: item.name,
                                                        value: item.id,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      899664305
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "gy-level" },
                                [
                                  _c("div", { staticClass: "tips" }, [
                                    _vm._v("干预预案级别说明(从轻到重)"),
                                  ]),
                                  _vm._l(_vm.levelList, function (item, index) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "item-desc" },
                                      [
                                        _c("label", { staticClass: "lbl" }, [
                                          _vm._v(_vm._s(item.title) + "："),
                                        ]),
                                        _c("span", { staticClass: "txt" }, [
                                          _vm._v(_vm._s(item.descr)),
                                        ]),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "干预时间", prop: "date" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "picker-options": _vm.pickerOptions,
                              placeholder: "请选择日期",
                              type: "date",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.form.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "date", $$v)
                              },
                              expression: "form.date",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "干预时长", prop: "times" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: { placeholder: "请输入时长" },
                            model: {
                              value: _vm.form.times,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "times", $$v)
                              },
                              expression: "form.times",
                            },
                          }),
                          _vm._v(" 小时 "),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "干预问题", prop: "quest" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "请选择干预问题",
                              },
                              model: {
                                value: _vm.form.quest,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "quest", $$v)
                                },
                                expression: "form.quest",
                              },
                            },
                            _vm._l(_vm.questList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.quest, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "内容记录", prop: "detail" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入内容",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.form.detail,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "detail", $$v)
                              },
                              expression: "form.detail",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("form")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.form.id ? "保存修改" : "立即创建") +
                              " "
                          ),
                        ]
                      ),
                      !_vm.form.id
                        ? _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.resetForm("form")
                                },
                              },
                            },
                            [_vm._v(" 重置 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("edit", { ref: "edit", on: { "fetch-data": _vm.getInfo } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }