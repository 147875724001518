<template>
  <div ref="print" class="test-container">
    <div class="print no-print" @click="print">
      <vab-icon icon="printer-line" />
      导出PDF/打印
    </div>
    <div class="tips">
      <div>
        <span>
          工作日可接受咨询的时间：（上午9：00-12：00，下午14：00-17：00）
        </span>
      </div>
      <div><span style="color: red">(请在此处填写空闲时间)</span></div>
    </div>
    <div class="title">大学生人格问卷（University Personality Inventory）</div>
    <el-table
      border
      :data="list"
      :header-cell-style="{
        color: '#333',
        'text-align': 'center',
      }"
      size="mini"
    >
      <el-table-column
        v-for="(item, index) in thead"
        :key="index"
        :label="item.title"
        min-width="60"
      >
        <template #default="{ row }">
          <span>{{ row[item.key] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="city">
      目前：返校
      <span>✔</span>
      ；居家
      <span></span>
      ；城市：
      <label>长沙</label>
    </div>
    <div class="zdy">
      <label>指导语：</label>
      以下问题是为了解你的身心健康状况并为了增进你的身心健康而设计的调查。请你按题号的顺序阅读，
      <label>在最近一年中你常常感觉到或体验到的</label>
      项目上做“
      <label>✔</label>
      ”选择。为了协助你顺利完成大学学业，身心健康地迎接新生活，请你真实选择。
    </div>
    <div class="subject">
      <div v-for="i1 in 4" :key="i1" class="item">
        <div>
          {{ i1 }}.身体健康状态良好。
          <label>✔</label>
        </div>
      </div>
      <div class="item tj">
        <div>
          1-4题打“✔”的数量：
          <span>（）</span>
        </div>
      </div>
      <div v-for="i2 in 4" :key="i2" class="item">
        <div>
          {{ i2 + 4 }}.身体健康状态良好。
          <label></label>
        </div>
      </div>
      <div class="item tj">
        <div>
          5-8题打“✔”的数量：
          <span>（）</span>
        </div>
      </div>
      <div v-for="i3 in 52" :key="i3" class="item">
        <div>
          {{ i3 + 8 }}.身体健康状态良好。
          <label></label>
        </div>
      </div>
      <div class="item tj">
        <div>
          9-60题打“✔”的数量：
          <span>（）</span>
        </div>
      </div>
      <div v-for="i4 in 4" :key="i4" class="item other">
        <div>
          {{ i4 + 60 }}.至今，你感到自身健康方面有问题吗？
          <label></label>
          无/
          <label></label>
          有，请注明：
        </div>
        <div class="txt">发了打发似的</div>
      </div>
      <div class="item other">
        <div>
          65.是否愿意接受团体咨询（6人以内）
          <label></label>
          是/
          <label></label>
          否
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Test',
    data() {
      return {
        list: [],
        thead: [
          {
            title: '测验日期',
            key: 'k1',
            value: '2023-05-20',
          },
          {
            title: '姓名',
            key: 'k2',
            value: '阿发',
          },
          {
            title: '学号',
            key: 'k3',
            value: '20230520',
          },
          {
            title: '性别',
            key: 'k4',
            value: '男',
          },
          {
            title: '年龄',
            key: 'k5',
            value: '20',
          },
          {
            title: '本/硕/博/教工',
            key: 'k6',
            value: '本',
          },
          {
            title: '民族',
            key: 'k7',
            value: '汉',
          },
          {
            title: '年级',
            key: 'k8',
            value: '2',
          },
          {
            title: '学院全称',
            key: 'k9',
            value: '湖南大学西校区计算机',
          },
          {
            title: '电话',
            key: 'k10',
            value: '13829384938',
          },
          {
            title: '生源地',
            key: 'k11',
            value: '湖南',
          },
          {
            title: '紧急联系人',
            key: 'k12',
            value: '发发发',
          },
          {
            title: '紧急联系人电话',
            key: 'k13',
            value: '13848394839',
          },
        ],
      }
    },
    created() {
      let d = {}
      for (let i = 0; i < this.thead.length; i++) {
        let item = this.thead[i]
        d[item.key] = item.value
      }
      this.list = [d]
    },
    methods: {
      print() {
        this.$print(this.$refs.print)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .test-container {
    width: 1400px;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    * {
      color: #333;
    }
    .tips {
      div {
        margin-bottom: 10px;
      }
      span {
        background-color: yellow;
      }
    }
    .title {
      text-align: center;
      font-size: 18px;
      padding: 10px;
      border-top: 1px solid;
      font-weight: bold;
    }
    .city {
      display: flex;
      margin-top: 10px;
      font-weight: bold;
      span {
        display: inline-block;
        width: 16px;
        height: 16px;
        border: 1px solid;
        margin-left: 2px;
      }
      label {
        border-bottom: 1px solid;
        padding: 0 5px;
      }
    }
    .zdy {
      margin: 10px 0;
      line-height: 20px;
      label {
        font-weight: bold;
      }
    }
    .subject {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 600px;
      .item {
        width: 20%;
        line-height: 30px;
        &.tj {
          font-weight: bold;
          padding: 10px 0;
          span {
            border-bottom: 1px solid;
            padding: 0 3px;
          }
        }
        > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          &.txt {
            border-bottom: 1px solid;
            font-weight: normal;
          }
        }
        &.other {
          margin-bottom: 10px;
          > div {
            display: -webkit-box;
            font-weight: bold;
          }
        }
        label {
          display: inline-block;
          width: 16px;
          height: 16px;
          border: 1px solid;
          line-height: 16px;
        }
      }
    }

    ::v-deep {
      .el-table th {
        background: none !important;
        .cell {
          color: #333;
          font-weight: bold;
        }
      }
      .el-table th.el-table__cell.is-leaf,
      .el-table td.el-table__cell,
      .el-table--group,
      .el-table--border {
        border-color: #333;
      }

      .el-table::before,
      .el-table--group::after,
      .el-table--border::after {
        background-color: #333;
      }
    }
  }
  @media print {
    @page {
      size: auto;
      margin: 5mm;
    }
  }
</style>
