var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "Left3" } }, [
    _c("div", { staticClass: "item-title" }, [_vm._v("建档情况")]),
    _c(
      "div",
      { staticClass: "d-flex" },
      _vm._l(_vm.rate, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "d-flex item" },
          [
            _c("dv-water-level-pond", { attrs: { config: item.config } }),
            _c("div", { staticClass: "title-name" }, [
              _vm._v(_vm._s(item.title)),
            ]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }