<template>
  <el-dialog
    :close-on-click-modal="false"
    title="干预对象"
    :visible.sync="dialogFormVisible"
    width="900px"
    @close="close"
  >
    <div class="box">
      <div class="left">
        <el-tree
          ref="ozTree"
          :accordion="true"
          :check-on-click-node="true"
          :check-strictly="true"
          :data="originList"
          highlight-current
          node-key="id"
          :props="defaultProps"
          @check="checkChange"
          @node-click="handleNodeClick"
        />
      </div>
      <div class="right">
        <el-table
          :data="filterData"
          max-height="370"
          @selection-change="setSelectRows"
        >
          <el-table-column align="center" type="selection" />
          <el-table-column
            align="center"
            label="姓名"
            prop="name"
            show-overflow-tooltip
          />
          <el-table-column align="right">
            <template slot="header">
              <el-input v-model="searchContent" placeholder="输入关键字搜索" />
            </template>
            <template #default="{ row }">
              {{ row.account }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getUser } from '@/api/crisis'
  import { getCurStudioOrgin } from '@/api/userManagementOrganization'

  export default {
    name: 'CrisisEdit',
    data() {
      return {
        originList: [], //获取组织结构
        userList: [],
        userArr: [], //选择的成员
        dialogFormVisible: false,
        defaultProps: {
          children: 'children',
          label: 'name',
        },
        editCheckId: '',
        searchContent: '',
      }
    },
    //搜索数据
    computed: {
      filterData: function () {
        let input = this.searchContent && this.searchContent.toLowerCase()
        let items = this.userList
        let items1
        if (input) {
          items1 = items.filter(function (item) {
            return Object.keys(item).some(function (key1) {
              return String(item[key1]).toLowerCase().match(input)
            })
          })
        } else {
          items1 = items
        }
        return items1
      },
    },
    created() {},
    methods: {
      showEdit() {
        this.getOriginList()
        this.dialogFormVisible = true
      },
      close() {
        this.editCheckId = ''
        this.userList = []
        this.dialogFormVisible = false
      },
      async getOriginList() {
        const { data } = await getCurStudioOrgin()
        this.originList = data
      },
      handleNodeClick(item, node, self) {
        //自己定义的editCheckId，防止单选出现混乱
        this.editCheckId = item.id
        this.$refs.ozTree.setCheckedKeys([item.id])
      },
      checkChange(item, node, self) {
        if (node === true) {
          this.editCheckId = item.id
          this.$refs.ozTree.setCheckedKeys([item.id])
        } else {
          if (this.editCheckId === item.id) {
            this.$refs.ozTree.setCheckedKeys([item.id])
          }
        }
        if (!item.children) {
          getUser({ id: item.id }).then((res) => {
            this.userList = res.data
          })
        }
      },
      setSelectRows(val) {
        this.userArr = val
      },
      save() {
        if (this.userArr.length) {
          this.$emit('fetch-data', this.userArr)
          this.close()
        } else {
          this.$baseMessage('请选择干预成员')
        }
      },
    },
  }
</script>
<style lang="scss">
  .box {
    display: flex;
    flex-direction: row;
    height: 400px;
    > div {
      &.left {
        width: 260px;
        overflow-y: scroll;
      }
      &.right {
        flex: 1;
      }
      margin: 0 10px;
      border-radius: 8px;
      border: 1px solid #eee;
      padding: 15px;
    }
  }
  /*.el-tree-node {
    .is-leaf + .el-checkbox .el-checkbox__inner {
      display: inline-block;
    }
    .el-checkbox .el-checkbox__inner {
      display: none;
    }
  }*/
</style>
