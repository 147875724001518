<template>
  <div class="menu-set-container">
    <div v-loading="loading" class="public-account-management clearfix">
      <div style="display: flex; min-width: 1000px">
        <!--左边配置菜单-->
        <div>
          <div class="left">
            <div class="weixin-hd"></div>
            <div class="weixin-menu menu_main clearfix">
              <div v-for="(item, i) of menuList" :key="i" class="menu_bottom">
                <!-- 一级菜单 -->
                <div
                  class="menu_item el-icon-s-fold"
                  :class="{ active: isActive === i }"
                  @click="menuClick(i, item)"
                >
                  {{ item.name }}
                </div>
                <!-- 以下为二级菜单-->
                <div v-if="isSubMenuFlag === i" class="submenu">
                  <div
                    v-for="(subItem, k) in item.sub_button"
                    :key="k"
                    class="subtitle menu_bottom"
                  >
                    <template v-if="item.sub_button">
                      <div
                        class="menu_subItem ellipsis"
                        :class="{ active: isSubMenuActive === i + '' + k }"
                        @click="subMenuClick(subItem, i, k)"
                      >
                        {{ subItem.name }}
                      </div>
                    </template>
                  </div>
                  <!-- 二级菜单加号， 当长度 小于 5 才显示二级菜单的加号  -->
                  <div
                    v-if="!item.sub_button || item.sub_button.length < 5"
                    class="menu_bottom menu_addicon"
                    @click="addSubMenu(i, item)"
                  >
                    <i class="el-icon-plus" />
                  </div>
                  <div v-if="false" class="sub-sort">
                    <div><i class="el-icon-top"></i></div>
                    <div><i class="el-icon-delete"></i></div>
                    <div><i class="el-icon-bottom"></i></div>
                  </div>
                </div>
              </div>
              <!-- 一级菜单加号 -->
              <div
                v-if="menuList.length < 3"
                class="menu_bottom menu_addicon"
                @click="addMenu"
              >
                <i class="el-icon-plus"></i>
              </div>
            </div>
          </div>
        </div>
        <!--右边配置-->
        <div v-if="showRightFlag" class="right">
          <div class="configure_page">
            <div class="top">
              <div>菜单配置</div>
              <div class="delete_btn">
                <el-button
                  icon="el-icon-delete"
                  size="mini"
                  type="danger"
                  @click="deleteMenu(tempObj)"
                >
                  删除当前菜单
                </el-button>
              </div>
            </div>
            <div>
              <span>菜单名称：</span>
              <el-input
                v-model="tempObj.name"
                class="input_width"
                clearable
                placeholder="请输入菜单名称"
                @input="bindInput(tempObj.name)"
              />
              <div class="maxlength">{{ maxMsg }}</div>
            </div>
            <div v-if="showConfigureContent">
              <div v-if="false" class="menu_content">
                <span>菜单标识：</span>
                <el-input
                  v-model="tempObj.menuKey"
                  class="input_width"
                  clearable
                  placeholder="请输入菜单 KEY"
                />
              </div>
              <div class="menu_content">
                <span>菜单内容：</span>
                <el-radio-group v-model="tempObj.type">
                  <el-radio
                    v-for="item in menuOptions"
                    :key="item.value"
                    :label="item.value"
                  >
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </div>
              <div v-if="tempObj.type === 'view'" class="configur_content">
                <span>跳转链接：</span>
                <el-input
                  v-model="tempObj.url"
                  clearable
                  placeholder="请输入链接"
                />
              </div>
              <div
                v-if="tempObj.type === 'miniprogram'"
                class="configur_content"
              >
                <div class="applet">
                  <span>小程序的 appid ：</span>
                  <el-input
                    v-model="tempObj.appid"
                    clearable
                    placeholder="请输入小程序的appid"
                  />
                </div>
                <div class="applet">
                  <span>小程序页面路径：</span>
                  <el-input
                    v-model="tempObj.pagepath"
                    clearable
                    placeholder="请输入小程序的页面路径，如：pages/index"
                  />
                </div>
                <div class="applet">
                  <span>小程序备用网页：</span>
                  <el-input
                    v-model="tempObj.url"
                    clearable
                    placeholder="不支持小程序的老版本客户端将打开本网页"
                  />
                </div>
                <p class="blue">
                  tips:需要和公众号进行关联才可以把小程序绑定到微信菜单上哟！
                </p>
              </div>
              <div
                v-if="tempObj.type === 'article_view_limited'"
                class="configur_content"
              >
                <el-row>
                  <div
                    v-if="tempObj && tempObj.replyArticles"
                    class="select-item"
                  >
                    <wx-news :articles="tempObj.replyArticles" />
                    <el-row class="ope-row">
                      <el-button
                        circle
                        icon="el-icon-delete"
                        type="danger"
                        @click="deleteMaterial"
                      />
                    </el-row>
                  </div>
                  <div v-else>
                    <el-row>
                      <el-col :span="24" style="text-align: center">
                        <el-button type="success" @click="openMaterial">
                          素材库选择
                          <i class="el-icon-circle-check el-icon--right"></i>
                        </el-button>
                      </el-col>
                    </el-row>
                  </div>
                  <el-dialog
                    title="选择图文"
                    :visible.sync="dialogNewsVisible"
                    width="90%"
                  >
                    <wx-material-select
                      :obj-data="{ type: 'news', accountId: accountId }"
                      @selectMaterial="selectMaterial"
                    />
                  </el-dialog>
                </el-row>
              </div>
              <div v-if="tempObj.type === 'click'" class="configur_content">
                <!--        hackResetWxReplySelect        -->
                <wx-replay v-if="false" :obj-data="tempObj" />
                <el-tabs v-model="tempObj.subType" type="border-card">
                  <el-tab-pane name="text">
                    <span slot="label">
                      <i class="el-icon-document"></i>
                      文本
                    </span>
                    <el-input
                      v-model="tempObj.value"
                      placeholder="请输入内容"
                      :rows="5"
                      type="textarea"
                    />
                  </el-tab-pane>
                  <el-tab-pane name="news">
                    <span slot="label">
                      <i class="el-icon-news"></i>
                      图文
                    </span>
                    <div>
                      <el-button icon="el-icon-circle-check" type="primary">
                        选择素材
                      </el-button>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </div>
        </div>
        <!-- 一进页面就显示的默认页面，当点击左边按钮的时候，就不显示了-->
        <div v-else class="right">
          <p>请从左侧选择菜单配置</p>
        </div>
      </div>
      <div class="save_div">
        <el-button
          class="save_btn"
          size="small"
          type="success"
          @click="handleSave"
        >
          保存并发布菜单
        </el-button>
        <el-button
          v-if="menuList.length"
          class="save_btn"
          size="small"
          type="danger"
          @click="handleDelete"
        >
          清空菜单
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import WxReplay from '@/views/tencent/components/wx-replay'
  import { getOfficialMenu } from '@/api/tencent'
  import { isUrl } from '@/utils/validate'
  export default {
    name: 'MenuSet',
    components: { WxReplay },
    data() {
      return {
        // 遮罩层
        loading: false,
        // 查询参数
        accountId: undefined,
        name: '', // 公众号名
        menuList: [],
        maxMsg: '',
        // ======================== 菜单操作 ========================
        isActive: -1, // 一级菜单点中样式
        isSubMenuActive: -1, // 一级菜单点中样式
        isSubMenuFlag: -1, // 二级菜单显示标志

        // ======================== 菜单编辑 ========================
        showRightFlag: false, // 右边配置显示默认详情还是配置详情
        nameMaxLength: 0, // 菜单名称最大长度；1 级是 4 字符；2 级是 7 字符；
        showConfigureContent: true, // 是否展示配置内容；如果有子菜单，就不显示配置内容
        hackResetWxReplySelect: false, // 重置 WxReplySelect 组件
        tempObj: {}, // 右边临时变量，作为中间值牵引关系
        tempSelfObj: {
          // 一些临时值放在这里进行判断，如果放在 tempObj，由于引用关系，menu 也会多了多余的参数
        },
        dialogNewsVisible: false, // 跳转图文时的素材选择弹窗
        menuOptions: [
          {
            value: 'view',
            label: '跳转网页',
          },
          {
            value: 'miniprogram',
            label: '跳转小程序',
          },
          /*{
            value: 'click',
            label: '点击回复',
          },*/
        ],
      }
    },
    created() {
      this.getMenuList('get')
    },
    methods: {
      getMenuList(type) {
        getOfficialMenu({ type: type }).then((res) => {
          this.menuList = res.data.selfmenu_info.button
        })
      },
      bindChange(val) {
        console.log(val)
        console.log(this.tempObj)
      },
      bindInput(val) {
        let temp = 0,
          curItem = this.tempSelfObj
        for (let k = 0; k < val.length; k++) {
          if (/[\u4e00-\u9fa5]/.test(val[k])) {
            temp += 2
          } else {
            temp++
          }
          if (temp > 8 && curItem.grand == '1') {
            val = val.substr(0, k)
            this.maxMsg = '仅支持中英文和数字，字数不超过4个汉字或8个字母'
            return false
          }
          if (temp > 16 && curItem.grand == '2') {
            val = val.substr(0, k)
            this.maxMsg = '仅支持中英文和数字，字数不超过8个汉字或16个字母'
            return false
          }
          this.maxMsg = ''
        }
      },
      // 一级菜单点击事件
      menuClick(i, item) {
        // 右侧的表单相关
        this.resetEditor()
        this.showRightFlag = true // 右边菜单
        this.tempObj = item // 这个如果放在顶部，flag 会没有。因为重新赋值了。
        this.tempSelfObj.grand = '1' // 表示一级菜单
        this.tempSelfObj.index = i // 表示一级菜单索引
        this.nameMaxLength = 4
        this.showConfigureContent = !(
          item.sub_button && item.sub_button.length > 0
        ) // 有子菜单，就不显示配置内容

        // 左侧的选中
        this.isActive = i // 一级菜单选中样式
        this.isSubMenuFlag = i // 二级菜单显示标志
        this.isSubMenuActive = -1 // 二级菜单去除选中样式
      },
      // 二级菜单点击事件
      subMenuClick(subItem, index, k) {
        // 右侧的表单相关
        this.resetEditor()
        this.showRightFlag = true // 右边菜单
        this.tempObj = subItem // 将点击的数据放到临时变量，对象有引用作用
        this.tempSelfObj.grand = '2' // 表示二级菜单
        this.tempSelfObj.index = index // 表示一级菜单索引
        this.tempSelfObj.secondIndex = k // 表示二级菜单索引
        this.nameMaxLength = 7
        this.showConfigureContent = true

        // 左侧的选中
        this.isActive = -1 // 一级菜单去除样式
        this.isSubMenuActive = index + '' + k // 二级菜单选中样式
      },
      // 添加横向一级菜单
      addMenu() {
        const menuKeyLength = this.menuList.length
        const addButton = {
          name: '菜单名称',
          sub_button: [],
          value: '',
        }
        this.$set(this.menuList, menuKeyLength, addButton)
        this.menuClick(this.menuKeyLength - 1, addButton)
      },
      // 添加横向二级菜单；item 表示要操作的父菜单
      addSubMenu(i, item) {
        // 清空父菜单的属性，因为它只需要 name 属性即可
        if (!item.sub_button || item.sub_button.length <= 0) {
          this.$set(item, 'sub_button', [])
          this.$delete(item, 'type')
          this.$delete(item, 'appid')
          this.$delete(item, 'pagepath')
          this.$delete(item, 'url')
          this.$delete(item, 'value')
          this.$delete(item, 'articleId')
          this.$delete(item, 'replyArticles')
          // 关闭配置面板
          this.showConfigureContent = false
        }

        let subMenuKeyLength = item.sub_button.length // 获取二级菜单key长度
        let addButton = {
          name: '子菜单名称',
          value: '',
        }
        this.$set(item.sub_button, subMenuKeyLength, addButton)
        this.subMenuClick(
          item.sub_button[subMenuKeyLength],
          i,
          subMenuKeyLength
        )
      },
      // 删除当前菜单
      deleteMenu(item) {
        this.$confirm('确定要删除吗?')
          .then(() => {
            // 删除数据
            if (this.tempSelfObj.grand === '1') {
              // 一级菜单的删除方法
              this.menuList.splice(this.tempSelfObj.index, 1)
            } else if (this.tempSelfObj.grand === '2') {
              // 二级菜单的删除方法
              this.menuList[this.tempSelfObj.index].sub_button.splice(
                this.tempSelfObj.secondIndex,
                1
              )
            }
            // 提示
            this.$baseMessage('删除成功')

            // 处理菜单的选中
            this.tempObj = {}
            this.showRightFlag = false
            this.isActive = -1
            this.isSubMenuActive = -1
          })
          .catch(() => {})
      },

      // ======================== 菜单编辑 ========================
      // 组装数据，删除多余参数
      assignment() {
        const list = JSON.parse(JSON.stringify(this.menuList))
        for (let i = 0; i < list.length; i++) {
          // 主菜单
          if (list[i].type) {
            if (list[i].type === 'click') {
              if (
                !list[i].name ||
                list[i].name === '' ||
                list[i].name === null
              ) {
                this.$message.error(`菜单名:${list[i].name}，请填写菜单名`)
                return false
              }
              /*if (
                !list[i].media_id ||
                list[i].media_id === '' ||
                list[i].media_id === null
              ) {
                this.$message.error(`菜单名:${list[i].name}，请选择素材`)
                return false
              }*/
              if (
                (!list[i].value ||
                  list[i].value === '' ||
                  list[i].value === null) &&
                !this.showConfigureContent
              ) {
                this.$message.error(`菜单名:${list[i].name}，请填写文本内容`)
                return false
              }
              list[i].key = 'mainKey_' + i
              delete list[i].sub_button
              delete list[i].url
              delete list[i].appid
              delete list[i].pagepath
            }
            if (list[i].type === 'view') {
              if (
                !list[i].name ||
                list[i].name === '' ||
                list[i].name === null
              ) {
                this.$message.error(`菜单名:${list[i].name}，请填写菜单名`)
                return false
              }
              if (!isUrl(list[i].url)) {
                this.$message.error(`菜单名:${list[i].name}，网页地址格式错误`)
                return false
              }

              delete list[i].sub_button
              delete list[i].appid
              delete list[i].pagepath
              delete list[i].media_id
              delete list[i].key
            }
            if (list[i].type === 'miniprogram') {
              if (
                !list[i].name ||
                list[i].name === '' ||
                list[i].name === null
              ) {
                this.$message.error(`菜单名:${list[i].name}，请填写菜单名`)
                return false
              }
              if (
                !list[i].appid ||
                list[i].appid === '' ||
                list[i].appid === null
              ) {
                this.$message.error(
                  `菜单名:${list[i].name}，小程序appid不能为空`
                )
                return false
              }
              if (
                !list[i].pagepath ||
                list[i].pagepath === '' ||
                list[i].pagepath === null
              ) {
                this.$message.error(
                  `菜单名:${list[i].name}，小程序页面路径不能为空`
                )
                return false
              }
              if (!isUrl(list[i].url)) {
                this.$message.error(
                  `菜单名:${list[i].name}，备用网页地址格式错误`
                )
                return false
              }

              delete list[i].sub_button
              delete list[i].media_id
              delete list[i].key
            }
          } else {
            if (!list[i].name || list[i].name === '' || list[i].name === null) {
              this.$message.error(`菜单名:${list[i].name}，请填写菜单名`)
              return false
            }
            delete list[i].url
            delete list[i].appid
            delete list[i].pagepath
            delete list[i].key
            delete list[i].media_id
          }
          if (list[i].sub_button) {
            // 子菜单
            const subs = list[i].sub_button
            for (let j = 0; j < subs.length; j++) {
              if (subs[j].type === 'click') {
                if (
                  !subs[j].name ||
                  subs[j].name === '' ||
                  subs[j].name === null
                ) {
                  this.$message.error(`菜单名:${subs[j].name}，请填写菜单名`)
                  return false
                }
                /*if (
                  subs[j].media_id === '' ||
                  subs[j].media_id === null ||
                  !subs[j].media_id
                ) {
                  this.$message.error(`菜单名:${subs[j].name}，请选择素材`)
                  return false
                }*/
                if (
                  !subs[j].value ||
                  subs[j].value === '' ||
                  subs[j].value === null
                ) {
                  this.$message.error(`菜单名:${subs[j].name}，请填写文本内容`)
                  return false
                }
                subs[j].key = 'rateKey_' + i + '_' + j
                delete subs[j].sub_button
                delete subs[j].url
                delete subs[j].appid
                delete subs[j].pagepath
              }
              if (subs[j].type === 'view') {
                if (
                  !subs[j].name ||
                  subs[j].name === '' ||
                  subs[j].name === null
                ) {
                  this.$message.error(`菜单名:${subs[j].name}，请填写菜单名`)
                  return false
                }
                if (!isUrl(subs[j].url)) {
                  this.$message.error(
                    `菜单名:${subs[j].name}，网页地址格式错误`
                  )
                  return false
                }
                delete subs[j].sub_button
                delete subs[j].appid
                delete subs[j].pagepath
                delete subs[j].media_id
                delete subs[j].key
              }
              if (subs[j].type === 'miniprogram') {
                if (
                  !subs[j].name ||
                  subs[j].name === '' ||
                  subs[j].name === null
                ) {
                  this.$message.error(`菜单名:${subs[j].name}，请填写菜单名`)
                  return false
                }
                if (
                  !subs[j].appid ||
                  subs[j].appid === '' ||
                  subs[j].appid === null
                ) {
                  this.$message.error(
                    `菜单名:${subs[j].name}，小程序appid不能为空`
                  )
                  return false
                }
                if (
                  !subs[j].pagepath ||
                  subs[j].pagepath === '' ||
                  subs[j].pagepath === null
                ) {
                  this.$message.error(
                    `菜单名:${subs[j].name}，小程序页面路径不能为空`
                  )
                  return false
                }
                if (!isUrl(subs[j].url)) {
                  this.$message.error(
                    `菜单名:${subs[j].name}，备用网页地址格式错误`
                  )
                  return false
                }

                delete subs[j].sub_button
                delete subs[j].media_id
                delete subs[j].key
              }
            }
          }
        }
        return list
      },
      handleSave() {
        const result = this.assignment()
        if (result === false) {
          return false
        } else {
          this.$confirm('确定要保证并发布该菜单吗？')
            .then(() => {
              this.loading = true
              getOfficialMenu({ type: 'update', button: this.menuList }).then(
                (res) => {
                  if (res.code === 200) {
                    this.$baseMessage(res.msg, 'success')
                  }
                }
              )
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
      // 表单 Editor 重置
      resetEditor() {
        this.hackResetWxReplySelect = false // 销毁组件
        this.$nextTick(() => {
          this.hackResetWxReplySelect = true // 重建组件
        })
      },
      handleDelete() {
        if (!this.menuList.length) return false
        this.$confirm('确定要清空所有菜单吗？')
          .then(() => {
            this.loading = true
            this.showRightFlag = false
            this.menuList = []
            //return deleteMenu(this.accountId)
          })
          .then(() => {
            //this.handleQuery()
            this.$baseMessage('清空成功')
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false
          })
      },
      openMaterial() {
        this.dialogNewsVisible = true
      },
      selectMaterial(item) {
        const articleId = item.articleId
        const articles = item.content.newsItem
        // 提示，针对多图文
        if (articles.length > 1) {
          this.$alert('您选择的是多图文，将默认跳转第一篇', '提示', {
            confirmButtonText: '确定',
          })
        }
        this.dialogNewsVisible = false

        // 设置菜单的回复
        this.tempObj.articleId = articleId
        this.tempObj.replyArticles = []
        articles.forEach((article) => {
          this.tempObj.replyArticles.push({
            title: article.title,
            description: article.digest,
            picUrl: article.picUrl,
            url: article.url,
          })
        })
      },
      deleteMaterial() {
        this.$delete(this.tempObj, 'articleId')
        this.$delete(this.tempObj, 'replyArticles')
      },
    },
  }
</script>

<style scoped lang="scss">
  .clearfix {
    *zoom: 1;
  }
  .clearfix::after {
    content: '';
    display: table;
    clear: both;
  }
  div {
    text-align: left;
  }
  .weixin-hd {
    color: #fff;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 64px;
    background: transparent url('~@/assets/menu_head.png') no-repeat 0 0;
    background-position: 0 0;
    background-size: 100%;
  }
  .weixin-menu {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent url('~@/assets/menu_foot.png') no-repeat 0 0;
    padding-left: 43px;
    font-size: 12px;
    display: flex;
  }
  .menu_option {
    width: 40% !important;
  }
  .public-account-management {
    margin: 0 auto;
    overflow-x: scroll;
    .left {
      width: 320px;
      height: 515px;
      padding: 515px 25px 0;
      position: relative;
      box-sizing: border-box;
      border: 1px solid #eee;
      /*第一级菜单*/
      .menu_main {
        .menu_bottom {
          position: relative;
          flex: 1;
          box-sizing: border-box;
          text-align: center;
          border: 1px solid #ebedee;
          background-color: #fff;
          cursor: pointer;
          height: 46px;
          &.menu_addicon {
            height: 46px;
            line-height: 46px;
          }
          .menu_item {
            height: 46px;
            line-height: 46px;
            text-align: center;
            box-sizing: border-box;
            width: 100%;
            overflow: hidden;
            &.active {
              border: 1px solid #2bb673;
              color: #2bb673;
            }
          }
          .menu_subItem {
            padding: 0 5px;
            height: 44px;
            line-height: 44px;
            text-align: center;
            box-sizing: border-box;
            &.active {
              border: 1px solid #2bb673;
              color: #2bb673;
            }
          }
        }
        i {
          color: #2bb673;
        }
        /*第二级菜单*/
        .submenu {
          position: absolute;
          width: 100%;
          bottom: 45px;
          .subtitle {
            background-color: #fff;
            box-sizing: border-box;
          }
        }
      }
    }
    .save_div {
      width: 100%;
      margin-top: 15px;
      text-align: center;
      .save_btn {
        bottom: 20px;
        left: 100px;
      }
    }
    /*右边菜单内容*/
    .right {
      flex: 1;
      background-color: #f5f8fb;
      padding: 20px;
      margin-left: 20px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      .configure_page {
        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;
          border-bottom: 1px solid #e2e7ed;
          padding-bottom: 15px;
        }
        .maxlength {
          font-size: 12px;
          padding-left: 70px;
          margin-top: 5px;
          color: red;
        }
        .menu_content {
          margin-top: 20px;
        }
        .configur_content {
          margin-top: 20px;
          background-color: #fff;
          padding: 20px 10px;
          border-radius: 5px;
        }
        .blue {
          color: #29b6f6;
          margin-top: 10px;
        }
        .applet {
          margin-bottom: 20px;
          span {
            width: 20%;
          }
        }
        .input_width {
          width: 40%;
        }
        .material {
          .input_width {
            width: 30%;
          }
          .el-textarea {
            width: 80%;
          }
        }
      }
    }
    .el-input {
      width: 70%;
      margin-right: 2%;
    }
  }
</style>
<!--素材样式-->
<style lang="scss" scoped>
  .pagination {
    text-align: right;
    margin-right: 25px;
  }
  .select-item {
    width: 280px;
    padding: 10px;
    margin: 0 auto 10px auto;
    border: 1px solid #eaeaea;
  }
  .select-item2 {
    padding: 10px;
    margin: 0 auto 10px auto;
    border: 1px solid #eaeaea;
  }
  .ope-row {
    padding-top: 10px;
    text-align: center;
  }
  .item-name {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }
</style>
