<template>
  <div class="addWarnigRule-container">
    <el-dialog
      :close-on-click-modal="false"
      title="编辑预警逻辑"
      :visible.sync="dialogFormVisible"
      width="800px"
      @close="close"
    >
      <el-form
        ref="subForm"
        class="demo-form"
        :label-position="labelPosition"
        label-width="130px"
        :model="form2"
        :rules="rules"
      >
        <div class="form-row">
          <div class="block-title">基本信息</div>
          <el-form-item label="标题" prop="name">
            <el-input v-model="form2.name" />
          </el-form-item>
          <el-form-item label="量表" prop="pid">
            <el-select
              v-model="form2.pid"
              clearable
              :disabled="form2.type === 'edit'"
              filterable
              placeholder="请选择量表"
              @change="bindChange"
            >
              <el-option
                v-for="item in evalList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="预警级别" prop="lv">
            <el-select
              v-model="form2.lv"
              clearable
              filterable
              placeholder="预警级别"
            >
              <el-option
                v-for="item in warningList"
                :key="item.id"
                :label="item.name"
                :value="item.sort"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="预警问题" prop="quest">
            <el-select
              v-model="form2.quest"
              clearable
              filterable
              placeholder="预警问题"
            >
              <el-option
                v-for="item in questList"
                :key="item.id"
                :label="item.name"
                :value="item.sort"
              />
            </el-select>
          </el-form-item>
        </div>
        <div class="form-row">
          <div class="block-title">
            <span>预警项</span>
            <div>
              <el-button icon="el-icon-refresh" type="primary" @click="refresh">
                刷新
              </el-button>
              <el-button
                icon="el-icon-plus"
                type="primary"
                @click="handleEdit({ pid: form2.pid, id: form2.id }, 'add')"
              >
                新增
              </el-button>
            </div>
          </div>
          <el-table
            ref="ruleTable"
            v-loading="listLoading"
            :data="list"
            @selection-change="setSelectRows"
          >
            <el-table-column align="center" type="selection" />
            <el-table-column align="center" label="标题" prop="name" />
            <el-table-column
              align="center"
              label="预警判断"
              prop="jungle_name"
            />
            <el-table-column
              align="center"
              label="预警分值"
              prop="warn_score"
            />
            <el-table-column align="center" label="操作" prop="title">
              <template #default="{ row }">
                <el-button type="text" @click="handleEdit(row, 'edit')">
                  编辑
                </el-button>
                <el-button
                  style="color: #da0c0c"
                  type="text"
                  @click="handleEdit(row, 'del')"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!--<el-pagination
            background
            :current-page="queryForm.pageNo"
            :page-size="queryForm.pageSize"
            :layout="layout"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />-->
        </div>
        <el-form-item label="关联关系" prop="relate">
          <el-radio-group v-model="form2.relate">
            <el-radio label="and">和</el-radio>
            <el-radio label="or">或</el-radio>
          </el-radio-group>
          <div style="line-height: initial; color: #bbb">
            当选择“和”时，需要同时满足所有勾选的预警项时，才会触发预警；当选择“或”时，只需满足一项勾选的预警项即可触发预警
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submitForm('subForm')">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <warn-relate ref="warn" @rule-list="getRuleLists" />
  </div>
</template>

<script>
  import { getEvalListBySid } from '@/api/mmptCategory'
  import { getWarnParam } from '@/api/crisis'
  import { doRuleEdit, doRuleItemEdit } from '@/api/settingAuthority'
  import WarnRelate from './warnRelate'

  export default {
    name: 'AddWarnigRule',
    components: { WarnRelate },
    data() {
      return {
        dialogFormVisible: false,
        list: [], //预警逻辑列表
        layout: 'total, sizes, prev, pager, next, jumper',
        listLoading: false,
        labelPosition: 'right',
        selectRows: [],
        form2: {
          name: '',
          pid: '',
          lv: '',
          check: [],
          relate: '',
          quest: '',
        },
        rules: {
          name: [{ required: true, message: '请输入标题', trigger: 'blur' }],
          pid: [{ required: true, trigger: 'change', message: '请选择量表' }],
          lv: [
            { required: true, trigger: 'change', message: '请选择预警级别' },
          ],
          relate: [
            { required: true, trigger: 'change', message: '请选择关联' },
          ],
          quest: [
            { required: true, trigger: 'change', message: '请选择预警问题' },
          ],
        },
        total: 0,
        warningList: [], //预警级别
        evalList: [], //量表
        questList: [], //预警问题
        queryForm: {
          pageNo: 1,
          pageSize: 1000,
        },
      }
    },
    created() {
      /*this.getData()
      this.getWarnLevel()
      this.getEvalList()*/
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      bindChange(val) {
        this.form2.pid = val
        this.getRuleLists()
      },
      handleEdit(row, type) {
        if (type === 'del') {
          this.$baseConfirm('确定删除该预警项吗？', null, () => {
            doRuleItemEdit({
              id: row.id,
              type: 'delete',
            }).then(async (res) => {
              this.$baseMessage(res.msg, 'success')
              this.refresh()
            })
          })
        } else {
          if (!row.pid) {
            this.$baseMessage('请先选择量表', 'error')
            return false
          }
          this.$refs['warn'].showEdit2(row, type)
        }
      },
      async getEvalList() {
        const { data } = await getEvalListBySid()
        this.evalList = data
      },
      getWarnLevel(type) {
        getWarnParam({ type: type }).then((res) => {
          let list = []
          res.data.forEach((i) => {
            i.status == '1' && list.push(i)
          })
          this[type + 'List'] = list
        })
      },
      showEdit(row, type) {
        if (type === 'add') {
          this.form2.type = 'add'
          this.form2.id = 0
          this.form2.plan = row.plan
          this.form2.check = []
          this.list = []
        } else {
          this.getDetailById({ plan: row.plan, id: row.id, type: 'info' })
        }
        this.getWarnLevel('warning')
        this.getWarnLevel('quest')
        this.getEvalList()
        this.dialogFormVisible = true
      },
      // 获取预警详情
      async getDetailById(param) {
        const { data } = await doRuleEdit(param)
        this.form2 = data
        this.form2.id = param.id
        this.form2.plan = param.plan
        this.form2.type = 'edit'
        this.form2.check = data.check
        this.$nextTick(() => {
          this.getRuleLists()
        })
      },
      // 预警项列表
      async getRuleLists() {
        this.listLoading = true
        const { data, totalCount } = await doRuleEdit({
          type: 'warning',
          id: this.form2.id,
          pid: this.form2.pid,
        })
        this.list = data
        this.total = totalCount
        this.listLoading = false
        this.chkDefault()
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.getRuleLists()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.getRuleLists()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.getRuleLists()
      },
      refresh() {
        this.queryForm.pageNo = 1
        this.getRuleLists()
      },
      //默认选择项
      chkDefault() {
        this.$nextTick(() => {
          this.form2.check.forEach((selected) => {
            this.list.forEach((row) => {
              if (selected === row.id) {
                this.$refs.ruleTable.toggleRowSelection(row, true)
              }
            })
          })
        })
      },
      submitForm(formName) {
        //提交
        this.$refs[formName].validate(async (valid, error) => {
          if (valid) {
            if (!this.list.length) {
              this.$baseMessage('请添加预警逻辑', 'error')
              return false
            }

            if (!this.selectRows.length && !this.form2.check.length) {
              this.$baseMessage('请选择预警项', 'error')
              return false
            } else {
              const ids = this.selectRows.map((item) => item.id)
              this.form2.check = ids
            }
            if (!this.form2.check.length) {
              this.$baseMessage('请选择预警项', 'error')
              return false
            }

            const { msg } = await doRuleEdit(this.form2)
            this.$baseMessage(msg, 'success')
            this.$emit('warn-list')
            this.close()
          } else {
            // eslint-disable-next-line no-console
            for (let key in error) {
              this.$message.error(error[key][0].message)
              break
            }
            return false
          }
        })
      },
      close() {
        this.$refs['subForm'].resetFields()
        this.form2 = this.$options.data().form2
        this.dialogFormVisible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .addWarnigRule-container {
    .form-row {
      &:not(:first-child) {
        padding-top: 20px;
        margin-bottom: 20px;
      }
      .block-title {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        padding-left: 20px;
        margin-bottom: 20px;
        &:before {
          position: absolute;
          content: '';
          width: 6px;
          height: 16px;
          background: #26cdb7;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        }
      }
    }
  }
</style>
