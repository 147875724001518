<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="500px"
      @close="close"
    >
      <el-form ref="form" label-width="80px" :model="form" :rules="rules">
        <el-form-item label="量表标题" prop="title">
          <el-input v-model="form.title" placeholder="请输入量表标题" />
        </el-form-item>
        <el-form-item label="选择量表" prop="pid">
          <el-select
            v-model="form.pid"
            clearable
            filterable
            placeholder="请选择量表"
          >
            <el-option
              v-for="item in evalList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="副标题" prop="subhead">
          <el-input v-model="form.subhead" placeholder="请输入副标题" />
        </el-form-item>
        <el-form-item label="分类" prop="cate">
          <el-select
            v-model="form.cate"
            clearable
            filterable
            placeholder="请选择分类"
          >
            <el-option
              v-for="item in cateList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
          <el-button
            icon="el-icon-refresh"
            style="font-size: 15px; padding-left: 10px"
            type="text"
            @click="refreshCate"
          />
          <el-button type="text" @click="addCate">新增</el-button>
        </el-form-item>
        <el-form-item label="封面" prop="image_file">
          <div class="img-uploader">
            <el-upload
              accept="image/png, image/jpeg"
              :action="UploadUrl()"
              :auto-upload="false"
              :on-change="fileChange"
              :on-remove="handleRemove"
              :show-file-list="false"
            >
              <img v-if="form.image_file" class="img" :src="form.image_file" />
              <i v-else class="el-icon-plus"></i>
            </el-upload>
            <div slot="tip" class="el-upload__tip">
              <div>
                只能上传
                <span>.jpg、.png</span>
                文件，且
                <span>不超过1M</span>
              </div>
              <div>(图片尺寸1:1)</div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="form.sort" placeholder="请输入数字，越大越靠前" />
        </el-form-item>
        <el-form-item label="详情页样式" prop="style">
          <el-switch
            v-model="form.style"
            active-text="默认样式"
            :active-value="10"
            inactive-text="自定义样式"
            :inactive-value="20"
          />
          <editor
            v-if="form.style === 20"
            v-model="form.content"
            :is-clear="isClear"
            @change="editorChange"
          />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-switch
            v-model="form.status"
            active-text="上架"
            :active-value="10"
            inactive-text="下架"
            :inactive-value="20"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
    <cate ref="cate" />
  </div>
</template>

<script>
  import { isNumber } from '@/utils/validate'
  import { freeListEdit, getFreeCate } from '@/api/eval'
  import Cate from '../components/cateEdit.vue'
  import Editor from '@/components/editor'

  export default {
    name: 'FreeEdit2',
    components: { Cate, Editor },
    props: {
      eval: {
        type: Array,
        default: () => [],
      },
      cate: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      const validateSort = (rule, value, callback) => {
        if (!isNumber(value)) {
          callback(new Error('请输入正整数'))
        } else {
          callback()
        }
      }
      return {
        evalList: [],
        cateList: [],
        form: {
          sort: '', //非必填；分类排序；type为edit/add时必填
          title: '', //非必填；标题；type为edit/add时必填
          pid: '', //非必填；量表ID；type为edit/add时必填
          subhead: '', //非必填；副标题；type为edit/add时必填
          image_file: '', //非必填；封面；type为edit/add时必填
          cate: '', //非必填；分类ID；type为edit/add时必填
          style: '', //非必填；详情类型，10默认 20富文本；type为edit/add时必填
          content: '', //非必填；详情富文本，当style=20时必填；type为edit/add时必填
          status: '', //非必填；状态，上架10下架20；
        },
        isClear: false, //设置为true的时候，这个可以用this.form.content=''来替代
        rules: {
          title: [
            { required: true, trigger: 'blur', message: '请输入分类名称' },
          ],
          pid: [{ required: true, trigger: 'change', message: '请选择量表' }],
          sort: [{ validator: validateSort, trigger: 'blur' }],
        },
        title: '',
        dialogFormVisible: false,
        hideUpload: false,
        hasImg: '',
      }
    },
    watch: {
      eval: {
        handler(newData) {
          this.evalList = newData
        },
        immediate: true,
        deep: true,
      },
      cate: {
        handler(newData) {
          this.cateList = newData
        },
        immediate: true,
        deep: true,
      },
    },
    methods: {
      async refreshCate() {
        const { data } = await getFreeCate()
        this.cateList = data
      },
      async addCate() {
        this.$refs['cate'].showEdit()
      },
      editorChange(val) {
        console.log(val)
      },
      showEdit(row) {
        if (!row) {
          this.title = '添加'
          this.form.type = 'add'
        } else {
          this.title = '编辑'
          let p = Object.assign({}, row)
          this.form = {
            id: p.id,
            type: 'edit',
            sort: p.sort,
            title: p.title,
            pid: p.paper_id,
            subhead: p.subhead,
            image_file: p.cover,
            cate: p.cate,
            style: p.style,
            content: p.content,
            status: p.status,
          }
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let p = this.form
            let formData = new FormData()
            /*formData.append('id', p.id || 0)*/
            formData.append('type', p.type)
            formData.append('title', p.title)
            formData.append('subhead', p.subhead)
            formData.append('pid', p.pid)
            formData.append('cate', p.cate)
            formData.append('style', p.style)
            formData.append('status', p.status)
            formData.append('content', p.content)
            formData.append('sort', p.sort === '' ? 0 : p.sort)
            if (this.hasImg && this.hasImg.raw)
              formData.append(
                'image_file',
                this.hasImg.raw,
                this.hasImg.raw.name
              )
            const { msg, code } = await freeListEdit(formData)
            this.$baseMessage(msg, code === 200 ? 'success' : 'error')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
      UploadUrl: function () {
        return ''
      },
      fileChange(file, fileList) {
        if (fileList.length >= 1) {
          this.handleRemove(fileList)
        }
        const isLt2M = file.size / 1024 / 1024 < 1
        if (file.raw.type !== 'image/jpeg' && file.raw.type !== 'image/png') {
          this.$message.error('上传图片只能是 JPG、PNG 格式!')
          return false
        } else if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 1MB!')
          fileList.map((item, index) => {
            if (item === file) {
              fileList.splice(index, 1)
            }
          })
        } else {
          this.form.image_file = URL.createObjectURL(file.raw)
          this.hasImg = file
          this.hideUpload = fileList.length > 0
        }
      },
      handleRemove(fileList) {
        this.hideUpload = fileList.length > 0
      },
    },
  }
</script>

<style lang="scss" scoped>
  .img-uploader {
    display: flex;
    :deep(.el-upload) {
      border: 1px dashed #d9d9d9;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    :deep(.el-upload--picture-card),
    :deep(.el-upload-list--picture-card .el-upload-list__item) {
      width: 100px;
      height: 100px;
    }
    :deep(.el-list-enter-active),
    :deep(.el-list-leave-active) {
      transition: none;
    }

    :deep(.el-list-enter),
    :deep(.el-list-leave-active) {
      opacity: 0;
    }
    .hide :deep(.el-upload--picture-card) {
      display: none;
    }
    .el-upload__tip {
      flex: 1;
      margin-left: 10px;
      color: #999;
      line-height: normal;
      span {
        color: #e64e1f;
      }
    }
    :deep(.el-upload:hover) {
      border-color: #92e6db;
    }
    .img,
    .el-icon-plus {
      width: 100px;
      height: 100px;
      display: block;
      line-height: 100px;
    }
  }
</style>
