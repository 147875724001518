import { render, staticRenderFns } from "./cate.vue?vue&type=template&id=38b21d81&scoped=true"
import script from "./cate.vue?vue&type=script&lang=js"
export * from "./cate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38b21d81",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\projects\\admin-hty\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('38b21d81')) {
      api.createRecord('38b21d81', component.options)
    } else {
      api.reload('38b21d81', component.options)
    }
    module.hot.accept("./cate.vue?vue&type=template&id=38b21d81&scoped=true", function () {
      api.rerender('38b21d81', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mmpt/free/cate.vue"
export default component.exports