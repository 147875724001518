<template>
  <div class="order-eval-orderview-container">
    <el-card shadow="never">
      <div slot="header" class="header">数据概览</div>
      <div class="info">
        <div class="item">
          <div class="title">测评人数（人）</div>
          <div class="num">{{ initData.user }}</div>
        </div>
        <div class="item">
          <div class="title">总收益（元）</div>
          <div class="num">{{ initData.money }}</div>
        </div>
        <div class="item">
          <div class="title">总订单（笔）</div>
          <div class="num">{{ initData.order }}</div>
        </div>
      </div>
    </el-card>
    <el-card shadow="never">
      <div slot="header" class="header">测评数据</div>
      <vab-chart
        v-if="initData"
        ref="charts2"
        :options="chart"
        style="margin: 50px 0"
        theme="vab-echarts-theme"
      />
    </el-card>
  </div>
</template>

<script>
  import { paperView } from '@/api/psyeval'
  import VabChart from '@/extra/VabChart/index.vue'

  export default {
    name: 'OrderView',
    components: { VabChart },
    data() {
      return {
        initData: {},
        chart: {},
      }
    },
    created() {
      this.getData()
    },
    methods: {
      getData() {
        paperView().then((res) => {
          this.initData = res.data
          this.getLine(res.view)
        })
      },
      getLine(data) {
        data.series.forEach((ds, s) => {
          /*this.$set(ds, 'label', {
            show: true,
          })*/
          this.$set(ds, 'smooth', true)
          this.$set(ds, 'name', data.legend.data[s])
        })
        this.chart = {
          tooltip: {
            trigger: 'axis',
          },
          legend: data.legend,
          xAxis: data.xAxis,
          yAxis: {
            type: 'value',
          },
          series: data.series,
        }
        this.chart.xAxis.splitLine = {
          show: false,
        }
        return this.chart
      },
    },
  }
</script>

<style lang="scss">
  .order-eval-orderview-container {
    .el-card {
      border: 0 !important;
    }
    .el-card__header {
      padding-left: 0 !important;
      padding-top: 0 !important;
    }
    .header {
      position: relative;
      font-size: 16px;
      font-weight: bold;
      padding: 0 20px;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 6px;
        background: #26cdb7;
        border-radius: 3px 0 0 3px;
      }
    }
    .info {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 200px;
        border-radius: 10px;
        padding: 30px 20px;
        text-align: center;
        color: #fff;
        margin: 10px;
        &:nth-child(1) {
          background-image: linear-gradient(135deg, #abdcff 10%, #0396ff 100%);
        }
        &:nth-child(2) {
          background-image: linear-gradient(135deg, #ffd3a5 10%, #fd6585 100%);
        }
        &:nth-child(3) {
          background-image: linear-gradient(135deg, #ee9ae5 10%, #5961f9 100%);
        }
        .num {
          font-size: 24px;
          font-weight: bold;
          margin-top: 10px;
        }
      }
    }
  }
</style>
