<template>
  <div class="add-form-container">
    <!--<el-steps :active="active" :align-center="true" process-status="wait">
      <el-step title="基本信息" />
      <el-step title="预警审核" />
      <el-step title="提交" />
    </el-steps>-->
    <el-row :gutter="15">
      <el-col
        :lg="{ span: 12, offset: 4 }"
        :md="{ span: 20, offset: 2 }"
        :sm="{ span: 20, offset: 2 }"
        :xl="{ span: 12, offset: 4 }"
        :xs="24"
      >
        <el-form
          ref="form"
          class="demo-form"
          :label-position="labelPosition"
          label-width="130px"
          :model="form"
          :rules="rules"
        >
          <div class="form-row">
            <el-form-item label="干预对象" prop="userArr">
              <el-button type="primary" @click="addPerson">
                添加干预对象
              </el-button>
            </el-form-item>
            <el-form-item
              v-if="form.userArr.length"
              label="预警审核"
              prop="userArr"
            >
              <el-table :data="form.userArr">
                <el-table-column
                  align="center"
                  label="编号"
                  prop="uid"
                  show-overflow-tooltip
                />
                <el-table-column
                  align="center"
                  label="姓名"
                  prop="name"
                  show-overflow-tooltip
                />
                <el-table-column
                  align="center"
                  label="干预等级"
                  prop="level"
                  show-overflow-tooltip
                >
                  <template #default="{ row }">
                    <el-select
                      v-model="row.level"
                      filterable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in levelList"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id"
                      />
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="干预程度"
                  prop="type"
                  show-overflow-tooltip
                >
                  <template #default="{ row }">
                    <el-select
                      v-model="row.type"
                      filterable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in typeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                  </template>
                </el-table-column>
              </el-table>
              <div class="gy-level">
                <div class="tips">干预预案级别说明(从轻到重)</div>
                <div
                  v-for="(item, index) in levelList"
                  :key="index"
                  class="item-desc"
                >
                  <label class="lbl">{{ item.title }}：</label>
                  <span class="txt">{{ item.descr }}</span>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="干预时间" prop="date">
              <el-date-picker
                v-model="form.date"
                :picker-options="pickerOptions"
                placeholder="请选择日期"
                type="date"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
            <el-form-item label="干预时长" prop="times">
              <el-input
                v-model="form.times"
                placeholder="请输入时长"
                style="width: 220px"
              />
              小时
            </el-form-item>
            <el-form-item label="干预问题" prop="quest">
              <el-select
                v-model="form.quest"
                filterable
                placeholder="请选择干预问题"
              >
                <el-option
                  v-for="item in questList"
                  :key="item.id"
                  :label="item.quest"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="内容记录" prop="detail">
              <el-input
                v-model="form.detail"
                placeholder="请输入内容"
                type="textarea"
              />
            </el-form-item>
          </div>
          <el-form-item>
            <el-button type="primary" @click="submitForm('form')">
              {{ form.id ? '保存修改' : '立即创建' }}
            </el-button>
            <el-button v-if="!form.id" @click="resetForm('form')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <edit ref="edit" @fetch-data="getInfo" />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { handleActivePath } from '@/utils/routes'
  import { getParams, meddleAdd } from '@/api/crisis'
  import Edit from './components/CrisisEdit'
  import { isNum } from '@/utils/validate'

  export default {
    name: 'AddCrisis',
    components: {
      Edit,
    },
    inject: ['reload'],
    data() {
      const validateNumber = (rule, value, callback) => {
        if (value.length && !isNum(value)) {
          callback(new Error('请输入正确的数字'))
        } else {
          callback()
        }
      }
      return {
        dialogFormVisible: false,
        active: 1,
        labelPosition: 'right',
        form: {
          detail: '',
          times: '',
          quest: '',
          date: '',
          userArr: [],
        },
        rules: {
          times: [
            { required: true, message: '请输入时长', trigger: 'blur' },
            { required: true, trigger: 'blur', validator: validateNumber },
          ],
          quest: [
            { required: true, message: '请选择干预问题', trigger: 'change' },
          ],
          detail: [
            { required: true, message: '请输入内容记录', trigger: 'blur' },
          ],
          date: [
            {
              required: true,
              message: '请选择时间',
              trigger: 'change',
            },
          ],
        },
        questList: [], //干预问题
        levelList: [], //干预等级
        typeList: [], //干预程度
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() + 24 * 60 * 60 * 1000
          },
        },
        descr: '', //描述
      }
    },
    created() {
      this.getQuesList('quest')
    },
    methods: {
      ...mapActions({
        changeTabsMeta: 'tabs/changeTabsMeta',
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      addPerson() {
        this.$refs['edit'].showEdit()
      },
      getInfo(val) {
        this.form.userArr = val.map((item) => {
          this.$set(item, 'level', '1')
          this.$set(item, 'type', '1')
          return item
        })
        this.getQuesList('level')
        this.getQuesList('type')
      },
      getQuesList(type) {
        getParams({ type: type }).then((res) => {
          this[type + 'List'] = res.data
        })
      },
      submitForm(formName) {
        //提交
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            let obj = this.form
            let p = {
              detail: obj.detail,
              times: obj.times,
              quest: obj.quest,
              date: obj.date,
              userArr: obj.userArr,
            }
            if (p.userArr.length === 0) {
              this.$baseMessage('请添加干预对象', 'error')
              return false
            }
            const { msg, code } = await meddleAdd(p)
            if (code === 200) {
              this.$baseMessage(msg, 'success')
              //关闭当前tab，返回list页并刷新
              await this.delVisitedRoute(handleActivePath(this.$route, true))
              this.reload()
              this.$router.push('/crisis/list')
            } else {
              this.$baseMessage(msg, 'error')
            }
          } else {
            // eslint-disable-next-line no-console
            console.log('error submit!!')
            return false
          }
        })
      },
      resetForm(formName) {
        this.$refs[formName].resetFields()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .add-form-container {
    .demo-form {
      margin-top: 10px;
    }
    ::v-deep {
      .el-form-item__content {
        .el-rate {
          display: inline-block;
          font-size: 0;
          line-height: 1;
          vertical-align: middle;
        }

        .el-transfer__buttons {
          padding: 10px 10px 0 10px;
        }
      }
      .el-switch__core {
        width: 50px;
      }
      .el-switch__label * {
        font-size: 12px;
      }
      .el-switch__label--left {
        position: relative;
        left: 44px;
        color: #fff;
        z-index: -1111;
      }
      .el-switch__label--right {
        position: relative;
        right: 44px;
        color: #fff;
        z-index: -1111;
      }
      .el-switch__label--right.is-active,
      .el-switch__label--left.is-active {
        z-index: 1111;
        color: #fff !important;
      }
      .el-tree {
        margin-top: 15px;
      }
    }
    .form-row {
      &:not(:first-child) {
        padding-top: 20px;
        margin-bottom: 20px;
      }
      .block-title {
        font-weight: bold;
        padding-left: 10px;
        border-left: 6px solid #26cdb7;
        margin-bottom: 20px;
      }
    }
    .gy-level {
      background-color: #f5f7fa;
      padding: 15px;
      margin-top: 20px;
      border-radius: 3px;
      .tips {
        font-weight: bold;
      }
      .item-desc {
        display: flex;
        align-items: flex-start;
        margin: 6px 0;
        line-height: 1.5;
        .lbl {
          width: 100px;
          font-weight: bold;
        }
      }
    }
  }
</style>
