<template>
  <el-drawer
    :close-on-click-modal="false"
    size="75%"
    title="计分规则"
    :visible.sync="drawer"
  >
    <div class="eval-score-container">
      <vab-query-form>
        <vab-query-form-left-panel :span="8">
          <el-button icon="el-icon-plus" type="primary" @click="handleEdit">
            添加
          </el-button>
        </vab-query-form-left-panel>
        <vab-query-form-right-panel :span="16">
          <el-form :inline="true" :model="queryForm" @submit.native.prevent>
            <el-form-item>
              <el-input
                v-model.trim="queryForm.name"
                clearable
                placeholder="请输入因子分名称"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                icon="el-icon-search"
                type="primary"
                @click="queryData"
              >
                查询
              </el-button>
            </el-form-item>
          </el-form>
        </vab-query-form-right-panel>
      </vab-query-form>
      <el-table v-loading="listLoading" border :data="list">
        <el-table-column align="center" label="名称" prop="name" />
        <el-table-column
          align="center"
          label="描述"
          prop="descr"
          show-overflow-tooltip
        />
        <el-table-column align="center" label="计分规则" prop="rules">
          <template #default="{ row }">
            {{ row.rules == 10 ? '求和' : '求均值' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="报告展示" prop="show_per">
          <template #default="{ row }">
            <div v-if="row.show_per == 10" style="margin-bottom: 5px">
              <el-tag>个人报告</el-tag>
            </div>
            <div v-if="row.show_team == 10">
              <el-tag type="success">团体报告</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="添加人" prop="add_name" />
        <el-table-column align="center" label="更新时间" prop="update_at" />
        <el-table-column align="center" label="操作" width="120">
          <template #default="{ row }">
            <el-button type="text" @click="handleEdit(row)">编辑</el-button>
            <el-button class="del" type="text" @click="handleDel(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :current-page="queryForm.pageNo"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <edit ref="point" @fetch-data="fetchData" />
  </el-drawer>
</template>

<script>
  import { delScore, getScoreList } from '@/api/eval'
  import Edit from './point'

  export default {
    name: 'ScoreList',
    components: { Edit },
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          pid: '',
          name: '',
        },
        drawer: false,
      }
    },
    created() {},
    methods: {
      showDrawer(id) {
        this.queryForm.pid = id
        this.fetchData()
        this.drawer = true
      },
      handleEdit(row) {
        if (row.id) {
          this.$refs['point'].showPoint(this.queryForm.pid, row.id)
        } else {
          this.$refs['point'].showPoint(this.queryForm.pid)
        }
      },
      handleDel(row) {
        this.$baseConfirm('确定要删除当前项吗', null, async () => {
          const { msg } = await delScore({ id: row.id })
          this.$baseMessage(msg, 'success')
          await this.fetchData()
        })
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount } = await getScoreList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.listLoading = false
      },
    },
  }
</script>

<style scoped>
  .eval-score-container {
    padding: 20px;
  }
</style>
