var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        "close-on-click-modal": false,
        title: "得分分级",
        visible: _vm.dialogFormVisible,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "80px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "得分范围", prop: "range" } },
            [
              _c("el-input", {
                attrs: { placeholder: "得分范围" },
                model: {
                  value: _vm.form.range,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "range", $$v)
                  },
                  expression: "form.range",
                },
              }),
              _c("div", { staticClass: "txt-tips" }, [
                _vm._v(
                  " “[]”英文中括号代表包含边界值，“()”英文小括号代表不包含边界值，比如(1,20]代表的范围为1到20，不包含1，包含20 "
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "等级", prop: "lv" } },
            [
              _c("el-input", {
                attrs: { placeholder: "等级" },
                model: {
                  value: _vm.form.lv,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "lv", $$v)
                  },
                  expression: "form.lv",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "等级描述", prop: "descr" } },
            [
              _c("el-input", {
                attrs: { placeholder: "等级描述", rows: "4", type: "textarea" },
                model: {
                  value: _vm.form.descr,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "descr", $$v)
                  },
                  expression: "form.descr",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }