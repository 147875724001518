<template>
  <el-dialog
    append-to-body
    :close-on-click-modal="false"
    title="编辑计分规则"
    :visible.sync="dialogFormVisible"
    width="900px"
    @close="close"
  >
    <el-row :gutter="15">
      <el-col :xs="24">
        <el-form ref="form" label-width="120px" :model="form" :rules="rules">
          <el-form-item label="因子分" prop="name">
            <el-input
              v-model="form.name"
              maxlength="20"
              placeholder="请输入因子分名称"
            />
          </el-form-item>
          <el-form-item
            label="包含题目"
            prop="ques"
            :rules="[
              { required: true, message: '请输入题号', trigger: 'blur' },
              {
                pattern: /^(\d+,?)+$/,
                message: '只能输入数字和英文逗号',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="form.ques"
              placeholder="多个题目请用英文逗号隔开"
            />
            <div class="txt-tips">
              请输入题目序号，多个题目中间用英文逗号隔开
            </div>
          </el-form-item>
          <el-form-item label="展示">
            <div>
              个人报告
              <el-switch
                v-model="form.show_per"
                :active-value="10"
                :inactive-value="20"
              />
            </div>
            <div>
              团体报告
              <el-switch
                v-model="form.show_team"
                :active-value="10"
                :inactive-value="20"
              />
            </div>
          </el-form-item>
          <el-form-item label="描述" prop="descr">
            <el-input v-model="form.descr" type="textarea" />
            <div class="txt-tips">
              因子分描述展示在个人报告和团测报告中，用于解释因子分
            </div>
          </el-form-item>
          <el-form-item label="计分规则" prop="rules">
            <el-select v-model="form.rules" placeholder="请选择计分规则">
              <el-option
                v-for="item in stype"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="得分分级" prop="ids">
            <el-button type="primary" @click="handleEdit(null, 'add')">
              添加分级
            </el-button>
            <el-table
              ref="ruleTable"
              border
              :data="list"
              style="margin-top: 10px"
            >
              <el-table-column align="center" label="得分范围" prop="range" />
              <el-table-column align="center" label="等级" prop="lv" />
              <el-table-column align="center" label="等级描述" prop="descr" />
              <el-table-column align="center" label="操作">
                <template #default="{ row }">
                  <el-button type="text" @click="handleEdit(row, 'edit')">
                    编辑
                  </el-button>
                  <el-button
                    style="color: #da0c0c"
                    type="text"
                    @click="handleEdit(row, 'del')"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="save">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <lv-edit ref="lv" @fetch-data="fetchData" />
  </el-dialog>
</template>

<script>
  import { scoreType } from '@/utils/selects'
  import { delLv, eidtScore, getLvList, getScoreDetail } from '@/api/eval'
  import LvEdit from './scoreLvEdit'

  export default {
    name: 'EditPoint',
    components: { LvEdit },
    data() {
      return {
        id: '',
        list: [],
        stype: scoreType,
        form: {
          name: '',
          ques: '',
          descr: '', //因子分描述
          show_per: 20, //个人报告是否显示 10显示 20不显示
          show_team: 20, //团体报告是否显示 10显示 20不显示
          rules: '', //计分规则 10求和 20求均值
          ids: [],
        },
        rules: {
          name: [
            { required: true, message: '请输入因子分名称', trigger: 'blur' },
          ],
          rules: [
            { required: true, message: '请选择计分规则', trigger: 'change' },
          ],
        },
        queryForm: {
          pageNo: 1,
          pageSize: 1000,
          id: '',
        },
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showPoint(pid, id) {
        this.queryForm.id = pid
        this.id = id
        this.getDetail()
        this.fetchData()
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      getDetail() {
        let id = this.id
        this.$nextTick(() => {
          if (id) {
            getScoreDetail({ id: id }).then((res) => {
              this.form = res.data
            })
          }
        })
      },
      handleEdit(row, type) {
        if (type === 'del') {
          this.$baseConfirm('确定删除该等级吗？', null, () => {
            delLv({
              id: row.id,
            }).then(async (res) => {
              this.$baseMessage(res.msg, 'success')
              await this.fetchData()
            })
          })
        } else {
          this.$refs['lv'].showEdit(row, this.id)
        }
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.form.pid = this.id || 0
            this.form.ids = this.list.map((item) => {
              return item.id
            })
            const { msg } = await eidtScore(this.queryForm.id, this.form)
            this.$baseMessage(msg, 'success')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
      async fetchData() {
        const { data } = await getLvList({
          id: this.queryForm.id,
          fid: this.id || 0,
        })
        this.list = data
      },
    },
  }
</script>

<style scoped></style>
