var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cate-container" },
    [
      _c(
        "div",
        { staticClass: "tabs" },
        _vm._l(_vm.tabList, function (tab, i) {
          return _c(
            "div",
            {
              key: i,
              staticClass: "tab-item",
              class: _vm.queryForm.type === tab.id ? "active" : "",
              on: {
                click: function ($event) {
                  return _vm.bindTab(tab.id)
                },
              },
            },
            [_vm._v(" " + _vm._s(tab.name) + " ")]
          )
        }),
        0
      ),
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-right-panel",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-plus", type: "primary" },
                  on: { click: _vm.handleEdit },
                },
                [_vm._v(" 新增 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { border: "", data: _vm.list },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "序号",
              type: "index",
              width: "60px",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "名称", prop: "title" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "排序", prop: "sort" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "del",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.delRow(row.id)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("edit", { ref: "edit", on: { "fetch-data": _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }