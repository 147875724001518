<template>
  <div class="team-freestyle-container">
    <vab-query-form>
      <vab-query-form-left-panel v-if="button.add" :span="8">
        <el-button icon="el-icon-plus" type="primary" @click="handleEdit">
          添加
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel
        v-if="button.search"
        :span="button.add ? 16 : 24"
      >
        <el-form
          v-if="isSid === 'all'"
          :inline="true"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item>
            <el-select
              v-model="queryForm.sid"
              clearable
              filterable
              placeholder="请选择渠道"
            >
              <el-option
                v-for="item in studioList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column type="index" width="80" />
      <el-table-column label="标题" prop="title" />
      <el-table-column v-if="isSid === 'all'" label="渠道" prop="name" />
      <el-table-column label="类型" prop="type" />
      <el-table-column align="center" label="操作时间" prop="date" />
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <el-button v-if="button.edit" type="text" @click="handleEdit(row)">
            编辑
          </el-button>
          <el-button
            v-if="button.delete"
            class="del"
            type="text"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getExpandList, expandDel } from '@/api/teamList'
  import Edit from './components/TeamFreestyleEdit'
  import { mapGetters } from 'vuex'
  import { getStudioList } from '@/api/platformChannelManagement'

  export default {
    name: 'Freestyle',
    components: { Edit },
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          title: '',
        },
        button: {},
        studioList: [],
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    created() {
      if (this.isSid === 'all') this.getStudio()
      this.fetchData()
    },
    methods: {
      async getStudio() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.id) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleDelete(row) {
        this.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await expandDel({ id: row.id })
          this.$baseMessage(msg, 'success')
          await this.fetchData()
        })
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getExpandList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>
