var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "set" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { lg: { span: 12 }, md: { span: 16 }, xs: { span: 24 } } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { "label-width": "80px", model: _vm.form },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "平台名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入平台名称" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "logo", prop: "logo" } },
                    [
                      _c("div", { staticClass: "img-uploader" }, [
                        _c(
                          "div",
                          {
                            staticClass: "el-upload el-upload__text",
                            on: { click: _vm.imgDialog },
                          },
                          [
                            _vm.form.logo
                              ? _c("img", {
                                  staticClass: "img",
                                  attrs: { src: _vm.form.logo },
                                })
                              : _c("i", { staticClass: "el-icon-plus" }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "el-upload__tip",
                            attrs: { slot: "tip" },
                            slot: "tip",
                          },
                          [
                            _c("div", [
                              _vm._v(" 只能上传 "),
                              _c("span", [_vm._v(".jpg、.png")]),
                              _vm._v(" 文件，且 "),
                              _c("span", [_vm._v("不超过1M")]),
                            ]),
                            _c("div", [_vm._v("(图片尺寸1:1)")]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "开发者ID", prop: "appid" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入开发者ID，即AppID" },
                        model: {
                          value: _vm.form.appid,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "appid", $$v)
                          },
                          expression: "form.appid",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "开发者密码", prop: "appsecret" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入开发者密码，即AppSecret" },
                        model: {
                          value: _vm.form.appsecret,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "appsecret", $$v)
                          },
                          expression: "form.appsecret",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商户号ID", prop: "shopid" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商户号ID" },
                        model: {
                          value: _vm.form.shopid,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "shopid", $$v)
                          },
                          expression: "form.shopid",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商户号密钥", prop: "paysecret" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商户号密钥" },
                        model: {
                          value: _vm.form.paysecret,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "paysecret", $$v)
                          },
                          expression: "form.paysecret",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "medium", type: "primary" },
                          on: { click: _vm.save },
                        },
                        [_vm._v(" 保存 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("gallery", { ref: "gallery", on: { checkedImg: _vm.getImg } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }