var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "right1" },
    [
      _c("Echart", {
        attrs: {
          id: "right1",
          height: "100%",
          options: _vm.options,
          width: "100%",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }