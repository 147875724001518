<template>
  <div class="warning-container">
    <vab-query-form>
      <div class="tabs">
        <el-button
          :type="queryForm.type === 1 ? 'success' : ''"
          @click="levelType(1)"
        >
          适应问题
        </el-button>
        <el-button
          :type="queryForm.type === 2 ? 'warning' : ''"
          @click="levelType(2)"
        >
          严重问题
        </el-button>
        <el-button
          :type="queryForm.type === 3 ? 'danger' : ''"
          @click="levelType(3)"
        >
          危机问题
        </el-button>
        <el-popover
          class="popEx"
          content="系统自动根据测评报告生成预警信息，划分适应问题，严重问题，干预问题三类，针对严重问题类和危机问题类预警名单学校需要重点关注并建立干预转介机制，适应性问题可通过开展个案辅导、团体辅导进行处理。"
          placement="bottom-start"
          title="预警使用说明"
          trigger="hover"
          width="350"
        >
          <span
            slot="reference"
            class="el-icon-info"
            style="line-height: 30px; margin-bottom: 10px"
          >
            预警等级
          </span>
        </el-popover>
      </div>
      <vab-query-form-left-panel :span="10">
        <!--<el-button
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit('', '/crisis/addCrisis')"
        >
          添加
        </el-button>-->
      </vab-query-form-left-panel>
      <vab-query-form-right-panel v-if="button.search" :span="14">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item v-if="isSid === 'all'">
            <el-select
              v-model="queryForm.sid"
              clearable
              filterable
              placeholder="选择渠道"
            >
              <el-option
                v-for="item in studioList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入用户名"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table v-loading="listLoading" :data="list">
      <el-table-column
        align="center"
        label="用户名"
        prop="name"
        show-overflow-tooltip
        width="100"
      />
      <el-table-column align="center" label="组织结构" prop="origin" />
      <el-table-column
        align="center"
        label="测评量表"
        prop="paper_name"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="测评类型" prop="eval_type" />
      <el-table-column align="center" label="预警因子集" prop="warn_factor">
        <template #default="{ row }">
          <template v-if="row.warn_factor.length < 3">
            <div
              v-for="(item, i) in row.warn_factor"
              :key="i"
              class="warning-box"
            >
              <span class="circle" :class="item.color"></span>
              {{ item.name }}
            </div>
          </template>
          <template v-else>
            <div class="warning-box">
              <span class="circle" :class="row.warn_factor[0].color"></span>
              {{ row.warn_factor[0].name }}
            </div>
            <el-popover placement="right" trigger="click" width="200">
              <div
                v-for="(item, i) in row.warn_factor"
                :key="i"
                class="warning-box"
              >
                <span class="circle" :class="item.color"></span>
                {{ item.name }}
              </div>
              <el-button slot="reference" type="text">查看更多>></el-button>
            </el-popover>
          </template>
        </template>
      </el-table-column>
      <!--<el-table-column
        align="center"
        prop="is_interv"
        label="干预与否"
        width="100"
      >
        <template #default="{ row }">
          <el-tag v-if="row.is_interv" type="danger">干预</el-tag>
          <el-tag v-else type="success">正常</el-tag>
        </template>
      </el-table-column>-->
      <el-table-column align="center" label="操作" width="85">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">干预</el-button>
          <el-button
            type="text"
            @click="handleEdit(row, '/other/mmptRecord', 'record')"
          >
            报告
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getWarnList, setStatus } from '@/api/crisis'
  import { getStudioList } from '@/api/platformChannelManagement'
  import { mapGetters } from 'vuex'
  import Edit from './components/AddSingleCrisis'

  export default {
    name: 'WarningList',
    components: { Edit },
    data() {
      return {
        list: [],
        studioList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          name: '',
          sid: '',
          type: 1,
        },
        button: {},
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    created() {
      this.getStudio()
      this.fetchData()
    },
    methods: {
      async getStudio() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      selectDate(val) {
        console.log(val)
        this.queryForm.time = val
      },
      handleEdit(row, url, type) {
        if (row.uid) {
          if (type === 'record') {
            let params = {
              id: row.eid,
              oid: row.id,
              pid: row.pid,
              uid: row.uid,
            }
            let routeData = this.routeTo(
              { params: encodeURIComponent(JSON.stringify(params)) },
              url
            )
            window.open(routeData.href, '_blank')
          } else {
            this.$refs['edit'].showEdit(row)
          }
        } else {
          this.$router.push(url)
        }
      },
      routeTo(id, url) {
        let routeData = this.$router.resolve({
          path: url,
          query: id,
        })
        return routeData
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      levelType(type) {
        this.queryForm.type = type
        this.queryData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getWarnList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
      doOptions(row, msg, type) {
        this.$baseConfirm(msg, null, () => {
          setStatus({
            id: row.id,
            type: type,
          }).then(async (res) => {
            this.$baseMessage(res.msg, 'success')
            await this.fetchData()
          })
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .tabs {
    margin-bottom: 15px;
    border-bottom: 2px solid #f5f5f5;
    .el-button {
      width: 80px;
    }
    .popEx {
      margin-left: 30px;
      color: #999;
    }
  }
  .warning-box {
    line-height: 24px;
    .circle {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      margin-right: 4px;
      animation: move 2s ease-in-out infinite;
      &.red {
        background-color: red;
        box-shadow: 0 0 4px red;
      }
      &.yellow {
        background-color: yellow;
        box-shadow: 0 0 4px yellow;
      }
      &.orange {
        background-color: orange;
        box-shadow: 0 0 4px orange;
      }
      @keyframes move {
        0% {
          transform: scale(0.6);
        }
        50% {
          transform: scale(1);
        }
        100% {
          transform: scale(0.6);
        }
      }
    }
  }
</style>
