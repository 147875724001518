<template>
  <div id="Left3">
    <div class="item-title">建档情况</div>
    <div class="d-flex">
      <div v-for="(item, index) in rate" :key="index" class="d-flex item">
        <dv-water-level-pond :config="item.config" />
        <!--<left3 :id="item.id" :tips="item.tips" :colors="item.colorData" />-->
        <div class="title-name">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  /*import Left3 from './components/leftChart3'*/
  export default {
    components: {
      /*Left3,*/
    },
    data() {
      return {
        rate: [
          {
            title: '小学',
            config: {
              data: [30],
              shape: 'round',
              waveHeight: 6,
              waveNum: 3,
            },
          },
          {
            title: '初中',
            config: {
              data: [20],
              shape: 'round',
              waveHeight: 6,
              waveNum: 3,
            },
          },
          {
            title: '高中',
            config: {
              data: [24],
              shape: 'round',
              waveHeight: 6,
              waveNum: 3,
            },
          },
          {
            title: '中专',
            config: {
              data: [16],
              shape: 'round',
              waveHeight: 6,
              waveNum: 3,
            },
          },
          {
            title: '其他',
            config: {
              data: [10],
              shape: 'round',
              waveHeight: 6,
              waveNum: 3,
            },
          },
        ],
        /*rate: [
          {
            id: 'centerRate1',
            tips: 12,
            title: '全部人数',
            colorData: {
              textStyle: '#3fc0fb',
              series: {
                color: ['#00bcd44a', 'transparent'],
                dataColor: {
                  normal: '#03a9f4',
                  shadowColor: '#97e2f5',
                },
              },
            },
          },
          {
            id: 'centerRate2',
            tips: 30,
            title: '小学',
            colorData: {
              textStyle: '#67e0e3',
              series: {
                color: ['#00bcd44a', 'transparent'],
                dataColor: {
                  normal: '#03a9f4',
                  shadowColor: '#97e2f5',
                },
              },
            },
          },
          {
            id: 'centerRate3',
            tips: 40,
            title: '初中',
            colorData: {
              textStyle: '#67e0e3',
              series: {
                color: ['#00bcd44a', 'transparent'],
                dataColor: {
                  normal: '#03a9f4',
                  shadowColor: '#97e2f5',
                },
              },
            },
          },
          {
            id: 'centerRate4',
            tips: 20,
            title: '高中',
            colorData: {
              textStyle: '#67e0e3',
              series: {
                color: ['#00bcd44a', 'transparent'],
                dataColor: {
                  normal: '#03a9f4',
                  shadowColor: '#97e2f5',
                },
              },
            },
          },
        ],*/
      }
    },
    mounted() {},
    methods: {},
  }
</script>

<style lang="scss">
  #Left3 {
    padding: 0.3rem;
    .d-flex {
      .item {
        width: 20%;
        flex-direction: column;
        .title-name {
          text-align: center;
          margin-top: 0.1rem;
        }
        .dv-water-pond-level {
          width: 1.2rem;
          height: 1.2rem;
          margin: auto;
        }
      }
    }
  }
</style>
