<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="showDialog"
      width="820px"
      @close="close"
    >
      <div class="modals">
        <div>
          <div class="item">
            <div class="top">
              <span>浩途智库</span>
              <span class="desc">直接可选择海量专业资源库使用</span>
            </div>
            <div class="con">
              浩途智库与全国专家、机构建立合作，提供免费以及收费等课程资源供选择，用户可自由选择智库内容上架使用。
            </div>
            <div class="btn">
              <el-button round type="primary" @click="showLibrary">
                去上架
              </el-button>
            </div>
          </div>
        </div>
        <div>
          <div class="item">
            <div class="top two">
              <span>自主上传</span>
              <span class="desc">自行上传各类音视频科普课程</span>
            </div>
            <div class="con">
              【自主上传】平台支持用户自行上传各类音视频文件对接平台，目前支持七牛云、阿里云等主流云存储空间。
            </div>
            <div class="btn">
              <el-button round type="warning" @click="toUpload">
                去上传
              </el-button>
            </div>
          </div>
          <div class="set-def">
            <el-radio v-model="setDef" :label="true" @input="bindRadio">
              设为默认模式
            </el-radio>
          </div>
        </div>
      </div>
      <div class="el-dialog__footer">
        <em>*</em>
        平台智库可能涉及知识产权问题，可供上架的资源均已获取授权，不能上架资源存在付费资源，请联系我们获取权限。
      </div>
    </el-dialog>

    <Video v-if="type === 'video'" ref="video" @fetch-data="fetchData" />
    <Article v-if="type === 'article'" ref="article" @fetch-data="fetchData" />
    <library ref="library" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import Video from './videoEdit'
  import Article from './articleEdit'
  import Library from './libraryEdit'

  export default {
    name: 'ChooseModal',
    components: { Library, Video, Article },
    data() {
      return {
        title: '',
        showDialog: false,
        cateList: [],
        setDef: false,
        type: '',
      }
    },
    methods: {
      bindRadio(e) {
        localStorage.setItem('setDef', e)
        this.$emit('fetch-data', e)
      },
      showChoose(type, cate) {
        this.type = type
        this.cateList = cate
        this.title = '新增' + (type === 'video' ? '视频' : '文章')
        this.showDialog = true
      },
      showLibrary() {
        this.close()
        this.$refs['library'].showLibrary(this.type)
      },
      close() {
        this.showDialog = false
      },
      toUpload() {
        this.close()
        this.$refs[this.type].showEdit('', this.cateList)
      },
      fetchData() {
        this.$emit('fetch-data')
      },
    },
  }
</script>

<style scoped lang="scss">
  .modals {
    display: flex;
    justify-content: space-between;
    .item {
      width: 350px;
      height: 200px;
      box-sizing: border-box;
      border: 3px solid #fff;
      border-radius: 8px;
      box-shadow: 0 0 23px 8px rgba(0, 0, 0, 0.06);
      background: #fff;
      padding: 15px 20px;
      &:hover {
        border-color: #26cdb7;
        box-shadow: 0 0 11px 5px rgba(38, 205, 183, 0.24);
        background: rgba(38, 205, 183, 0.08);
      }
      .top {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding-left: 50px;
        line-height: 40px;
        padding-bottom: 10px;
        margin-bottom: 15px;
        color: rgb(83, 85, 87);
        font-style: italic;
        font-weight: bold;
        font-size: 16px;
        &:before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          bottom: 0;
          width: 40px;
          height: 40px;
          background: url('~@/assets/resource.svg') no-repeat;
          background-size: 100%;
        }
        &.two:before {
          background-image: url('~@/assets/zzup.svg');
        }
        .desc {
          color: rgba(83, 85, 87, 0.8);
          font-size: 10px;
          font-weight: normal;
        }
      }
      .con {
        color: rgba(83, 85, 87, 0.65);
        font-size: 12px;
        line-height: 1.5;
      }
      .btn {
        margin-top: 20px;
        text-align: center;
        .el-button {
          width: 180px;
        }
      }
    }
    .set-def {
      text-align: center;
      padding: 20px;
      .el-radio {
        color: rgba(83, 85, 87, 0.65);
      }
      :deep().el-radio__label {
        font-size: 12px !important;
      }
    }
  }
  .el-dialog__footer {
    padding: 0 !important;
    color: rgba(83, 85, 87, 0.8);
    font-size: 12px;
    text-align: left;
    em {
      color: red;
    }
  }
</style>
