<template>
  <el-dialog
    append-to-body
    :close-on-click-modal="false"
    title="得分分级"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="得分范围" prop="range">
        <el-input v-model="form.range" placeholder="得分范围" />
        <div class="txt-tips">
          “[]”英文中括号代表包含边界值，“()”英文小括号代表不包含边界值，比如(1,20]代表的范围为1到20，不包含1，包含20
        </div>
      </el-form-item>
      <el-form-item label="等级" prop="lv">
        <el-input v-model="form.lv" placeholder="等级" />
      </el-form-item>
      <el-form-item label="等级描述" prop="descr">
        <el-input
          v-model="form.descr"
          placeholder="等级描述"
          rows="4"
          type="textarea"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { eidtLv } from '@/api/eval'

  export default {
    name: 'ScoreLvEdit',
    data() {
      return {
        pid: '', //量表ID
        form: {
          range: '', //得分范围
          lv: '', //等级
          descr: '', //描述
        },
        rules: {
          lv: [{ required: true, message: '请输入等级', trigger: 'blur' }],
          range: [
            { required: true, message: '请输入得分范围', trigger: 'blur' },
            {
              pattern: /(\(|\[)((\d+(\.))?\d+,(\d+(\.))?\d+)(?=\]|\))/g,
              message: '格式不正确',
              trigger: 'blur',
            },
          ],
          descr: [
            { required: true, message: '请输入等级描述', trigger: 'blur' },
          ],
        },
        dialogFormVisible: false,
      }
    },
    methods: {
      showEdit(row, pid) {
        this.pid = pid
        if (row && row.id) {
          this.form = JSON.parse(JSON.stringify(row))
        } else {
          this.form.id = 0
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await eidtLv(this.pid || 0, this.form)
            this.$baseMessage(msg, 'success')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style scoped></style>
