import request from '@/utils/request'
//分类列表
export function getCateList(params) {
  return request({
    url: '/article/art-cate',
    method: 'get',
    params,
  })
}
//编辑分类
export function cateEdit(data) {
  return request({
    url: '/article/art-cate-change',
    method: 'post',
    data,
  })
}
//文章列表
export function getArticleList(params) {
  return request({
    url: '/article/art-list',
    method: 'get',
    params,
  })
}
//编辑文章
export function articleEdit(data) {
  return request({
    url: '/article/art-change',
    method: 'post',
    data,
  })
}
