var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "right",
            "label-width": "80px",
            model: _vm.form,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "包含题数", prop: "num" } },
            [
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { "prefix-icon": "el-icon-edit" },
                model: {
                  value: _vm.form.num,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "num", $$v)
                  },
                  expression: "form.num",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "量表类型", prop: "is_major" } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "请选择量表类型" },
                  model: {
                    value: _vm.form.is_major,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_major", $$v)
                    },
                    expression: "form.is_major",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "渠道", prop: "sid_arr" } },
            [
              _c("el-transfer", {
                attrs: {
                  data: _vm.studioList,
                  filterable: "",
                  props: { key: "id", label: "name" },
                  titles: ["渠道列表", "已选渠道"],
                },
                model: {
                  value: _vm.form.sid_arr,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sid_arr", $$v)
                  },
                  expression: "form.sid_arr",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上传文件", prop: "file" } },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  attrs: {
                    accept: ".xls,.xlsx",
                    action: _vm.UploadUrl(),
                    "auto-upload": false,
                    "before-upload": _vm.beforeUploadFile,
                    "file-list": _vm.form.file,
                    "on-change": _vm.fileChange,
                    "on-error": _vm.handleError,
                    "on-exceed": _vm.exceedFile,
                    "on-remove": _vm.handleRemove,
                    "on-success": _vm.handleSuccess,
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.hasFile,
                          expression: "!hasFile",
                        },
                      ],
                      attrs: {
                        slot: "trigger",
                        size: "small",
                        type: "primary",
                      },
                      slot: "trigger",
                    },
                    [_vm._v(" 选取文件 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.hasFile,
                          expression: "hasFile",
                        },
                      ],
                      attrs: { disabled: "", size: "small", type: "primary" },
                    },
                    [_vm._v(" 选取文件 ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _vm.title === "编辑"
                        ? _c("span", [
                            _vm._v(
                              " （温馨提示：若选择文件上传，之前上传的文件将会被覆盖） "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" 只能上传 "),
                      _c("span", [_vm._v("xls、xlsx")]),
                      _vm._v(" 文件，且 "),
                      _c("span", [_vm._v("不超过2M")]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }