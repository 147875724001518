<template>
  <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
  import * as echarts from 'echarts'
  import tdTheme from './theme/vab-echarts-theme.json'
  import resizeMixins from '@/utils/resizeMixins'
  import * as di from '@/assets/map/fujian'

  export default {
    name: 'Echart',
    mixins: [resizeMixins],
    props: {
      mapName: {
        type: String,
        default: null,
      },
      className: {
        type: String,
        default: 'chart',
      },
      id: {
        type: String,
        default: 'chart',
      },
      width: {
        type: String,
        default: '100%',
      },
      height: {
        type: String,
        default: '2.5rem',
      },
      options: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        chart: null,
      }
    },
    watch: {
      options: {
        handler(options) {
          // 设置true清空echart缓存
          this.chart.setOption(options, true)
        },
        deep: true,
      },
    },
    mounted() {
      echarts.registerTheme('tdTheme', tdTheme) // 覆盖默认主题
      this.initChart()
      if (this.mapName) {
        this.$nextTick(() => {
          echarts.registerMap(this.mapName, di.area())
        })
      }
    },
    methods: {
      initChart() {
        // 初始化echart
        this.chart = echarts.init(this.$el, 'tdTheme')
        this.chart.setOption(this.options, true)
      },
    },
  }
</script>

<style></style>
