var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "setIndex-container" },
    [
      _c("mobile"),
      false
        ? _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "手机端", name: "mobile" } },
                [_c("mobile")],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "PC端", name: "pc" } },
                [_c("pc")],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }