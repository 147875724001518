var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      false
        ? _c("Echart", {
            ref: "centerLeft2Chart",
            attrs: {
              id: "centerLeft2Chart",
              height: "3.4rem",
              "map-name": "福建",
              options: _vm.options,
              width: "100%",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }