<template>
  <div class="parent-list-container">
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="家长姓名"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.user_name"
              clearable
              placeholder="学生姓名"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.phone"
              clearable
              placeholder="绑定手机号"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table v-loading="listLoading" border :data="list">
      <el-table-column align="center" label="家长信息" prop="name">
        <template #default="{ row }">
          <div style="display: flex; align-items: center">
            <el-image :src="row.avatar" />
            <span style="padding-left: 8px; flex: 1">
              {{ row.name }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="手机号码" prop="phone" />
      <el-table-column align="center" label="绑定学生" prop="user">
        <template #default="{ row }">
          <el-link v-for="(u, i) in row.user" :key="i">
            {{ u.user_name }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column align="center" label="注册时间" prop="change_at" />
      <el-table-column align="center" label="登录时间" prop="login_at" />
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <el-button
            v-if="button.delete"
            type="text"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
          <el-button v-else disabled type="text">删除</el-button>
          <template v-if="button.edit">
            <el-popover v-if="row.user.length > 1" placement="top" width="180">
              <div class="stu">
                <div v-for="(stu, s) in row.user" :key="s" class="item">
                  <span>{{ s + 1 }}.{{ stu.user_name }}</span>
                  <el-button class="del" type="text" @click="handleEdit(stu)">
                    解绑
                  </el-button>
                </div>
              </div>
              <el-button
                class="del"
                style="margin-left: 10px"
                type="text"
                slot="reference"
              >
                解绑
              </el-button>
            </el-popover>
            <el-button
              v-else
              class="del"
              type="text"
              @click="handleEdit(row.user)"
            >
              解绑
            </el-button>
          </template>
          <el-button v-else disabled type="text">解绑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { delParent, getParentList, unbind } from '@/api/tencent'

  export default {
    name: 'ParentList',
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          name: '',
          user_name: '',
          phone: '',
        },
        button: {},
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleEdit(row) {
        this.$baseConfirm('你确定要解绑当前项吗', null, async () => {
          const { msg, code } = await unbind({ pid: row.pid, uid: row.uid })
          this.$baseMessage(msg, code === 200 ? 'success' : 'error')
          await this.fetchData()
        })
      },
      handleDelete(row) {
        this.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg, code } = await delParent({ id: row.id })
          this.$baseMessage(msg, code === 200 ? 'success' : 'error')
          await this.fetchData()
        })
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getParentList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>

<style scoped lang="scss">
  .stu {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      &:not(:first-child) {
        border-top: 1px solid #eee;
      }
    }
  }
</style>
