import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/platform/eval-list',
    method: 'get',
    params,
  })
}

export function uploadFile(data) {
  return request({
    url: '/user/origin',
    method: 'post',
    data,
  })
}

export function doEdit(data, type) {
  console.log(type)
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/platform/eval-edit',
    method: 'post',
    data,
  })
}

//量表添加
export function addEval(data) {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/platform/eval-add',
    method: 'post',
    data,
  })
}

export function doDelete(data) {
  return request({
    url: '/platform/eval-del',
    method: 'post',
    data,
  })
}
