<template>
  <el-dialog
    append-to-body
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="600px"
    @close="close"
  >
    <el-form
      ref="form"
      label-position="right"
      label-width="80px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="章节名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入章节名称" />
      </el-form-item>
      <el-form-item label="时长" prop="duration">
        <el-input
          v-model="form.duration"
          placeholder="请输入章节时长，如：35:23(即35分23秒)"
        />
      </el-form-item>
      <el-form-item label="链接" prop="url">
        <el-input v-model="form.url" placeholder="请输入链接" />
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input v-model="form.sort" placeholder="数字越大越靠前" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { editChapter, getDetail } from '@/api/resource'
  import { isNumber } from '@/utils/validate'
  export default {
    name: 'ArticleEdit',
    data() {
      const validateSort = (rule, value, callback) => {
        if (!isNumber(value)) {
          callback(new Error('请输入正整数'))
        } else {
          callback()
        }
      }
      return {
        title: '',
        showDialog: false,
        form: {},
        rules: {
          name: [
            { required: true, trigger: 'blur', message: '请输入章节名称' },
          ],
          sort: [{ validator: validateSort, trigger: 'blur' }],
          url: [{ required: true, trigger: 'blur', message: '链接不能为空' }],
        },
      }
    },
    methods: {
      initData(param, cid) {
        if (!param) param = {}
        this.form = {
          name: param.name || '',
          sort: param.sort || 0,
          url: param.url || '',
          duration: param.duration || '',
          c_id: cid,
        }
      },
      showEdit(row, cid) {
        if (!row) {
          this.title = '添加'
          this.initData(null, cid)
        } else {
          this.title = '编辑'
          getDetail({ id: row.id, type: 'chapter' }).then((res) => {
            let data = res.data
            this.initData(data, cid)
            this.form.id = data.id
          })
        }
        this.showDialog = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.showDialog = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await editChapter(this.form)
            this.$baseMessage(msg, 'success')

            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss"></style>
