<template>
  <div>
    <div class="form-row">
      <div class="block-title">基本情况</div>
      <el-descriptions border :column="4">
        <el-descriptions-item label="姓名">
          {{ form.name }}
        </el-descriptions-item>
        <el-descriptions-item label="性别">
          {{ form.sex === 1 ? '男' : form.sex === 2 ? '女' : '' }}
        </el-descriptions-item>
        <el-descriptions-item label="入学年度">
          {{ form.enrolment_year }}
        </el-descriptions-item>
        <el-descriptions-item label="出生年月">
          {{ form.birthday }}
        </el-descriptions-item>
        <el-descriptions-item label="民族">
          {{ form.nation }}
        </el-descriptions-item>
        <el-descriptions-item label="籍贯">
          {{ form.native }}
        </el-descriptions-item>
        <el-descriptions-item label="联系电话" :span="2">
          {{ form.mobile }}
        </el-descriptions-item>
        <el-descriptions-item label="家庭住址" :span="4">
          {{ form.addr }}
        </el-descriptions-item>
        <el-descriptions-item label="兴趣特长" :span="4">
          {{ form.hobby }}
        </el-descriptions-item>
        <el-descriptions-item label="过往病史" :span="4">
          {{ form.is_mhistory === 0 ? '无' : form.mhistory }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <template v-if="!!form.is_extend">
      <div class="form-row">
        <div class="block-title">家庭情况</div>
        <el-table
          border
          :class="{ minh: !form.family_member.length }"
          :data="form.family_member"
        >
          <el-table-column label="称谓" prop="title" />
          <el-table-column label="姓名" prop="name" />
          <el-table-column label="年龄" prop="age" />
          <el-table-column label="学历" prop="seniority" />
          <el-table-column label="职业" prop="job" />
          <el-table-column label="你对他（她）的喜欢程度" prop="level">
            <template #default="{ row }">
              {{ lovely[row.level] }}
            </template>
          </el-table-column>
        </el-table>
        <el-descriptions :column="1">
          <el-descriptions-item label="家庭类型">
            {{ familyList[form.family_type] }}
          </el-descriptions-item>
          <el-descriptions-item label="留守经历">
            {{ form.family_rusu === 1 ? '有' : '无' }}
          </el-descriptions-item>
          <el-descriptions-item label="排行情况">
            {{
              form.family_seniority === 0
                ? '独生子女'
                : '多子女，排行：' + paih[form.seniority]
            }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="form-row">
        <div class="block-title">学习经历</div>
        <el-table
          border
          :class="{ minh: !form.career_scool.length }"
          :data="form.career_scool"
        >
          <el-table-column label="起止日期" prop="time">
            <template #default="{ row }">
              {{ row.time[0] + ' ~ ' + row.time[1] }}
            </template>
          </el-table-column>
          <el-table-column label="何校学习" prop="scool" />
          <el-table-column label="职务" prop="duty" />
          <el-table-column label="对当时所处集体的喜欢程度" prop="level">
            <template #default="{ row }">
              {{ lovely[row.level] }}
            </template>
          </el-table-column>
        </el-table>
        <el-table
          border
          :class="{ minh: !form.career_succ.length }"
          :data="form.career_succ"
        >
          <el-table-column label="成功经历" type="index" width="80px" />
          <el-table-column label="时间" prop="time" />
          <el-table-column label="事件" prop="remark" />
        </el-table>
        <el-table
          border
          :class="{ minh: !form.career_fail.length }"
          :data="form.career_fail"
        >
          <el-table-column label="失败经历" type="index" width="80px" />
          <el-table-column label="时间" prop="time" />
          <el-table-column label="事件" prop="remark" />
        </el-table>
      </div>
      <div class="form-row">
        <div class="block-title">自我评价</div>
        <el-descriptions :column="1">
          <template v-for="(my, m) in form.evaluate">
            <el-descriptions-item :key="m" :label="my.name">
              <template v-for="(myopt, _m) in my.options">
                <span v-if="myopt.id === form.evaluate_result[m]" :key="_m">
                  {{ myopt.remark }}
                </span>
              </template>
            </el-descriptions-item>
          </template>
        </el-descriptions>
      </div>
      <div class="form-row">
        <div class="block-title">困惑烦恼</div>
        <div>{{ form.confused || '暂无' }}</div>
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'UserAchives',
    props: {
      cdata: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        form: {},
        familyList: [
          '小家庭（与父母兄弟姐妹一起居住）',
          '大家庭（与父母兄弟姐妹祖父母或外祖父母一起居住）',
          '其它类型',
        ],
        lovely: ['喜欢', '较喜欢', '一般'],
        paih: [1, 2, 3, '其他'],
      }
    },
    watch: {
      cdata: {
        handler(newData) {
          this.form = newData
        },
        immediate: true,
        deep: true,
      },
    },
    created() {},
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .el-table--small {
    margin-bottom: 20px;
  }
  .minh {
    height: 120px;
  }
  .form-row {
    background: #fff;
    margin: 20px 40px 40px 40px;
    .block-title {
      font-weight: bold;
      padding-left: 10px;
      border-left: 6px solid #26cdb7;
      margin-bottom: 10px;
      border-radius: 3px;
      font-size: 18px;
    }
    &.edit {
      padding: 20px 40px;
      box-shadow: 0 0 10px 5px rgba(194, 194, 194, 0.2);
      border-radius: 10px;
      .block-title {
        margin-bottom: 30px;
      }
    }
    .f-item {
      display: flex;
      flex-wrap: wrap;
      .width-block {
        width: 100%;
      }
      .family {
        .item {
          display: flex;
          flex-wrap: wrap;
          &:not(:first-child) {
            margin-top: 10px;
          }
          .el-input {
            width: 120px;
          }
          .btn-box {
            width: 80px;
            .btn {
              font-size: 20px;
              margin: 0 5px;
              color: $base-color-blue;
            }
          }
        }
      }
    }
  }
  @media print {
    @page {
      size: A4;
      margin: 10mm 0;
    }
    table,
    figure {
      page-break-inside: avoid;
    }
  }
</style>
