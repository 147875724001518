<template>
  <div class="set-mobile">
    <div class="block">
      <div class="block-top">
        <span class="name">首页图标</span>
        <el-button
          :disabled="!checkAccess({ permission: ['editNav'] })"
          type="text"
          @click="edit(null, true)"
        >
          点击添加
        </el-button>
        <el-popover
          v-model="showNum"
          v-permissions="{ permission: ['editNav'] }"
          placement="bottom"
          style="margin-left: 15px"
          trigger="manual"
          width="200"
        >
          <div class="set-num">
            <div class="title">调整排版</div>
            <el-input-number v-model="num" :max="6" :min="1" size="mini" />
            <div class="btn">
              <el-button type="text" @click="showNums">取消</el-button>
              <el-button size="mini" type="primary" @click="saveNum">
                保存
              </el-button>
            </div>
          </div>
          <el-button type="text" slot="reference" @click="showNums">
            调整排版
          </el-button>
        </el-popover>
        <div class="flex1">
          <el-popover
            placement="left"
            style="margin-left: 15px"
            trigger="hover"
            width="375"
          >
            <div class="page-m index">
              <div class="title">首页</div>
              <div class="bg banner"></div>
              <div class="iconbar">
                <div
                  v-for="(nav, index) in iconList"
                  :key="index"
                  class="item"
                  :style="iconWidth"
                >
                  <el-image class="img" :src="nav.cover" />
                  <div>{{ nav.title }}</div>
                </div>
              </div>
              <div class="icon-page">
                <div
                  v-for="icon in iconPage"
                  :key="icon"
                  class="page-item"
                ></div>
              </div>
              <div class="bg other"></div>
              <div class="bottom"></div>
            </div>
            <span slot="reference">鼠标悬停查看效果</span>
          </el-popover>
        </div>
      </div>
      <el-table border :data="iconList">
        <el-table-column align="center" label="图标" prop="cover">
          <template #default="{ row }">
            <el-image fit="contain" :src="row.cover" />
          </template>
        </el-table-column>
        <el-table-column align="center" label="标题" prop="title" />
        <el-table-column align="center" label="更新时间" prop="update_at" />
        <el-table-column align="center" label="操作" width="100">
          <template #default="{ row }">
            <el-button
              :disabled="!checkAccess({ permission: ['editNav'] })"
              type="text"
              @click="edit(row, true)"
            >
              编辑
            </el-button>
            <el-button
              :disabled="!checkAccess({ permission: ['delNav'] })"
              type="text"
              @click="del(row, 'index')"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="false" class="block">
      <div class="block-top">
        <span class="name">个人中心菜单栏</span>
        <el-button
          :disabled="!checkAccess({ permission: ['editNav'] })"
          type="text"
          @click="edit(null, false, 1)"
        >
          点击添加
        </el-button>
        <div class="flex1">
          <el-popover
            placement="left"
            style="margin-left: 15px"
            trigger="hover"
            width="375"
          >
            <div class="page-m ucenter">
              <div class="title">个人中心</div>
              <div
                class="bg banner"
                style="height: 110px; border-radius: 5px"
              ></div>
              <div class="iconbar"></div>
              <div class="cust-menu">
                <div v-for="(menu, _i) in perList" :key="_i" class="c-item">
                  <template v-if="menu.show === 10">
                    <div class="name">{{ menu.title }}</div>
                    <div class="desc"></div>
                    <div class="arrow">
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </template>
                </div>
                <template v-if="false">
                  <template v-for="(child, index2) in menu.children">
                    <div v-if="child.show === 10" :key="index2" class="c-item">
                      <div class="name">{{ child.title }}</div>
                      <div class="desc"></div>
                      <div class="arrow">
                        <i class="el-icon-arrow-right"></i>
                      </div>
                    </div>
                  </template>
                </template>
              </div>
            </div>
            <span slot="reference">鼠标悬停查看效果</span>
          </el-popover>
        </div>
      </div>
      <el-table
        ref="refTable"
        border
        :data="perList"
        default-expand-all
        row-key="id"
        :tree-props="{ children: 'children' }"
        @row-click="clickRow"
      >
        <el-table-column label="菜单栏标题" prop="title" />
        <el-table-column align="center" label="显示" prop="show">
          <template #default="{ row }">
            <span @click.stop="">
              <el-switch
                :active-value="10"
                :inactive-value="20"
                :value="row.show * 1"
                @change="setStatus(row)"
              />
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="更新时间" prop="update_at" />
        <el-table-column align="right" label="操作" width="180">
          <template #default="{ row }">
            <template v-if="row.rank == 1">
              <el-button
                :disabled="!checkAccess({ permission: ['editNav'] })"
                type="text"
                @click.stop="edit(row, false, 2, 'add')"
              >
                新增下级
              </el-button>
            </template>
            <el-button
              v-if="checkAccess({ permission: ['editNav'] })"
              type="text"
              @click.stop="edit(row, false)"
            >
              编辑
            </el-button>
            <el-button
              v-else
              disabled
              title="暂无权限，如有需要，请联系管理员"
              type="text"
            >
              编辑
            </el-button>
            <el-button
              v-if="checkAccess({ permission: ['delNav'] })"
              type="text"
              @click.stop="del(row, 'personal')"
            >
              删除
            </el-button>
            <el-button
              v-else
              disabled
              title="暂无权限，如有需要，请联系管理员"
              type="text"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <m-index ref="m-index" :menu="menuList" @refresh="refresh" />
    <m-ucenter ref="m-ucenter" :menu="menuList" @refresh="refresh" />
  </div>
</template>

<script>
  import MIndex from '@/views/setting/components/m-index'
  import MUcenter from '@/views/setting/components/m-ucenter'
  import {
    editIdxIcon,
    editPerMenu,
    eidtIconNum,
    getIdxIcon,
    getMyMenu,
    getIndexInfo,
  } from '@/api/idx'
  export default {
    name: 'Mobile',
    components: { MIndex, MUcenter },
    data() {
      return {
        iconList: [], //首页icon
        perList: [], //个人中心菜单栏
        num: 4,
        oldNum: 4,
        showNum: false,
        menuList: [],
      }
    },
    computed: {
      iconWidth() {
        return {
          width: (100 / this.num).toFixed(4) + '%',
        }
      },
      iconPage() {
        return Math.ceil(this.iconList.length / this.num)
      },
    },
    created() {
      this.getNum()
      this.getIcon()
      this.getPMenu()
      this.getMenu()
    },
    methods: {
      getMenu() {
        getIndexInfo().then((res) => {
          let d = res
          this.menuList = d.menu
        })
      },
      refresh(val) {
        if (val === 'index') this.getIcon()
        else this.getPMenu()
      },
      getIcon() {
        //获取首页icon
        getIdxIcon().then((res) => {
          this.iconList = res.list
        })
      },
      getPMenu() {
        //获取个人中心菜单
        getMyMenu().then((res) => {
          let d = res.list
          this.perList = d.map((v, i) => {
            let flag = 0
            v.children.map((v2, i2) => {
              if (v2.show === 10) {
                flag++
              }
            })
            return Object.assign(v, { hasChild: flag })
          })
        })
      },
      async getNum() {
        //获取首页icon排版个数
        const { list } = await eidtIconNum({ style: 'get' })
        let n = list.sort * 1
        this.num = n
        this.oldNum = n
      },
      showNums() {
        this.showNum = !this.showNum
        this.num = this.oldNum
      },
      async saveNum() {
        //设置首页icon排版
        this.showNum = !this.showNum
        await eidtIconNum({ sort: this.num, style: 'sort' }).then((res) => {
          if (res.code === 200) {
            this.oldNum = this.num
            this.$baseMessage(res.msg, 'success')
          }
        })
      },
      clickRow(row, index, e) {
        //表格树结构点击展开
        this.$refs.refTable.toggleRowExpansion(row)
      },
      edit(row, isIndex, rank, add) {
        if (isIndex) {
          this.$refs['m-index'].showEdit(row)
        } else {
          this.$refs['m-ucenter'].showEdit(row, rank, add)
        }
      },
      del(row, platform) {
        let txt = '你确定要删除当前【' + row.title + '】项吗'
        if (row.rank == 1)
          txt = '确定要删除当前菜单【' + row.title + '】及其子菜单吗'
        this.$baseConfirm(txt, null, async () => {
          if (platform === 'index') {
            editIdxIcon({ id: row.id, style: 'delete' }).then((res) => {
              if (res.code === 200) {
                this.getIcon()
                this.$baseMessage(res.msg, 'success')
              }
            })
          } else if (platform === 'personal') {
            editPerMenu({ id: row.id, style: 'delete' }).then((res) => {
              if (res.code === 200) {
                this.getPMenu()
                this.$baseMessage(res.msg, 'success')
              }
            })
          }
        })
      },
      setStatus(row) {
        //个人中心菜单设置显示隐藏
        let txt = row.show == 10 ? '隐藏' : '显示'
        let txt2 = row.rank == 1 ? '及其子菜单' : ''
        this.$baseConfirm(
          '确定' + txt + '当前菜单【' + row.title + '】' + txt2 + '吗',
          null,
          async () => {
            editPerMenu({ id: row.id, style: 'show' }).then((res) => {
              if (res.code === 200) {
                this.getPMenu()
                this.$baseMessage(res.msg, 'success')
                this.$forceUpdate()
              }
            })
          }
        )
      },
    },
  }
</script>

<style lang="scss">
  .page-m {
    height: 600px;
    border: 1px solid #eee;
    overflow: hidden;
    &.index {
      padding: 0 15px;
      background-image: linear-gradient(
        180deg,
        rgba(41, 210, 187, 0.04) 0.001%,
        rgba(209, 234, 252, 0.31) 30.382%,
        #f4f8fb 86.28%
      );
      .iconbar {
        height: 100px;
        overflow: hidden;
      }
      .icon-page {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        .page-item {
          width: 9px;
          height: 4px;
          border-radius: 10px;
          background: #ddd;
          margin: 0 4px;
          &:first-child {
            width: 18px;
            background: #26cdb7;
          }
        }
      }
    }
    &.ucenter {
      padding: 0 10px;
      background: #f8faf9;
      overflow-y: scroll;
      .title {
        background: #fff;
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: 10px;
      }
      .iconbar {
        background: #fff;
        height: 94px;
        border-radius: 5px;
        margin-top: 15px;
      }
      .cust-menu {
        background: #fff;
        padding: 0 15px;
        margin-top: 15px;
        border-radius: 5px;

        .c-item {
          display: flex;
          align-items: center;
          padding: 15px 0;

          &:not(:first-child) {
            border-top: 1px solid #eee;
          }

          .name {
            font-size: 14px;
          }

          .desc {
            flex: 1;
            font-size: 12px;
            color: #999;
            margin: 0 5px;
            text-align: right;
          }
        }
      }
    }
    .title {
      line-height: 44px;
      text-align: center;
    }
    .bg {
      border-radius: 8px;
      background: #eee;
      &.banner {
        height: 130px;
      }
      &.other {
        height: 300px;
      }
    }
    .iconbar {
      display: flex;
      flex-wrap: wrap;
      margin: 10px 0;
      .item {
        text-align: center;
        font-size: 12px;
        margin: 10px 0;
        .img {
          width: 60px;
          height: 60px;
          margin-bottom: 6px;
        }
      }
    }
  }
  .set-num {
    text-align: center;
    .title {
      font-weight: bold;
      margin-bottom: 15px;
    }
    .btn {
      margin: 15px;
    }
  }
  .menu {
    margin: 15px 0;
    .item {
      display: flex;
      align-items: center;
      &:not(:first-child) {
        margin-top: 10px;
      }
      label {
        width: 50px;
        text-align: right;
        padding-right: 10px;
      }
      .sub-item {
        .active {
          background: #26cdb7;
          color: #fff;
          border-color: #26cdb7;
        }
      }
    }
  }
  .choose-img {
    width: 60px;
    height: 60px;
    text-align: center;
    border: 1px dashed #d9d9d9;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    line-height: 60px;
    background: #fff;
    :deep(.el-image) {
      width: 100%;
      height: 100%;
    }
  }
  .img-tip {
    font-size: 12px;
    color: #999;
    margin-left: 10px;
  }
</style>
