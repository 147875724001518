<template>
  <el-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24">
    <el-card shadow="never">
      <div class="wechat-box">
        <div class="img">
          <img src="" />
        </div>
        <div class="right">
          <p class="f-title">浩途科技</p>
          <p class="f-desc">浩途科技浩途科技</p>
        </div>
      </div>
      <div class="wechat-box">
        <div class="img">
          <img src="" />
        </div>
        <div class="right">
          <p class="f-title">浩途科技</p>
          <p class="f-desc">浩途科技浩途科技</p>
        </div>
      </div>
      <div class="wechat-box">
        <div class="right">
          <p class="f-title">浩途科技</p>
          <p class="f-desc">浩途科技浩途科技</p>
        </div>
      </div>
      <div class="wechat-box">
        <div class="right">
          <p class="f-title">浩途科技</p>
          <p class="f-desc">浩途科技浩途科技</p>
        </div>
      </div>
    </el-card>
    <el-card shadow="never">
      <template #header>
        <span>待办事项</span>
        <router-link class="see-more" to="toDo">查看更多&gt;&gt;</router-link>
      </template>
      <div class="todo-list">
        <div class="item">
          <div class="time">3月22日 17：47</div>
          <div class="title">这是一个标题</div>
        </div>
        <div class="item">
          <div class="time">3月22日 17：47</div>
          <div class="title">这是一个标题</div>
        </div>
      </div>
    </el-card>
  </el-col>
</template>

<script>
  export default {
    name: 'Right',
    data() {
      return {}
    },
    created() {},
    mounted() {},
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .wechat-box {
    display: flex;
    &:not(:first-child) {
      margin-top: 15px;
    }
    .img {
      width: 80px;
      height: 80px;
      margin-right: 15px;
      background: #f9f9f9;
      img {
        object-fit: fill;
      }
    }
    .right {
      flex: 1;
      .f-title {
        font-weight: bold;
      }
      .f-desc {
        font-size: 12px;
        color: #9a9a9a;
      }
    }
  }
  .see-more {
    float: right;
    padding: 3px 0;
    font-family: serif;
    font-size: 12px;
    color: #999;
  }
  .todo-list {
    .item {
      display: flex;
      align-items: center;
      line-height: 25px;
      .time {
        margin-right: 10px;
        color: #999;
      }
      .title {
        flex: 1;
        width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
</style>
