var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.dialogFormVisible,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "80px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "量表标题", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入量表标题" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择量表", prop: "pid" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择量表",
                      },
                      model: {
                        value: _vm.form.pid,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "pid", $$v)
                        },
                        expression: "form.pid",
                      },
                    },
                    _vm._l(_vm.evalList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "副标题", prop: "subhead" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入副标题" },
                    model: {
                      value: _vm.form.subhead,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "subhead", $$v)
                      },
                      expression: "form.subhead",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分类", prop: "cate" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择分类",
                      },
                      model: {
                        value: _vm.form.cate,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "cate", $$v)
                        },
                        expression: "form.cate",
                      },
                    },
                    _vm._l(_vm.cateList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                  _c("el-button", {
                    staticStyle: {
                      "font-size": "15px",
                      "padding-left": "10px",
                    },
                    attrs: { icon: "el-icon-refresh", type: "text" },
                    on: { click: _vm.refreshCate },
                  }),
                  _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.addCate } },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "封面", prop: "image_file" } },
                [
                  _c(
                    "div",
                    { staticClass: "img-uploader" },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            accept: "image/png, image/jpeg",
                            action: _vm.UploadUrl(),
                            "auto-upload": false,
                            "on-change": _vm.fileChange,
                            "on-remove": _vm.handleRemove,
                            "show-file-list": false,
                          },
                        },
                        [
                          _vm.form.image_file
                            ? _c("img", {
                                staticClass: "img",
                                attrs: { src: _vm.form.image_file },
                              })
                            : _c("i", { staticClass: "el-icon-plus" }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _c("div", [
                            _vm._v(" 只能上传 "),
                            _c("span", [_vm._v(".jpg、.png")]),
                            _vm._v(" 文件，且 "),
                            _c("span", [_vm._v("不超过1M")]),
                          ]),
                          _c("div", [_vm._v("(图片尺寸1:1)")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入数字，越大越靠前" },
                    model: {
                      value: _vm.form.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort", $$v)
                      },
                      expression: "form.sort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "详情页样式", prop: "style" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-text": "默认样式",
                      "active-value": 10,
                      "inactive-text": "自定义样式",
                      "inactive-value": 20,
                    },
                    model: {
                      value: _vm.form.style,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "style", $$v)
                      },
                      expression: "form.style",
                    },
                  }),
                  _vm.form.style === 20
                    ? _c("editor", {
                        attrs: { "is-clear": _vm.isClear },
                        on: { change: _vm.editorChange },
                        model: {
                          value: _vm.form.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "content", $$v)
                          },
                          expression: "form.content",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-text": "上架",
                      "active-value": 10,
                      "inactive-text": "下架",
                      "inactive-value": 20,
                    },
                    model: {
                      value: _vm.form.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "status", $$v)
                      },
                      expression: "form.status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("cate", { ref: "cate" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }