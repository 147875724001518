<template>
  <div class="user-list-container">
    <el-collapse v-model="list" @change="handleChange">
      <el-collapse-item
        v-for="item in list"
        :key="item.id"
        :name="item.id"
        :title="item.title"
      >
        <div v-if="item.type == 1">
          {{ item.cotnent }}
        </div>
        <div v-else>
          <a :href="item.content" target="_blank">点击下载</a>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
  import { getNoticeList, readNotice } from '@/api/platformChannelManagement'
  import { mapGetters } from 'vuex'
  export default {
    name: 'NoticeList',
    data() {
      return {
        list: [],
        studioList: [],
        treeData: [], //获取组织结构
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          sid: '',
          name: '',
          phone: '',
          orgid: '',
          sex: '',
          account: '',
          loginTime: [],
        },
        button: {},
        docUrl: '',
        defaultProps: {
          children: 'children',
          label: 'name',
          value: 'id',
          checkStrictly: true,
        },
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    created() {
      this.fetchData()
    },
    mounted() {},
    methods: {
      handleChange(val) {
        console.log(val)
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async getOriginList(id) {
        await readNotice({ id: id })
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getNoticeList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>
