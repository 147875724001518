<template>
  <div class="eval-list-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="8">
        <el-button
          v-if="checkAccess({ permission: ['addEval'] })"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit(null)"
        >
          添加
        </el-button>
        <el-button
          v-else
          disabled
          icon="el-icon-plus"
          title="暂无权限，如有需要，请联系管理员"
          type="primary"
        >
          添加
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="16">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item v-if="isSid === 'all'">
            <el-select
              v-model="queryForm.sid"
              clearable
              filterable
              placeholder="请选择渠道"
            >
              <el-option
                v-for="item in studioList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入量表名"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column align="center" label="标题" prop="title" />
      <el-table-column
        align="center"
        label="简介"
        prop="intro"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="上架" prop="status">
        <template #default="{ row }">
          <el-switch
            :active-value="10"
            :inactive-value="20"
            :value="row.status * 1"
            @change="setStatus(row)"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" label="最后编辑时间" prop="change_at" />
      <el-table-column align="center" label="添加人" prop="add_name" />
      <el-table-column align="center" label="备注" prop="remark" />
      <el-table-column align="center" label="操作" width="160">
        <template #default="{ row }">
          <el-button
            :disabled="!!row.is_default"
            :title="row.is_default ? '内置量表' : ''"
            type="text"
            @click="handleEdit(row)"
          >
            编辑
          </el-button>
          <el-button
            :disabled="!!row.is_default"
            :title="row.is_default ? '内置量表' : ''"
            type="text"
            @click="handleOption(row, 'subject')"
          >
            题目
          </el-button>
          <el-button
            :disabled="!!row.is_default"
            :title="row.is_default ? '内置量表' : ''"
            type="text"
            @click="handleOption(row, 'score')"
          >
            计分
          </el-button>
          <el-button
            class="del"
            :disabled="!!row.is_default"
            :title="row.is_default ? '内置量表' : ''"
            type="text"
            @click="handleDel(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
    <subject ref="subject" @fetch-data="fetchData" />
    <score ref="score" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { getStudioList } from '@/api/platformChannelManagement'
  import { getEvalList, setEvalStatus } from '@/api/eval'
  import Edit from './components/addEval'
  import Subject from './components/subject/subList'
  import Score from './components/score/scoreList'

  export default {
    name: 'EvalList',
    components: { Edit, Subject, Score },
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        studioList: [],
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          sid: '',
          name: '',
        },
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    created() {
      this.fetchData()
      if (this.isSid === 'all') this.getStudio()
    },
    methods: {
      handleEdit(row) {
        if (row) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit('')
        }
      },
      handleOption(row, type) {
        this.$refs[type].showDrawer(row.id)
      },
      setStatus(row) {
        let txt = row.status === 10 ? '下架' : '上架'
        this.$baseConfirm('确定' + txt + '当前量表吗', null, async () => {
          await setEvalStatus({
            id: row.id,
          }).then((res) => {
            this.fetchData()
          })
        })
      },
      handleDel(row) {
        this.$baseConfirm('确定删除当前量表吗', null, async () => {
          await setEvalStatus({
            id: row.id,
            status: 30,
          }).then((res) => {
            this.fetchData()
          })
        })
      },
      async getStudio() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount } = await getEvalList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.listLoading = false
      },
    },
  }
</script>

<style lang="scss">
  @media screen and (min-width: 1000px) {
    .el-tooltip__popper {
      max-width: 40% !important;
    }
  }
</style>
