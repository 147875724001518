<template>
  <div class="team-list-container">
    <vab-query-form>
      <vab-query-form-left-panel v-if="button.add" :span="8">
        <el-button
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit('', '/crisis/addCrisis')"
        >
          添加
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel
        v-if="button.search"
        :span="button.add ? 16 : 24"
      >
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item v-if="isSid === 'all'">
            <el-select
              v-model="queryForm.sid"
              clearable
              filterable
              placeholder="选择渠道"
            >
              <el-option
                v-for="item in studioList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入用户名"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.level"
              clearable
              filterable
              placeholder="干预等级"
            >
              <el-option
                v-for="item in levelList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.quest"
              clearable
              filterable
              placeholder="干预问题"
            >
              <el-option
                v-for="item in questList"
                :key="item.id"
                :label="item.quest"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.type"
              clearable
              filterable
              placeholder="干预程度"
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table v-loading="listLoading" :data="list">
      <el-table-column
        align="center"
        label="姓名"
        prop="name"
        show-overflow-tooltip
        width="100"
      />
      <el-table-column align="center" label="组织结构" prop="origin" />
      <el-table-column
        align="center"
        label="干预等级"
        prop="level"
        show-overflow-tooltip
        width="100"
      />
      <el-table-column align="center" label="干预问题" prop="quest" />
      <el-table-column
        align="center"
        label="干预时长"
        prop="use_at"
        width="100"
      >
        <template #default="{ row }">
          {{ row.use_at + '小时' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="干预程度"
        prop="type"
        width="100"
      />
      <el-table-column align="center" label="记录时间" prop="created_at" />
      <el-table-column
        align="center"
        label="记录人"
        prop="record_name"
        width="100"
      />
      <el-table-column align="center" label="操作" width="85">
        <template #default="{ row }">
          <el-button
            v-if="button.delete"
            type="text"
            @click="doOptions(row, '确定删除该记录吗？', 'del')"
          >
            删除
          </el-button>
          <el-button v-else disabled="disabled" type="text">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getList, getParams, setStatus } from '@/api/crisis'
  import { getStudioList } from '@/api/platformChannelManagement'
  import { mapGetters } from 'vuex'

  export default {
    name: 'CrisisList',
    data() {
      return {
        list: [],
        studioList: [],
        questList: [], //干预问题
        levelList: [], //干预等级
        typeList: [], //干预程度
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          name: '',
          sid: '',
          level: '',
          quest: '',
          type: '',
        },
        button: {},
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    created() {
      this.getStudio()
      this.getQuesList('quest')
      this.getQuesList('level')
      this.getQuesList('type')
      this.fetchData()
    },
    methods: {
      async getStudio() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      getQuesList(type) {
        getParams({ type: type }).then((res) => {
          this[type + 'List'] = res.data
        })
      },
      selectDate(val) {
        console.log(val)
        this.queryForm.time = val
      },
      handleEdit(row, url, type) {
        if (row.id) {
          if (type === 'record') {
            let routeData = this.routeTo({ id: row.id }, url)
            window.open(routeData.href, '_blank')
          } else {
            this.$router.push({
              path: url,
              query: { id: row.id },
            })
          }
        } else {
          this.$router.push(url)
        }
      },
      routeTo(id, url) {
        let routeData = this.$router.resolve({
          path: url,
          query: id,
        })
        return routeData
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
      doOptions(row, msg, type) {
        this.$baseConfirm(msg, null, () => {
          setStatus({
            id: row.id,
            type: type,
          }).then(async (res) => {
            this.$baseMessage(res.msg, 'success')
            await this.fetchData()
          })
        })
      },
    },
  }
</script>
<style lang="scss">
  .team-list-container {
  }
</style>
