var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        "close-on-click-modal": false,
        title: "编辑计分规则",
        visible: _vm.dialogFormVisible,
        width: "900px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 15 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    "label-width": "120px",
                    model: _vm.form,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "因子分", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "20",
                          placeholder: "请输入因子分名称",
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "包含题目",
                        prop: "ques",
                        rules: [
                          {
                            required: true,
                            message: "请输入题号",
                            trigger: "blur",
                          },
                          {
                            pattern: /^(\d+,?)+$/,
                            message: "只能输入数字和英文逗号",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "多个题目请用英文逗号隔开" },
                        model: {
                          value: _vm.form.ques,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ques", $$v)
                          },
                          expression: "form.ques",
                        },
                      }),
                      _c("div", { staticClass: "txt-tips" }, [
                        _vm._v(" 请输入题目序号，多个题目中间用英文逗号隔开 "),
                      ]),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "展示" } }, [
                    _c(
                      "div",
                      [
                        _vm._v(" 个人报告 "),
                        _c("el-switch", {
                          attrs: { "active-value": 10, "inactive-value": 20 },
                          model: {
                            value: _vm.form.show_per,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "show_per", $$v)
                            },
                            expression: "form.show_per",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _vm._v(" 团体报告 "),
                        _c("el-switch", {
                          attrs: { "active-value": 10, "inactive-value": 20 },
                          model: {
                            value: _vm.form.show_team,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "show_team", $$v)
                            },
                            expression: "form.show_team",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "描述", prop: "descr" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.form.descr,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "descr", $$v)
                          },
                          expression: "form.descr",
                        },
                      }),
                      _c("div", { staticClass: "txt-tips" }, [
                        _vm._v(
                          " 因子分描述展示在个人报告和团测报告中，用于解释因子分 "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "计分规则", prop: "rules" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择计分规则" },
                          model: {
                            value: _vm.form.rules,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "rules", $$v)
                            },
                            expression: "form.rules",
                          },
                        },
                        _vm._l(_vm.stype, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "得分分级", prop: "ids" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleEdit(null, "add")
                            },
                          },
                        },
                        [_vm._v(" 添加分级 ")]
                      ),
                      _c(
                        "el-table",
                        {
                          ref: "ruleTable",
                          staticStyle: { "margin-top": "10px" },
                          attrs: { border: "", data: _vm.list },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "得分范围",
                              prop: "range",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "等级",
                              prop: "lv",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "等级描述",
                              prop: "descr",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { align: "center", label: "操作" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleEdit(row, "edit")
                                          },
                                        },
                                      },
                                      [_vm._v(" 编辑 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { color: "#da0c0c" },
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleEdit(row, "del")
                                          },
                                        },
                                      },
                                      [_vm._v(" 删除 ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        { attrs: { type: "primary" }, on: { click: _vm.save } },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("lv-edit", { ref: "lv", on: { "fetch-data": _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }