<template>
  <el-dialog
    append-to-body
    :close-on-click-modal="false"
    title="编辑量表"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="标题" prop="title">
        <el-input
          v-model="form.title"
          maxlength="20"
          placeholder="标题"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="简介" prop="intro">
        <el-input
          v-model="form.intro"
          placeholder="简介"
          rows="4"
          type="textarea"
        />
      </el-form-item>
      <el-form-item label="须知" prop="notice">
        <el-input
          v-model="form.notice"
          placeholder="须知"
          rows="4"
          type="textarea"
        />
      </el-form-item>
      <el-form-item label="上架" prop="status">
        <el-switch
          v-model="form.status"
          :active-value="10"
          :inactive-value="20"
        />
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          maxlength="100"
          placeholder="备注"
          rows="4"
          show-word-limit
          type="textarea"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { evalEdit } from '@/api/eval'

  export default {
    name: 'AddEval',
    data() {
      return {
        form: {
          title: '', //量表名称
          intro: '', //量表简介
          notice: '', //量表须知
          remark: '', //量表备注
          status: 20, //量表状态；10上架 20下架
        },
        rules: {
          title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        },
        dialogFormVisible: false,
      }
    },
    methods: {
      showEdit(row) {
        if (!row) {
          this.form.type = 'add'
        } else {
          this.form = JSON.parse(JSON.stringify(row))
          this.form.type = 'edit'
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        console.log(this.form)
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await evalEdit(this.form)
            this.$baseMessage(msg, 'success')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style scoped></style>
