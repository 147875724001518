var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "print", staticClass: "test-container" },
    [
      _c(
        "div",
        { staticClass: "print no-print", on: { click: _vm.print } },
        [
          _c("vab-icon", { attrs: { icon: "printer-line" } }),
          _vm._v(" 导出PDF/打印 "),
        ],
        1
      ),
      _vm._m(0),
      _c("div", { staticClass: "title" }, [
        _vm._v("大学生人格问卷（University Personality Inventory）"),
      ]),
      _c(
        "el-table",
        {
          attrs: {
            border: "",
            data: _vm.list,
            "header-cell-style": {
              color: "#333",
              "text-align": "center",
            },
            size: "mini",
          },
        },
        _vm._l(_vm.thead, function (item, index) {
          return _c("el-table-column", {
            key: index,
            attrs: { label: item.title, "min-width": "60" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_c("span", [_vm._v(_vm._s(row[item.key]))])]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _vm._m(1),
      _vm._m(2),
      _c(
        "div",
        { staticClass: "subject" },
        [
          _vm._l(4, function (i1) {
            return _c("div", { key: i1, staticClass: "item" }, [
              _c("div", [
                _vm._v(" " + _vm._s(i1) + ".身体健康状态良好。 "),
                _c("label", [_vm._v("✔")]),
              ]),
            ])
          }),
          _vm._m(3),
          _vm._l(4, function (i2) {
            return _c("div", { key: i2, staticClass: "item" }, [
              _c("div", [
                _vm._v(" " + _vm._s(i2 + 4) + ".身体健康状态良好。 "),
                _c("label"),
              ]),
            ])
          }),
          _vm._m(4),
          _vm._l(52, function (i3) {
            return _c("div", { key: i3, staticClass: "item" }, [
              _c("div", [
                _vm._v(" " + _vm._s(i3 + 8) + ".身体健康状态良好。 "),
                _c("label"),
              ]),
            ])
          }),
          _vm._m(5),
          _vm._l(4, function (i4) {
            return _c("div", { key: i4, staticClass: "item other" }, [
              _c("div", [
                _vm._v(
                  " " + _vm._s(i4 + 60) + ".至今，你感到自身健康方面有问题吗？ "
                ),
                _c("label"),
                _vm._v(" 无/ "),
                _c("label"),
                _vm._v(" 有，请注明： "),
              ]),
              _c("div", { staticClass: "txt" }, [_vm._v("发了打发似的")]),
            ])
          }),
          _vm._m(6),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tips" }, [
      _c("div", [
        _c("span", [
          _vm._v(
            " 工作日可接受咨询的时间：（上午9：00-12：00，下午14：00-17：00） "
          ),
        ]),
      ]),
      _c("div", [
        _c("span", { staticStyle: { color: "red" } }, [
          _vm._v("(请在此处填写空闲时间)"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "city" }, [
      _vm._v(" 目前：返校 "),
      _c("span", [_vm._v("✔")]),
      _vm._v(" ；居家 "),
      _c("span"),
      _vm._v(" ；城市： "),
      _c("label", [_vm._v("长沙")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "zdy" }, [
      _c("label", [_vm._v("指导语：")]),
      _vm._v(
        " 以下问题是为了解你的身心健康状况并为了增进你的身心健康而设计的调查。请你按题号的顺序阅读， "
      ),
      _c("label", [_vm._v("在最近一年中你常常感觉到或体验到的")]),
      _vm._v(" 项目上做“ "),
      _c("label", [_vm._v("✔")]),
      _vm._v(
        " ”选择。为了协助你顺利完成大学学业，身心健康地迎接新生活，请你真实选择。 "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item tj" }, [
      _c("div", [_vm._v(" 1-4题打“✔”的数量： "), _c("span", [_vm._v("（）")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item tj" }, [
      _c("div", [_vm._v(" 5-8题打“✔”的数量： "), _c("span", [_vm._v("（）")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item tj" }, [
      _c("div", [
        _vm._v(" 9-60题打“✔”的数量： "),
        _c("span", [_vm._v("（）")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item other" }, [
      _c("div", [
        _vm._v(" 65.是否愿意接受团体咨询（6人以内） "),
        _c("label"),
        _vm._v(" 是/ "),
        _c("label"),
        _vm._v(" 否 "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }