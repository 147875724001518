var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        "close-on-click-modal": false,
        title: "编辑量表",
        visible: _vm.dialogFormVisible,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "80px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标题", prop: "title" } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "20",
                  placeholder: "标题",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "简介", prop: "intro" } },
            [
              _c("el-input", {
                attrs: { placeholder: "简介", rows: "4", type: "textarea" },
                model: {
                  value: _vm.form.intro,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "intro", $$v)
                  },
                  expression: "form.intro",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "须知", prop: "notice" } },
            [
              _c("el-input", {
                attrs: { placeholder: "须知", rows: "4", type: "textarea" },
                model: {
                  value: _vm.form.notice,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "notice", $$v)
                  },
                  expression: "form.notice",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上架", prop: "status" } },
            [
              _c("el-switch", {
                attrs: { "active-value": 10, "inactive-value": 20 },
                model: {
                  value: _vm.form.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "status", $$v)
                  },
                  expression: "form.status",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "100",
                  placeholder: "备注",
                  rows: "4",
                  "show-word-limit": "",
                  type: "textarea",
                },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }