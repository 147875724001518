var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        "close-on-click-modal": false,
        size: "75%",
        title: "章节列表",
        visible: _vm.showDrawerBox,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDrawerBox = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "chapter-list" },
        [
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-plus", type: "primary" },
                  on: { click: _vm.handleEdit },
                },
                [_vm._v(" 新增章节 ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: { border: "", data: _vm.list },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "序号",
                  type: "index",
                  width: "60px",
                },
              }),
              _c("el-table-column", { attrs: { label: "名称", prop: "name" } }),
              _c("el-table-column", {
                attrs: { align: "center", label: "时长", prop: "duration" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "排序", prop: "sort" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "操作", width: "160px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "del",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.delRow(row.id)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.queryForm.pageNo,
              layout: _vm.layout,
              "page-size": _vm.queryForm.pageSize,
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _c("edit", { ref: "edit", on: { "fetch-data": _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }