var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "80px", model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "联系我们" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "tel" } }, [
                    _vm._v("联系电话"),
                  ]),
                  _c("el-radio", { attrs: { label: "link" } }, [
                    _vm._v("客服链接"),
                  ]),
                  _c("el-radio", { attrs: { label: "wechat" } }, [
                    _vm._v("二维码"),
                  ]),
                ],
                1
              ),
              _vm.form.type
                ? _c(
                    "div",
                    { staticStyle: { "margin-top": "15px" } },
                    [
                      _vm.form.type === "tel"
                        ? _c("el-input", {
                            attrs: { placeholder: "请输入联系电话" },
                          })
                        : _vm._e(),
                      _vm.form.type === "link"
                        ? _c("el-input", {
                            attrs: { placeholder: "请输入客服链接" },
                          })
                        : _vm._e(),
                      _vm.form.type === "wechat"
                        ? _c("div", { staticClass: "img-uploader" }, [
                            _c(
                              "div",
                              {
                                staticClass: "el-upload el-upload__text",
                                on: { click: _vm.imgDialog },
                              },
                              [
                                _vm.form.wechat
                                  ? _c("img", {
                                      staticClass: "img",
                                      attrs: { src: _vm.form.wechat },
                                    })
                                  : _c("i", { staticClass: "el-icon-plus" }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "el-upload__tip",
                                attrs: { slot: "tip" },
                                slot: "tip",
                              },
                              [
                                _c("div", [
                                  _vm._v(" 只能上传 "),
                                  _c("span", [_vm._v(".jpg、.png")]),
                                  _vm._v(" 文件，且 "),
                                  _c("span", [_vm._v("不超过1M")]),
                                ]),
                                _c("div", [_vm._v("(图片尺寸1:1)")]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "关于我们" } },
            [
              _c("editor", {
                attrs: { "is-clear": _vm.isClear },
                on: { change: _vm.editorChange },
                model: {
                  value: _vm.form.about,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "about", $$v)
                  },
                  expression: "form.about",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户协议" } },
            [
              _c("editor", {
                attrs: { "is-clear": _vm.isClear },
                on: { change: _vm.editorChange },
                model: {
                  value: _vm.form.exp,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "exp", $$v)
                  },
                  expression: "form.exp",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "意见反馈" } },
            [
              _c("el-input", { attrs: { placeholder: "请输入链接" } }),
              _c("div", { staticClass: "txt-tips" }, [
                _vm._v("请输入反馈跳转链接，推荐使用问卷星"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.save },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("gallery", { ref: "gallery", on: { checkedImg: _vm.getImg } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }