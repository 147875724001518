var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.form.typeName,
        visible: _vm.dialogFormVisible,
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: { "label-width": "140px", model: _vm.form },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "选择关联题目",
                prop: "index",
                rules: {
                  required: true,
                  message: "请选择关联题目",
                  trigger: "blur",
                },
              },
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择",
                  },
                  model: {
                    value: _vm.form.index,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "index", $$v)
                    },
                    expression: "form.index",
                  },
                },
                _vm._l(_vm.list, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.title, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.index !== false && _vm.form.index !== ""
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "选择关联选项",
                    prop: "select",
                    rules: {
                      required: true,
                      message: "请选择关联选项",
                      trigger: "blur",
                    },
                  },
                },
                [
                  _vm._l(_vm.list, function (opt, o) {
                    return [
                      opt.id === _vm.form.index
                        ? [
                            _c(
                              "el-radio-group",
                              {
                                key: o,
                                model: {
                                  value: _vm.form.select,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "select", $$v)
                                  },
                                  expression: "form.select",
                                },
                              },
                              _vm._l(opt.options, function (item, index) {
                                return _c(
                                  "el-radio",
                                  { key: index, attrs: { label: index } },
                                  [_vm._v(" " + _vm._s(item.label) + " ")]
                                )
                              }),
                              1
                            ),
                          ]
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("完成编辑")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }