var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.showDialog,
            width: "820px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
            close: _vm.close,
          },
        },
        [
          _c("div", { staticClass: "modals" }, [
            _c("div", [
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "top" }, [
                  _c("span", [_vm._v("浩途智库")]),
                  _c("span", { staticClass: "desc" }, [
                    _vm._v("直接可选择海量专业资源库使用"),
                  ]),
                ]),
                _c("div", { staticClass: "con" }, [
                  _vm._v(
                    " 浩途智库与全国专家、机构建立合作，提供免费以及收费等课程资源供选择，用户可自由选择智库内容上架使用。 "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "btn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { round: "", type: "primary" },
                        on: { click: _vm.showLibrary },
                      },
                      [_vm._v(" 去上架 ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", [
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "top two" }, [
                  _c("span", [_vm._v("自主上传")]),
                  _c("span", { staticClass: "desc" }, [
                    _vm._v("自行上传各类音视频科普课程"),
                  ]),
                ]),
                _c("div", { staticClass: "con" }, [
                  _vm._v(
                    " 【自主上传】平台支持用户自行上传各类音视频文件对接平台，目前支持七牛云、阿里云等主流云存储空间。 "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "btn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { round: "", type: "warning" },
                        on: { click: _vm.toUpload },
                      },
                      [_vm._v(" 去上传 ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "set-def" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: true },
                      on: { input: _vm.bindRadio },
                      model: {
                        value: _vm.setDef,
                        callback: function ($$v) {
                          _vm.setDef = $$v
                        },
                        expression: "setDef",
                      },
                    },
                    [_vm._v(" 设为默认模式 ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "el-dialog__footer" }, [
            _c("em", [_vm._v("*")]),
            _vm._v(
              " 平台智库可能涉及知识产权问题，可供上架的资源均已获取授权，不能上架资源存在付费资源，请联系我们获取权限。 "
            ),
          ]),
        ]
      ),
      _vm.type === "video"
        ? _c("Video", { ref: "video", on: { "fetch-data": _vm.fetchData } })
        : _vm._e(),
      _vm.type === "article"
        ? _c("Article", { ref: "article", on: { "fetch-data": _vm.fetchData } })
        : _vm._e(),
      _c("library", { ref: "library", on: { "fetch-data": _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }