<template>
  <div class="cate-container">
    <div class="tabs">
      <div
        v-for="(tab, i) in tabList"
        :key="i"
        class="tab-item"
        :class="queryForm.type === tab.id ? 'active' : ''"
        @click="bindTab(tab.id)"
      >
        {{ tab.name }}
      </div>
    </div>
    <vab-query-form>
      <vab-query-form-right-panel :span="2">
        <el-button icon="el-icon-plus" type="primary" @click="handleEdit">
          新增
        </el-button>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column align="center" label="序号" type="index" width="60px" />
      <el-table-column align="center" label="名称" prop="title" />
      <el-table-column align="center" label="排序" prop="sort" />
      <el-table-column align="center" label="操作">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button class="del" type="text" @click="delRow(row.id)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { delCate, getCateList } from '@/api/resource'
  import Edit from './components/cateEdit'

  export default {
    name: 'Class',
    components: { Edit },
    data() {
      return {
        tabList: [
          {
            name: '图文分类',
            id: 0,
          },
          {
            name: '课程分类',
            id: 1,
          },
        ],
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          type: 0,
        },
        button: {},
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      bindTab(id) {
        this.queryForm.type = id
        this.queryData()
      },
      handleEdit(row) {
        if (row.id) {
          this.$refs['edit'].showEdit(row, this.queryForm.type)
        } else {
          this.$refs['edit'].showEdit(null, this.queryForm.type)
        }
      },
      delRow(id) {
        this.$baseConfirm('你确定要删除当前分类吗', null, async () => {
          const { msg } = await delCate({ id: id })
          this.$baseMessage(msg, 'success')
          await this.fetchData()
        })
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getCateList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>

<style scoped lang="scss">
  .cate-container {
    .tabs {
      display: flex;
      align-items: center;
      background-color: #f9f9f9;
      padding: 15px 20px 0;
      margin-bottom: 15px;
      .tab-item {
        padding: 10px 20px;
        cursor: pointer;
        &.active {
          background: #fff;
          mask: url('~@/assets/tabs_images/vab-tab.png');
          mask-size: 100% 100%;
          font-weight: bold;
          color: #26cdb7;
        }
      }
    }
  }
</style>
