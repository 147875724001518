<template>
  <div class="eval-free-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="4">
        <el-button icon="el-icon-plus" type="primary" @click="handleEdit">
          添加
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="20">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.title"
              clearable
              placeholder="请输入量表标题"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.pid"
              clearable
              filterable
              placeholder="请选择量表"
            >
              <el-option
                v-for="item in evalList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.cate"
              clearable
              filterable
              placeholder="请选择分类"
            >
              <el-option
                v-for="item in cateList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table v-loading="listLoading" border :data="list">
      <el-table-column align="center" type="index" />
      <el-table-column align="center" label="量表标题" prop="title" />
      <el-table-column align="center" label="选择量表" prop="eval_name" />
      <el-table-column align="center" label="分类" prop="cate_name" />
      <el-table-column align="center" label="封面" prop="cover">
        <template #default="{ row }">
          <el-image :src="row.cover" />
        </template>
      </el-table-column>
      <el-table-column align="center" label="排序" prop="sort" />
      <el-table-column align="center" label="状态" prop="status">
        <template #default="{ row }">
          <el-popconfirm
            v-if="row.status == 10"
            title="当前量表已上架，是否下架？"
            @confirm="setStatus(row)"
          >
            <el-button size="mini" type="primary" slot="reference">
              已上架
            </el-button>
          </el-popconfirm>
          <el-popconfirm
            v-else
            title="当前量表已下架，是否上架？"
            @confirm="setStatus(row)"
          >
            <el-button size="mini" type="info" slot="reference">
              已下架
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column align="center" label="已测次数" prop="nums" />
      <el-table-column align="center" label="编辑时间" prop="update_at" />
      <el-table-column align="center" label="操作" width="85">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import Edit from './freeEdit'
  import { getFreeCate, getFreeList, setStatus } from '@/api/eval'
  import { getEvalListBySid } from '@/api/mmptCategory'

  export default {
    name: 'EvalList',
    components: { Edit },
    data() {
      return {
        list: [],
        evalList: [], //获取所有量表
        cateList: [], //获取分类
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          title: '',
          cate: '',
          pid: '',
        },
      }
    },
    created() {
      this.getEvalList()
      this.getCateList()
      this.fetchData()
    },
    methods: {
      async getEvalList() {
        const { data } = await getEvalListBySid()
        this.evalList = data
      },
      async getCateList() {
        const { data } = await getFreeCate()
        this.cateList = data
      },
      setStatus(row) {
        setStatus({
          id: row.id,
          status: row.status,
        }).then(async (res) => {
          this.$baseMessage(res.msg, 'success')
          await this.fetchData()
        })
      },
      handleEdit(row) {
        if (row.id) {
          this.$refs['edit'].getPath(row)
          /*this.$router.push({
            path: '/mmpt/free/editfree',
            query: { id: row.id },
          })*/
        } else {
          this.$refs['edit'].getPath()
          //this.$router.push('/mmpt/free/editfree')
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount } = await getFreeList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.listLoading = false
      },
    },
  }
</script>

<style scoped></style>
