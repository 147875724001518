<template>
  <div class="add-survey-container">
    <div class="add-title">{{ title }}</div>
    <el-row :gutter="15">
      <el-col
        class="survey-box"
        :md="{ span: 16, offset: 4 }"
        :xl="{ span: 16, offset: 4 }"
      >
        <!--题目类型-->
        <div class="type-box">
          <div v-for="(tab, index) in tx" :key="index" class="item">
            <div class="item-name">{{ tab.title }}</div>
            <div class="sub-item">
              <div
                v-for="(item, _i) in tab.data"
                :key="_i"
                class="sub-item-name"
              >
                <el-button class="lbl" type="primary" @click="addType(item)">
                  {{ item.label }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <!--题目列表-->
        <div v-loading="loading" class="ti-box">
          <div v-if="initData" class="ti-title">
            <div class="t">
              <span>标题</span>
              <el-input
                v-model="initData.title"
                class="ipt"
                placeholder="请输入标题"
              />
            </div>
            <div v-if="false" class="t">
              <span>说明</span>
              <el-input class="ipt" placeholder="请输入说明" type="textarea" />
            </div>
          </div>
          <div class="ti-subject">
            <div v-for="(item, index) in list" :key="index" class="item">
              <div v-if="item.type !== 'page'" class="title">
                <div class="l-name">
                  <span v-if="item.index === false">*</span>
                  {{
                    item.title === '.题目' ? index + 1 + item.title : item.title
                  }}
                </div>
                <div class="r-type">{{ item.typeName }}</div>
              </div>
              <div class="option">
                <!--选择题-->
                <template
                  v-if="item.type === 'radio' || item.type === 'checkbox'"
                >
                  <div
                    v-for="(rdo, j) in item.options"
                    :key="j"
                    :class="`el-${item.type}`"
                  >
                    <span :class="`el-${item.type}__inner`"></span>
                    <span :class="`el-${item.type}__label`">
                      {{ rdo.label }}
                    </span>
                  </div>
                  <div v-if="item.hasOther" :class="`el-${item.type}`">
                    <span :class="`el-${item.type}__inner`"></span>
                    <span :class="`el-${item.type}__label`">
                      {{ item.other }}
                    </span>
                  </div>
                </template>
                <!--矩阵题-->
                <template
                  v-else-if="
                    item.type === 'matrix_radio' ||
                    item.type === 'matrix_checkbox'
                  "
                >
                  <div class="matrix-box">
                    <div class="matrix-item">
                      <div class="matrix-title"></div>
                      <div class="matrix-sub-item">
                        <div
                          v-for="(mr, m) in item.options"
                          :key="m"
                          class="flex1"
                        >
                          {{ mr.label }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="(row, r) in item.row"
                      :key="r"
                      class="matrix-item"
                    >
                      <div class="matrix-title">{{ row }}</div>
                      <div class="matrix-sub-item">
                        <div
                          v-for="(mr, m) in item.options"
                          :key="m"
                          class="flex1"
                        >
                          <span
                            :class="
                              item.type === 'matrix_radio'
                                ? 'el-radio__inner'
                                : 'el-checkbox__inner'
                            "
                            :value="mr.label"
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else-if="item.type === 'page'">
                  <div class="page">
                    <div>分页</div>
                    <div>第{{ item.page }}页/共{{ item.page + 1 }}页</div>
                  </div>
                </template>
                <template v-else>
                  <el-input class="txt" />
                </template>
              </div>
              <div class="operation">
                <el-button
                  v-if="item.type !== 'page'"
                  type="text"
                  @click="handleEdit(item, index)"
                >
                  编辑
                </el-button>
                <el-button
                  v-if="item.type !== 'page'"
                  type="text"
                  @click="handleRelation(item, index)"
                >
                  关联
                </el-button>
                <el-button
                  v-if="list.length > 1"
                  type="text"
                  @click="removeSub(item)"
                >
                  删除
                </el-button>
                <el-button
                  :disabled="index === 0"
                  type="text"
                  @click="upRecord(list, index)"
                >
                  上移
                </el-button>
                <el-button
                  :disabled="index === list.length - 1"
                  type="text"
                  @click="downRecord(list, index)"
                >
                  下移
                </el-button>
                <el-button
                  :disabled="index === 0"
                  type="text"
                  @click="toFirst(list, index)"
                >
                  最前
                </el-button>
                <el-button
                  :disabled="index === list.length - 1"
                  type="text"
                  @click="toLast(list, index)"
                >
                  最后
                </el-button>
              </div>
            </div>
          </div>
          <div class="bottom">
            <div class="tip">点击左侧边栏添加题目</div>
            <el-button class="btn" type="primary" @click="submit">
              发布问卷
            </el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <radio ref="radio" @update-item="updateItem" />
    <matrix ref="matrix" @update-item="updateItem" />
    <write ref="text" @update-item="updateItem" />
    <relation ref="relation" @update-item="updateItem" />
  </div>
</template>

<script>
  import { myComponents } from './components/type'
  import { mapActions } from 'vuex'
  import { addSurvey, editSurvey, getSurveyDetail } from '@/api/teamList'
  import radio from './components/radio'
  import matrix from './components/matrix'
  import write from './components/write'
  import relation from './components/relation'
  import { handleActivePath } from '@/utils/routes'
  export default {
    name: 'AddSurvey',
    components: {
      radio,
      matrix,
      write,
      relation,
    },
    inject: ['reload'],
    data() {
      return {
        loading: true,
        title: '',
        tx: myComponents, //题目类型
        list: [],
        initData: {
          title: '',
        },
      }
    },
    created() {
      this.getPath()
    },
    methods: {
      ...mapActions({
        changeTabsMeta: 'tabs/changeTabsMeta',
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      initItem(type, tname) {
        //拿到当前list中最大的ID,设置ID
        let maxId = Math.max.apply(
          Math,
          this.list.map(function (o) {
            return o.id
          })
        )
        let id = this.list.length ? maxId + 1 : 1
        let data = {
          id: id,
          index: false,
          type: type || 'radio',
          typeName: tname || '单选题',
          select: false,
          title: '.题目',
          tips: '',
          required: true,
          hasTips: false,
          hasOther: false,
          other: '选项其他',
          options: [
            {
              label: '选项1',
              text: false,
              required: false,
            },
            {
              label: '选项2',
              text: false,
              required: false,
            },
          ],
        }
        return data
      },
      getPath() {
        const route = this.$route
        this.$nextTick(() => {
          let rid = route.query.id
          this.title = rid ? '编辑问卷' : '新增问卷'
          if (rid) {
            this.getDetailById(rid)
          } else {
            let data = this.initItem()
            this.list.push(data)
            this.loading = false
          }
        })
      },
      async getDetailById(id) {
        this.loading = true
        let page = 1
        const { data } = await getSurveyDetail({ id })
        this.initData = data
        this.list = data.content.map((item, index) => {
          if (item.id === undefined) {
            item.id = index
          }
          if (item.type === 'page') {
            item.page = page++
          }
          return item
        })
        this.loading = false
      },
      addType(tab) {
        let item = this.initItem(tab.type, tab.label)
        if (tab.type === 'matrix_radio' || tab.type === 'matrix_checkbox') {
          item.row = ['分题目1', '分题目2']
        } else if (tab.type === 'page') {
          let data = this.list
          if (data[data.length - 1].type === 'page') {
            this.$baseMessage('请勿连续添加分页', 'error')
            return false
          }
          let num = data.filter((i) => i.type === 'page')
          item = {
            id: this.list[this.list.length - 1].id + 1,
            type: tab.type,
            title: tab.label,
            page: num.length + 1,
          }
        } else if (tab.type === 'radio' || tab.type === 'checkbox') {
          // eslint-disable-next-line
        } else {
          delete item.options
          delete item.hasOther
          delete item.other
          item.type = 'text'
          item.subType = tab.type
        }
        this.list.push(item)
      },
      removeSub(item) {
        //删除题目
        let li = this.list[0]
        this.$baseConfirm('确定要删除当前项吗', null, async () => {
          let index = this.list.indexOf(item)
          if (index !== -1) {
            this.list.splice(index, 1)
            let page = 1
            this.list = this.list.map((list) => {
              list.page = page
              if (list.type === 'page') {
                page++
              }
              return list
            })
          }
        })
      },
      updateItem(val) {
        let p = JSON.parse(JSON.stringify(val))
        this.list[p.index] = p.data
        this.$forceUpdate()
      },
      swapItems(arr, index1, index2) {
        //交换元素 --//数组,元素1，元素2
        arr[index1] = arr.splice(index2, 1, arr[index1])[0]
        return arr
      },
      upRecord(arr, $index) {
        //上移
        if ($index === 0) {
          return
        }
        if (arr[$index].index !== arr[$index - 1].id) {
          this.swapItems(arr, $index, $index - 1)
        } else {
          this.$baseMessage('当前题与上一题为关联关系，无法上移', 'error')
        }
      },
      downRecord(arr, $index) {
        //下移
        if ($index === arr.length - 1) {
          return
        }
        if (arr[$index].id !== arr[$index + 1].index) {
          this.swapItems(arr, $index, $index + 1)
        } else {
          this.$baseMessage('当前题与下一题为关联关系，无法下移', 'error')
        }
      },
      toFirst(fieldData, index) {
        //置顶
        if (index !== 0) {
          if (fieldData[index].type === 'page') {
            this.$baseMessage('分页不能置顶', 'error')
            return false
          }
          // fieldData[index] = fieldData.splice(0, 1, fieldData[index])[0]; 这种方法是与另一个元素交换了位子，
          let arr1 = fieldData.slice(0, index + 1)
          let arr2 = arr1.filter((item) => item.id === fieldData[index].index)
          if (arr2.length > 0) {
            this.$baseMessage('题目存在关联关系，无法置顶', 'error')
            return false
          }
          fieldData.unshift(fieldData.splice(index, 1)[0])
        }
      },
      toLast(fieldData, index) {
        //置底
        // fieldData[index] = fieldData.splice(0, 1, fieldData[index])[0]; 这种方法是与另一个元素交换了位子，
        let arr1 = fieldData.slice(index, fieldData.length)
        let arr2 = arr1.filter((item) => fieldData[index].id === item.index)
        if (arr2.length > 0) {
          this.$baseMessage('题目存在关联关系，无法置底', 'error')
          return false
        }
        fieldData.push(fieldData.splice(index, 1)[0])
      },
      handleEdit(row, index) {
        if (row.type === 'radio' || row.type === 'checkbox') {
          this.$refs['radio'].showEdit(row, index)
        } else if (
          row.type === 'matrix_radio' ||
          row.type === 'matrix_checkbox'
        ) {
          this.$refs['matrix'].showEdit(row, index)
        } else {
          this.$refs['text'].showEdit(row, index)
        }
      },
      handleRelation(row, index) {
        //关联
        this.$refs['relation'].showEdit(this.list, row, index)
      },
      async submit() {
        let data = this.list
        if (!this.initData.title) {
          this.$baseMessage('请输入问卷标题', 'error')
          return false
        } else if (data[0].type === 'page') {
          this.$baseMessage('问卷第一项不能为分页', 'error')
          return false
        } else if (data[data.length - 1].type === 'page') {
          this.$baseMessage('问卷最后一项不能为分页', 'error')
          return false
        } else {
          let id = this.$route.query.id
          if (id) {
            const { msg } = await editSurvey(id, {
              title: this.initData.title,
              content: data,
            })
            this.$baseMessage(msg, 'success')
          } else {
            const { msg } = await addSurvey({
              title: this.initData.title,
              content: data,
            })
            this.$baseMessage(msg, 'success')
          }
          //关闭当前tab，返回list页并刷新
          await this.delVisitedRoute(handleActivePath(this.$route, true))
          this.reload()
          this.$router.push('/survey/surveyList')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .add-survey-container {
    .add-title {
      text-align: center;
      line-height: 50px;
      background: #f0fcfa;
      margin: -20px -20px 20px;
      font-weight: bold;
      font-size: 16px;
    }
    .survey-box {
      display: flex;
      height: calc(100vh - 165px - 20px * 4 - 55px);
      overflow: scroll;
    }
    .type-box {
      position: sticky;
      top: 0;
      width: 220px;
      padding-right: 20px;
      border-right: 2px solid #e9e7e7;
      .item {
        margin-bottom: 40px;
        .item-name {
          padding: 3px 10px;
          font-weight: bold;
        }
        .sub-item {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .sub-item-name {
            width: 50%;
            padding: 10px;
            .lbl {
              width: 100%;
            }
          }
        }
      }
    }
    .ti-box {
      /*height: calc(100vh - 165px - 20px * 4 - 55px);*/
      flex: 1;
      padding-left: 30px;
      .ti-title {
        .t {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          > span {
            margin-right: 10px;
          }
          .ipt {
            flex: 1;
          }
        }
      }
      .ti-subject {
        .item {
          background: #f9f9f9;
          padding: 15px 20px;
          margin-bottom: 15px;
          &:hover {
            background: #eee;
          }
          .title {
            display: flex;
            line-height: 20px;
            .l-name {
              flex: 1;
              span {
                color: red;
              }
            }
            .r-type {
              font-size: 12px;
              color: #999;
              margin-left: 15px;
            }
          }
          .option {
            padding-left: 20px;
            .el-radio,
            .el-checkbox {
              display: flex;
              line-height: 1.3;
              margin-right: 0;
              white-space: normal;
              margin-top: 8px;
              &__inner {
                margin-top: 2px !important;
              }
              &__label {
                flex: 1;
              }
            }
          }
          .matrix-box {
            .matrix-item {
              display: flex;
              margin: 10px 0;
              .matrix-title {
                width: 30%;
              }
              .matrix-sub-item {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .flex1 {
                  flex: 1;
                  text-align: center;
                }
              }
            }
          }
          .page {
            color: #999;
            text-align: center;
            font-size: 12px;
            div {
              margin: 10px 0;
            }
          }
          .txt {
            width: 200px;
            margin: 10px 0;
          }
          .operation {
            text-align: right;
          }
        }
      }
      .bottom {
        text-align: center;
        .tip {
          font-size: 12px;
          color: #999;
          margin-bottom: 15px;
        }
        .btn {
          width: 200px;
        }
      }
    }
  }
</style>
