<template>
  <el-drawer
    :close-on-click-modal="false"
    size="75%"
    title="章节列表"
    :visible.sync="showDrawerBox"
  >
    <div class="chapter-list">
      <div>
        <el-button icon="el-icon-plus" type="primary" @click="handleEdit">
          新增章节
        </el-button>
      </div>
      <el-table v-loading="listLoading" border :data="list">
        <el-table-column
          align="center"
          label="序号"
          type="index"
          width="60px"
        />
        <el-table-column label="名称" prop="name" />
        <el-table-column align="center" label="时长" prop="duration" />
        <el-table-column align="center" label="排序" prop="sort" />
        <el-table-column align="center" label="操作" width="160px">
          <template #default="{ row }">
            <el-button type="text" @click="handleEdit(row)">编辑</el-button>
            <el-button class="del" type="text" @click="delRow(row.id)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :current-page="queryForm.pageNo"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <edit ref="edit" @fetch-data="fetchData" />
  </el-drawer>
</template>

<script>
  import { delChapter, getChapterList } from '@/api/resource'
  import Edit from './edit'

  export default {
    name: 'ChapterList',
    components: { Edit },
    data() {
      return {
        showDrawerBox: false,
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          c_id: '',
        },
        total: 0,
      }
    },
    methods: {
      showDrawer(row) {
        this.queryForm.c_id = row.id
        this.fetchData()
        this.showDrawerBox = true
      },
      delRow(id) {
        this.$baseConfirm('你确定要删除当前章节吗', null, async () => {
          const { msg } = await delChapter({ id: id })
          this.$baseMessage(msg, 'success')
          await this.fetchData()
        })
      },
      handleEdit(row) {
        if (row.id) {
          this.$refs['edit'].showEdit(row, this.queryForm.c_id)
        } else {
          this.$refs['edit'].showEdit(null, this.queryForm.c_id)
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getChapterList(
          this.queryForm
        )
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>

<style scoped lang="scss">
  .chapter-list {
    padding: 0 20px;
  }
</style>
