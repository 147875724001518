var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    {
      attrs: { type: "border-card" },
      on: { "tab-click": _vm.handleClick },
      model: {
        value: _vm.tempObj.type,
        callback: function ($$v) {
          _vm.$set(_vm.tempObj, "type", $$v)
        },
        expression: "tempObj.type",
      },
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { name: "text" } },
        [
          _c("span", { attrs: { slot: "label" }, slot: "label" }, [
            _c("i", { staticClass: "el-icon-document" }),
            _vm._v(" 文本 "),
          ]),
          _c("el-input", {
            attrs: { placeholder: "请输入内容", rows: 5, type: "textarea" },
            on: { input: _vm.inputContent },
            model: {
              value: _vm.tempObj.content,
              callback: function ($$v) {
                _vm.$set(_vm.tempObj, "content", $$v)
              },
              expression: "tempObj.content",
            },
          }),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { name: "image" } },
        [
          _c("span", { attrs: { slot: "label" }, slot: "label" }, [
            _c("i", { staticClass: "el-icon-picture" }),
            _vm._v(" 图片 "),
          ]),
          _c("el-row", [
            _vm.tempObj.url
              ? _c(
                  "div",
                  { staticClass: "select-item" },
                  [
                    _c("img", {
                      staticClass: "material-img",
                      attrs: { src: _vm.tempObj.url },
                    }),
                    _vm.tempObj.name
                      ? _c("p", { staticClass: "item-name" }, [
                          _vm._v(_vm._s(_vm.tempObj.name)),
                        ])
                      : _vm._e(),
                    _c(
                      "el-row",
                      { staticClass: "ope-row" },
                      [
                        _c("el-button", {
                          attrs: {
                            circle: "",
                            icon: "el-icon-delete",
                            type: "danger",
                          },
                          on: { click: _vm.deleteObj },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c(
                          "el-col",
                          { staticClass: "col-select", attrs: { span: 12 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "success" },
                                on: { click: _vm.openMaterial },
                              },
                              [
                                _vm._v(" 素材库选择 "),
                                _c("i", {
                                  staticClass:
                                    "el-icon-circle-check el-icon--right",
                                }),
                              ]
                            ),
                            _c("el-dialog", {
                              attrs: {
                                "append-to-body": "",
                                title: "选择图片",
                                visible: _vm.dialogImageVisible,
                                width: "90%",
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.dialogImageVisible = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { staticClass: "col-add", attrs: { span: 12 } },
                          [
                            _c(
                              "el-upload",
                              {
                                attrs: {
                                  action: _vm.actionUrl,
                                  "before-upload": _vm.beforeImageUpload,
                                  data: _vm.uploadData,
                                  "file-list": _vm.fileList,
                                  headers: _vm.headers,
                                  limit: 1,
                                  multiple: "",
                                  "on-success": _vm.handleUploadSuccess,
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { type: "primary" } },
                                  [_vm._v("上传图片")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-upload__tip",
                                    attrs: { slot: "tip" },
                                    slot: "tip",
                                  },
                                  [
                                    _vm._v(
                                      " 支持 bmp/png/jpeg/jpg/gif 格式，大小不超过 2M "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ]),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { name: "voice" } },
        [
          _c("span", { attrs: { slot: "label" }, slot: "label" }, [
            _c("i", { staticClass: "el-icon-phone" }),
            _vm._v(" 语音 "),
          ]),
          _c("el-row", [
            _vm.tempObj.url
              ? _c(
                  "div",
                  { staticClass: "select-item2" },
                  [
                    _c("p", { staticClass: "item-name" }, [
                      _vm._v(_vm._s(_vm.tempObj.name)),
                    ]),
                    _c("div", { staticClass: "item-infos" }),
                    _c(
                      "el-row",
                      { staticClass: "ope-row" },
                      [
                        _c("el-button", {
                          attrs: {
                            circle: "",
                            icon: "el-icon-delete",
                            type: "danger",
                          },
                          on: { click: _vm.deleteObj },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c(
                          "el-col",
                          { staticClass: "col-select", attrs: { span: 12 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "success" },
                                on: { click: _vm.openMaterial },
                              },
                              [
                                _vm._v(" 素材库选择 "),
                                _c("i", {
                                  staticClass:
                                    "el-icon-circle-check el-icon--right",
                                }),
                              ]
                            ),
                            _c("el-dialog", {
                              attrs: {
                                "append-to-body": "",
                                title: "选择语音",
                                visible: _vm.dialogVoiceVisible,
                                width: "90%",
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.dialogVoiceVisible = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { staticClass: "col-add", attrs: { span: 12 } },
                          [
                            _c(
                              "el-upload",
                              {
                                attrs: {
                                  action: _vm.actionUrl,
                                  "before-upload": _vm.beforeVoiceUpload,
                                  data: _vm.uploadData,
                                  "file-list": _vm.fileList,
                                  headers: _vm.headers,
                                  limit: 1,
                                  multiple: "",
                                  "on-success": _vm.handleUploadSuccess,
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { type: "primary" } },
                                  [_vm._v("点击上传")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-upload__tip",
                                    attrs: { slot: "tip" },
                                    slot: "tip",
                                  },
                                  [
                                    _vm._v(
                                      " 格式支持 mp3/wma/wav/amr，文件大小不超过 2M，播放长度不超过 60s "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ]),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { name: "video" } },
        [
          _c("span", { attrs: { slot: "label" }, slot: "label" }, [
            _c("i", { staticClass: "el-icon-share" }),
            _vm._v(" 视频 "),
          ]),
          _c(
            "el-row",
            [
              _c("el-input", {
                attrs: { placeholder: "请输入标题" },
                on: { input: _vm.inputContent },
                model: {
                  value: _vm.tempObj.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.tempObj, "title", $$v)
                  },
                  expression: "tempObj.title",
                },
              }),
              _c("div", { staticStyle: { margin: "20px 0" } }),
              _c("el-input", {
                attrs: { placeholder: "请输入描述" },
                on: { input: _vm.inputContent },
                model: {
                  value: _vm.tempObj.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.tempObj, "description", $$v)
                  },
                  expression: "tempObj.description",
                },
              }),
              _c("div", { staticStyle: { margin: "20px 0" } }),
              _c("div", { staticStyle: { "text-align": "center" } }),
              _c("div", { staticStyle: { margin: "20px 0" } }),
              _c(
                "el-row",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success" },
                          on: { click: _vm.openMaterial },
                        },
                        [
                          _vm._v(" 素材库选择 "),
                          _c("i", {
                            staticClass: "el-icon-circle-check el-icon--right",
                          }),
                        ]
                      ),
                      _c("el-dialog", {
                        attrs: {
                          "append-to-body": "",
                          title: "选择视频",
                          visible: _vm.dialogVideoVisible,
                          width: "90%",
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.dialogVideoVisible = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: _vm.actionUrl,
                            "before-upload": _vm.beforeVideoUpload,
                            data: _vm.uploadData,
                            "file-list": _vm.fileList,
                            headers: _vm.headers,
                            limit: 1,
                            multiple: "",
                            "on-success": _vm.handleUploadSuccess,
                          },
                        },
                        [
                          _c("el-button", { attrs: { type: "primary" } }, [
                            _vm._v(" 新建视频 "),
                            _c("i", {
                              staticClass: "el-icon-upload el-icon--right",
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { name: "news" } },
        [
          _c("span", { attrs: { slot: "label" }, slot: "label" }, [
            _c("i", { staticClass: "el-icon-news" }),
            _vm._v(" 图文 "),
          ]),
          _c(
            "el-row",
            [
              _vm.tempObj.articles
                ? _c(
                    "div",
                    { staticClass: "select-item" },
                    [
                      _c(
                        "el-row",
                        { staticClass: "ope-row" },
                        [
                          _c("el-button", {
                            attrs: {
                              circle: "",
                              icon: "el-icon-delete",
                              type: "danger",
                            },
                            on: { click: _vm.deleteObj },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.tempObj.content
                ? _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "success" },
                                  on: { click: _vm.openMaterial },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.newsType === "1"
                                          ? "选择已发布图文"
                                          : "选择草稿箱图文"
                                      ) +
                                      " "
                                  ),
                                  _c("i", {
                                    staticClass:
                                      "el-icon-circle-check el-icon--right",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("el-dialog", {
                attrs: {
                  "append-to-body": "",
                  title: "选择图文",
                  visible: _vm.dialogNewsVisible,
                  width: "90%",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogNewsVisible = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { name: "music" } },
        [
          _c("span", { attrs: { slot: "label" }, slot: "label" }, [
            _c("i", { staticClass: "el-icon-service" }),
            _vm._v(" 音乐 "),
          ]),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("div", { staticClass: "thumb-div" }, [
                    _vm.tempObj.thumbMediaUrl
                      ? _c("img", {
                          staticStyle: { width: "100px" },
                          attrs: { src: _vm.tempObj.thumbMediaUrl },
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon",
                        }),
                    _c(
                      "div",
                      { staticClass: "thumb-but" },
                      [
                        _c(
                          "el-upload",
                          {
                            attrs: {
                              action: _vm.actionUrl,
                              "before-upload": _vm.beforeThumbImageUpload,
                              data: _vm.uploadData,
                              "file-list": _vm.fileList,
                              headers: _vm.headers,
                              limit: 1,
                              multiple: "",
                              "on-success": _vm.handleUploadSuccess,
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "trigger",
                                  size: "mini",
                                  type: "text",
                                },
                                slot: "trigger",
                              },
                              [_vm._v(" 本地上传 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "5px" },
                                attrs: { size: "mini", type: "text" },
                                on: { click: _vm.openMaterial },
                              },
                              [_vm._v(" 素材库选择 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-dialog", {
                    attrs: {
                      "append-to-body": "",
                      title: "选择图片",
                      visible: _vm.dialogThumbVisible,
                      width: "80%",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogThumbVisible = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入标题" },
                    on: { input: _vm.inputContent },
                    model: {
                      value: _vm.tempObj.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempObj, "title", $$v)
                      },
                      expression: "tempObj.title",
                    },
                  }),
                  _c("div", { staticStyle: { margin: "20px 0" } }),
                  _c("el-input", {
                    attrs: { placeholder: "请输入描述" },
                    on: { input: _vm.inputContent },
                    model: {
                      value: _vm.tempObj.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempObj, "description", $$v)
                      },
                      expression: "tempObj.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticStyle: { margin: "20px 0" } }),
          _c("el-input", {
            attrs: { placeholder: "请输入音乐链接" },
            on: { input: _vm.inputContent },
            model: {
              value: _vm.tempObj.musicUrl,
              callback: function ($$v) {
                _vm.$set(_vm.tempObj, "musicUrl", $$v)
              },
              expression: "tempObj.musicUrl",
            },
          }),
          _c("div", { staticStyle: { margin: "20px 0" } }),
          _c("el-input", {
            attrs: { placeholder: "请输入高质量音乐链接" },
            on: { input: _vm.inputContent },
            model: {
              value: _vm.tempObj.hqMusicUrl,
              callback: function ($$v) {
                _vm.$set(_vm.tempObj, "hqMusicUrl", $$v)
              },
              expression: "tempObj.hqMusicUrl",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }