var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "eval-cate-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-left-panel",
            { attrs: { span: 12 } },
            [
              _vm.checkAccess({ permission: ["addFreeCate"] })
                ? _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus", type: "primary" },
                      on: { click: _vm.handleEdit },
                    },
                    [_vm._v(" 添加 ")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: "",
                        icon: "el-icon-plus",
                        title: "暂无权限，如有需要，请联系管理员",
                        type: "primary",
                      },
                    },
                    [_vm._v(" 添加 ")]
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { border: "", data: _vm.list },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", prop: "id" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "分类", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "排序", prop: "sort" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm.checkAccess({ permission: ["addFreeCate"] })
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: "",
                              title: "暂无权限，如有需要，请联系管理员",
                              type: "text",
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                    _vm.checkAccess({ permission: ["deleteFreeCate"] })
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: "",
                              title: "暂无权限，如有需要，请联系管理员",
                              type: "text",
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("edit", { ref: "edit", on: { "fetch-data": _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }