var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "set-pc" },
    [
      _c(
        "div",
        { staticClass: "block" },
        [
          _c(
            "div",
            { staticClass: "block-top" },
            [
              _c("span", { staticClass: "name" }, [_vm._v("顶部导航")]),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.checkAccess({ permission: ["editNav"] }),
                    type: "text",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.edit(null, 1, 1)
                    },
                  },
                },
                [_vm._v(" 点击添加 ")]
              ),
              false
                ? _c("div", { staticClass: "flex1" }, [
                    _c("span", [_vm._v("鼠标悬停查看效果")]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "refTable",
              attrs: {
                data: _vm.navList,
                "default-expand-all": "",
                "row-key": "id",
                "tree-props": { children: "children" },
              },
              on: { "row-click": _vm.clickRow },
            },
            [
              _c("el-table-column", {
                attrs: { label: "菜单栏标题", prop: "title" },
              }),
              _c("el-table-column", {
                attrs: { label: "显示", prop: "show" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-value": 10,
                                "inactive-value": 20,
                                value: row.show,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.setStatus(row)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "更新时间", prop: "update_at" },
              }),
              _c("el-table-column", {
                attrs: { align: "right", label: "操作", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.rank == 1
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: !_vm.checkAccess({
                                      permission: ["editNav"],
                                    }),
                                    type: "text",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.edit(row, 2, "add")
                                    },
                                  },
                                },
                                [_vm._v(" 新增下级 ")]
                              ),
                            ]
                          : _vm._e(),
                        _vm.checkAccess({ permission: ["editNav"] })
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.edit(row, 2)
                                  },
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: "",
                                  title: "暂无权限，如有需要，请联系管理员",
                                  type: "text",
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                        _vm.checkAccess({ permission: ["delNav"] })
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.del(row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: "",
                                  title: "暂无权限，如有需要，请联系管理员",
                                  type: "text",
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "block" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "bottom-bg" },
          [
            _c(
              "div",
              { staticClass: "bg", on: { click: _vm.imgDialog } },
              [
                _vm.bgCover
                  ? _c("el-image", {
                      attrs: { fit: "cover", src: _vm.bgCover },
                    })
                  : _c(
                      "div",
                      { staticClass: "choose-img" },
                      [
                        _c("el-button", { attrs: { type: "text" } }, [
                          _vm._v("点击上传图片"),
                        ]),
                        _c("div", { staticClass: "img-tip" }, [
                          _vm._v("推荐尺寸：1920*300"),
                        ]),
                      ],
                      1
                    ),
              ],
              1
            ),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.editBg } },
              [_vm._v("保存图片")]
            ),
          ],
          1
        ),
      ]),
      _c("p-index", { ref: "p-index", on: { refresh: _vm.getMenu } }),
      _c("gallery", { ref: "gallery", on: { checkedImg: _vm.getImg } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "block-top" }, [
      _c("span", { staticClass: "name" }, [_vm._v("底部固定栏")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }