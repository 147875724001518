var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-col",
    { attrs: { lg: 6, md: 24, sm: 24, xl: 6, xs: 24 } },
    [
      _c("el-card", { attrs: { shadow: "never" } }, [
        _c("div", { staticClass: "wechat-box" }, [
          _c("div", { staticClass: "img" }, [
            _c("img", { attrs: { src: "" } }),
          ]),
          _c("div", { staticClass: "right" }, [
            _c("p", { staticClass: "f-title" }, [_vm._v("浩途科技")]),
            _c("p", { staticClass: "f-desc" }, [_vm._v("浩途科技浩途科技")]),
          ]),
        ]),
        _c("div", { staticClass: "wechat-box" }, [
          _c("div", { staticClass: "img" }, [
            _c("img", { attrs: { src: "" } }),
          ]),
          _c("div", { staticClass: "right" }, [
            _c("p", { staticClass: "f-title" }, [_vm._v("浩途科技")]),
            _c("p", { staticClass: "f-desc" }, [_vm._v("浩途科技浩途科技")]),
          ]),
        ]),
        _c("div", { staticClass: "wechat-box" }, [
          _c("div", { staticClass: "right" }, [
            _c("p", { staticClass: "f-title" }, [_vm._v("浩途科技")]),
            _c("p", { staticClass: "f-desc" }, [_vm._v("浩途科技浩途科技")]),
          ]),
        ]),
        _c("div", { staticClass: "wechat-box" }, [
          _c("div", { staticClass: "right" }, [
            _c("p", { staticClass: "f-title" }, [_vm._v("浩途科技")]),
            _c("p", { staticClass: "f-desc" }, [_vm._v("浩途科技浩途科技")]),
          ]),
        ]),
      ]),
      _c(
        "el-card",
        {
          attrs: { shadow: "never" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("span", [_vm._v("待办事项")]),
                  _c(
                    "router-link",
                    { staticClass: "see-more", attrs: { to: "toDo" } },
                    [_vm._v("查看更多>>")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "todo-list" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "time" }, [_vm._v("3月22日 17：47")]),
              _c("div", { staticClass: "title" }, [_vm._v("这是一个标题")]),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "time" }, [_vm._v("3月22日 17：47")]),
              _c("div", { staticClass: "title" }, [_vm._v("这是一个标题")]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }