<template>
  <el-dialog
    :close-on-click-modal="false"
    title="编辑预警项"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
  >
    <el-form
      v-if="initData"
      ref="form"
      label-width="100px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="标题" prop="name">
        <el-input v-model="form.name" placeholder="请输入标题" />
      </el-form-item>
      <el-form-item label="预警判断" prop="jungle">
        <el-radio-group v-model="form.jungle">
          <el-radio
            v-for="item in initData.jungle"
            :key="item.id"
            :label="item.id"
          >
            {{ item.name }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="预警类型" prop="warn_type">
        <el-radio-group v-model="form.warn_type">
          <el-radio
            v-for="item in initData.type"
            :key="item.id"
            :label="item.id"
          >
            {{ item.name }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="form.jungle === '1'">
        <el-form-item
          v-if="form.warn_type === '1'"
          label="因子分"
          prop="factor_radio"
          :rules="[
            {
              required: form.warn_type === '1',
              message: '请选择因子分',
              trigger: 'change',
            },
          ]"
        >
          <el-radio-group v-model="form.factor_radio">
            <el-radio
              v-for="item in initData.factor"
              :key="item.id"
              :label="item.id"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-else
          label="题目"
          prop="quest"
          :rules="[
            { required: true, message: '请输入题号', trigger: 'blur' },
            { type: 'number', message: '题号必须为数字值', trigger: 'blur' },
          ]"
        >
          <el-input v-model.number="form.quest" placeholder="请输入题号" />
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item
          v-if="form.warn_type === '1'"
          label="因子分"
          prop="factor_checkbox"
          :rules="[
            { required: true, message: '请选择因子分', trigger: 'change' },
          ]"
        >
          <el-checkbox-group v-model="form.factor_checkbox">
            <el-checkbox
              v-for="item in initData.factor"
              :key="item.id"
              :label="item.id"
            >
              {{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item
          v-else
          label="题目"
          prop="quest"
          :rules="[
            { required: true, message: '请输入如题号', trigger: 'blur' },
            {
              pattern: /^(\d+,?)+$/,
              message: '只能输入数字和英文逗号',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="form.quest"
            placeholder="多个题目请用英文逗号隔开"
          />
        </el-form-item>
      </template>
      <el-form-item label="计分类型" prop="score">
        <el-radio-group v-model="form.score">
          <el-radio
            v-for="item in initData.score"
            :key="item.id"
            :label="item.id"
          >
            {{ item.name }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="预警分值" prop="warn_score">
        <el-input
          v-model="form.warn_score"
          placeholder="请输入预警范围，例如：(1,20]"
        />
        <div class="tips">
          “[]”英文中括号代表包含边界值，“()”英文小括号代表不包含边界值，比如(1,20]代表的范围为1到20，不包含1，包含20
        </div>
      </el-form-item>
      <el-form-item
        v-if="form.jungle === '2'"
        label="预警项目数"
        prop="warn_nums"
        :rules="[
          { required: true, message: '请输入预警项目数', trigger: 'blur' },
          {
            pattern: /(\(|\[)(\d+,\d+)(?=\]|\))/g,
            message: '格式不正确',
            trigger: 'blur',
          },
        ]"
      >
        <el-input v-model="form.warn_nums" placeholder="请输入预警项目数范围" />
        <div class="tips">
          “[]”英文中括号代表包含边界值，“()”英文小括号代表不包含边界值，比如(1,20]代表的范围为1到20，不包含1，包含20
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getWarnType, doRuleItemEdit } from '@/api/settingAuthority'
  export default {
    name: 'WarnRelate',
    data() {
      return {
        initData: null,
        form: {
          name: '',
          jungle: '1', //预警判断
          warn_type: '1', //预警类型
          factor_checkbox: [], //因子分字段
          factor_radio: '',
          quest: '', //题目字段
          score: '', //计分类型字段
          warn_score: '', //预警计分段
          warn_nums: '', //预警项目数
        },
        rules: {
          name: [{ required: true, message: '请输入标题', trigger: 'blur' }],
          jungle: [
            { required: true, trigger: 'change', message: '请选择预警判断' },
          ],
          warn_type: [
            { required: true, trigger: 'change', message: '请选择预警类型' },
          ],
          score: [
            { required: true, trigger: 'change', message: '请选择计分类型' },
          ],
          warn_score: [
            { required: true, message: '请输入预警范围', trigger: 'blur' },
            {
              pattern: /(\(|\[)((\d+(\.))?\d+,(\d+(\.))?\d+)(?=\]|\))/g,
              message: '格式不正确',
              trigger: 'blur',
            },
          ],
        },
        type: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit2(row, type) {
        if (type === 'add') {
          this.getWarnItem(row.pid)
          this.form.pid = row.pid
          this.form.id = row.id
        } else {
          this.form = JSON.parse(JSON.stringify(row))
          this.getWarnItem(row.pid)
        }
        this.form.type = type
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      async getWarnItem(id) {
        const { data } = await getWarnType({ id: id })
        this.initData = data
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await doRuleItemEdit(this.form)
            this.$baseMessage(msg, 'success')
            this.$emit('rule-list')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .tips {
    line-height: initial;
    color: #bbb;
  }
</style>
