<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="showFreeDrawer"
    width="800px"
    @close="close"
  >
    <div class="free-eidt-container">
      <el-row v-loading="loading" :gutter="15">
        <el-col
          :lg="{ span: 16, offset: 4 }"
          :md="{ span: 20, offset: 2 }"
          :sm="{ span: 20, offset: 2 }"
          :xl="{ span: 12, offset: 6 }"
          :xs="24"
        >
          <el-form ref="form" label-width="100px" :model="form" :rules="rules">
            <el-form-item label="选择量表" prop="pid">
              <el-select
                v-model="form.pid"
                clearable
                :disabled="form.type === 'edit'"
                filterable
                placeholder="请选择量表"
                @change="bindChange"
              >
                <el-option
                  v-for="item in evalList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="量表标题" prop="title">
              <el-input v-model="form.title" placeholder="请输入量表标题" />
            </el-form-item>
            <el-form-item label="副标题" prop="subhead">
              <el-input
                v-model="form.subhead"
                placeholder="请输入副标题"
                rows="5"
                type="textarea"
              />
            </el-form-item>
            <el-form-item label="分类" prop="cate">
              <el-select
                v-model="form.cate"
                clearable
                filterable
                placeholder="请选择分类"
              >
                <el-option
                  v-for="item in cateList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              <el-button
                icon="el-icon-refresh"
                style="font-size: 15px; padding-left: 10px"
                type="text"
                @click="refreshCate"
              />
              <el-button type="text" @click="addCate">新增</el-button>
            </el-form-item>
            <el-form-item label="封面" prop="cover">
              <div class="img-uploader">
                <div class="el-upload el-upload__text" @click="imgDialog">
                  <img v-if="form.cover" class="img" :src="form.cover" />
                  <i v-else class="el-icon-plus"></i>
                </div>
                <div slot="tip" class="el-upload__tip">
                  <div>
                    只能上传
                    <span>.jpg、.png</span>
                    文件，且
                    <span>不超过1M</span>
                  </div>
                  <div>(图片尺寸1:1)</div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="排序" prop="sort">
              <el-input-number
                v-model="form.sort"
                controls-position="right"
                :min="0"
                placeholder="越大越靠前"
              />
            </el-form-item>
            <el-form-item label="虚拟测评次数" prop="nums">
              <el-input-number
                v-model="form.nums"
                controls-position="right"
                :min="0"
              />
            </el-form-item>
            <el-form-item label="自定义样式" prop="style">
              <el-switch
                v-model="form.style"
                :active-value="20"
                :inactive-value="10"
              />
              <editor
                v-if="form.style === 20"
                v-model="form.content"
                :is-clear="isClear"
                @change="editorChange"
              />
            </el-form-item>
            <el-form-item label="上架" prop="status">
              <el-switch
                v-model="form.status"
                :active-value="10"
                :inactive-value="20"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="save('form')">保存</el-button>
            </el-form-item>
          </el-form>
          <gallery ref="gallery" @checkedImg="getImg" />
        </el-col>
      </el-row>
      <cate ref="cate" />
    </div>
  </el-dialog>
</template>

<script>
  import { isNumber } from '@/utils/validate'
  import { freeListEdit, getFreeCate } from '@/api/eval'
  import Cate from '../components/cateEdit'
  import Editor from '@/components/editor'
  import Gallery from '@/components/gallery'
  import { getEvalListBySid } from '@/api/mmptCategory'

  export default {
    name: 'FreeEdit',
    components: { Cate, Editor, Gallery },
    inject: ['reload'],
    data() {
      const validateSort = (rule, value, callback) => {
        if (value !== '' && !isNumber(value)) {
          callback(new Error('请输入正整数'))
        } else {
          callback()
        }
      }
      return {
        showFreeDrawer: false,
        evalList: [],
        cateList: [],
        form: {
          sort: '', //非必填；分类排序；type为edit/add时必填
          title: '', //非必填；标题；type为edit/add时必填
          pid: '', //非必填；量表ID；type为edit/add时必填
          subhead: '', //非必填；副标题；type为edit/add时必填
          cover: '', //非必填；封面；type为edit/add时必填
          cate: '',
          style: 10, //非必填；详情类型，10默认 20富文本；type为edit/add时必填
          content: '', //非必填；详情富文本，当style=20时必填；type为edit/add时必填
          status: 20, //非必填；状态，上架10下架20；
          nums: '',
        },
        isClear: false, //设置为true的时候，这个可以用this.form.content=''来替代
        rules: {
          title: [
            { required: true, trigger: 'blur', message: '请输入量表标题' },
          ],
          pid: [{ required: true, trigger: 'change', message: '请选择量表' }],
          cate: [{ required: true, trigger: 'change', message: '请选择分类' }],
          //sort: [{ validator: validateSort, trigger: 'blur' }],
        },
        title: '',
        hideUpload: false,
        hasImg: '',
        loading: true,
      }
    },
    created() {
      //this.getPath()
      this.refreshCate()
      this.getEvalList()
    },
    methods: {
      bindChange(e) {
        const cur = this.evalList.filter((item) => e === item.id)
        this.form.title = cur[0].name
        this.form.subhead = cur[0].intro
      },
      getPath(row) {
        this.$nextTick(() => {
          if (row) {
            let rid = row.id
            this.title = '编辑量表'
            freeListEdit({ id: rid, type: 'info' }).then((res) => {
              let p = res.data
              this.form = {
                id: p.id,
                type: 'edit',
                sort: p.sort,
                title: p.title,
                pid: p.paper_id,
                subhead: p.subhead,
                cover: p.cover,
                cate: p.cate,
                style: p.style * 1,
                content: p.content,
                status: p.status * 1,
                nums: p.nums,
              }
            })
          } else {
            this.form.type = 'add'
            this.title = '新增量表'
          }
          this.loading = false
        })
        this.showFreeDrawer = true
      },
      imgDialog() {
        this.$refs['gallery'].showEdit()
      },
      getImg(val) {
        this.form.cover = val.url
      },
      async refreshCate() {
        const { data } = await getFreeCate()
        this.cateList = data
      },
      async getEvalList() {
        const { data } = await getEvalListBySid()
        this.evalList = data
      },
      async addCate() {
        this.$refs['cate'].showEdit()
      },
      editorChange(val) {
        console.log(val)
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.showFreeDrawer = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let p = this.form
            if (p.style === 20) {
              if (!p.content) {
                this.$baseMessage('请输入自定义详情', 'error')
                return false
              }
            }
            p.id = p.id || 0
            p.sort = p.sort === '' ? 0 : p.sort
            const { msg, code } = await freeListEdit(p)
            this.$baseMessage(msg, code === 200 ? 'success' : 'error')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .free-eidt-container {
    .w215 {
      width: 215px;
    }
    .img-uploader {
      display: flex;
      :deep(.el-upload) {
        border: 1px dashed #d9d9d9;
        border-radius: 3px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      :deep(.el-upload--picture-card),
      :deep(.el-upload-list--picture-card .el-upload-list__item) {
        width: 100px;
        height: 100px;
      }
      :deep(.el-list-enter-active),
      :deep(.el-list-leave-active) {
        transition: none;
      }

      :deep(.el-list-enter),
      :deep(.el-list-leave-active) {
        opacity: 0;
      }
      .hide :deep(.el-upload--picture-card) {
        display: none;
      }
      .el-upload__tip {
        flex: 1;
        margin-left: 10px;
        color: #999;
        line-height: normal;
        span {
          color: #e64e1f;
        }
      }
      :deep(.el-upload:hover) {
        border-color: #92e6db;
      }
      .img,
      .el-icon-plus {
        width: 100px;
        height: 100px;
        display: block;
        line-height: 100px;
      }
    }
  }
</style>
