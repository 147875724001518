<template>
  <div class="menu-management-container">
    <el-row :gutter="20">
      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <el-card shadow="hover">
          <vab-query-form>
            <vab-query-form-top-panel :span="12">
              <el-button
                v-permissions="{ permission: ['addMenu'] }"
                icon="el-icon-plus"
                type="primary"
                @click="handleEdit()"
              >
                添加
              </el-button>
              <el-button @click="toggle">展开/折叠</el-button>
            </vab-query-form-top-panel>
          </vab-query-form>
          <el-table
            ref="tableTree"
            v-loading="listLoading"
            :data="list"
            row-key="id"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column
              label="菜单标题"
              min-width="150"
              prop="title"
              show-overflow-tooltip
            />
            <el-table-column
              label="类型"
              min-width="100"
              prop="type_text"
              show-overflow-tooltip
            />
            <el-table-column
              label="组件名称"
              min-width="100"
              prop="name"
              show-overflow-tooltip
            />
            <el-table-column
              label="图标"
              min-width="80"
              prop="icon"
              show-overflow-tooltip
            >
              <template #default="{ row }">
                <span v-if="row.icon">
                  <vab-icon v-if="row.icon" :icon="row.icon" />
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="权限标识"
              min-width="120"
              prop="ability"
              show-overflow-tooltip
            />
            <el-table-column
              label="状态"
              min-width="100"
              prop="avoid"
              show-overflow-tooltip
            >
              <template #default="{ row }">
                <el-tag v-if="row.avoid == 0">{{ row.avoid_text }}</el-tag>
                <el-tag v-else type="danger">{{ row.avoid_text }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              label="排序"
              min-width="80"
              prop="sort"
              show-overflow-tooltip
            />
            <el-table-column
              fixed="right"
              label="操作"
              show-overflow-tooltip
              width="120"
            >
              <template #default="{ row }">
                <el-button
                  v-if="row.type == 1"
                  v-permissions="{ permission: ['addMenu'] }"
                  type="text"
                  @click="handleAddSubmenu(row)"
                >
                  添加
                </el-button>
                <el-button
                  v-permissions="{ permission: ['addMenu'] }"
                  type="text"
                  @click="handleEdit(row)"
                >
                  编辑
                </el-button>
                <el-button
                  v-permissions="{ permission: ['deleteMenu'] }"
                  type="text"
                  @click="handleDelete(row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <edit ref="edit" :list="list" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getSystemMenuList, doDeleteSystemMenu } from '@/api/settingAuthority'
  import Edit from './components/menuEdit'

  export default {
    name: 'MenuManagement',
    components: { Edit },
    data() {
      return {
        list: [],
        listLoading: true,
        isExpandAll: false, // 全部展开
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.listLoading = true
        const { data } = await getSystemMenuList()
        this.list = data
        this.listLoading = false
      },
      handleEdit(row) {
        if (row) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleAddSubmenu(row) {
        row.parents.push(row.id)
        this.$refs['edit'].showEdit({ parents: row.parents })
      },
      // 点击切换
      toggle() {
        this.isExpandAll = !this.isExpandAll
        this.toggleExpand(this.list, this.isExpandAll)
      },

      /** 展开/收起方法
       * @param {data} array 绑定table中的data
       * @param {isExpand} boolean 是否展开
       */
      toggleExpand(data, isExpand) {
        data.forEach((item) => {
          this.$refs['tableTree'].toggleRowExpansion(item, isExpand)
          if (item.children != null) {
            this.toggleExpand(item.children, isExpand)
          }
        })
      },
      handleDelete(row) {
        if (row.path) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await doDeleteSystemMenu({ id: row.id })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            this.fetchData()
          })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.menu-management';
  #{$base}-container {
    padding: 0 !important;
    background: $base-color-background !important;
  }
  .menu-title {
    margin-left: 5px;
  }
</style>
