import { render, staticRenderFns } from "./articleEdit.vue?vue&type=template&id=ff09a06a&scoped=true"
import script from "./articleEdit.vue?vue&type=script&lang=js"
export * from "./articleEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff09a06a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\projects\\admin-hty\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ff09a06a')) {
      api.createRecord('ff09a06a', component.options)
    } else {
      api.reload('ff09a06a', component.options)
    }
    module.hot.accept("./articleEdit.vue?vue&type=template&id=ff09a06a&scoped=true", function () {
      api.rerender('ff09a06a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/more/library/components/articleEdit.vue"
export default component.exports