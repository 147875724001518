<template>
  <div class="set">
    <el-form ref="form" label-width="80px" :model="form">
      <el-form-item label="二维码" prop="img">
        <div class="img-uploader">
          <div class="el-upload el-upload__text" @click="imgDialog">
            <img v-if="form.img" class="img" :src="form.img" />
            <i v-else class="el-icon-plus"></i>
          </div>
          <div slot="tip" class="el-upload__tip">
            <div>
              只能上传
              <span>.jpg、.png</span>
              文件，且
              <span>不超过1M</span>
            </div>
            <div>(图片尺寸1:1)</div>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <gallery ref="gallery" @checkedImg="getImg" />
  </div>
</template>

<script>
  import { setReport } from '@/api/psyeval'
  import Gallery from '@/components/gallery/index.vue'

  export default {
    name: 'Record',
    components: { Gallery },
    data() {
      return {
        form: {
          img: '',
        },
      }
    },
    created() {
      this.getWxCode()
    },
    methods: {
      imgDialog() {
        this.$refs['gallery'].showEdit()
      },
      getImg(val) {
        this.form.img = val.url
        const { data } = setReport({ img: this.form.img, type: 'edit' })
        this.$baseMessage(data.msg, data.code === 200 ? 'success' : 'error')
      },
      getWxCode() {
        setReport({ type: 'info' }).then((res) => {
          this.form.img = res.data.img || ''
        })
      },
    },
  }
</script>

<style scoped lang="scss"></style>
