<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="增加人数" prop="num">
        <el-input-number
          v-model="form.num"
          controls-position="right"
          :max="99999999"
          :min="0"
          style="width: 100%"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { editStudioAddnum } from '@/api/platformChannelManagement'
  import { isNumber } from '@/utils/validate'

  export default {
    name: 'PlatformChannelManagementAddnum',
    data() {
      const validateTrench = (rule, value, callback) => {
        const reg = /^[a-zA-Z]+$/
        if (!reg.test(value)) {
          callback(new Error('请输入英文字母'))
        } else callback()
      }
      return {
        form: {
          id: '',
          num: '',
        },
        rules: {
          num: [
            {
              required: true,
              trigger: 'blur',
              message: '请输入需要增加的人数',
            },
          ],
        },
        title: '',
        dialogFormVisible: false,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
          },
        },
      }
    },
    created() {},
    methods: {
      showEdit(row) {
        this.title = '增加人数'
        this.form.id = row.id
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg, code } = await editStudioAddnum(this.form)
            if (code === 200) {
              this.$baseMessage(msg, 'success')
              this.$emit('fetch-data')
              this.close()
            } else {
              this.$baseMessage(msg, 'error')
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
